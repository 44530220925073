export interface NPC {
    name: string;
    size: number;
}

export const npcIdMap: Record<number, NPC> = {
    0: { name: "Tool Leprechaun", size: 1 },
    1: { name: "Molanisk", size: 1 },
    2: { name: "Aberrant spectre", size: 2 },
    3: { name: "Aberrant spectre", size: 2 },
    4: { name: "Aberrant spectre", size: 2 },
    5: { name: "Aberrant spectre", size: 2 },
    6: { name: "Aberrant spectre", size: 2 },
    7: { name: "Aberrant spectre", size: 2 },
    8: { name: "Nechryael", size: 1 },
    9: { name: "Twig", size: 1 },
    10: { name: "Death spawn", size: 1 },
    11: { name: "Nechryael", size: 1 },
    12: { name: "Hudo", size: 1 },
    13: { name: "Piles", size: 1 },
    14: { name: "Rometti", size: 1 },
    15: { name: "Gulluck", size: 1 },
    16: { name: "Heckel Funch", size: 1 },
    17: { name: "Rug Merchant", size: 1 },
    18: { name: "Rug Merchant", size: 1 },
    19: { name: "Rug Merchant", size: 1 },
    20: { name: "Rug Merchant", size: 1 },
    21: { name: "null", size: 1 },
    22: { name: "Rug Merchant", size: 1 },
    23: { name: "Monkey", size: 1 },
    24: { name: "null", size: 1 },
    25: { name: "null", size: 1 },
    26: { name: "Zombie", size: 1 },
    27: { name: "Zombie", size: 1 },
    28: { name: "Zombie", size: 1 },
    29: { name: "Zombie", size: 1 },
    30: { name: "Zombie", size: 1 },
    31: { name: "Zombie", size: 1 },
    32: { name: "Zombie", size: 1 },
    33: { name: "Zombie", size: 1 },
    34: { name: "Zombie", size: 1 },
    35: { name: "Zombie", size: 1 },
    36: { name: "Zombie", size: 1 },
    37: { name: "Zombie", size: 1 },
    38: { name: "Zombie", size: 1 },
    39: { name: "Zombie", size: 1 },
    40: { name: "Zombie", size: 1 },
    41: { name: "Zombie", size: 1 },
    42: { name: "Zombie", size: 1 },
    43: { name: "Zombie", size: 1 },
    44: { name: "Zombie", size: 1 },
    45: { name: "Zombie", size: 1 },
    46: { name: "Zombie", size: 1 },
    47: { name: "Zombie", size: 1 },
    48: { name: "Zombie", size: 1 },
    49: { name: "Zombie", size: 1 },
    50: { name: "Zombie", size: 1 },
    51: { name: "Zombie", size: 1 },
    52: { name: "Zombie", size: 1 },
    53: { name: "Zombie", size: 1 },
    54: { name: "Zombie", size: 1 },
    55: { name: "Zombie", size: 1 },
    56: { name: "Zombie", size: 1 },
    57: { name: "Zombie", size: 1 },
    58: { name: "Zombie", size: 1 },
    59: { name: "Zombie", size: 1 },
    60: { name: "Zombie", size: 1 },
    61: { name: "Zombie", size: 1 },
    62: { name: "Zombie", size: 1 },
    63: { name: "Zombie", size: 1 },
    64: { name: "Zombie", size: 1 },
    65: { name: "Zombie", size: 1 },
    66: { name: "Zombie", size: 1 },
    67: { name: "Zombie", size: 1 },
    68: { name: "Zombie", size: 1 },
    69: { name: "Summoned Zombie", size: 1 },
    70: { name: "Skeleton", size: 1 },
    71: { name: "Skeleton", size: 1 },
    72: { name: "Skeleton", size: 1 },
    73: { name: "Skeleton", size: 1 },
    74: { name: "Skeleton", size: 1 },
    75: { name: "Skeleton", size: 1 },
    76: { name: "Skeleton", size: 1 },
    77: { name: "Skeleton", size: 1 },
    78: { name: "Skeleton", size: 1 },
    79: { name: "Skeleton", size: 1 },
    80: { name: "Skeleton", size: 1 },
    81: { name: "Skeleton", size: 1 },
    82: { name: "Skeleton", size: 1 },
    83: { name: "Skeleton", size: 1 },
    84: { name: "Skeleton Mage", size: 1 },
    85: { name: "Ghost", size: 1 },
    86: { name: "Ghost", size: 1 },
    87: { name: "Ghost", size: 1 },
    88: { name: "Ghost", size: 1 },
    89: { name: "Ghost", size: 1 },
    90: { name: "Ghost", size: 1 },
    91: { name: "Ghost", size: 1 },
    92: { name: "Ghost", size: 1 },
    93: { name: "Ghost", size: 1 },
    94: { name: "Ghost", size: 1 },
    95: { name: "Ghost", size: 1 },
    96: { name: "Ghost", size: 1 },
    97: { name: "Ghost", size: 1 },
    98: { name: "Ghost", size: 1 },
    99: { name: "Ghost", size: 1 },
    100: { name: "Rock Crab", size: 1 },
    101: { name: "Rocks", size: 1 },
    102: { name: "Rock Crab", size: 1 },
    103: { name: "Rocks", size: 1 },
    104: { name: "Hellhound", size: 2 },
    105: { name: "Hellhound", size: 2 },
    106: { name: "Wolf", size: 2 },
    107: { name: "White wolf", size: 2 },
    108: { name: "White wolf", size: 2 },
    109: { name: "Big Wolf", size: 2 },
    110: { name: "Wolf", size: 2 },
    111: { name: "Dog", size: 1 },
    112: { name: "Wild dog", size: 1 },
    113: { name: "Wild dog", size: 1 },
    114: { name: "Guard dog", size: 1 },
    115: { name: "Big Wolf", size: 2 },
    116: { name: "Wolf", size: 2 },
    117: { name: "Wolf", size: 2 },
    118: { name: "Ignatius Vulcan", size: 1 },
    119: { name: "null", size: 1 },
    120: { name: "Crawling hand", size: 1 },
    121: { name: "Cockatrice", size: 1 },
    122: { name: "Basilisk", size: 1 },
    123: { name: "Kurask", size: 1 },
    124: { name: "Abyssal demon", size: 1 },
    125: { name: "Left head", size: 1 },
    126: { name: "Middle head", size: 1 },
    127: { name: "Right head", size: 1 },
    128: { name: "Kalphite Queen", size: 1 },
    129: { name: "Tentacle", size: 1 },
    130: { name: "Skeleton", size: 1 },
    131: { name: "Guard dog", size: 1 },
    132: { name: "Hobgoblin", size: 1 },
    133: { name: "Troll", size: 1 },
    134: { name: "Huge spider", size: 2 },
    135: { name: "Hellhound", size: 2 },
    136: { name: "Ogre", size: 2 },
    137: { name: "Baby red dragon", size: 2 },
    138: { name: "Kalphite Soldier", size: 3 },
    139: { name: "Steel dragon", size: 3 },
    140: { name: "Dagannoth", size: 3 },
    141: { name: "Tok-Xil", size: 3 },
    142: { name: "Demon", size: 2 },
    143: { name: "Rocnar", size: 2 },
    144: { name: "Hangman game", size: 2 },
    145: { name: "Hangman game", size: 2 },
    146: { name: "Hangman game", size: 2 },
    147: { name: "Hangman game", size: 2 },
    148: { name: "Hangman game", size: 2 },
    149: { name: "Hangman game", size: 2 },
    150: { name: "Hangman game", size: 2 },
    151: { name: "Hangman game", size: 2 },
    152: { name: "Hangman game", size: 2 },
    153: { name: "Hangman game", size: 2 },
    154: { name: "Treasure fairy", size: 1 },
    155: { name: "Jacky Jester", size: 1 },
    156: { name: "Combat stone", size: 1 },
    157: { name: "Combat stone", size: 1 },
    158: { name: "Combat stone", size: 1 },
    159: { name: "Combat stone", size: 1 },
    160: { name: "Combat stone", size: 1 },
    161: { name: "Combat stone", size: 1 },
    162: { name: "Combat stone", size: 1 },
    163: { name: "Combat stone", size: 1 },
    164: { name: "Combat stone", size: 1 },
    165: { name: "Combat stone", size: 1 },
    166: { name: "Combat stone", size: 1 },
    167: { name: "Combat stone", size: 1 },
    168: { name: "Combat stone", size: 1 },
    169: { name: "Combat stone", size: 1 },
    170: { name: "Combat stone", size: 1 },
    171: { name: "Combat stone", size: 1 },
    172: { name: "Combat stone", size: 1 },
    173: { name: "Combat stone", size: 1 },
    174: { name: "Combat stone", size: 1 },
    175: { name: "Combat stone", size: 1 },
    176: { name: "Combat stone", size: 1 },
    177: { name: "Combat stone", size: 1 },
    178: { name: "Combat stone", size: 1 },
    179: { name: "Combat stone", size: 1 },
    180: { name: "Combat stone", size: 1 },
    181: { name: "Combat stone", size: 1 },
    182: { name: "Combat stone", size: 1 },
    183: { name: "Combat stone", size: 1 },
    184: { name: "Combat stone", size: 1 },
    185: { name: "Combat stone", size: 1 },
    186: { name: "Combat stone", size: 1 },
    187: { name: "Combat stone", size: 1 },
    188: { name: "Combat stone", size: 1 },
    189: { name: "Combat stone", size: 1 },
    190: { name: "Combat stone", size: 1 },
    191: { name: "Combat stone", size: 1 },
    192: { name: "Combat stone", size: 1 },
    193: { name: "Combat stone", size: 1 },
    194: { name: "Combat stone", size: 1 },
    195: { name: "Combat stone", size: 1 },
    196: { name: "Combat stone", size: 1 },
    197: { name: "Combat stone", size: 1 },
    198: { name: "Combat stone", size: 1 },
    199: { name: "Combat stone", size: 1 },
    200: { name: "Combat stone", size: 1 },
    201: { name: "Combat stone", size: 1 },
    202: { name: "Combat stone", size: 1 },
    203: { name: "Combat stone", size: 1 },
    204: { name: "Combat stone", size: 1 },
    205: { name: "Combat stone", size: 1 },
    206: { name: "Combat stone", size: 1 },
    207: { name: "Combat stone", size: 1 },
    208: { name: "Combat stone", size: 1 },
    209: { name: "Combat stone", size: 1 },
    210: { name: "Combat stone", size: 1 },
    211: { name: "Combat stone", size: 1 },
    212: { name: "Combat stone", size: 1 },
    213: { name: "Combat stone", size: 1 },
    214: { name: "Combat stone", size: 1 },
    215: { name: "Combat stone", size: 1 },
    216: { name: "Combat stone", size: 1 },
    217: { name: "Combat stone", size: 1 },
    218: { name: "Combat stone", size: 1 },
    219: { name: "Combat stone", size: 1 },
    220: { name: "Combat stone", size: 1 },
    221: { name: "Rick", size: 1 },
    222: { name: "null", size: 1 },
    223: { name: "Maid", size: 1 },
    224: { name: "null", size: 1 },
    225: { name: "Cook", size: 1 },
    226: { name: "null", size: 1 },
    227: { name: "Butler", size: 1 },
    228: { name: "null", size: 1 },
    229: { name: "Demon butler", size: 1 },
    230: { name: "null", size: 1 },
    231: { name: "Wolf", size: 2 },
    232: { name: "Jungle Wolf", size: 2 },
    233: { name: "Macaroni Penguin", size: 1 },
    234: { name: "Butterfly", size: 1 },
    235: { name: "Butterfly", size: 1 },
    236: { name: "Butterfly", size: 1 },
    237: { name: "Butterfly", size: 1 },
    238: { name: "Butterfly", size: 1 },
    239: { name: "King Black Dragon", size: 5 },
    240: { name: "Black demon", size: 3 },
    241: { name: "Baby blue dragon", size: 2 },
    242: { name: "Baby blue dragon", size: 2 },
    243: { name: "Baby blue dragon", size: 2 },
    244: { name: "Baby red dragon", size: 2 },
    245: { name: "Baby red dragon", size: 2 },
    246: { name: "Baby red dragon", size: 2 },
    247: { name: "Red dragon", size: 4 },
    248: { name: "Red dragon", size: 4 },
    249: { name: "Red dragon", size: 4 },
    250: { name: "Red dragon", size: 4 },
    251: { name: "Red dragon", size: 4 },
    252: { name: "Black dragon", size: 4 },
    253: { name: "Black dragon", size: 4 },
    254: { name: "Black dragon", size: 4 },
    255: { name: "Black dragon", size: 4 },
    256: { name: "Black dragon", size: 4 },
    257: { name: "Black dragon", size: 4 },
    258: { name: "Black dragon", size: 4 },
    259: { name: "Black dragon", size: 4 },
    260: { name: "Green dragon", size: 4 },
    261: { name: "Green dragon", size: 4 },
    262: { name: "Green dragon", size: 4 },
    263: { name: "Green dragon", size: 4 },
    264: { name: "Green dragon", size: 4 },
    265: { name: "Blue dragon", size: 4 },
    266: { name: "Blue dragon", size: 4 },
    267: { name: "Blue dragon", size: 4 },
    268: { name: "Blue dragon", size: 4 },
    269: { name: "Blue dragon", size: 4 },
    270: { name: "Bronze dragon", size: 4 },
    271: { name: "Bronze dragon", size: 4 },
    272: { name: "Iron dragon", size: 4 },
    273: { name: "Iron dragon", size: 4 },
    274: { name: "Steel dragon", size: 4 },
    275: { name: "Steel dragon", size: 4 },
    276: { name: "Town Crier", size: 1 },
    277: { name: "Town Crier", size: 1 },
    278: { name: "Town Crier", size: 1 },
    279: { name: "Town Crier", size: 1 },
    280: { name: "Town Crier", size: 1 },
    281: { name: "Gull", size: 2 },
    282: { name: "Cormorant", size: 2 },
    283: { name: "Pelican", size: 2 },
    284: { name: "Gull", size: 2 },
    285: { name: "Gull", size: 2 },
    286: { name: "Gull", size: 2 },
    287: { name: "Cormorant", size: 2 },
    288: { name: "Pelican", size: 2 },
    289: { name: "Ghoul", size: 1 },
    290: { name: "Dwarf", size: 1 },
    291: { name: "Chaos dwarf", size: 1 },
    292: { name: "Dwarf", size: 1 },
    293: { name: "Adventurer Jon", size: 1 },
    294: { name: "Dwarf", size: 1 },
    295: { name: "Dwarf", size: 1 },
    296: { name: "Dwarf", size: 1 },
    297: { name: "Thordur", size: 1 },
    298: { name: "Thordur", size: 1 },
    299: { name: "Gunthor the brave", size: 1 },
    300: { name: "Jailer", size: 1 },
    301: { name: "Black Heather", size: 1 },
    302: { name: "Donny the lad", size: 1 },
    303: { name: "Speedy Keith", size: 1 },
    304: { name: "Salarin the twisted", size: 1 },
    305: { name: "Jennifer", size: 1 },
    306: { name: "Lumbridge Guide", size: 1 },
    307: { name: "Dr Jekyll", size: 1 },
    308: { name: "Emblem Trader", size: 1 },
    309: { name: "Reacher", size: 1 },
    310: { name: "Ayesha", size: 1 },
    311: { name: "Ironman tutor", size: 1 },
    312: { name: "Frog", size: 1 },
    313: { name: "Reacher", size: 1 },
    314: { name: "Dr Jekyll", size: 1 },
    315: { name: "Leagues Tutor", size: 1 },
    316: { name: "Leagues Tutor", size: 1 },
    317: { name: "null", size: 1 },
    318: { name: "Dark core", size: 1 },
    319: { name: "Corporeal Beast", size: 5 },
    320: { name: "Dark energy core", size: 1 },
    321: { name: "Mime", size: 1 },
    322: { name: "Drunken Dwarf", size: 1 },
    323: { name: "Strange plant", size: 1 },
    324: { name: "null", size: 3 },
    326: { name: "Genie", size: 1 },
    327: { name: "Genie", size: 1 },
    328: { name: "Reacher", size: 1 },
    329: { name: "Reacher", size: 1 },
    330: { name: "Swarm", size: 1 },
    331: { name: "Reacher", size: 1 },
    332: { name: "Strange watcher", size: 1 },
    333: { name: "Strange watcher", size: 1 },
    334: { name: "Strange watcher", size: 1 },
    335: { name: "Reacher", size: 1 },
    336: { name: "Reacher", size: 1 },
    337: { name: "Sergeant Damien", size: 1 },
    338: { name: "Suspect", size: 1 },
    339: { name: "Suspect", size: 1 },
    340: { name: "Suspect", size: 1 },
    341: { name: "Suspect", size: 1 },
    342: { name: "Molly", size: 1 },
    343: { name: "Suspect", size: 1 },
    344: { name: "Suspect", size: 1 },
    345: { name: "Suspect", size: 1 },
    346: { name: "Suspect", size: 1 },
    347: { name: "Suspect", size: 1 },
    348: { name: "Suspect", size: 1 },
    349: { name: "Suspect", size: 1 },
    350: { name: "Suspect", size: 1 },
    351: { name: "Suspect", size: 1 },
    352: { name: "Molly", size: 1 },
    353: { name: "Suspect", size: 1 },
    354: { name: "Suspect", size: 1 },
    355: { name: "Suspect", size: 1 },
    356: { name: "Molly", size: 1 },
    357: { name: "Suspect", size: 1 },
    358: { name: "Suspect", size: 1 },
    359: { name: "Suspect", size: 1 },
    360: { name: "Suspect", size: 1 },
    361: { name: "Molly", size: 1 },
    362: { name: "Molly", size: 1 },
    363: { name: "Molly", size: 1 },
    364: { name: "Molly", size: 1 },
    365: { name: "Molly", size: 1 },
    366: { name: "Molly", size: 1 },
    367: { name: "Molly", size: 1 },
    368: { name: "Prison Pete", size: 1 },
    369: { name: "Balloon Animal", size: 2 },
    370: { name: "Balloon Animal", size: 2 },
    371: { name: "Balloon Animal", size: 2 },
    372: { name: "Freaky Forester", size: 1 },
    373: { name: "Pheasant", size: 1 },
    374: { name: "Pheasant", size: 1 },
    375: { name: "Rick Turpentine", size: 1 },
    376: { name: "Rick Turpentine", size: 1 },
    377: { name: "Phosani's Nightmare", size: 5 },
    378: { name: "The Nightmare", size: 5 },
    379: { name: "Quiz Master", size: 1 },
    380: { name: "Pillory Guard", size: 1 },
    381: { name: "Tramp", size: 1 },
    382: { name: "Tramp", size: 1 },
    383: { name: "Tramp", size: 1 },
    384: { name: "null", size: 1 },
    385: { name: "Man", size: 1 },
    386: { name: "Avan", size: 1 },
    387: { name: "Avan", size: 1 },
    388: { name: "Dark Core", size: 1 },
    389: { name: "Reacher", size: 1 },
    390: { name: "Evil Bob", size: 1 },
    391: { name: "Evil Bob", size: 1 },
    392: { name: "Trotters", size: 1 },
    393: { name: "Servant", size: 1 },
    394: { name: "Rod Fishing spot", size: 1 },
    395: { name: "Cat", size: 1 },
    396: { name: "Jon", size: 1 },
    397: { name: "Guard", size: 1 },
    398: { name: "Guard", size: 1 },
    399: { name: "Guard", size: 1 },
    400: { name: "Guard", size: 1 },
    401: { name: "null", size: 1 },
    402: { name: "null", size: 1 },
    403: { name: "Vannaka", size: 1 },
    404: { name: "Chaeldar", size: 1 },
    405: { name: "null", size: 1 },
    406: { name: "Cave crawler", size: 2 },
    407: { name: "Cave crawler", size: 2 },
    408: { name: "Cave crawler", size: 2 },
    409: { name: "Cave crawler", size: 2 },
    410: { name: "Kurask", size: 3 },
    411: { name: "Kurask", size: 3 },
    412: { name: "Gargoyle", size: 3 },
    413: { name: "Gargoyle", size: 3 },
    414: { name: "Banshee", size: 2 },
    415: { name: "Abyssal demon", size: 1 },
    416: { name: "Abyssal demon", size: 1 },
    417: { name: "Basilisk", size: 2 },
    418: { name: "Basilisk", size: 2 },
    419: { name: "Cockatrice", size: 2 },
    420: { name: "Cockatrice", size: 3 },
    421: { name: "Rockslug", size: 1 },
    422: { name: "Rockslug", size: 1 },
    423: { name: "Dust devil", size: 1 },
    424: { name: "null", size: 1 },
    425: { name: "Skotos", size: 1 },
    426: { name: "Turoth", size: 1 },
    427: { name: "Turoth", size: 2 },
    428: { name: "Turoth", size: 2 },
    429: { name: "Turoth", size: 1 },
    430: { name: "Turoth", size: 1 },
    431: { name: "Turoth", size: 2 },
    432: { name: "Turoth", size: 2 },
    433: { name: "Pyrefiend", size: 1 },
    434: { name: "Pyrefiend", size: 1 },
    435: { name: "Pyrefiend", size: 1 },
    436: { name: "Pyrefiend", size: 1 },
    437: { name: "Jelly", size: 1 },
    438: { name: "Jelly", size: 1 },
    439: { name: "Jelly", size: 1 },
    440: { name: "Jelly", size: 1 },
    441: { name: "Jelly", size: 1 },
    442: { name: "Jelly", size: 1 },
    443: { name: "Infernal Mage", size: 1 },
    444: { name: "Infernal Mage", size: 1 },
    445: { name: "Infernal Mage", size: 1 },
    446: { name: "Infernal Mage", size: 1 },
    447: { name: "Infernal Mage", size: 1 },
    448: { name: "Crawling Hand", size: 1 },
    449: { name: "Crawling Hand", size: 1 },
    450: { name: "Crawling Hand", size: 1 },
    451: { name: "Crawling Hand", size: 1 },
    452: { name: "Crawling Hand", size: 1 },
    453: { name: "Crawling Hand", size: 2 },
    454: { name: "Crawling Hand", size: 2 },
    455: { name: "Crawling Hand", size: 2 },
    456: { name: "Crawling Hand", size: 2 },
    457: { name: "Crawling Hand", size: 2 },
    458: { name: "Lizard", size: 3 },
    459: { name: "Desert Lizard", size: 2 },
    460: { name: "Desert Lizard", size: 2 },
    461: { name: "Desert Lizard", size: 2 },
    462: { name: "Small Lizard", size: 1 },
    463: { name: "Small Lizard", size: 1 },
    464: { name: "Harpie Bug Swarm", size: 1 },
    465: { name: "Skeletal Wyvern", size: 3 },
    466: { name: "Skeletal Wyvern", size: 3 },
    467: { name: "Skeletal Wyvern", size: 3 },
    468: { name: "Skeletal Wyvern", size: 3 },
    469: { name: "Killerwatt", size: 1 },
    470: { name: "Killerwatt", size: 1 },
    471: { name: "null", size: 1 },
    472: { name: "Ghost", size: 1 },
    473: { name: "Ghost", size: 1 },
    474: { name: "Ghost", size: 1 },
    475: { name: "Hole in the wall", size: 1 },
    476: { name: "Wall beast", size: 1 },
    477: { name: "Giant frog", size: 2 },
    478: { name: "Big frog", size: 1 },
    479: { name: "Frog", size: 1 },
    480: { name: "Cave slime", size: 1 },
    481: { name: "Cave bug", size: 1 },
    482: { name: "Cave bug larva", size: 1 },
    483: { name: "Cave bug", size: 2 },
    484: { name: "Bloodveld", size: 2 },
    485: { name: "Bloodveld", size: 2 },
    486: { name: "Bloodveld", size: 2 },
    487: { name: "Bloodveld", size: 2 },
    488: { name: "<col=00ffff>Storm Cloud</col>", size: 3 },
    489: { name: "<col=00ffff>Storm Cloud</col>", size: 1 },
    490: { name: "null", size: 1 },
    491: { name: "Entomologist", size: 1 },
    492: { name: "Cave kraken", size: 2 },
    493: { name: "Whirlpool", size: 2 },
    494: { name: "Kraken", size: 4 },
    495: { name: "Venenatis spiderling", size: 1 },
    496: { name: "Whirlpool", size: 5 },
    497: { name: "Callisto cub", size: 1 },
    498: { name: "Smoke devil", size: 1 },
    499: { name: "Thermonuclear smoke devil", size: 4 },
    500: { name: "Olivia", size: 1 },
    501: { name: "Sarah", size: 1 },
    502: { name: "Vanessa", size: 1 },
    503: { name: "Richard", size: 1 },
    504: { name: "Alice", size: 1 },
    505: { name: "Ghost", size: 1 },
    506: { name: "Ghost", size: 1 },
    507: { name: "Ghost", size: 1 },
    508: { name: "Soulless", size: 1 },
    509: { name: "Death wing", size: 2 },
    510: { name: "Dark wizard", size: 1 },
    511: { name: "Invrigar the Necromancer", size: 1 },
    512: { name: "Dark wizard", size: 1 },
    513: { name: "Mugger", size: 1 },
    514: { name: "Witch", size: 1 },
    515: { name: "Witch", size: 1 },
    516: { name: "Black Knight", size: 1 },
    517: { name: "Black Knight", size: 1 },
    518: { name: "Highwayman", size: 1 },
    519: { name: "Highwayman", size: 1 },
    520: { name: "Chaos druid", size: 1 },
    521: { name: "Pirate", size: 1 },
    522: { name: "Pirate", size: 1 },
    523: { name: "Pirate", size: 1 },
    524: { name: "Pirate", size: 1 },
    525: { name: "Thug", size: 1 },
    526: { name: "Rogue", size: 1 },
    527: { name: "Monk of Zamorak", size: 1 },
    528: { name: "Monk of Zamorak", size: 1 },
    529: { name: "Monk of Zamorak", size: 1 },
    530: { name: "Tribesman", size: 1 },
    531: { name: "Dark warrior", size: 1 },
    532: { name: "Chaos druid warrior", size: 1 },
    533: { name: "Fungi", size: 1 },
    534: { name: "null", size: 1 },
    535: { name: "Fungi", size: 1 },
    536: { name: "null", size: 1 },
    537: { name: "Zygomite", size: 1 },
    538: { name: "Fungi", size: 1 },
    539: { name: "Elfinlocks", size: 1 },
    540: { name: "Clockwork cat", size: 1 },
    541: { name: "Clockwork cat", size: 1 },
    542: { name: "Monk", size: 1 },
    543: { name: "Monk", size: 1 },
    544: { name: "Monk", size: 1 },
    545: { name: "Monk", size: 1 },
    546: { name: "Rufus", size: 1 },
    547: { name: "Mi-Gor", size: 1 },
    548: { name: "Puffin", size: 1 },
    549: { name: "Puffin", size: 1 },
    550: { name: "Brother Tranquility", size: 1 },
    551: { name: "Brother Tranquility", size: 1 },
    552: { name: "Brother Tranquility", size: 1 },
    553: { name: "Monk", size: 1 },
    554: { name: "Monk", size: 1 },
    555: { name: "Monk", size: 1 },
    556: { name: "Monk", size: 1 },
    557: { name: "Zombie monk", size: 1 },
    558: { name: "Zombie monk", size: 1 },
    559: { name: "Zombie monk", size: 1 },
    560: { name: "Zombie monk", size: 1 },
    561: { name: "Sorebones", size: 1 },
    562: { name: "Sorebones", size: 1 },
    563: { name: "Zombie pirate", size: 1 },
    564: { name: "Zombie pirate", size: 1 },
    565: { name: "Zombie pirate", size: 1 },
    566: { name: "Zombie pirate", size: 1 },
    567: { name: "Zombie pirate", size: 1 },
    568: { name: "Zombie pirate", size: 1 },
    569: { name: "Zombie pirate", size: 1 },
    570: { name: "Zombie pirate", size: 1 },
    571: { name: "Zombie pirate", size: 1 },
    572: { name: "Zombie pirate", size: 1 },
    573: { name: "Zombie pirate", size: 1 },
    574: { name: "Zombie pirate", size: 1 },
    575: { name: "Zombie pirate", size: 1 },
    576: { name: "Zombie pirate", size: 1 },
    577: { name: "Zombie pirate", size: 1 },
    578: { name: "Zombie pirate", size: 1 },
    579: { name: "Zombie pirate", size: 1 },
    580: { name: "Zombie pirate", size: 1 },
    581: { name: "Zombie pirate", size: 1 },
    582: { name: "Zombie pirate", size: 1 },
    583: { name: "Zombie pirate", size: 1 },
    584: { name: "Zombie pirate", size: 1 },
    585: { name: "Zombie pirate", size: 1 },
    586: { name: "Zombie pirate", size: 1 },
    587: { name: "Zombie pirate", size: 1 },
    588: { name: "Zombie pirate", size: 1 },
    589: { name: "Zombie pirate", size: 1 },
    590: { name: "Zombie pirate", size: 1 },
    591: { name: "Zombie pirate", size: 1 },
    592: { name: "Zombie pirate", size: 1 },
    593: { name: "Zombie pirate", size: 1 },
    594: { name: "Zombie pirate", size: 1 },
    595: { name: "Zombie pirate", size: 1 },
    596: { name: "Zombie pirate", size: 1 },
    597: { name: "Zombie pirate", size: 1 },
    598: { name: "Zombie pirate", size: 1 },
    599: { name: "Zombie pirate", size: 1 },
    600: { name: "Barrelchest", size: 3 },
    601: { name: "Pirate Pete", size: 1 },
    602: { name: "Pirate Pete", size: 1 },
    603: { name: "Captain Braindeath", size: 1 },
    604: { name: "50% Luke", size: 1 },
    605: { name: "Davey", size: 1 },
    606: { name: "Captain Donnie", size: 1 },
    607: { name: "Zombie protester", size: 1 },
    608: { name: "Zombie protester", size: 1 },
    609: { name: "Zombie protester", size: 1 },
    610: { name: "Zombie protester", size: 1 },
    611: { name: "Zombie protester", size: 1 },
    612: { name: "Zombie protester", size: 1 },
    613: { name: "Zombie pirate", size: 1 },
    614: { name: "Zombie pirate", size: 1 },
    615: { name: "Zombie pirate", size: 1 },
    616: { name: "Zombie pirate", size: 1 },
    617: { name: "Zombie pirate", size: 1 },
    618: { name: "Zombie pirate", size: 1 },
    619: { name: "Zombie swab", size: 1 },
    620: { name: "Zombie swab", size: 1 },
    621: { name: "Zombie swab", size: 1 },
    622: { name: "Zombie swab", size: 1 },
    623: { name: "Zombie swab", size: 1 },
    624: { name: "Zombie swab", size: 1 },
    625: { name: "Evil spirit", size: 2 },
    626: { name: "Fever spider", size: 2 },
    627: { name: "Brewer", size: 1 },
    628: { name: "Brewer", size: 1 },
    629: { name: "Brewer", size: 1 },
    630: { name: "Brewer", size: 1 },
    631: { name: "Brewer", size: 1 },
    632: { name: "Brewer", size: 1 },
    633: { name: "Brewer", size: 1 },
    634: { name: "Brewer", size: 1 },
    635: { name: "Fishing spot", size: 1 },
    636: { name: "Karamthulhu", size: 1 },
    637: { name: "Fungi", size: 1 },
    638: { name: "null", size: 1 },
    639: { name: "Tyras guard", size: 1 },
    640: { name: "Ug", size: 1 },
    641: { name: "Aga", size: 1 },
    642: { name: "Arrg", size: 2 },
    643: { name: "Arrg", size: 2 },
    644: { name: "Ug", size: 1 },
    645: { name: "Ice wolf", size: 2 },
    646: { name: "Ice wolf", size: 2 },
    647: { name: "Ice wolf", size: 2 },
    648: { name: "Ice Troll", size: 1 },
    649: { name: "Ice Troll", size: 1 },
    650: { name: "Ice Troll", size: 1 },
    651: { name: "Ice Troll", size: 1 },
    652: { name: "Ice Troll", size: 1 },
    653: { name: "Ice Troll", size: 1 },
    654: { name: "Ice Troll", size: 1 },
    655: { name: "Goblin", size: 1 },
    656: { name: "Goblin", size: 1 },
    657: { name: "Goblin", size: 1 },
    658: { name: "Goblin", size: 1 },
    659: { name: "Goblin", size: 1 },
    660: { name: "Goblin", size: 1 },
    661: { name: "Goblin", size: 1 },
    662: { name: "Goblin", size: 1 },
    663: { name: "Goblin", size: 1 },
    664: { name: "Goblin", size: 1 },
    665: { name: "Goblin", size: 1 },
    666: { name: "Goblin", size: 1 },
    667: { name: "Goblin", size: 1 },
    668: { name: "Goblin", size: 1 },
    669: { name: "General Bentnoze", size: 1 },
    670: { name: "General Wartface", size: 1 },
    671: { name: "Grubfoot", size: 1 },
    672: { name: "Grubfoot", size: 1 },
    673: { name: "Grubfoot", size: 1 },
    674: { name: "Goblin", size: 1 },
    675: { name: "General Bentnoze", size: 1 },
    676: { name: "General Wartface", size: 1 },
    677: { name: "Goblin", size: 1 },
    678: { name: "Goblin", size: 1 },
    679: { name: "Rasolo", size: 1 },
    680: { name: "Giant skeleton", size: 1 },
    681: { name: "Giant skeleton", size: 1 },
    682: { name: "Damis", size: 1 },
    683: { name: "Damis", size: 1 },
    684: { name: "null", size: 1 },
    685: { name: "Stranger", size: 1 },
    686: { name: "Malak", size: 1 },
    687: { name: "Bartender", size: 1 },
    688: { name: "Eblis", size: 1 },
    689: { name: "Eblis", size: 1 },
    690: { name: "Bandit", size: 1 },
    691: { name: "Bandit", size: 1 },
    692: { name: "Bandit", size: 1 },
    693: { name: "Bandit", size: 1 },
    694: { name: "Bandit", size: 1 },
    695: { name: "Bandit", size: 1 },
    696: { name: "Troll child", size: 1 },
    697: { name: "Troll child", size: 1 },
    698: { name: "Ice troll", size: 1 },
    699: { name: "Ice troll", size: 1 },
    700: { name: "Ice troll", size: 1 },
    701: { name: "Ice troll", size: 1 },
    702: { name: "Ice troll", size: 1 },
    703: { name: "Ice troll", size: 1 },
    704: { name: "Ice troll", size: 1 },
    705: { name: "Ice troll", size: 1 },
    706: { name: "Ice block", size: 2 },
    707: { name: "Ice block", size: 2 },
    708: { name: "Troll father", size: 1 },
    709: { name: "Troll mother", size: 1 },
    710: { name: "Ice wolf", size: 2 },
    711: { name: "Ice wolf", size: 2 },
    712: { name: "Ice wolf", size: 2 },
    713: { name: "Ice wolf", size: 2 },
    714: { name: "Ice wolf", size: 2 },
    715: { name: "Ice wolf", size: 2 },
    716: { name: "null", size: 1 },
    717: { name: "Mummy", size: 1 },
    718: { name: "Mummy ashes", size: 1 },
    719: { name: "Mummy ashes", size: 1 },
    720: { name: "Mummy", size: 1 },
    721: { name: "Mummy", size: 1 },
    722: { name: "Mummy", size: 1 },
    723: { name: "Mummy", size: 1 },
    724: { name: "Mummy", size: 1 },
    725: { name: "Mummy", size: 1 },
    726: { name: "Mummy", size: 1 },
    727: { name: "Mummy", size: 1 },
    728: { name: "Mummy", size: 1 },
    729: { name: "Scarabs", size: 1 },
    730: { name: "Azzanadra", size: 2 },
    731: { name: "Sheep", size: 1 },
    732: { name: "Fred the Farmer", size: 1 },
    733: { name: "Bandit Leader", size: 1 },
    734: { name: "Bandit", size: 1 },
    735: { name: "Bandit", size: 1 },
    736: { name: "Bandit", size: 1 },
    737: { name: "Bandit", size: 1 },
    738: { name: "Bandit champion", size: 1 },
    739: { name: "Cowardly Bandit", size: 1 },
    740: { name: "null", size: 1 },
    741: { name: "My Arm", size: 1 },
    742: { name: "My Arm", size: 1 },
    743: { name: "null", size: 1 },
    744: { name: "null", size: 1 },
    745: { name: "null", size: 1 },
    746: { name: "null", size: 1 },
    747: { name: "null", size: 1 },
    748: { name: "null", size: 1 },
    749: { name: "null", size: 1 },
    750: { name: "My Arm", size: 1 },
    751: { name: "My Arm", size: 1 },
    752: { name: "My Arm", size: 1 },
    753: { name: "Adventurer", size: 2 },
    754: { name: "Captain Barnaby", size: 1 },
    755: { name: "Murcaily", size: 1 },
    756: { name: "Jagbakoba", size: 1 },
    757: { name: "Tool Leprechaun", size: 1 },
    758: { name: "Flies", size: 1 },
    759: { name: "Unnamed troll child", size: 1 },
    760: { name: "Drunken Dwarf's Leg", size: 1 },
    761: { name: "null", size: 1 },
    762: { name: "Baby Roc", size: 1 },
    763: { name: "Giant Roc", size: 5 },
    764: { name: "Shadow", size: 4 },
    765: { name: "Queen Sigrid", size: 1 },
    766: { name: "Banker", size: 1 },
    767: { name: "Arnor", size: 1 },
    768: { name: "Haming", size: 1 },
    769: { name: "Moldof", size: 1 },
    770: { name: "Helga", size: 1 },
    771: { name: "Matilda", size: 1 },
    772: { name: "Ashild", size: 1 },
    773: { name: "Skraeling", size: 1 },
    774: { name: "Skraeling", size: 1 },
    775: { name: "Fishmonger", size: 1 },
    776: { name: "Greengrocer", size: 1 },
    777: { name: "Ethereal Being", size: 1 },
    778: { name: "Ethereal Being", size: 1 },
    779: { name: "Ethereal Numerator", size: 1 },
    780: { name: "Ethereal Expert", size: 1 },
    781: { name: "Ethereal Perceptive", size: 1 },
    782: { name: "Ethereal Guide", size: 1 },
    783: { name: "Ethereal Fluke", size: 1 },
    784: { name: "Ethereal Mimic", size: 1 },
    785: { name: "Me", size: 1 },
    786: { name: "Me", size: 1 },
    787: { name: "Suqah", size: 2 },
    788: { name: "Suqah", size: 2 },
    789: { name: "Suqah", size: 2 },
    790: { name: "Suqah", size: 2 },
    791: { name: "Suqah", size: 2 },
    792: { name: "Suqah", size: 2 },
    793: { name: "Suqah", size: 2 },
    794: { name: "Scarab Mage", size: 1 },
    795: { name: "Locust rider", size: 2 },
    796: { name: "Locust rider", size: 2 },
    797: { name: "Giant Scarab", size: 3 },
    798: { name: "Giant Scarab", size: 3 },
    799: { name: "Scarab Mage", size: 1 },
    800: { name: "Locust rider", size: 2 },
    801: { name: "Locust rider", size: 2 },
    802: { name: "Olaf the Bard", size: 1 },
    803: { name: "Lalli", size: 1 },
    804: { name: "Golden sheep", size: 1 },
    805: { name: "Golden sheep", size: 1 },
    806: { name: "Golden sheep", size: 1 },
    807: { name: "Golden sheep", size: 1 },
    808: { name: "Fossegrimen", size: 1 },
    809: { name: "Ospak", size: 1 },
    810: { name: "Styrmir", size: 1 },
    811: { name: "Torbrund", size: 1 },
    812: { name: "Fridgeir", size: 1 },
    813: { name: "Longhall Bouncer", size: 1 },
    814: { name: "Guildmaster", size: 1 },
    815: { name: "Duke Horacio", size: 1 },
    816: { name: "null", size: 4 },
    817: { name: "Elvarg", size: 4 },
    818: { name: "null", size: 1 },
    819: { name: "Klarense", size: 1 },
    820: { name: "Wormbrain", size: 1 },
    821: { name: "Oracle", size: 1 },
    822: { name: "Oziach", size: 1 },
    823: { name: "Melzar the Mad", size: 1 },
    824: { name: "Captain Ned", size: 1 },
    825: { name: "Cabin boy Jenkins", size: 1 },
    826: { name: "Cabin boy Jenkins", size: 1 },
    827: { name: "Larry", size: 1 },
    828: { name: "Larry", size: 1 },
    829: { name: "Larry", size: 1 },
    830: { name: "Penguin", size: 1 },
    831: { name: "Penguin", size: 1 },
    832: { name: "Penguin", size: 1 },
    833: { name: "KGP Guard", size: 1 },
    834: { name: "Pescaling Pax", size: 1 },
    835: { name: "Ping", size: 1 },
    836: { name: "Ping", size: 1 },
    837: { name: "Pong", size: 1 },
    838: { name: "Pong", size: 1 },
    839: { name: "Ping", size: 1 },
    840: { name: "Pong", size: 1 },
    841: { name: "KGP Agent", size: 1 },
    842: { name: "KGP Agent", size: 1 },
    843: { name: "null", size: 1 },
    844: { name: "Noodle", size: 1 },
    845: { name: "Penguin", size: 1 },
    846: { name: "Penguin suit", size: 1 },
    847: { name: "Agility Instructor", size: 1 },
    848: { name: "Army Commander", size: 1 },
    849: { name: "Penguin", size: 1 },
    850: { name: "Penguin", size: 1 },
    851: { name: "Penguin", size: 1 },
    852: { name: "Icelord", size: 2 },
    853: { name: "Icelord", size: 2 },
    854: { name: "Icelord", size: 2 },
    855: { name: "Icelord", size: 2 },
    856: { name: "Crusher", size: 1 },
    857: { name: "Crusher", size: 1 },
    858: { name: "Crusher", size: 1 },
    859: { name: "Crusher", size: 1 },
    860: { name: "Grish", size: 2 },
    861: { name: "Uglug Nar", size: 2 },
    862: { name: "Pilg", size: 2 },
    863: { name: "Grug", size: 2 },
    864: { name: "Ogre guard", size: 2 },
    865: { name: "Ogre guard", size: 2 },
    866: { name: "Zogre", size: 2 },
    867: { name: "Zogre", size: 2 },
    868: { name: "Zogre", size: 2 },
    869: { name: "Zogre", size: 2 },
    870: { name: "Zogre", size: 2 },
    871: { name: "Zogre", size: 2 },
    872: { name: "Skogre", size: 2 },
    873: { name: "Zogre", size: 2 },
    874: { name: "Zogre", size: 2 },
    875: { name: "Zogre", size: 2 },
    876: { name: "Zogre", size: 2 },
    877: { name: "Zogre", size: 2 },
    878: { name: "Skogre", size: 2 },
    879: { name: "Skogre", size: 2 },
    880: { name: "Zombie", size: 1 },
    881: { name: "Zavistic Rarve", size: 1 },
    882: { name: "Slash Bash", size: 3 },
    883: { name: "Sithik Ints", size: 1 },
    884: { name: "Sithik Ints", size: 1 },
    885: { name: "Gargh", size: 2 },
    886: { name: "Scarg", size: 2 },
    887: { name: "Gruh", size: 2 },
    888: { name: "Irwin Feaselbaum", size: 1 },
    889: { name: "null", size: 1 },
    890: { name: "null", size: 1 },
    891: { name: "Moss Guardian", size: 2 },
    892: { name: "Golrie", size: 1 },
    893: { name: "Father Reen", size: 1 },
    894: { name: "Father Reen", size: 1 },
    895: { name: "Father Badden", size: 1 },
    896: { name: "Father Badden", size: 1 },
    897: { name: "Denath", size: 1 },
    898: { name: "Denath", size: 1 },
    899: { name: "Eric", size: 1 },
    900: { name: "Eric", size: 1 },
    901: { name: "Evil Dave", size: 1 },
    902: { name: "Evil Dave", size: 1 },
    903: { name: "Matthew", size: 1 },
    904: { name: "Matthew", size: 1 },
    905: { name: "Jennifer", size: 1 },
    906: { name: "Jennifer", size: 1 },
    907: { name: "Tanya", size: 1 },
    908: { name: "Tanya", size: 1 },
    909: { name: "Patrick", size: 1 },
    910: { name: "Patrick", size: 1 },
    911: { name: "Agrith Naar", size: 3 },
    912: { name: "Sand storm", size: 3 },
    913: { name: "null", size: 1 },
    914: { name: "null", size: 1 },
    915: { name: "null", size: 1 },
    916: { name: "null", size: 1 },
    917: { name: "Clay golem", size: 1 },
    918: { name: "Clay golem", size: 1 },
    919: { name: "null", size: 1 },
    920: { name: "Ghost", size: 1 },
    921: { name: "Donie", size: 1 },
    922: { name: "Restless ghost", size: 1 },
    923: { name: "Father Urhney", size: 1 },
    924: { name: "Skeleton", size: 1 },
    925: { name: "Rock", size: 2 },
    926: { name: "Stick", size: 2 },
    927: { name: "Pee Hat", size: 2 },
    928: { name: "Kraka", size: 2 },
    929: { name: "Dung", size: 2 },
    930: { name: "Ash", size: 2 },
    931: { name: "Thrower Troll", size: 1 },
    932: { name: "Thrower Troll", size: 1 },
    933: { name: "Thrower Troll", size: 1 },
    934: { name: "Thrower Troll", size: 1 },
    935: { name: "Thrower Troll", size: 1 },
    936: { name: "Mountain troll", size: 1 },
    937: { name: "Mountain troll", size: 1 },
    938: { name: "Mountain troll", size: 1 },
    939: { name: "Mountain troll", size: 1 },
    940: { name: "Mountain troll", size: 1 },
    941: { name: "Mountain troll", size: 1 },
    942: { name: "Mountain troll", size: 1 },
    943: { name: "Filliman Tarlock", size: 1 },
    944: { name: "Nature Spirit", size: 1 },
    945: { name: "Ghast", size: 2 },
    946: { name: "Ghast", size: 2 },
    947: { name: "Ulizius", size: 1 },
    948: { name: "Klenter", size: 1 },
    949: { name: "Mummy", size: 1 },
    950: { name: "Mummy", size: 1 },
    951: { name: "Mummy", size: 1 },
    952: { name: "Mummy", size: 1 },
    953: { name: "Mummy", size: 1 },
    954: { name: "Weird Old Man", size: 1 },
    955: { name: "Kalphite Worker", size: 2 },
    956: { name: "Kalphite Worker", size: 2 },
    957: { name: "Kalphite Soldier", size: 3 },
    958: { name: "Kalphite Soldier", size: 3 },
    959: { name: "Kalphite Guardian", size: 4 },
    960: { name: "Kalphite Guardian", size: 4 },
    961: { name: "Kalphite Worker", size: 2 },
    962: { name: "Kalphite Guardian", size: 4 },
    963: { name: "Kalphite Queen", size: 5 },
    964: { name: "Hellpuppy", size: 1 },
    965: { name: "Kalphite Queen", size: 5 },
    966: { name: "Kalphite Larva", size: 1 },
    967: { name: "Anna", size: 1 },
    968: { name: "David", size: 1 },
    969: { name: "Anna", size: 1 },
    970: { name: "Dagannoth", size: 1 },
    971: { name: "Dagannoth", size: 1 },
    972: { name: "Dagannoth", size: 1 },
    973: { name: "Dagannoth", size: 2 },
    974: { name: "Dagannoth", size: 2 },
    975: { name: "Dagannoth", size: 2 },
    976: { name: "Dagannoth", size: 2 },
    977: { name: "Dagannoth", size: 2 },
    978: { name: "Dagannoth", size: 2 },
    979: { name: "Dagannoth", size: 2 },
    980: { name: "Dagannoth mother", size: 3 },
    981: { name: "Dagannoth mother", size: 3 },
    982: { name: "Dagannoth mother", size: 3 },
    983: { name: "Dagannoth mother", size: 3 },
    984: { name: "Dagannoth mother", size: 3 },
    985: { name: "Dagannoth mother", size: 3 },
    986: { name: "Dagannoth mother", size: 3 },
    987: { name: "Dagannoth mother", size: 3 },
    988: { name: "Dagannoth mother", size: 3 },
    989: { name: "Veliaf Hurtz", size: 1 },
    990: { name: "Sigmund", size: 1 },
    991: { name: "Sigmund", size: 1 },
    992: { name: "Sigmund", size: 1 },
    993: { name: "Sigmund", size: 1 },
    994: { name: "Sigmund", size: 1 },
    995: { name: "Guard", size: 1 },
    996: { name: "Nardok", size: 1 },
    997: { name: "Dartog", size: 1 },
    998: { name: "Guard", size: 1 },
    999: { name: "Guard", size: 1 },
    1000: { name: "Guard", size: 1 },
    1001: { name: "Guard", size: 1 },
    1002: { name: "Guard", size: 1 },
    1003: { name: "Guard", size: 1 },
    1004: { name: "Guard", size: 1 },
    1005: { name: "Guard", size: 1 },
    1006: { name: "Guard", size: 1 },
    1007: { name: "Guard", size: 1 },
    1008: { name: "Guard", size: 1 },
    1009: { name: "Guard", size: 1 },
    1010: { name: "Gertrude's cat", size: 1 },
    1011: { name: "Gamer", size: 1 },
    1012: { name: "Gamer", size: 1 },
    1013: { name: "Barman", size: 1 },
    1014: { name: "Gamer", size: 1 },
    1015: { name: "Gamer", size: 1 },
    1016: { name: "Gamer", size: 1 },
    1017: { name: "Gamer", size: 1 },
    1018: { name: "Gamer", size: 1 },
    1019: { name: "Gamer", size: 1 },
    1020: { name: "Rat", size: 1 },
    1021: { name: "Rat", size: 1 },
    1022: { name: "Rat", size: 1 },
    1023: { name: "null", size: 1 },
    1024: { name: "Zygomite", size: 2 },
    1025: { name: "Necromancer", size: 1 },
    1026: { name: "Bandit", size: 1 },
    1027: { name: "Guard Bandit", size: 1 },
    1028: { name: "Barbarian guard", size: 1 },
    1029: { name: "Sleepwalker", size: 1 },
    1030: { name: "Sleepwalker", size: 1 },
    1031: { name: "Sleepwalker", size: 1 },
    1032: { name: "Sleepwalker", size: 1 },
    1033: { name: "null", size: 1 },
    1034: { name: "null", size: 1 },
    1035: { name: "null", size: 1 },
    1036: { name: "null", size: 1 },
    1037: { name: "Snake", size: 1 },
    1038: { name: "Monkey", size: 1 },
    1039: { name: "Albino bat", size: 2 },
    1040: { name: "Crab", size: 1 },
    1041: { name: "Giant mosquito", size: 1 },
    1042: { name: "Jungle horror", size: 2 },
    1043: { name: "Jungle horror", size: 1 },
    1044: { name: "Jungle horror", size: 2 },
    1045: { name: "Jungle horror", size: 1 },
    1046: { name: "Jungle horror", size: 2 },
    1047: { name: "Cave horror", size: 2 },
    1048: { name: "Cave horror", size: 2 },
    1049: { name: "Cave horror", size: 2 },
    1050: { name: "Cave horror", size: 2 },
    1051: { name: "Cave horror", size: 2 },
    1052: { name: "Cavey Davey", size: 1 },
    1053: { name: "Mac", size: 1 },
    1054: { name: "Launa", size: 1 },
    1055: { name: "Launa", size: 1 },
    1056: { name: "Brana", size: 1 },
    1057: { name: "Tolna", size: 2 },
    1058: { name: "Tolna", size: 1 },
    1059: { name: "Tolna", size: 1 },
    1060: { name: "Angry bear", size: 2 },
    1061: { name: "Angry unicorn", size: 2 },
    1062: { name: "Angry giant rat", size: 2 },
    1063: { name: "null", size: 1 },
    1064: { name: "null", size: 1 },
    1065: { name: "Angry goblin", size: 1 },
    1066: { name: "Fear reaper", size: 2 },
    1067: { name: "Confusion beast", size: 2 },
    1068: { name: "Confusion beast", size: 2 },
    1069: { name: "Confusion beast", size: 2 },
    1070: { name: "Confusion beast", size: 2 },
    1071: { name: "Confusion beast", size: 2 },
    1072: { name: "Hopeless creature", size: 2 },
    1073: { name: "Hopeless creature", size: 2 },
    1074: { name: "Hopeless creature", size: 2 },
    1075: { name: "Tolna", size: 3 },
    1076: { name: "Tolna", size: 3 },
    1077: { name: "Tolna", size: 3 },
    1078: { name: "Runa", size: 1 },
    1079: { name: "Halla", size: 1 },
    1080: { name: "Finn", size: 1 },
    1081: { name: "Osvald", size: 1 },
    1082: { name: "Runolf", size: 1 },
    1083: { name: "Tjorvi", size: 1 },
    1084: { name: "Ingrid", size: 1 },
    1085: { name: "Thora", size: 1 },
    1086: { name: "Signy", size: 1 },
    1087: { name: "Hild", size: 1 },
    1088: { name: "Armod", size: 1 },
    1089: { name: "Beigarth", size: 1 },
    1090: { name: "Reinn", size: 1 },
    1091: { name: "Alviss", size: 1 },
    1092: { name: "Fullangr", size: 1 },
    1093: { name: "Jari", size: 1 },
    1094: { name: "Thorodin", size: 1 },
    1095: { name: "Ferd", size: 1 },
    1096: { name: "Donal", size: 1 },
    1097: { name: "Sea Snake Young", size: 2 },
    1098: { name: "Sea Snake Hatchling", size: 1 },
    1099: { name: "Guard", size: 1 },
    1100: { name: "Guard", size: 1 },
    1101: { name: "Giant Sea Snake", size: 5 },
    1102: { name: "Elena", size: 1 },
    1103: { name: "Da Vinci", size: 1 },
    1104: { name: "Da Vinci", size: 1 },
    1105: { name: "Chancy", size: 1 },
    1106: { name: "Chancy", size: 1 },
    1107: { name: "Hops", size: 1 },
    1108: { name: "Hops", size: 1 },
    1109: { name: "Julie", size: 1 },
    1110: { name: "Guidor", size: 1 },
    1111: { name: "Guard", size: 1 },
    1112: { name: "Guard", size: 1 },
    1113: { name: "Guard", size: 1 },
    1114: { name: "null", size: 1 },
    1115: { name: "null", size: 1 },
    1116: { name: "null", size: 1 },
    1117: { name: "null", size: 1 },
    1118: { name: "Man", size: 1 },
    1119: { name: "Woman", size: 1 },
    1120: { name: "Dominic Onion", size: 1 },
    1126: { name: "Barrelchest (hard)", size: 3 },
    1127: { name: "Giant Scarab (hard)", size: 3 },
    1128: { name: "Dessous (hard)", size: 1 },
    1129: { name: "Kamil (hard)", size: 1 },
    1130: { name: "Woman", size: 1 },
    1131: { name: "Woman", size: 1 },
    1132: { name: "Child", size: 1 },
    1133: { name: "Child", size: 1 },
    1134: { name: "Damis (hard)", size: 1 },
    1135: { name: "Damis (hard)", size: 1 },
    1136: { name: "null", size: 1 },
    1137: { name: "Priest", size: 1 },
    1138: { name: "Man", size: 1 },
    1139: { name: "Woman", size: 1 },
    1140: { name: "Woman", size: 1 },
    1141: { name: "Woman", size: 1 },
    1142: { name: "Woman", size: 1 },
    1143: { name: "null", size: 1 },
    1144: { name: "Paladin", size: 1 },
    1145: { name: "Jerico", size: 1 },
    1146: { name: "Chemist", size: 1 },
    1147: { name: "Guard", size: 1 },
    1148: { name: "null", size: 1 },
    1149: { name: "null", size: 3 },
    1150: { name: "null", size: 1 },
    1151: { name: "null", size: 1 },
    1152: { name: "Nurse Sarah", size: 1 },
    1153: { name: "Ogre", size: 2 },
    1154: { name: "Shark", size: 1 },
    1155: { name: "Shark", size: 1 },
    1156: { name: "Shark", size: 1 },
    1157: { name: "Archer", size: 1 },
    1158: { name: "Warrior", size: 1 },
    1159: { name: "Monk", size: 1 },
    1160: { name: "Wizard", size: 1 },
    1161: { name: "Fairy Queen", size: 1 },
    1162: { name: "Shamus", size: 1 },
    1163: { name: "Tree spirit", size: 1 },
    1164: { name: "Cave monk", size: 1 },
    1165: { name: "Monk of Entrana", size: 1 },
    1166: { name: "Monk of Entrana", size: 1 },
    1167: { name: "Monk of Entrana", size: 1 },
    1168: { name: "Monk of Entrana", size: 1 },
    1169: { name: "Monk of Entrana", size: 1 },
    1170: { name: "Monk of Entrana", size: 1 },
    1171: { name: "Monk", size: 1 },
    1173: { name: "Chicken", size: 1 },
    1174: { name: "Chicken", size: 1 },
    1175: { name: "Rooster", size: 1 },
    1176: { name: "Li'l lamb", size: 1 },
    1177: { name: "Lamb", size: 1 },
    1178: { name: "Sheep", size: 1 },
    1179: { name: "Lumbridge Guide", size: 1 },
    1180: { name: "null", size: 1 },
    1181: { name: "Lumbridge Guide", size: 1 },
    1182: { name: "? ? ? ?", size: 1 },
    1183: { name: "? ? ? ?", size: 1 },
    1184: { name: "? ? ? ?", size: 1 },
    1185: { name: "? ? ? ?", size: 1 },
    1186: { name: "? ? ? ?", size: 1 },
    1187: { name: "? ? ? ?", size: 1 },
    1188: { name: "? ? ? ?", size: 1 },
    1189: { name: "? ? ? ?", size: 1 },
    1190: { name: "? ? ? ?", size: 1 },
    1191: { name: "? ? ? ?", size: 1 },
    1192: { name: "General Wartface", size: 1 },
    1193: { name: "General Bentnoze", size: 1 },
    1194: { name: "null", size: 1 },
    1195: { name: "General Wartface", size: 1 },
    1196: { name: "null", size: 1 },
    1197: { name: "General Bentnoze", size: 1 },
    1198: { name: "null", size: 1 },
    1199: { name: "Claus the Chef", size: 1 },
    1200: { name: "null", size: 2 },
    1201: { name: "Philipe Carnillean", size: 1 },
    1202: { name: "Henryeta Carnillean", size: 1 },
    1203: { name: "null", size: 1 },
    1204: { name: "null", size: 1 },
    1205: { name: "Hazeel", size: 3 },
    1206: { name: "null", size: 1 },
    1207: { name: "Hazeel Cultist", size: 1 },
    1208: { name: "Khazard Guard", size: 1 },
    1209: { name: "Head Guard", size: 1 },
    1210: { name: "Khazard Guard", size: 1 },
    1211: { name: "Khazard Guard", size: 1 },
    1212: { name: "null", size: 1 },
    1213: { name: "null", size: 1 },
    1214: { name: "Khazard Barman", size: 1 },
    1215: { name: "Kelvin", size: 1 },
    1216: { name: "Joe", size: 1 },
    1217: { name: "Slave", size: 1 },
    1218: { name: "Hengrad", size: 1 },
    1219: { name: "null", size: 1 },
    1220: { name: "null", size: 1 },
    1221: { name: "null", size: 1 },
    1222: { name: "null", size: 2 },
    1223: { name: "Spectator", size: 1 },
    1224: { name: "Bouncer", size: 2 },
    1225: { name: "Khazard Ogre", size: 2 },
    1226: { name: "Khazard Scorpion", size: 3 },
    1227: { name: "Arzinian Avatar of Strength", size: 1 },
    1228: { name: "Arzinian Avatar of Strength", size: 1 },
    1229: { name: "Arzinian Avatar of Strength", size: 1 },
    1230: { name: "Arzinian Avatar of Ranging", size: 1 },
    1231: { name: "Arzinian Avatar of Ranging", size: 1 },
    1232: { name: "Arzinian Avatar of Ranging", size: 1 },
    1233: { name: "Arzinian Avatar of Magic", size: 1 },
    1234: { name: "Arzinian Avatar of Magic", size: 1 },
    1235: { name: "Arzinian Avatar of Magic", size: 1 },
    1236: { name: "Arzinian Being of Bordanzan", size: 1 },
    1237: { name: "Saboteur", size: 1 },
    1238: { name: "Gnome shop keeper", size: 1 },
    1239: { name: "null", size: 1 },
    1240: { name: "Cute Creature", size: 1 },
    1241: { name: "Evil Creature", size: 1 },
    1242: { name: "null", size: 1 },
    1243: { name: "Cute Creature", size: 1 },
    1244: { name: "Evil Creature", size: 1 },
    1245: { name: "null", size: 1 },
    1246: { name: "Cute Creature", size: 1 },
    1247: { name: "Evil Creature", size: 1 },
    1248: { name: "null", size: 1 },
    1249: { name: "Cute Creature", size: 1 },
    1250: { name: "Evil Creature", size: 1 },
    1251: { name: "null", size: 1 },
    1252: { name: "Cute Creature", size: 1 },
    1253: { name: "Evil Creature", size: 1 },
    1254: { name: "null", size: 1 },
    1255: { name: "Cute Creature", size: 1 },
    1256: { name: "Evil Creature", size: 1 },
    1257: { name: "fluffie", size: 1 },
    1258: { name: "fluffie", size: 1 },
    1259: { name: "Odd Old Man", size: 2 },
    1260: { name: "Fortunato", size: 1 },
    1261: { name: "Ram", size: 1 },
    1262: { name: "Ram", size: 1 },
    1263: { name: "Ram", size: 1 },
    1264: { name: "Ram", size: 1 },
    1265: { name: "Ram", size: 1 },
    1266: { name: "Bones", size: 1 },
    1267: { name: "Vulture", size: 2 },
    1268: { name: "Vulture", size: 2 },
    1269: { name: "Dr Fenkenstrain", size: 1 },
    1270: { name: "Fenkenstrain's Monster", size: 1 },
    1271: { name: "Lord Rologarth", size: 1 },
    1272: { name: "Gardener Ghost", size: 1 },
    1273: { name: "Experiment", size: 2 },
    1274: { name: "Experiment", size: 2 },
    1275: { name: "Experiment", size: 1 },
    1276: { name: "Loar Shadow", size: 1 },
    1277: { name: "Loar Shade", size: 1 },
    1278: { name: "Shade Spirit", size: 1 },
    1279: { name: "Phrin Shadow", size: 1 },
    1280: { name: "Phrin Shade", size: 1 },
    1281: { name: "Riyl Shadow", size: 1 },
    1282: { name: "Riyl Shade", size: 1 },
    1283: { name: "Asyn Shadow", size: 1 },
    1284: { name: "Asyn Shade", size: 1 },
    1285: { name: "Fiyr Shadow", size: 1 },
    1286: { name: "Fiyr Shade", size: 1 },
    1287: { name: "Afflicted(Ulsquire)", size: 1 },
    1288: { name: "Ulsquire Shauncy", size: 1 },
    1289: { name: "Afflicted(Razmire)", size: 1 },
    1290: { name: "Razmire Keelgan", size: 1 },
    1291: { name: "Mort'ton Local", size: 1 },
    1292: { name: "Mort'ton Local", size: 1 },
    1293: { name: "Afflicted", size: 1 },
    1294: { name: "Afflicted", size: 1 },
    1295: { name: "Mort'ton local", size: 1 },
    1296: { name: "Mort'ton local", size: 1 },
    1297: { name: "Afflicted", size: 1 },
    1298: { name: "Afflicted", size: 1 },
    1299: { name: "Sheep", size: 1 },
    1300: { name: "Sheep", size: 1 },
    1301: { name: "Sheep", size: 1 },
    1302: { name: "Sheep", size: 1 },
    1303: { name: "Sheep", size: 1 },
    1304: { name: "Sheep", size: 1 },
    1305: { name: "Hairdresser", size: 1 },
    1306: { name: "Makeover Mage", size: 1 },
    1307: { name: "Makeover Mage", size: 1 },
    1308: { name: "Sheep", size: 1 },
    1309: { name: "Sheep", size: 1 },
    1310: { name: "Bartender", size: 1 },
    1311: { name: "Bartender", size: 1 },
    1312: { name: "Bartender", size: 1 },
    1313: { name: "Bartender", size: 1 },
    1314: { name: "Bartender", size: 1 },
    1315: { name: "Emily", size: 1 },
    1316: { name: "Kaylee", size: 1 },
    1317: { name: "Tina", size: 1 },
    1318: { name: "Bartender", size: 1 },
    1319: { name: "Bartender", size: 1 },
    1320: { name: "Bartender", size: 1 },
    1321: { name: "null", size: 1 },
    1322: { name: "null", size: 1 },
    1323: { name: "Tarquin", size: 1 },
    1324: { name: "Sigurd", size: 1 },
    1325: { name: "Hari", size: 1 },
    1326: { name: "Barfy Bill", size: 1 },
    1327: { name: "Tyras guard", size: 1 },
    1328: { name: "null", size: 1 },
    1329: { name: "null", size: 1 },
    1330: { name: "null", size: 1 },
    1331: { name: "null", size: 1 },
    1332: { name: "null", size: 1 },
    1333: { name: "null", size: 1 },
    1334: { name: "null", size: 1 },
    1335: { name: "Jack Seagull", size: 1 },
    1336: { name: "Longbow Ben", size: 1 },
    1337: { name: "Ahab", size: 1 },
    1338: { name: "Seagull", size: 1 },
    1339: { name: "Seagull", size: 1 },
    1340: { name: "Matthias", size: 1 },
    1341: { name: "Matthias", size: 1 },
    1342: { name: "Gyr Falcon", size: 1 },
    1343: { name: "Gyr Falcon", size: 1 },
    1344: { name: "Gyr Falcon", size: 1 },
    1345: { name: "Gyr Falcon", size: 1 },
    1346: { name: "Prickly kebbit", size: 1 },
    1347: { name: "Sabre-toothed kebbit", size: 1 },
    1348: { name: "Barb-tailed kebbit", size: 1 },
    1349: { name: "Wild kebbit", size: 1 },
    1350: { name: "Artimeus", size: 1 },
    1351: { name: "Seth Groats", size: 1 },
    1352: { name: "Tassie Slipcast", size: 1 },
    1353: { name: "Hammerspike Stoutbeard", size: 1 },
    1354: { name: "Dwarf gang member", size: 1 },
    1355: { name: "Dwarf gang member", size: 1 },
    1356: { name: "Dwarf gang member", size: 1 },
    1357: { name: "Phantuwti Fanstuwi Farsight", size: 1 },
    1358: { name: "Tindel Marchant", size: 1 },
    1359: { name: "null", size: 1 },
    1360: { name: "Petra Fiyed", size: 1 },
    1361: { name: "Jimmy the Chisel", size: 1 },
    1362: { name: "Slagilith", size: 2 },
    1363: { name: "Rock pile", size: 2 },
    1364: { name: "Slagilith", size: 2 },
    1365: { name: "Fire elemental", size: 1 },
    1366: { name: "Earth elemental", size: 1 },
    1367: { name: "Earth elemental", size: 1 },
    1368: { name: "Elemental rock", size: 1 },
    1369: { name: "Air elemental", size: 1 },
    1370: { name: "Water elemental", size: 1 },
    1371: { name: "Guard", size: 1 },
    1372: { name: "Guard", size: 1 },
    1373: { name: "Hamal the Chieftain", size: 1 },
    1374: { name: "Ragnar", size: 1 },
    1375: { name: "Svidi", size: 1 },
    1376: { name: "Jokul", size: 1 },
    1377: { name: "The Kendal", size: 2 },
    1378: { name: "The Kendal", size: 2 },
    1379: { name: "Camp dweller", size: 1 },
    1380: { name: "Camp dweller", size: 1 },
    1381: { name: "Camp dweller", size: 1 },
    1382: { name: "Camp dweller", size: 1 },
    1383: { name: "Camp dweller", size: 1 },
    1384: { name: "Mountain Goat", size: 1 },
    1385: { name: "Mountain Goat", size: 1 },
    1386: { name: "Mountain Goat", size: 1 },
    1387: { name: "Mountain Goat", size: 1 },
    1388: { name: "Bald Headed Eagle", size: 2 },
    1389: { name: "Bernald", size: 1 },
    1390: { name: "Queen Ellamaria", size: 1 },
    1391: { name: "Trolley", size: 1 },
    1392: { name: "Trolley", size: 1 },
    1393: { name: "Trolley", size: 1 },
    1394: { name: "null", size: 1 },
    1395: { name: "Billy, a guard of Falador", size: 1 },
    1396: { name: "Bob, another guard of Falador", size: 1 },
    1397: { name: "Brother Althric", size: 1 },
    1398: { name: "PKMaster0036", size: 1 },
    1399: { name: "King Roald", size: 1 },
    1400: { name: "Nulodion", size: 1 },
    1401: { name: "Dwarf", size: 1 },
    1402: { name: "Dwarf", size: 1 },
    1403: { name: "Dwarf", size: 1 },
    1404: { name: "Dwarf", size: 1 },
    1405: { name: "Dwarf", size: 1 },
    1406: { name: "Dwarf", size: 1 },
    1407: { name: "Dwarf", size: 1 },
    1408: { name: "Dwarf", size: 1 },
    1409: { name: "Black Guard", size: 1 },
    1410: { name: "Black Guard", size: 1 },
    1411: { name: "Black Guard", size: 1 },
    1412: { name: "Black Guard", size: 1 },
    1413: { name: "Engineering assistant", size: 1 },
    1414: { name: "Engineering assistant", size: 1 },
    1415: { name: "Engineer", size: 1 },
    1416: { name: "Squirrel", size: 1 },
    1417: { name: "Squirrel", size: 1 },
    1418: { name: "Squirrel", size: 1 },
    1419: { name: "Raccoon", size: 1 },
    1420: { name: "Raccoon", size: 1 },
    1421: { name: "Raccoon", size: 1 },
    1422: { name: "Hazelmere", size: 1 },
    1423: { name: "null", size: 1 },
    1424: { name: "null", size: 1 },
    1425: { name: "Glough", size: 1 },
    1426: { name: "null", size: 1 },
    1427: { name: "null", size: 1 },
    1428: { name: "Charlie", size: 1 },
    1429: { name: "Foreman", size: 1 },
    1430: { name: "Shipyard worker", size: 1 },
    1431: { name: "Femi", size: 1 },
    1432: { name: "Black demon", size: 3 },
    1433: { name: "Gnome guard", size: 1 },
    1434: { name: "Garkor", size: 1 },
    1435: { name: "Lumo", size: 1 },
    1436: { name: "Bunkdo", size: 1 },
    1437: { name: "Carado", size: 1 },
    1438: { name: "Lumdo", size: 1 },
    1439: { name: "Karam", size: 1 },
    1440: { name: "Bunkwicket", size: 1 },
    1441: { name: "Waymottin", size: 1 },
    1442: { name: "Zooknock", size: 1 },
    1443: { name: "Jungle Demon", size: 3 },
    1444: { name: "Daero", size: 1 },
    1445: { name: "Daero", size: 1 },
    1446: { name: "Waydar", size: 1 },
    1447: { name: "Pirate", size: 1 },
    1448: { name: "Thief", size: 1 },
    1449: { name: "null", size: 1 },
    1450: { name: "null", size: 1 },
    1451: { name: "null", size: 2 },
    1452: { name: "null", size: 2 },
    1453: { name: "Lumdo", size: 1 },
    1454: { name: "Lumdo", size: 1 },
    1455: { name: "null", size: 1 },
    1456: { name: "null", size: 1 },
    1457: { name: "null", size: 1 },
    1458: { name: "null", size: 1 },
    1459: { name: "null", size: 1 },
    1460: { name: "G.L.O. Caranock", size: 1 },
    1461: { name: "Mugger", size: 1 },
    1462: { name: "Small ninja monkey", size: 1 },
    1463: { name: "Medium ninja monkey", size: 1 },
    1464: { name: "Gorilla", size: 1 },
    1465: { name: "Bearded gorilla", size: 1 },
    1466: { name: "Ancient monkey", size: 1 },
    1467: { name: "Small zombie monkey", size: 1 },
    1468: { name: "Large zombie monkey", size: 1 },
    1469: { name: "Monkey", size: 1 },
    1470: { name: "Rantz", size: 2 },
    1471: { name: "Fycie", size: 1 },
    1472: { name: "Bugs", size: 1 },
    1473: { name: "Swamp toad", size: 1 },
    1474: { name: "Bloated Toad", size: 1 },
    1475: { name: "Chompy bird", size: 1 },
    1476: { name: "Chompy bird", size: 1 },
    1477: { name: "Eudav", size: 1 },
    1478: { name: "Oronwen", size: 1 },
    1479: { name: "Banker", size: 1 },
    1480: { name: "Banker", size: 1 },
    1481: { name: "Dalldav", size: 1 },
    1482: { name: "Gethin", size: 1 },
    1483: { name: "Nickolaus", size: 1 },
    1484: { name: "Nickolaus", size: 1 },
    1485: { name: "Nickolaus", size: 1 },
    1486: { name: "Nickolaus", size: 1 },
    1487: { name: "Desert eagle", size: 3 },
    1488: { name: "Jungle eagle", size: 3 },
    1489: { name: "Polar eagle", size: 3 },
    1490: { name: "Eagle", size: 3 },
    1491: { name: "null", size: 3 },
    1492: { name: "null", size: 3 },
    1493: { name: "null", size: 3 },
    1494: { name: "Kebbit", size: 1 },
    1495: { name: "Charlie", size: 1 },
    1496: { name: "Boulder", size: 2 },
    1497: { name: "Fishing spot", size: 1 },
    1498: { name: "Fishing spot", size: 1 },
    1499: { name: "Fishing spot", size: 1 },
    1500: { name: "Fishing spot", size: 1 },
    1501: { name: "Aleck", size: 1 },
    1502: { name: "Leon", size: 1 },
    1503: { name: "Hunting expert", size: 1 },
    1504: { name: "Hunting expert", size: 1 },
    1505: { name: "Ferret", size: 1 },
    1506: { name: "Rod Fishing spot", size: 1 },
    1507: { name: "Rod Fishing spot", size: 1 },
    1508: { name: "Rod Fishing spot", size: 1 },
    1509: { name: "Rod Fishing spot", size: 1 },
    1510: { name: "Fishing spot", size: 1 },
    1511: { name: "Fishing spot", size: 1 },
    1512: { name: "Rod Fishing spot", size: 1 },
    1513: { name: "Rod Fishing spot", size: 1 },
    1514: { name: "Fishing spot", size: 1 },
    1515: { name: "Rod Fishing spot", size: 1 },
    1516: { name: "Rod Fishing spot", size: 1 },
    1517: { name: "Fishing spot", size: 1 },
    1518: { name: "Fishing spot", size: 1 },
    1519: { name: "Fishing spot", size: 1 },
    1520: { name: "Fishing spot", size: 1 },
    1521: { name: "Fishing spot", size: 1 },
    1522: { name: "Fishing spot", size: 1 },
    1523: { name: "Fishing spot", size: 1 },
    1524: { name: "Fishing spot", size: 1 },
    1525: { name: "Fishing spot", size: 1 },
    1526: { name: "Rod Fishing spot", size: 1 },
    1527: { name: "Rod Fishing spot", size: 1 },
    1528: { name: "Fishing spot", size: 1 },
    1529: { name: "Rod Fishing spot", size: 1 },
    1530: { name: "Fishing spot", size: 1 },
    1531: { name: "Rod Fishing spot", size: 1 },
    1532: { name: "Fishing spot", size: 1 },
    1533: { name: "Fishing spot", size: 1 },
    1534: { name: "Fishing spot", size: 1 },
    1535: { name: "Fishing spot", size: 1 },
    1536: { name: "Fishing spot", size: 1 },
    1537: { name: "Skeleton hero", size: 1 },
    1538: { name: "Skeleton brute", size: 1 },
    1539: { name: "Skeleton warlord", size: 1 },
    1540: { name: "Skeleton heavy", size: 1 },
    1541: { name: "Skeleton thug", size: 1 },
    1542: { name: "Fishing spot", size: 1 },
    1543: { name: "Gargoyle", size: 3 },
    1544: { name: "Fishing spot", size: 1 },
    1545: { name: "Black knight", size: 1 },
    1546: { name: "Guard", size: 1 },
    1547: { name: "Guard", size: 1 },
    1548: { name: "Guard", size: 1 },
    1549: { name: "Guard", size: 1 },
    1550: { name: "Guard", size: 1 },
    1551: { name: "Guard", size: 1 },
    1552: { name: "Guard", size: 1 },
    1553: { name: "Crab", size: 1 },
    1554: { name: "Seagull", size: 1 },
    1555: { name: "Seagull", size: 1 },
    1556: { name: "Fire wizard", size: 1 },
    1557: { name: "Water wizard", size: 1 },
    1558: { name: "Earth wizard", size: 1 },
    1559: { name: "Air wizard", size: 1 },
    1560: { name: "Ordan", size: 1 },
    1561: { name: "Jorzik", size: 1 },
    1562: { name: "Smiddi Ryak (hard)", size: 1 },
    1563: { name: "Rolayne Twickit (hard)", size: 1 },
    1564: { name: "Jayene Kliyn (medium)", size: 1 },
    1565: { name: "Valantay Eppel (medium)", size: 1 },
    1566: { name: "Dalcian Fang (easy)", size: 1 },
    1567: { name: "Fyiona Fray (easy)", size: 1 },
    1568: { name: "Abidor Crank", size: 1 },
    1569: { name: "Benjamin", size: 1 },
    1570: { name: "Liam", size: 1 },
    1571: { name: "Miala", size: 1 },
    1572: { name: "Verak", size: 1 },
    1573: { name: "Forester (hard)", size: 1 },
    1574: { name: "Woman-at-arms (hard)", size: 1 },
    1575: { name: "Apprentice (medium)", size: 1 },
    1576: { name: "Ranger (medium)", size: 1 },
    1577: { name: "Adventurer (easy)", size: 1 },
    1578: { name: "Mage (easy)", size: 1 },
    1579: { name: "Hiylik Myna", size: 1 },
    1580: { name: "Dummy", size: 1 },
    1581: { name: "Dummy", size: 1 },
    1582: { name: "Dummy", size: 1 },
    1583: { name: "Dummy", size: 1 },
    1584: { name: "Dummy", size: 1 },
    1585: { name: "Dummy", size: 1 },
    1586: { name: "Dummy", size: 1 },
    1587: { name: "Dummy", size: 1 },
    1588: { name: "Dummy", size: 1 },
    1589: { name: "Dummy", size: 1 },
    1590: { name: "Dummy", size: 1 },
    1591: { name: "Dummy", size: 1 },
    1592: { name: "Dummy", size: 1 },
    1593: { name: "Dummy", size: 1 },
    1594: { name: "Dummy", size: 1 },
    1595: { name: "Dummy", size: 1 },
    1596: { name: "Dummy", size: 1 },
    1597: { name: "Dummy", size: 1 },
    1598: { name: "Dummy", size: 1 },
    1599: { name: "Dummy", size: 1 },
    1600: { name: "Gundai", size: 1 },
    1601: { name: "Lundail", size: 1 },
    1602: { name: "Chamber guardian", size: 1 },
    1603: { name: "Kolodion", size: 1 },
    1604: { name: "Kolodion", size: 1 },
    1605: { name: "Kolodion", size: 1 },
    1606: { name: "Kolodion", size: 1 },
    1607: { name: "Kolodion", size: 3 },
    1608: { name: "Kolodion", size: 1 },
    1609: { name: "Kolodion", size: 3 },
    1610: { name: "Battle mage", size: 1 },
    1611: { name: "Battle mage", size: 1 },
    1612: { name: "Battle mage", size: 1 },
    1613: { name: "Banker", size: 1 },
    1614: { name: "Phials", size: 1 },
    1615: { name: "Banknote Exchange Merchant", size: 1 },
    1616: { name: "High Priestess Zul-Harcinqa", size: 1 },
    1617: { name: "Priestess Zul-Gwenwynig", size: 1 },
    1618: { name: "Banker", size: 1 },
    1619: { name: "Cat", size: 1 },
    1620: { name: "Cat", size: 1 },
    1621: { name: "Cat", size: 1 },
    1622: { name: "Cat", size: 1 },
    1623: { name: "Cat", size: 1 },
    1624: { name: "Cat", size: 1 },
    1625: { name: "Hellcat", size: 1 },
    1626: { name: "Lazy cat", size: 1 },
    1627: { name: "Lazy cat", size: 1 },
    1628: { name: "Lazy cat", size: 1 },
    1629: { name: "Lazy cat", size: 1 },
    1630: { name: "Lazy cat", size: 1 },
    1631: { name: "Lazy cat", size: 1 },
    1632: { name: "Lazy hellcat", size: 1 },
    1633: { name: "Banker", size: 1 },
    1634: { name: "Banker", size: 1 },
    1635: { name: "Baby impling", size: 1 },
    1636: { name: "Young impling", size: 1 },
    1637: { name: "Gourmet impling", size: 1 },
    1638: { name: "Earth impling", size: 1 },
    1639: { name: "Essence impling", size: 1 },
    1640: { name: "Eclectic impling", size: 1 },
    1641: { name: "Nature impling", size: 1 },
    1642: { name: "Magpie impling", size: 1 },
    1643: { name: "Ninja impling", size: 1 },
    1644: { name: "Dragon impling", size: 1 },
    1645: { name: "Baby impling", size: 1 },
    1646: { name: "Young impling", size: 1 },
    1647: { name: "Gourmet impling", size: 1 },
    1648: { name: "Earth impling", size: 1 },
    1649: { name: "Essence impling", size: 1 },
    1650: { name: "Eclectic impling", size: 1 },
    1651: { name: "Nature impling", size: 1 },
    1652: { name: "Magpie impling", size: 1 },
    1653: { name: "Ninja impling", size: 1 },
    1654: { name: "Dragon impling", size: 1 },
    1655: { name: "Egg launcher", size: 1 },
    1656: { name: "Commander Connad", size: 1 },
    1657: { name: "Captain Cain", size: 1 },
    1658: { name: "Private Paldo", size: 1 },
    1659: { name: "Private Pendron", size: 1 },
    1660: { name: "Private Pierreb", size: 1 },
    1661: { name: "Private Paldon", size: 1 },
    1662: { name: "Major Attack", size: 1 },
    1663: { name: "Major Collect", size: 1 },
    1664: { name: "Major Defend", size: 1 },
    1665: { name: "Major Heal", size: 1 },
    1666: { name: "Sergeant Sambur", size: 1 },
    1667: { name: "Penance Fighter", size: 1 },
    1668: { name: "Penance Ranger", size: 1 },
    1669: { name: "Penance Runner", size: 1 },
    1670: { name: "Penance Healer", size: 1 },
    1671: { name: "Strange Old Man", size: 1 },
    1672: { name: "Ahrim the Blighted", size: 1 },
    1673: { name: "Dharok the Wretched", size: 1 },
    1674: { name: "Guthan the Infested", size: 1 },
    1675: { name: "Karil the Tainted", size: 1 },
    1676: { name: "Torag the Corrupted", size: 1 },
    1677: { name: "Verac the Defiled", size: 1 },
    1678: { name: "Bloodworm", size: 1 },
    1679: { name: "Crypt rat", size: 1 },
    1680: { name: "Giant crypt rat", size: 2 },
    1681: { name: "Giant crypt rat", size: 2 },
    1682: { name: "Giant crypt rat", size: 2 },
    1683: { name: "Crypt spider", size: 1 },
    1684: { name: "Giant crypt spider", size: 1 },
    1685: { name: "Skeleton", size: 1 },
    1686: { name: "Skeleton", size: 1 },
    1687: { name: "Skeleton", size: 1 },
    1688: { name: "Skeleton", size: 1 },
    1689: { name: "Splatter", size: 1 },
    1690: { name: "Splatter", size: 1 },
    1691: { name: "Splatter", size: 1 },
    1692: { name: "Splatter", size: 1 },
    1693: { name: "Splatter", size: 1 },
    1694: { name: "Shifter", size: 1 },
    1695: { name: "Shifter", size: 1 },
    1696: { name: "Shifter", size: 1 },
    1697: { name: "Shifter", size: 1 },
    1698: { name: "Shifter", size: 1 },
    1699: { name: "Shifter", size: 1 },
    1700: { name: "Shifter", size: 1 },
    1701: { name: "Shifter", size: 1 },
    1702: { name: "Shifter", size: 1 },
    1703: { name: "Shifter", size: 1 },
    1704: { name: "Ravager", size: 1 },
    1705: { name: "Ravager", size: 1 },
    1706: { name: "Ravager", size: 1 },
    1707: { name: "Ravager", size: 1 },
    1708: { name: "Ravager", size: 1 },
    1709: { name: "Spinner", size: 1 },
    1710: { name: "Spinner", size: 1 },
    1711: { name: "Spinner", size: 1 },
    1712: { name: "Spinner", size: 1 },
    1713: { name: "Spinner", size: 1 },
    1714: { name: "Torcher", size: 1 },
    1715: { name: "Torcher", size: 1 },
    1716: { name: "Torcher", size: 1 },
    1717: { name: "Torcher", size: 1 },
    1718: { name: "Torcher", size: 1 },
    1719: { name: "Torcher", size: 1 },
    1720: { name: "Torcher", size: 1 },
    1721: { name: "Torcher", size: 1 },
    1722: { name: "Torcher", size: 1 },
    1723: { name: "Torcher", size: 1 },
    1724: { name: "Defiler", size: 1 },
    1725: { name: "Defiler", size: 1 },
    1726: { name: "Defiler", size: 1 },
    1727: { name: "Defiler", size: 1 },
    1728: { name: "Defiler", size: 1 },
    1729: { name: "Defiler", size: 1 },
    1730: { name: "Defiler", size: 1 },
    1731: { name: "Defiler", size: 1 },
    1732: { name: "Defiler", size: 1 },
    1733: { name: "Defiler", size: 1 },
    1734: { name: "Brawler", size: 2 },
    1735: { name: "Brawler", size: 2 },
    1736: { name: "Brawler", size: 2 },
    1737: { name: "Brawler", size: 2 },
    1738: { name: "Brawler", size: 2 },
    1739: { name: "Portal", size: 3 },
    1740: { name: "Portal", size: 3 },
    1741: { name: "Portal", size: 3 },
    1742: { name: "Portal", size: 3 },
    1743: { name: "Portal", size: 3 },
    1744: { name: "Portal", size: 3 },
    1745: { name: "Portal", size: 3 },
    1746: { name: "Portal", size: 3 },
    1747: { name: "Portal", size: 3 },
    1748: { name: "Portal", size: 3 },
    1749: { name: "Portal", size: 3 },
    1750: { name: "Portal", size: 3 },
    1751: { name: "Portal", size: 3 },
    1752: { name: "Portal", size: 3 },
    1753: { name: "Portal", size: 3 },
    1754: { name: "Portal", size: 3 },
    1755: { name: "Void Knight", size: 1 },
    1756: { name: "Void Knight", size: 1 },
    1757: { name: "Void Knight", size: 1 },
    1758: { name: "Void Knight", size: 1 },
    1759: { name: "Squire", size: 1 },
    1760: { name: "Squire", size: 1 },
    1761: { name: "Squire", size: 1 },
    1762: { name: "Squire", size: 1 },
    1763: { name: "null", size: 1 },
    1764: { name: "Squire", size: 1 },
    1765: { name: "Squire", size: 1 },
    1766: { name: "Squire", size: 1 },
    1767: { name: "Squire", size: 1 },
    1768: { name: "Squire", size: 1 },
    1769: { name: "Squire", size: 1 },
    1770: { name: "Squire", size: 1 },
    1771: { name: "Squire (Novice)", size: 1 },
    1772: { name: "Squire (Intermediate)", size: 1 },
    1773: { name: "Squire (Veteran)", size: 1 },
    1774: { name: "Uri", size: 1 },
    1775: { name: "Uri", size: 1 },
    1776: { name: "Uri", size: 1 },
    1777: { name: "Double agent", size: 1 },
    1778: { name: "Double agent", size: 1 },
    1779: { name: "Guardian mummy", size: 1 },
    1780: { name: "Annoyed guardian mummy", size: 1 },
    1781: { name: "Tarik", size: 1 },
    1782: { name: "Scarab Swarm", size: 1 },
    1783: { name: "Malignius Mortifer", size: 1 },
    1784: { name: "Zombie", size: 1 },
    1785: { name: "Skeleton", size: 1 },
    1786: { name: "Ghost", size: 1 },
    1787: { name: "Skeleton mage", size: 1 },
    1788: { name: "Betty", size: 1 },
    1789: { name: "Grum", size: 1 },
    1790: { name: "Gerrant", size: 1 },
    1791: { name: "Wydin", size: 1 },
    1792: { name: "Goat", size: 1 },
    1793: { name: "Goat", size: 1 },
    1794: { name: "Billy Goat", size: 1 },
    1795: { name: "Goat", size: 1 },
    1796: { name: "Goat", size: 1 },
    1797: { name: "Billy Goat", size: 1 },
    1798: { name: "White Knight", size: 1 },
    1799: { name: "White Knight", size: 1 },
    1800: { name: "White Knight", size: 1 },
    1801: { name: "Summer Elemental", size: 1 },
    1802: { name: "Summer Elemental", size: 1 },
    1803: { name: "Summer Elemental", size: 1 },
    1804: { name: "Summer Elemental", size: 1 },
    1805: { name: "Summer Elemental", size: 1 },
    1806: { name: "Summer Elemental", size: 1 },
    1807: { name: "Sorceress", size: 1 },
    1808: { name: "Apprentice", size: 1 },
    1809: { name: "Osman", size: 1 },
    1810: { name: "Osman", size: 1 },
    1811: { name: "Osman", size: 1 },
    1812: { name: "null", size: 1 },
    1813: { name: "Del-Monty", size: 1 },
    1814: { name: "San Fan", size: 1 },
    1815: { name: "Fancy Dan", size: 1 },
    1816: { name: "Honest Jimmy", size: 1 },
    1817: { name: "Monkey", size: 1 },
    1818: { name: "Swarm", size: 1 },
    1819: { name: "null", size: 1 },
    1820: { name: "null", size: 1 },
    1821: { name: "null", size: 1 },
    1822: { name: "null", size: 1 },
    1823: { name: "null", size: 1 },
    1824: { name: "null", size: 1 },
    1825: { name: "Blue Monkey", size: 1 },
    1826: { name: "Red Monkey", size: 1 },
    1827: { name: "Parrot", size: 1 },
    1828: { name: "Parrot", size: 1 },
    1829: { name: "White Knight", size: 1 },
    1830: { name: "Shark", size: 1 },
    1831: { name: "Swan", size: 1 },
    1832: { name: "Black swan", size: 1 },
    1833: { name: "Bandit shopkeeper", size: 1 },
    1834: { name: "Gorak", size: 2 },
    1835: { name: "Cosmic Being", size: 1 },
    1836: { name: "null", size: 1 },
    1837: { name: "null", size: 1 },
    1838: { name: "Duck", size: 1 },
    1839: { name: "Duck", size: 1 },
    1840: { name: "Fairy Godfather", size: 1 },
    1841: { name: "Fairy Nuff", size: 1 },
    1842: { name: "Fairy Queen", size: 1 },
    1843: { name: "Centaur", size: 2 },
    1844: { name: "Centaur", size: 2 },
    1845: { name: "Stag", size: 2 },
    1846: { name: "Wood Dryad", size: 1 },
    1847: { name: "Fairy Very Wise", size: 1 },
    1848: { name: "Fairy", size: 1 },
    1849: { name: "Fairy", size: 1 },
    1850: { name: "Fairy", size: 1 },
    1851: { name: "Fairy", size: 1 },
    1852: { name: "Rabbit", size: 1 },
    1853: { name: "Rabbit", size: 1 },
    1854: { name: "Butterfly", size: 1 },
    1855: { name: "Butterfly", size: 1 },
    1856: { name: "Starflower", size: 1 },
    1857: { name: "Starflower", size: 1 },
    1858: { name: "null", size: 1 },
    1859: { name: "null", size: 1 },
    1860: { name: "null", size: 1 },
    1861: { name: "Tree spirit", size: 1 },
    1862: { name: "Tree spirit", size: 1 },
    1863: { name: "Tree spirit", size: 1 },
    1864: { name: "Tree spirit", size: 1 },
    1865: { name: "Tree spirit", size: 1 },
    1866: { name: "Tree spirit", size: 1 },
    1867: { name: "Sir Amik Varze", size: 1 },
    1868: { name: "null", size: 1 },
    1869: { name: "Sir Amik Varze", size: 1 },
    1870: { name: "Evil Chicken", size: 1 },
    1871: { name: "Baby black dragon", size: 2 },
    1872: { name: "Baby black dragon", size: 2 },
    1873: { name: "K'klik", size: 1 },
    1874: { name: "Ice troll runt", size: 1 },
    1875: { name: "Ice troll male", size: 1 },
    1876: { name: "Ice troll female", size: 1 },
    1877: { name: "Ice troll grunt", size: 1 },
    1878: { name: "Mawnis Burowgar", size: 1 },
    1879: { name: "Mawnis Burowgar", size: 1 },
    1880: { name: "Fridleif Shieldson", size: 1 },
    1881: { name: "Thakkrad Sigmundson", size: 1 },
    1882: { name: "Maria Gunnars", size: 1 },
    1883: { name: "Maria Gunnars", size: 1 },
    1884: { name: "Jofridr Mordstatter", size: 1 },
    1885: { name: "Morten Holdstrom", size: 1 },
    1886: { name: "Gunnar Holdstrom", size: 1 },
    1887: { name: "Anne Isaakson", size: 1 },
    1888: { name: "Lisse Isaakson", size: 1 },
    1889: { name: "Honour guard", size: 1 },
    1890: { name: "Honour guard", size: 1 },
    1891: { name: "Honour guard", size: 1 },
    1892: { name: "Honour guard", size: 1 },
    1893: { name: "Kjedelig Uppsen", size: 1 },
    1894: { name: "Trogen Konungarde", size: 1 },
    1895: { name: "Slug Hemligssen", size: 1 },
    1896: { name: "Candle seller", size: 1 },
    1897: { name: "King Gjuki Sorvott IV", size: 1 },
    1898: { name: "HRH Hrafn", size: 1 },
    1899: { name: "Thorkel Silkbeard", size: 1 },
    1900: { name: "Mord Gunnars", size: 1 },
    1901: { name: "Art Critic Jacques", size: 1 },
    1902: { name: "Historian Minas", size: 1 },
    1903: { name: "Barnabus Hurma", size: 1 },
    1904: { name: "Marius Giste", size: 1 },
    1905: { name: "Caden Azro", size: 1 },
    1906: { name: "Thias Leacke", size: 1 },
    1907: { name: "Sinco Doar", size: 1 },
    1908: { name: "Tinse Torpe", size: 1 },
    1909: { name: "Information clerk", size: 1 },
    1910: { name: "Museum guard", size: 1 },
    1911: { name: "Museum guard", size: 1 },
    1912: { name: "Museum guard", size: 1 },
    1913: { name: "Teacher and pupil", size: 1 },
    1914: { name: "Schoolgirl", size: 1 },
    1915: { name: "Schoolgirl", size: 1 },
    1916: { name: "Schoolgirl", size: 1 },
    1917: { name: "Schoolgirl", size: 1 },
    1918: { name: "Schoolgirl", size: 1 },
    1919: { name: "Schoolboy", size: 1 },
    1920: { name: "Schoolboy", size: 1 },
    1921: { name: "Schoolgirl", size: 1 },
    1922: { name: "Teacher and pupil", size: 1 },
    1923: { name: "Teacher and pupil", size: 1 },
    1924: { name: "Schoolboy", size: 1 },
    1925: { name: "Teacher", size: 1 },
    1926: { name: "Schoolgirl", size: 1 },
    1927: { name: "Workman", size: 2 },
    1928: { name: "Workman", size: 2 },
    1929: { name: "Workman", size: 2 },
    1930: { name: "Workman", size: 2 },
    1931: { name: "Workman", size: 2 },
    1932: { name: "Workman", size: 2 },
    1933: { name: "Dig Site workman", size: 1 },
    1934: { name: "Barge workman", size: 1 },
    1935: { name: "Barge workman", size: 1 },
    1936: { name: "Barge workman", size: 1 },
    1937: { name: "Barge workman", size: 1 },
    1938: { name: "Barge foreman", size: 1 },
    1939: { name: "Ed Wood", size: 1 },
    1940: { name: "Mord Gunnars", size: 1 },
    1941: { name: "Hring Hring", size: 1 },
    1942: { name: "Flosi Dalksson", size: 1 },
    1943: { name: "Raum Urda-Stein", size: 1 },
    1944: { name: "Skuli Myrka", size: 1 },
    1945: { name: "Keepa Kettilon", size: 1 },
    1946: { name: "null", size: 1 },
    1947: { name: "Guard", size: 1 },
    1948: { name: "Guard", size: 1 },
    1949: { name: "Guard", size: 1 },
    1950: { name: "Guard", size: 1 },
    1951: { name: "Freygerd", size: 1 },
    1952: { name: "Lensa", size: 1 },
    1953: { name: "null", size: 1 },
    1954: { name: "null", size: 1 },
    1955: { name: "null", size: 1 },
    1956: { name: "null", size: 1 },
    1957: { name: "null", size: 1 },
    1958: { name: "null", size: 1 },
    1959: { name: "null", size: 1 },
    1960: { name: "null", size: 1 },
    1961: { name: "null", size: 1 },
    1962: { name: "null", size: 1 },
    1963: { name: "null", size: 1 },
    1964: { name: "null", size: 1 },
    1965: { name: "null", size: 1 },
    1966: { name: "null", size: 1 },
    1967: { name: "null", size: 1 },
    1968: { name: "null", size: 1 },
    1969: { name: "null", size: 2 },
    1970: { name: "null", size: 2 },
    1971: { name: "null", size: 1 },
    1972: { name: "null", size: 1 },
    1973: { name: "null", size: 2 },
    1974: { name: "null", size: 1 },
    1975: { name: "null", size: 1 },
    1976: { name: "null", size: 1 },
    1977: { name: "null", size: 1 },
    1978: { name: "null", size: 1 },
    1979: { name: "null", size: 1 },
    1980: { name: "null", size: 1 },
    1981: { name: "null", size: 1 },
    1982: { name: "null", size: 1 },
    1983: { name: "null", size: 1 },
    1984: { name: "null", size: 1 },
    1985: { name: "null", size: 1 },
    1986: { name: "null", size: 1 },
    1987: { name: "null", size: 1 },
    1988: { name: "null", size: 3 },
    1989: { name: "null", size: 1 },
    1990: { name: "Vanligga Gastfrihet", size: 1 },
    1991: { name: "Sassilik", size: 1 },
    1992: { name: "Miner", size: 1 },
    1993: { name: "Miner", size: 1 },
    1994: { name: "null", size: 1 },
    1995: { name: "null", size: 1 },
    1996: { name: "null", size: 1 },
    1997: { name: "Eric", size: 1 },
    1998: { name: "Gruva Patrull", size: 1 },
    1999: { name: "Brendt", size: 1 },
    2000: { name: "Grundt", size: 1 },
    2001: { name: "Duckling", size: 1 },
    2002: { name: "Ducklings", size: 1 },
    2003: { name: "Duck", size: 1 },
    2004: { name: "Drake", size: 1 },
    2005: { name: "Lesser demon", size: 2 },
    2006: { name: "Lesser demon", size: 2 },
    2007: { name: "Lesser demon", size: 2 },
    2008: { name: "Lesser demon", size: 2 },
    2009: { name: "null", size: 1 },
    2010: { name: "null", size: 1 },
    2011: { name: "null", size: 1 },
    2012: { name: "null", size: 1 },
    2013: { name: "null", size: 1 },
    2014: { name: "null", size: 1 },
    2015: { name: "null", size: 1 },
    2016: { name: "null", size: 1 },
    2017: { name: "null", size: 1 },
    2018: { name: "Lesser demon", size: 2 },
    2019: { name: "null", size: 2 },
    2020: { name: "null", size: 1 },
    2021: { name: "null", size: 1 },
    2022: { name: "null", size: 1 },
    2023: { name: "null", size: 1 },
    2024: { name: "null", size: 1 },
    2025: { name: "Greater demon", size: 3 },
    2026: { name: "Greater demon", size: 3 },
    2027: { name: "Greater demon", size: 3 },
    2028: { name: "Greater demon", size: 3 },
    2029: { name: "Greater demon", size: 3 },
    2030: { name: "Greater demon", size: 3 },
    2031: { name: "Greater demon", size: 3 },
    2032: { name: "Greater demon", size: 3 },
    2033: { name: "Priestess Zul-Gwenwynig", size: 1 },
    2034: { name: "Zul-Urgish", size: 2 },
    2035: { name: "Zul-Cheray", size: 1 },
    2036: { name: "Zul-Gutusolly", size: 1 },
    2037: { name: "Sacrifice", size: 1 },
    2038: { name: "Zul-Onan", size: 1 },
    2039: { name: "Zul-Aniel", size: 1 },
    2040: { name: "Zul-Areth", size: 1 },
    2041: { name: "Broken Handz", size: 1 },
    2042: { name: "Zulrah", size: 5 },
    2043: { name: "Zulrah", size: 5 },
    2044: { name: "Zulrah", size: 5 },
    2045: { name: "Snakeling", size: 1 },
    2046: { name: "Snakeling", size: 1 },
    2047: { name: "Snakeling", size: 1 },
    2048: { name: "Black demon", size: 3 },
    2049: { name: "Black demon", size: 3 },
    2050: { name: "Black demon", size: 3 },
    2051: { name: "Black demon", size: 3 },
    2052: { name: "Black demon", size: 3 },
    2053: { name: "Fritz the Glassblower", size: 1 },
    2054: { name: "Chaos Elemental", size: 3 },
    2055: { name: "Chaos Elemental Jr.", size: 1 },
    2056: { name: "Dark wizard", size: 1 },
    2057: { name: "Dark wizard", size: 1 },
    2058: { name: "Dark wizard", size: 1 },
    2059: { name: "Dark wizard", size: 1 },
    2060: { name: "Dodgy Squire", size: 1 },
    2061: { name: "Glough", size: 1 },
    2062: { name: "Oomlie bird", size: 1 },
    2063: { name: "Penguin", size: 1 },
    2064: { name: "Terrorbird", size: 2 },
    2065: { name: "Terrorbird", size: 2 },
    2066: { name: "Terrorbird", size: 2 },
    2067: { name: "Mounted terrorbird gnome", size: 2 },
    2068: { name: "Mounted terrorbird gnome", size: 2 },
    2069: { name: "Crow", size: 1 },
    2070: { name: "Crow", size: 1 },
    2071: { name: "Crow", size: 1 },
    2072: { name: "Crow", size: 1 },
    2073: { name: "Crow", size: 1 },
    2074: { name: "Crow", size: 1 },
    2075: { name: "Fire giant", size: 2 },
    2076: { name: "Fire giant", size: 2 },
    2077: { name: "Fire giant", size: 2 },
    2078: { name: "Fire giant", size: 2 },
    2079: { name: "Fire giant", size: 2 },
    2080: { name: "Fire giant", size: 2 },
    2081: { name: "Fire giant", size: 2 },
    2082: { name: "Fire giant", size: 2 },
    2083: { name: "Fire giant", size: 2 },
    2084: { name: "Fire giant", size: 2 },
    2085: { name: "Ice giant", size: 2 },
    2086: { name: "Ice giant", size: 2 },
    2087: { name: "Ice giant", size: 2 },
    2088: { name: "Ice giant", size: 2 },
    2089: { name: "Ice giant", size: 2 },
    2090: { name: "Moss giant", size: 2 },
    2091: { name: "Moss giant", size: 2 },
    2092: { name: "Moss giant", size: 2 },
    2093: { name: "Moss giant", size: 2 },
    2094: { name: "Jogre", size: 2 },
    2095: { name: "Ogre", size: 2 },
    2096: { name: "Ogre", size: 2 },
    2097: { name: "Cyclops", size: 2 },
    2098: { name: "Hill Giant", size: 2 },
    2099: { name: "Hill Giant", size: 2 },
    2100: { name: "Hill Giant", size: 2 },
    2101: { name: "Hill Giant", size: 2 },
    2102: { name: "Hill Giant", size: 2 },
    2103: { name: "Hill Giant", size: 2 },
    2104: { name: "Ork", size: 1 },
    2105: { name: "Ork", size: 1 },
    2106: { name: "Ork", size: 1 },
    2107: { name: "Ork", size: 1 },
    2108: { name: "Wise Old Man", size: 1 },
    2109: { name: "null", size: 1 },
    2110: { name: "Wise Old Man", size: 1 },
    2111: { name: "Wise Old Man", size: 1 },
    2112: { name: "Wise Old Man", size: 1 },
    2113: { name: "Wise Old Man", size: 1 },
    2114: { name: "Bed", size: 2 },
    2115: { name: "Thing under the bed", size: 2 },
    2116: { name: "Miss Schism", size: 1 },
    2117: { name: "Banker", size: 1 },
    2118: { name: "Banker", size: 1 },
    2119: { name: "Banker", size: 1 },
    2120: { name: "Market Guard", size: 1 },
    2121: { name: "Olivia", size: 1 },
    2122: { name: "Pillory Guard", size: 1 },
    2123: { name: "Bank guard", size: 1 },
    2124: { name: "null", size: 1 },
    2125: { name: "Badger", size: 1 },
    2126: { name: "Badger", size: 1 },
    2127: { name: "Snakeling", size: 1 },
    2128: { name: "Snakeling", size: 1 },
    2129: { name: "Snakeling", size: 1 },
    2130: { name: "Snakeling", size: 1 },
    2131: { name: "Snakeling", size: 1 },
    2132: { name: "Snakeling", size: 1 },
    2133: { name: "Tiles", size: 1 },
    2134: { name: "Aisles", size: 1 },
    2135: { name: "Lorelai", size: 1 },
    2136: { name: "Rory", size: 1 },
    2137: { name: "Cyclops", size: 2 },
    2138: { name: "Cyclops", size: 2 },
    2139: { name: "Cyclops", size: 2 },
    2140: { name: "Cyclops", size: 2 },
    2141: { name: "Cyclops", size: 2 },
    2142: { name: "Cyclops", size: 2 },
    2143: { name: "Sraracha", size: 1 },
    2144: { name: "Sraracha", size: 1 },
    2145: { name: "Undead Druid", size: 1 },
    2146: { name: "Fishing spot", size: 1 },
    2147: { name: "null", size: 1 },
    2148: { name: "Grand Exchange Clerk", size: 1 },
    2149: { name: "Grand Exchange Clerk", size: 1 },
    2150: { name: "Grand Exchange Clerk", size: 1 },
    2151: { name: "Grand Exchange Clerk", size: 1 },
    2152: { name: "Brugsen Bursen", size: 1 },
    2153: { name: "Gunnjorn", size: 1 },
    2154: { name: "TzHaar-Mej", size: 1 },
    2155: { name: "TzHaar-Mej", size: 1 },
    2156: { name: "TzHaar-Mej", size: 1 },
    2157: { name: "TzHaar-Mej", size: 1 },
    2158: { name: "TzHaar-Mej", size: 1 },
    2159: { name: "TzHaar-Mej", size: 1 },
    2160: { name: "TzHaar-Mej", size: 1 },
    2161: { name: "TzHaar-Hur", size: 1 },
    2162: { name: "TzHaar-Hur", size: 1 },
    2163: { name: "TzHaar-Hur", size: 1 },
    2164: { name: "TzHaar-Hur", size: 1 },
    2165: { name: "TzHaar-Hur", size: 1 },
    2166: { name: "TzHaar-Hur", size: 1 },
    2167: { name: "TzHaar-Xil", size: 1 },
    2168: { name: "TzHaar-Xil", size: 1 },
    2169: { name: "TzHaar-Xil", size: 1 },
    2170: { name: "TzHaar-Xil", size: 1 },
    2171: { name: "TzHaar-Xil", size: 1 },
    2172: { name: "TzHaar-Xil", size: 1 },
    2173: { name: "TzHaar-Ket", size: 1 },
    2174: { name: "TzHaar-Ket", size: 1 },
    2175: { name: "TzHaar-Ket", size: 1 },
    2176: { name: "TzHaar-Ket", size: 1 },
    2177: { name: "TzHaar-Ket", size: 1 },
    2178: { name: "TzHaar-Ket", size: 1 },
    2179: { name: "TzHaar-Ket", size: 1 },
    2180: { name: "TzHaar-Mej-Jal", size: 1 },
    2181: { name: "TzHaar-Mej-Kah", size: 1 },
    2182: { name: "Rock Golem", size: 1 },
    2183: { name: "TzHaar-Hur-Tel", size: 1 },
    2184: { name: "TzHaar-Hur-Lek", size: 1 },
    2185: { name: "TzHaar-Mej-Roh", size: 1 },
    2186: { name: "TzHaar-Ket", size: 1 },
    2187: { name: "TzHaar-Ket", size: 1 },
    2188: { name: "Rocks", size: 1 },
    2189: { name: "Tz-Kih", size: 1 },
    2190: { name: "Tz-Kih", size: 1 },
    2191: { name: "Tz-Kek", size: 2 },
    2192: { name: "Tz-Kek", size: 2 },
    2193: { name: "Tok-Xil", size: 3 },
    2194: { name: "Tok-Xil", size: 3 },
    2195: { name: "William", size: 2 },
    2196: { name: "Ian", size: 2 },
    2197: { name: "Larry", size: 2 },
    2198: { name: "Darren", size: 2 },
    2199: { name: "Edward", size: 2 },
    2200: { name: "Richard", size: 2 },
    2201: { name: "Neil", size: 2 },
    2202: { name: "Edmond", size: 2 },
    2203: { name: "Simon", size: 2 },
    2204: { name: "Sam", size: 2 },
    2205: { name: "Commander Zilyana", size: 2 },
    2206: { name: "Starlight", size: 2 },
    2207: { name: "Growler", size: 3 },
    2208: { name: "Bree", size: 2 },
    2209: { name: "Saradomin priest", size: 1 },
    2210: { name: "Spiritual warrior", size: 1 },
    2211: { name: "Spiritual ranger", size: 1 },
    2212: { name: "Spiritual mage", size: 1 },
    2213: { name: "Knight of Saradomin", size: 1 },
    2214: { name: "Knight of Saradomin", size: 1 },
    2215: { name: "General Graardor", size: 4 },
    2216: { name: "Sergeant Strongstack", size: 1 },
    2217: { name: "Sergeant Steelwill", size: 1 },
    2218: { name: "Sergeant Grimspike", size: 1 },
    2219: { name: "Purple Pewter Director", size: 1 },
    2220: { name: "Purple Pewter Director", size: 1 },
    2221: { name: "Blue Opal Director", size: 1 },
    2222: { name: "Yellow Fortune Director", size: 1 },
    2223: { name: "Green Gemstone Director", size: 1 },
    2224: { name: "White Chisel Director", size: 1 },
    2225: { name: "Silver Cog Director", size: 1 },
    2226: { name: "Brown Engine Director", size: 1 },
    2227: { name: "Red Axe Director", size: 1 },
    2228: { name: "Commander Veldaban", size: 1 },
    2229: { name: "Red Axe Cat", size: 1 },
    2230: { name: "Red Axe Cat", size: 1 },
    2231: { name: "null", size: 1 },
    2232: { name: "Black Guard Berserker", size: 1 },
    2233: { name: "Ogre", size: 2 },
    2234: { name: "Jogre", size: 2 },
    2235: { name: "Cyclops", size: 2 },
    2236: { name: "Cyclops", size: 2 },
    2237: { name: "Ork", size: 1 },
    2238: { name: "Ork", size: 1 },
    2239: { name: "Ork", size: 1 },
    2240: { name: "Ork", size: 1 },
    2241: { name: "Hobgoblin", size: 1 },
    2242: { name: "Spiritual ranger", size: 1 },
    2243: { name: "Spiritual warrior", size: 1 },
    2244: { name: "Spiritual mage", size: 1 },
    2245: { name: "Goblin", size: 1 },
    2246: { name: "Goblin", size: 1 },
    2247: { name: "Goblin", size: 1 },
    2248: { name: "Goblin", size: 1 },
    2249: { name: "Goblin", size: 1 },
    2250: { name: "Door-support", size: 1 },
    2251: { name: "Door", size: 1 },
    2252: { name: "Door", size: 1 },
    2253: { name: "Door-support", size: 1 },
    2254: { name: "Door", size: 1 },
    2255: { name: "Door", size: 1 },
    2256: { name: "Door-support", size: 1 },
    2257: { name: "Door", size: 1 },
    2258: { name: "Door", size: 1 },
    2259: { name: "Dagannoth", size: 1 },
    2260: { name: "null", size: 1 },
    2261: { name: "Giant Rock Crab", size: 2 },
    2262: { name: "Boulder", size: 2 },
    2263: { name: "Bardur", size: 1 },
    2264: { name: "Dagannoth fledgeling", size: 2 },
    2265: { name: "Dagannoth Supreme", size: 3 },
    2266: { name: "Dagannoth Prime", size: 3 },
    2267: { name: "Dagannoth Rex", size: 3 },
    2268: { name: "Cave goblin", size: 1 },
    2269: { name: "Cave goblin", size: 1 },
    2270: { name: "Cave goblin", size: 1 },
    2271: { name: "Cave goblin", size: 1 },
    2272: { name: "Cave goblin", size: 1 },
    2273: { name: "Cave goblin", size: 1 },
    2274: { name: "Cave goblin", size: 1 },
    2275: { name: "Cave goblin", size: 1 },
    2276: { name: "Cave goblin", size: 1 },
    2277: { name: "Cave goblin", size: 1 },
    2278: { name: "Cave goblin", size: 1 },
    2279: { name: "Cave goblin", size: 1 },
    2280: { name: "Cave goblin", size: 1 },
    2281: { name: "Cave goblin", size: 1 },
    2282: { name: "Cave goblin", size: 1 },
    2283: { name: "Cave goblin", size: 1 },
    2284: { name: "Cave goblin", size: 1 },
    2285: { name: "Cave goblin", size: 1 },
    2286: { name: "Ur-zek", size: 1 },
    2287: { name: "Ur-vass", size: 1 },
    2288: { name: "Ur-taal", size: 1 },
    2289: { name: "Ur-meg", size: 1 },
    2290: { name: "Ur-lun", size: 1 },
    2291: { name: "Ur-pel", size: 1 },
    2292: { name: "Banker", size: 1 },
    2293: { name: "Banker", size: 1 },
    2294: { name: "Bartak", size: 1 },
    2295: { name: "Turgall", size: 1 },
    2296: { name: "Reldak", size: 1 },
    2297: { name: "Miltog", size: 1 },
    2298: { name: "Mernik", size: 1 },
    2299: { name: "Cave goblin", size: 1 },
    2300: { name: "Crate goblin", size: 1 },
    2301: { name: "Cave goblin", size: 1 },
    2302: { name: "Goblin scribe", size: 1 },
    2303: { name: "null", size: 1 },
    2304: { name: "Gourmet", size: 1 },
    2305: { name: "Gourmet", size: 1 },
    2306: { name: "Gourmet", size: 1 },
    2307: { name: "Gourmet", size: 1 },
    2308: { name: "Turgok", size: 1 },
    2309: { name: "Markog", size: 1 },
    2310: { name: "Durgok", size: 1 },
    2311: { name: "Tindar", size: 1 },
    2312: { name: "Gundik", size: 1 },
    2313: { name: "Zenkog", size: 1 },
    2314: { name: "Lurgon", size: 1 },
    2315: { name: "Ur-tag", size: 1 },
    2316: { name: "Guard", size: 1 },
    2317: { name: "Guard", size: 1 },
    2318: { name: "Zanik", size: 1 },
    2319: { name: "Young 'un", size: 1 },
    2320: { name: "Tyke", size: 1 },
    2321: { name: "Nipper", size: 1 },
    2322: { name: "Nipper", size: 1 },
    2323: { name: "Cave goblin child", size: 1 },
    2324: { name: "Cave goblin child", size: 1 },
    2325: { name: "Cave goblin child", size: 1 },
    2326: { name: "Cave goblin child", size: 1 },
    2327: { name: "Cave goblin child", size: 1 },
    2328: { name: "Cave goblin child", size: 1 },
    2329: { name: "Cave goblin child", size: 1 },
    2330: { name: "Cave goblin child", size: 1 },
    2331: { name: "Cave goblin child", size: 1 },
    2332: { name: "Cave goblin child", size: 1 },
    2333: { name: "Cave goblin child", size: 1 },
    2334: { name: "Cave goblin child", size: 1 },
    2335: { name: "Cave goblin child", size: 1 },
    2336: { name: "Cave goblin child", size: 1 },
    2337: { name: "Cave goblin child", size: 1 },
    2338: { name: "Cave goblin child", size: 1 },
    2339: { name: "Spit goblin", size: 1 },
    2340: { name: "Goblin fish", size: 1 },
    2341: { name: "Movario", size: 1 },
    2342: { name: "Darve", size: 1 },
    2343: { name: "Moths", size: 1 },
    2344: { name: "Barlak", size: 1 },
    2345: { name: "Saniboch", size: 1 },
    2346: { name: "Dromund's cat", size: 1 },
    2347: { name: "Blasidar the sculptor", size: 1 },
    2348: { name: "Riki the sculptor's model", size: 1 },
    2349: { name: "Riki the sculptor's model", size: 1 },
    2350: { name: "Riki the sculptor's model", size: 1 },
    2351: { name: "Riki the sculptor's model", size: 1 },
    2352: { name: "Riki the sculptor's model", size: 1 },
    2353: { name: "Riki the sculptor's model", size: 1 },
    2354: { name: "Riki the sculptor's model", size: 1 },
    2355: { name: "Riki the sculptor's model", size: 1 },
    2356: { name: "Vigr", size: 1 },
    2357: { name: "Santiri", size: 1 },
    2358: { name: "Saro", size: 1 },
    2359: { name: "Gunslik", size: 1 },
    2360: { name: "Wemund", size: 1 },
    2361: { name: "Randivor", size: 1 },
    2362: { name: "Hervi", size: 1 },
    2363: { name: "Nolar", size: 1 },
    2364: { name: "Gulldamar", size: 1 },
    2365: { name: "Tati", size: 1 },
    2366: { name: "Agmundi", size: 1 },
    2367: { name: "Vermundi", size: 1 },
    2368: { name: "Banker", size: 1 },
    2369: { name: "Banker", size: 1 },
    2370: { name: "Librarian", size: 1 },
    2371: { name: "Assistant", size: 1 },
    2372: { name: "Customer", size: 1 },
    2373: { name: "Customer", size: 1 },
    2374: { name: "Dromund", size: 1 },
    2375: { name: "Rind the gardener", size: 1 },
    2376: { name: "Factory Manager", size: 1 },
    2377: { name: "Factory Worker", size: 1 },
    2378: { name: "Factory Worker", size: 1 },
    2379: { name: "Factory Worker", size: 1 },
    2380: { name: "Factory Worker", size: 1 },
    2381: { name: "Inn Keeper", size: 1 },
    2382: { name: "Inn Keeper", size: 1 },
    2383: { name: "Barmaid", size: 1 },
    2384: { name: "Barman", size: 1 },
    2385: { name: "Cart conductor", size: 1 },
    2386: { name: "Cart conductor", size: 1 },
    2387: { name: "Cart conductor", size: 1 },
    2388: { name: "Cart conductor", size: 1 },
    2389: { name: "Cart conductor", size: 1 },
    2390: { name: "Cart conductor", size: 1 },
    2391: { name: "Cart conductor", size: 1 },
    2392: { name: "Cart conductor", size: 1 },
    2393: { name: "Rowdy dwarf", size: 1 },
    2394: { name: "Hegir", size: 1 },
    2395: { name: "Haera", size: 1 },
    2396: { name: "Runvastr", size: 1 },
    2397: { name: "Sune", size: 1 },
    2398: { name: "Bentamir", size: 1 },
    2399: { name: "Ulifed", size: 1 },
    2400: { name: "Reinald", size: 1 },
    2401: { name: "Karl", size: 1 },
    2402: { name: "Gauss", size: 1 },
    2403: { name: "Myndill", size: 1 },
    2404: { name: "Kjut", size: 1 },
    2405: { name: "Tombar", size: 1 },
    2406: { name: "Odmar", size: 1 },
    2407: { name: "Audmann", size: 1 },
    2408: { name: "Drunken Dwarf", size: 1 },
    2409: { name: "Drunken Dwarf", size: 1 },
    2410: { name: "Red Axe Director", size: 1 },
    2411: { name: "Red Axe Director", size: 1 },
    2412: { name: "Red Axe Henchman", size: 1 },
    2413: { name: "Red Axe Henchman", size: 1 },
    2414: { name: "Red Axe Henchman", size: 1 },
    2415: { name: "Colonel Grimsson", size: 1 },
    2416: { name: "Colonel Grimsson", size: 1 },
    2417: { name: "Ogre shaman", size: 2 },
    2418: { name: "Ogre shaman", size: 2 },
    2419: { name: "Grunsh", size: 2 },
    2420: { name: "Gnome emissary", size: 1 },
    2421: { name: "Gnome companion", size: 1 },
    2422: { name: "Gnome companion", size: 1 },
    2423: { name: "Chaos dwarf", size: 1 },
    2424: { name: "Gunslik", size: 1 },
    2425: { name: "Nolar", size: 1 },
    2426: { name: "Factory Worker", size: 1 },
    2427: { name: "Cart conductor", size: 1 },
    2428: { name: "Gauss", size: 1 },
    2429: { name: "Drunken Dwarf", size: 1 },
    2430: { name: "Rowdy dwarf", size: 1 },
    2431: { name: "Ulifed", size: 1 },
    2432: { name: "null", size: 1 },
    2433: { name: "Dwarven Boatman", size: 1 },
    2434: { name: "Dwarven Miner", size: 1 },
    2435: { name: "Dwarven Miner", size: 1 },
    2436: { name: "Dwarven Miner", size: 1 },
    2437: { name: "Dwarven Miner", size: 1 },
    2438: { name: "Dwarven Miner", size: 1 },
    2439: { name: "Dwarven Miner", size: 1 },
    2440: { name: "Dwarven Miner", size: 1 },
    2441: { name: "Dwarven Miner", size: 1 },
    2442: { name: "Dwarven Miner", size: 1 },
    2443: { name: "Dwarven Miner", size: 1 },
    2444: { name: "Dwarven Miner", size: 1 },
    2445: { name: "Dwarven Miner", size: 1 },
    2446: { name: "Dwarven Miner", size: 1 },
    2447: { name: "Dwarven Miner", size: 1 },
    2448: { name: "Dwarven Miner", size: 1 },
    2449: { name: "Buinn", size: 5 },
    2450: { name: "Animated Bronze Armour", size: 1 },
    2451: { name: "Animated Iron Armour", size: 1 },
    2452: { name: "Animated Steel Armour", size: 1 },
    2453: { name: "Animated Black Armour", size: 1 },
    2454: { name: "Animated Mithril Armour", size: 1 },
    2455: { name: "Animated Adamant Armour", size: 1 },
    2456: { name: "Animated Rune Armour", size: 1 },
    2457: { name: "null", size: 1 },
    2458: { name: "null", size: 1 },
    2459: { name: "Gamfred", size: 1 },
    2460: { name: "Ajjat", size: 1 },
    2461: { name: "Kamfreena", size: 1 },
    2462: { name: "Shanomi", size: 1 },
    2463: { name: "Cyclops", size: 2 },
    2464: { name: "Cyclops", size: 2 },
    2465: { name: "Cyclops", size: 2 },
    2466: { name: "Cyclops", size: 2 },
    2467: { name: "Cyclops", size: 2 },
    2468: { name: "Cyclops", size: 2 },
    2469: { name: "Lidio", size: 1 },
    2470: { name: "Lilly", size: 1 },
    2471: { name: "Anton", size: 1 },
    2472: { name: "Jade", size: 1 },
    2473: { name: "null", size: 1 },
    2474: { name: "Catablepon", size: 2 },
    2475: { name: "Catablepon", size: 2 },
    2476: { name: "Catablepon", size: 2 },
    2477: { name: "Giant spider", size: 1 },
    2478: { name: "Spider", size: 1 },
    2479: { name: "Scorpion", size: 2 },
    2480: { name: "Scorpion", size: 2 },
    2481: { name: "Minotaur", size: 1 },
    2482: { name: "Minotaur", size: 1 },
    2483: { name: "Minotaur", size: 1 },
    2484: { name: "Goblin", size: 1 },
    2485: { name: "Goblin", size: 1 },
    2486: { name: "Goblin", size: 1 },
    2487: { name: "Goblin", size: 1 },
    2488: { name: "Goblin", size: 1 },
    2489: { name: "Goblin", size: 1 },
    2490: { name: "Wolf", size: 2 },
    2491: { name: "Wolf", size: 2 },
    2492: { name: "Rat", size: 1 },
    2493: { name: "null", size: 1 },
    2494: { name: "Gate of War", size: 1 },
    2495: { name: "Ricketty door", size: 1 },
    2496: { name: "Oozing barrier", size: 1 },
    2497: { name: "Portal of Death", size: 1 },
    2498: { name: "Flesh Crawler", size: 2 },
    2499: { name: "Flesh Crawler", size: 2 },
    2500: { name: "Flesh Crawler", size: 2 },
    2501: { name: "Zombie", size: 1 },
    2502: { name: "Zombie", size: 1 },
    2503: { name: "Zombie", size: 1 },
    2504: { name: "Zombie", size: 1 },
    2505: { name: "Zombie", size: 1 },
    2506: { name: "Zombie", size: 1 },
    2507: { name: "Zombie", size: 1 },
    2508: { name: "Zombie", size: 1 },
    2509: { name: "Zombie", size: 1 },
    2510: { name: "Giant rat", size: 2 },
    2511: { name: "Giant rat", size: 2 },
    2512: { name: "Giant rat", size: 2 },
    2513: { name: "Rat", size: 1 },
    2514: { name: "Ankou", size: 1 },
    2515: { name: "Ankou", size: 1 },
    2516: { name: "Ankou", size: 1 },
    2517: { name: "Ankou", size: 1 },
    2518: { name: "Ankou", size: 1 },
    2519: { name: "Ankou", size: 1 },
    2520: { name: "Skeleton", size: 1 },
    2521: { name: "Skeleton", size: 1 },
    2522: { name: "Skeleton", size: 1 },
    2523: { name: "Skeleton", size: 1 },
    2524: { name: "Skeleton", size: 1 },
    2525: { name: "Skeleton", size: 1 },
    2526: { name: "Skeleton", size: 1 },
    2527: { name: "Ghost", size: 1 },
    2528: { name: "Ghost", size: 1 },
    2529: { name: "Ghost", size: 1 },
    2530: { name: "Ghost", size: 1 },
    2531: { name: "Ghost", size: 1 },
    2532: { name: "Ghost", size: 1 },
    2533: { name: "Ghost", size: 1 },
    2534: { name: "Ghost", size: 1 },
    2535: { name: "Johanhus Ulsbrecht", size: 1 },
    2536: { name: "H.A.M. Guard", size: 1 },
    2537: { name: "H.A.M. Guard", size: 1 },
    2538: { name: "H.A.M. Guard", size: 1 },
    2539: { name: "H.A.M. Deacon", size: 1 },
    2540: { name: "H.A.M. Member", size: 1 },
    2541: { name: "H.A.M. Member", size: 1 },
    2542: { name: "H.A.M. Member", size: 1 },
    2543: { name: "H.A.M. Member", size: 1 },
    2544: { name: "Mounted terrorchick gnome", size: 1 },
    2545: { name: "Captain Lamdoo", size: 1 },
    2546: { name: "Terrorchick gnome", size: 1 },
    2547: { name: "Gianne jnr.", size: 1 },
    2548: { name: "Timble", size: 1 },
    2549: { name: "Tamble", size: 1 },
    2550: { name: "Spang", size: 1 },
    2551: { name: "Brambickle", size: 1 },
    2552: { name: "Wingstone", size: 1 },
    2553: { name: "Penwie", size: 1 },
    2554: { name: "Generic Diplomat", size: 1 },
    2555: { name: "Ambassador Gimblewap", size: 1 },
    2556: { name: "Ambassador Spanfipple", size: 1 },
    2557: { name: "Ambassador Ferrnook", size: 1 },
    2558: { name: "Professor Manglethorp", size: 1 },
    2559: { name: "Damwin", size: 1 },
    2560: { name: "Professor Onglewip", size: 1 },
    2561: { name: "Professor Imblewyn", size: 1 },
    2562: { name: "Perrdur", size: 1 },
    2563: { name: "Dalila", size: 1 },
    2564: { name: "Sorrn", size: 1 },
    2565: { name: "Mimm", size: 1 },
    2566: { name: "Eebel", size: 1 },
    2567: { name: "Ermin", size: 1 },
    2568: { name: "Portobello", size: 1 },
    2569: { name: "Captain Ninto", size: 1 },
    2570: { name: "Captain Daerkin", size: 1 },
    2571: { name: "Meegle", size: 1 },
    2572: { name: "Wurbel", size: 1 },
    2573: { name: "Sarble", size: 1 },
    2574: { name: "Guard Vemmeldo", size: 1 },
    2575: { name: "Burkor", size: 1 },
    2576: { name: "Froono", size: 1 },
    2577: { name: "Abbot Langley", size: 1 },
    2578: { name: "Brother Jered", size: 1 },
    2579: { name: "Monk", size: 1 },
    2580: { name: "Mage of Zamorak", size: 1 },
    2581: { name: "Mage of Zamorak", size: 1 },
    2582: { name: "Mage of Zamorak", size: 1 },
    2583: { name: "Dark Mage", size: 1 },
    2584: { name: "Abyssal leech", size: 1 },
    2585: { name: "Abyssal guardian", size: 1 },
    2586: { name: "Abyssal walker", size: 1 },
    2587: { name: "Skippy", size: 1 },
    2588: { name: "Skippy", size: 1 },
    2589: { name: "Skippy", size: 1 },
    2590: { name: "Skippy", size: 1 },
    2591: { name: "A pile of broken glass", size: 1 },
    2592: { name: "Mogre", size: 2 },
    2593: { name: "Werewolf", size: 1 },
    2594: { name: "Werewolf", size: 1 },
    2595: { name: "Werewolf", size: 1 },
    2596: { name: "Werewolf", size: 1 },
    2597: { name: "Werewolf", size: 1 },
    2598: { name: "Werewolf", size: 1 },
    2599: { name: "Werewolf", size: 1 },
    2600: { name: "Werewolf", size: 1 },
    2601: { name: "Werewolf", size: 1 },
    2602: { name: "Werewolf", size: 1 },
    2603: { name: "Werewolf", size: 1 },
    2604: { name: "Werewolf", size: 1 },
    2605: { name: "Werewolf", size: 1 },
    2606: { name: "Werewolf", size: 1 },
    2607: { name: "Werewolf", size: 1 },
    2608: { name: "Werewolf", size: 1 },
    2609: { name: "Werewolf", size: 1 },
    2610: { name: "Werewolf", size: 1 },
    2611: { name: "Werewolf", size: 1 },
    2612: { name: "Werewolf", size: 1 },
    2613: { name: "Boris", size: 1 },
    2614: { name: "Imre", size: 1 },
    2615: { name: "Yuri", size: 1 },
    2616: { name: "Joseph", size: 1 },
    2617: { name: "Nikolai", size: 1 },
    2618: { name: "Eduard", size: 1 },
    2619: { name: "Lev", size: 1 },
    2620: { name: "Georgy", size: 1 },
    2621: { name: "Svetlana", size: 1 },
    2622: { name: "Irina", size: 1 },
    2623: { name: "Alexis", size: 1 },
    2624: { name: "Milla", size: 1 },
    2625: { name: "Galina", size: 1 },
    2626: { name: "Sofiya", size: 1 },
    2627: { name: "Ksenia", size: 1 },
    2628: { name: "Yadviga", size: 1 },
    2629: { name: "Nikita", size: 1 },
    2630: { name: "Vera", size: 1 },
    2631: { name: "Zoja", size: 1 },
    2632: { name: "Liliya", size: 1 },
    2633: { name: "Banker", size: 1 },
    2634: { name: "Myre Blamish Snail", size: 1 },
    2635: { name: "Bob", size: 1 },
    2636: { name: "Bob", size: 1 },
    2637: { name: "Sphinx", size: 2 },
    2638: { name: "Neite", size: 1 },
    2639: { name: "Robert the Strong", size: 1 },
    2640: { name: "Odysseus", size: 2 },
    2641: { name: "Dragonkin", size: 3 },
    2642: { name: "King Black Dragon", size: 5 },
    2643: { name: "R4ng3rNo0b889", size: 1 },
    2644: { name: "Love Cats", size: 2 },
    2645: { name: "Blood Blamish Snail", size: 1 },
    2646: { name: "Ochre Blamish Snail", size: 1 },
    2647: { name: "Bruise Blamish Snail", size: 1 },
    2648: { name: "Bark Blamish Snail", size: 1 },
    2649: { name: "Myre Blamish Snail", size: 1 },
    2650: { name: "Blood Blamish Snail", size: 1 },
    2651: { name: "Ochre Blamish Snail", size: 1 },
    2652: { name: "Bruise Blamish Snail", size: 1 },
    2653: { name: "Fishing spot", size: 1 },
    2654: { name: "Fishing spot", size: 1 },
    2655: { name: "Fishing spot", size: 1 },
    2656: { name: "Aluft Gianne snr.", size: 1 },
    2657: { name: "Gnome Waiter", size: 1 },
    2658: { name: "Head chef", size: 1 },
    2659: { name: "Purepker895", size: 1 },
    2660: { name: "Qutiedoll", size: 1 },
    2661: { name: "1337sp34kr", size: 1 },
    2662: { name: "Elfinlocks", size: 1 },
    2663: { name: "Elstan", size: 1 },
    2664: { name: "Dantaera", size: 1 },
    2665: { name: "Kragen", size: 1 },
    2666: { name: "Lyra", size: 1 },
    2667: { name: "Francis", size: 1 },
    2668: { name: "<col=00ffff>Combat dummy</col>", size: 1 },
    2669: { name: "Garth", size: 1 },
    2670: { name: "Ellena", size: 1 },
    2671: { name: "Selena", size: 1 },
    2672: { name: "Vasquen", size: 1 },
    2673: { name: "Rhonen", size: 1 },
    2674: { name: "Dreven", size: 1 },
    2675: { name: "Taria", size: 1 },
    2676: { name: "Rhazien", size: 1 },
    2677: { name: "Torrell", size: 1 },
    2678: { name: "Alain", size: 1 },
    2679: { name: "Heskel", size: 1 },
    2680: { name: "Treznor", size: 1 },
    2681: { name: "Fayeth", size: 1 },
    2682: { name: "Bolongo", size: 1 },
    2683: { name: "Gileth", size: 1 },
    2684: { name: "Frizzy Skernip", size: 1 },
    2685: { name: "Yulf Squecks", size: 1 },
    2686: { name: "Praistan Ebola", size: 1 },
    2687: { name: "Prissy Scilla", size: 1 },
    2688: { name: "Imiago", size: 1 },
    2689: { name: "Liliwen", size: 1 },
    2690: { name: "Cool Mom227", size: 1 },
    2691: { name: "Sheep", size: 1 },
    2692: { name: "Sheep", size: 1 },
    2693: { name: "Sheep", size: 1 },
    2694: { name: "Sheep", size: 1 },
    2695: { name: "Sheep", size: 1 },
    2696: { name: "Sheep", size: 1 },
    2697: { name: "Sheep", size: 1 },
    2698: { name: "Sheep", size: 1 },
    2699: { name: "Sheep", size: 1 },
    2700: { name: "Reacher", size: 1 },
    2701: { name: "Reacher", size: 1 },
    2702: { name: "Reacher", size: 1 },
    2703: { name: "Reach", size: 1 },
    2704: { name: "Reach", size: 1 },
    2705: { name: "Reach", size: 1 },
    2706: { name: "Reach", size: 1 },
    2707: { name: "Reach", size: 1 },
    2708: { name: "Reach", size: 1 },
    2709: { name: "Reacher", size: 1 },
    2710: { name: "Reacher", size: 1 },
    2711: { name: "Reacher", size: 1 },
    2712: { name: "Reacher", size: 1 },
    2713: { name: "Wise Old Man", size: 1 },
    2714: { name: "Combat stone", size: 1 },
    2715: { name: "Combat stone", size: 1 },
    2716: { name: "Combat stone", size: 1 },
    2717: { name: "Combat stone", size: 1 },
    2718: { name: "Combat stone", size: 1 },
    2719: { name: "Combat stone", size: 1 },
    2720: { name: "Combat stone", size: 1 },
    2721: { name: "Combat stone", size: 1 },
    2722: { name: "Combat stone", size: 1 },
    2723: { name: "Combat stone", size: 1 },
    2724: { name: "Combat stone", size: 1 },
    2725: { name: "Combat stone", size: 1 },
    2726: { name: "Combat stone", size: 1 },
    2727: { name: "Combat stone", size: 1 },
    2728: { name: "Combat stone", size: 1 },
    2729: { name: "Combat stone", size: 1 },
    2730: { name: "Combat stone", size: 1 },
    2731: { name: "Combat stone", size: 1 },
    2732: { name: "Combat stone", size: 1 },
    2733: { name: "Combat stone", size: 1 },
    2734: { name: "Combat stone", size: 1 },
    2735: { name: "Combat stone", size: 1 },
    2736: { name: "Combat stone", size: 1 },
    2737: { name: "Combat stone", size: 1 },
    2738: { name: "Combat stone", size: 1 },
    2739: { name: "Combat stone", size: 1 },
    2740: { name: "Combat stone", size: 1 },
    2741: { name: "Combat stone", size: 1 },
    2742: { name: "Combat stone", size: 1 },
    2743: { name: "Combat stone", size: 1 },
    2744: { name: "Combat stone", size: 1 },
    2745: { name: "Combat stone", size: 1 },
    2746: { name: "Combat stone", size: 1 },
    2747: { name: "Combat stone", size: 1 },
    2748: { name: "Combat stone", size: 1 },
    2749: { name: "Combat stone", size: 1 },
    2750: { name: "Combat stone", size: 1 },
    2751: { name: "Combat stone", size: 1 },
    2752: { name: "Combat stone", size: 1 },
    2753: { name: "Combat stone", size: 1 },
    2754: { name: "Combat stone", size: 1 },
    2755: { name: "Combat stone", size: 1 },
    2756: { name: "Combat stone", size: 1 },
    2757: { name: "Combat stone", size: 1 },
    2758: { name: "Combat stone", size: 1 },
    2759: { name: "Combat stone", size: 1 },
    2760: { name: "Combat stone", size: 1 },
    2761: { name: "Combat stone", size: 1 },
    2762: { name: "Combat stone", size: 1 },
    2763: { name: "Combat stone", size: 1 },
    2764: { name: "Combat stone", size: 1 },
    2765: { name: "Combat stone", size: 1 },
    2766: { name: "Combat stone", size: 1 },
    2767: { name: "Combat stone", size: 1 },
    2768: { name: "Combat stone", size: 1 },
    2769: { name: "Combat stone", size: 1 },
    2770: { name: "Combat stone", size: 1 },
    2771: { name: "Combat stone", size: 1 },
    2772: { name: "Combat stone", size: 1 },
    2773: { name: "Combat stone", size: 1 },
    2774: { name: "Combat stone", size: 1 },
    2775: { name: "Combat stone", size: 1 },
    2776: { name: "Combat stone", size: 1 },
    2777: { name: "Combat stone", size: 1 },
    2778: { name: "Combat stone", size: 1 },
    2779: { name: "null", size: 1 },
    2780: { name: "null", size: 1 },
    2781: { name: "null", size: 1 },
    2782: { name: "Clockwork cat", size: 1 },
    2783: { name: "Hirko", size: 1 },
    2784: { name: "Holoy", size: 1 },
    2785: { name: "Hura", size: 1 },
    2786: { name: "Sheep", size: 1 },
    2787: { name: "Sheep", size: 1 },
    2788: { name: "Sheep", size: 1 },
    2789: { name: "Sheep", size: 1 },
    2790: { name: "Cow", size: 2 },
    2791: { name: "Cow", size: 2 },
    2792: { name: "Cow calf", size: 2 },
    2793: { name: "Cow", size: 2 },
    2794: { name: "Cow calf", size: 2 },
    2795: { name: "Cow", size: 2 },
    2796: { name: "Pig", size: 1 },
    2797: { name: "Pig", size: 2 },
    2798: { name: "Piglet", size: 1 },
    2799: { name: "Piglet", size: 1 },
    2800: { name: "Piglet", size: 1 },
    2801: { name: "Cow calf", size: 1 },
    2802: { name: "Sheepdog", size: 1 },
    2803: { name: "Rooster", size: 1 },
    2804: { name: "Chicken", size: 1 },
    2805: { name: "Chicken", size: 1 },
    2806: { name: "Chicken", size: 1 },
    2807: { name: "Pig", size: 1 },
    2808: { name: "Pig", size: 2 },
    2809: { name: "Piglet", size: 1 },
    2810: { name: "Piglet", size: 1 },
    2811: { name: "Piglet", size: 1 },
    2812: { name: "Father Aereck", size: 1 },
    2813: { name: "Shop keeper", size: 1 },
    2814: { name: "Shop assistant", size: 1 },
    2815: { name: "Shop keeper", size: 1 },
    2816: { name: "Shop assistant", size: 1 },
    2817: { name: "Shop keeper", size: 1 },
    2818: { name: "Shop assistant", size: 1 },
    2819: { name: "Shop keeper", size: 1 },
    2820: { name: "Shop assistant", size: 1 },
    2821: { name: "Shop keeper", size: 1 },
    2822: { name: "Shop assistant", size: 1 },
    2823: { name: "Shop keeper", size: 1 },
    2824: { name: "Shop assistant", size: 1 },
    2825: { name: "Shop keeper", size: 1 },
    2826: { name: "Shop assistant", size: 1 },
    2827: { name: "Bat", size: 1 },
    2828: { name: "Dryad", size: 1 },
    2829: { name: "Fairy", size: 1 },
    2830: { name: "Mysterious Old Man", size: 1 },
    2831: { name: "null", size: 1 },
    2832: { name: "<col=00ffff>Chair</col>", size: 1 },
    2833: { name: "Lil' Creator", size: 1 },
    2834: { name: "Giant bat", size: 2 },
    2835: { name: "Camel", size: 2 },
    2836: { name: "Golem", size: 2 },
    2837: { name: "Unicorn", size: 2 },
    2838: { name: "Grizzly bear", size: 2 },
    2839: { name: "Black bear", size: 2 },
    2840: { name: "Earth warrior", size: 1 },
    2841: { name: "Ice warrior", size: 1 },
    2842: { name: "Ice warrior", size: 1 },
    2843: { name: "Otherworldly being", size: 1 },
    2844: { name: "Magic axe", size: 1 },
    2845: { name: "Snake", size: 1 },
    2846: { name: "Skavid", size: 1 },
    2847: { name: "Yeti", size: 3 },
    2848: { name: "Monkey", size: 1 },
    2849: { name: "Black unicorn", size: 2 },
    2850: { name: "Veos", size: 1 },
    2851: { name: "Ice warrior", size: 1 },
    2852: { name: "Fly trap", size: 1 },
    2853: { name: "Shadow warrior", size: 1 },
    2854: { name: "Rat", size: 1 },
    2855: { name: "Rat", size: 1 },
    2856: { name: "Giant rat", size: 2 },
    2857: { name: "Giant rat", size: 2 },
    2858: { name: "Giant rat", size: 2 },
    2859: { name: "Giant rat", size: 2 },
    2860: { name: "Giant rat", size: 2 },
    2861: { name: "Giant rat", size: 2 },
    2862: { name: "Giant rat", size: 2 },
    2863: { name: "Giant rat", size: 2 },
    2864: { name: "Giant rat", size: 2 },
    2865: { name: "Dungeon rat", size: 2 },
    2866: { name: "Dungeon rat", size: 2 },
    2867: { name: "Dungeon rat", size: 2 },
    2868: { name: "Fairy shop keeper", size: 1 },
    2869: { name: "Fairy shop assistant", size: 1 },
    2870: { name: "Valaine", size: 1 },
    2871: { name: "Scavvo", size: 1 },
    2872: { name: "Peksa", size: 1 },
    2873: { name: "Silk trader", size: 1 },
    2874: { name: "Gem trader", size: 1 },
    2875: { name: "Zeke", size: 1 },
    2876: { name: "Louie Legs", size: 1 },
    2877: { name: "Karim", size: 1 },
    2878: { name: "Ranael", size: 1 },
    2879: { name: "Dommik", size: 1 },
    2880: { name: "Zaff", size: 1 },
    2881: { name: "Baraek", size: 1 },
    2882: { name: "Horvik", size: 1 },
    2883: { name: "Lowe", size: 1 },
    2884: { name: "Shop keeper", size: 1 },
    2885: { name: "Shop assistant", size: 1 },
    2886: { name: "null", size: 1 },
    2887: { name: "Asyff", size: 1 },
    2888: { name: "Shop keeper", size: 1 },
    2889: { name: "Grum", size: 1 },
    2890: { name: "Wydin", size: 1 },
    2891: { name: "Gerrant", size: 1 },
    2892: { name: "Brian", size: 1 },
    2893: { name: "Jiminua", size: 1 },
    2894: { name: "Shop keeper", size: 1 },
    2895: { name: "Cook", size: 1 },
    2896: { name: "Cook", size: 1 },
    2897: { name: "Banker", size: 1 },
    2898: { name: "Banker", size: 1 },
    2899: { name: "Iffie", size: 1 },
    2900: { name: "Elsie", size: 1 },
    2901: { name: "Cleaner", size: 1 },
    2902: { name: "Stray dog", size: 1 },
    2903: { name: "Orange salamander", size: 1 },
    2904: { name: "Red salamander", size: 1 },
    2905: { name: "Black salamander", size: 1 },
    2906: { name: "Swamp lizard", size: 1 },
    2907: { name: "Sabre-toothed kyatt", size: 2 },
    2908: { name: "Spined larupia", size: 2 },
    2909: { name: "Horned graahk", size: 2 },
    2910: { name: "Chinchompa", size: 1 },
    2911: { name: "Carnivorous chinchompa", size: 1 },
    2912: { name: "Black chinchompa", size: 1 },
    2913: { name: "Master fisher", size: 1 },
    2914: { name: "Otto Godblessed", size: 1 },
    2915: { name: "Otto Godblessed", size: 1 },
    2916: { name: "Waterfiend", size: 1 },
    2917: { name: "Waterfiend", size: 1 },
    2918: { name: "Brutal green dragon", size: 4 },
    2919: { name: "Mithril dragon", size: 4 },
    2920: { name: "Confused barbarian", size: 1 },
    2921: { name: "Lost barbarian", size: 1 },
    2922: { name: "Stray dog", size: 1 },
    2923: { name: "Blast Furnace Foreman", size: 1 },
    2924: { name: "Tin ore", size: 3 },
    2925: { name: "Copper ore", size: 3 },
    2926: { name: "Iron ore", size: 3 },
    2927: { name: "Mithril ore", size: 3 },
    2928: { name: "Adamantite ore", size: 3 },
    2929: { name: "Runite ore", size: 3 },
    2930: { name: "Silver ore", size: 3 },
    2931: { name: "Gold ore", size: 3 },
    2932: { name: "Coal", size: 3 },
    2933: { name: "Perfect gold ore", size: 3 },
    2934: { name: "null", size: 1 },
    2935: { name: "null", size: 1 },
    2936: { name: "null", size: 1 },
    2937: { name: "null", size: 1 },
    2938: { name: "null", size: 1 },
    2939: { name: "null", size: 1 },
    2940: { name: "null", size: 1 },
    2941: { name: "null", size: 1 },
    2942: { name: "null", size: 1 },
    2943: { name: "null", size: 1 },
    2944: { name: "null", size: 1 },
    2945: { name: "null", size: 1 },
    2946: { name: "Nail beast", size: 2 },
    2947: { name: "Nail beast", size: 2 },
    2948: { name: "Nail beast", size: 2 },
    2949: { name: "Squire", size: 1 },
    2950: { name: "Void Knight", size: 1 },
    2951: { name: "Void Knight", size: 1 },
    2952: { name: "Void Knight", size: 1 },
    2953: { name: "Void Knight", size: 1 },
    2954: { name: "Zamorak wizard", size: 1 },
    2955: { name: "Saradomin wizard", size: 1 },
    2956: { name: "Spring Elemental", size: 1 },
    2957: { name: "Spring Elemental", size: 1 },
    2958: { name: "Spring Elemental", size: 1 },
    2959: { name: "Spring Elemental", size: 1 },
    2960: { name: "Spring Elemental", size: 1 },
    2961: { name: "Spring Elemental", size: 1 },
    2962: { name: "Spring Elemental", size: 1 },
    2963: { name: "Spring Elemental", size: 1 },
    2964: { name: "null", size: 1 },
    2965: { name: "null", size: 1 },
    2966: { name: "null", size: 1 },
    2967: { name: "null", size: 1 },
    2968: { name: "null", size: 1 },
    2969: { name: "null", size: 1 },
    2970: { name: "null", size: 1 },
    2971: { name: "null", size: 1 },
    2972: { name: "King Awowogei", size: 1 },
    2973: { name: "null", size: 1 },
    2974: { name: "King Awowogei", size: 1 },
    2975: { name: "Mizaru", size: 1 },
    2976: { name: "Kikazaru", size: 1 },
    2977: { name: "Iwazaru", size: 1 },
    2978: { name: "Big Snake", size: 2 },
    2979: { name: "null", size: 1 },
    2980: { name: "Mawnis Burowgar", size: 1 },
    2981: { name: "Honour guard", size: 1 },
    2982: { name: "Honour guard", size: 1 },
    2983: { name: "Fridleif Shieldson", size: 1 },
    2984: { name: "Thakkrad Sigmundson", size: 1 },
    2985: { name: "Velorina", size: 1 },
    2986: { name: "Necrovarus", size: 1 },
    2987: { name: "Gravingas", size: 1 },
    2988: { name: "Ghost disciple", size: 1 },
    2989: { name: "Ak-Haranu", size: 1 },
    2990: { name: "null", size: 3 },
    2991: { name: "null", size: 1 },
    2992: { name: "Undead cow", size: 2 },
    2993: { name: "Undead chicken", size: 1 },
    2994: { name: "Giant lobster", size: 2 },
    2995: { name: "Robin", size: 1 },
    2996: { name: "Old crone", size: 1 },
    2997: { name: "Old man", size: 1 },
    2998: { name: "Ghost villager", size: 1 },
    2999: { name: "Tortured soul", size: 1 },
    3000: { name: "Ghost shopkeeper", size: 1 },
    3001: { name: "Ghost innkeeper", size: 1 },
    3002: { name: "Ghost farmer", size: 1 },
    3003: { name: "Ghost banker", size: 1 },
    3004: { name: "Ghost sailor", size: 1 },
    3005: { name: "Ghost captain", size: 1 },
    3006: { name: "Ghost captain", size: 1 },
    3007: { name: "Ghost guard", size: 1 },
    3008: { name: "Ghost (?)", size: 1 },
    3009: { name: "Ghost (?)", size: 1 },
    3010: { name: "Guard", size: 1 },
    3011: { name: "Guard", size: 1 },
    3012: { name: "Trainee Guard", size: 1 },
    3013: { name: "Captain", size: 1 },
    3014: { name: "Man", size: 1 },
    3015: { name: "Woman", size: 1 },
    3016: { name: "Shadow spider", size: 1 },
    3017: { name: "Giant spider", size: 1 },
    3018: { name: "Giant spider", size: 1 },
    3019: { name: "Spider", size: 1 },
    3020: { name: "Jungle spider", size: 1 },
    3021: { name: "Deadly red spider", size: 1 },
    3022: { name: "Ice spider", size: 1 },
    3023: { name: "Poison spider", size: 1 },
    3024: { name: "Scorpion", size: 2 },
    3025: { name: "Poison Scorpion", size: 2 },
    3026: { name: "Pit Scorpion", size: 1 },
    3027: { name: "King Scorpion", size: 2 },
    3028: { name: "Goblin", size: 1 },
    3029: { name: "Goblin", size: 1 },
    3030: { name: "Goblin", size: 1 },
    3031: { name: "Goblin", size: 1 },
    3032: { name: "Goblin", size: 1 },
    3033: { name: "Goblin", size: 1 },
    3034: { name: "Goblin", size: 1 },
    3035: { name: "Goblin", size: 1 },
    3036: { name: "Goblin", size: 1 },
    3037: { name: "Goblin", size: 1 },
    3038: { name: "Goblin", size: 1 },
    3039: { name: "Goblin", size: 1 },
    3040: { name: "Goblin", size: 1 },
    3041: { name: "Goblin", size: 1 },
    3042: { name: "Goblin", size: 1 },
    3043: { name: "Goblin", size: 1 },
    3044: { name: "Goblin", size: 1 },
    3045: { name: "Goblin", size: 1 },
    3046: { name: "Goblin", size: 1 },
    3047: { name: "Goblin", size: 1 },
    3048: { name: "Goblin", size: 1 },
    3049: { name: "Hobgoblin", size: 1 },
    3050: { name: "Hobgoblin", size: 1 },
    3051: { name: "Goblin", size: 1 },
    3052: { name: "Goblin", size: 1 },
    3053: { name: "Goblin", size: 1 },
    3054: { name: "Goblin", size: 1 },
    3055: { name: "Barbarian", size: 1 },
    3056: { name: "Barbarian", size: 1 },
    3057: { name: "Barbarian", size: 1 },
    3058: { name: "Barbarian", size: 1 },
    3059: { name: "Barbarian", size: 1 },
    3060: { name: "Barbarian", size: 1 },
    3061: { name: "Barbarian", size: 1 },
    3062: { name: "Barbarian", size: 1 },
    3063: { name: "Hunding", size: 1 },
    3064: { name: "Barbarian", size: 1 },
    3065: { name: "Barbarian", size: 1 },
    3066: { name: "Barbarian", size: 1 },
    3067: { name: "Barbarian", size: 1 },
    3068: { name: "Barbarian", size: 1 },
    3069: { name: "Barbarian", size: 1 },
    3070: { name: "Barbarian", size: 1 },
    3071: { name: "Barbarian", size: 1 },
    3072: { name: "Barbarian", size: 1 },
    3073: { name: "Goblin", size: 1 },
    3074: { name: "Goblin", size: 1 },
    3075: { name: "Goblin", size: 1 },
    3076: { name: "Goblin", size: 1 },
    3077: { name: "Phoenix", size: 1 },
    3078: { name: "Phoenix", size: 1 },
    3079: { name: "Phoenix", size: 1 },
    3080: { name: "Phoenix", size: 1 },
    3081: { name: "Phoenix", size: 1 },
    3082: { name: "Phoenix", size: 1 },
    3083: { name: "Phoenix", size: 1 },
    3084: { name: "Phoenix", size: 1 },
    3086: { name: "<col=00ffff>Portal</col>", size: 1 },
    3087: { name: "null", size: 1 },
    3088: { name: "<col=00efef>Portal</col>", size: 1 },
    3089: { name: "Banker", size: 1 },
    3090: { name: "Banker", size: 1 },
    3091: { name: "Banker", size: 1 },
    3092: { name: "Banker", size: 1 },
    3093: { name: "Banker", size: 1 },
    3094: { name: "Banker", size: 1 },
    3095: { name: "Chief servant", size: 1 },
    3096: { name: "Taxidermist", size: 1 },
    3097: { name: "Estate agent", size: 1 },
    3098: { name: "Stonemason", size: 1 },
    3099: { name: "Hellpuppy", size: 1 },
    3100: { name: "Sir Renitee", size: 1 },
    3101: { name: "Sawmill operator", size: 1 },
    3102: { name: "Garden supplier", size: 1 },
    3103: { name: "Garden supplier", size: 1 },
    3104: { name: "null", size: 1 },
    3105: { name: "Hans", size: 1 },
    3106: { name: "Man", size: 1 },
    3107: { name: "Man", size: 1 },
    3108: { name: "Man", size: 1 },
    3109: { name: "Man", size: 1 },
    3110: { name: "Man", size: 1 },
    3111: { name: "Woman", size: 1 },
    3112: { name: "Woman", size: 1 },
    3113: { name: "Woman", size: 1 },
    3114: { name: "Farmer", size: 1 },
    3115: { name: "Farid Morrisane (ores and bars)", size: 1 },
    3116: { name: "Tz-Kih", size: 1 },
    3117: { name: "Tz-Kih", size: 1 },
    3118: { name: "Tz-Kek", size: 2 },
    3119: { name: "Tz-Kek", size: 2 },
    3120: { name: "Tz-Kek", size: 1 },
    3121: { name: "Tok-Xil", size: 3 },
    3122: { name: "Tok-Xil", size: 3 },
    3123: { name: "Yt-MejKot", size: 4 },
    3124: { name: "Yt-MejKot", size: 4 },
    3125: { name: "Ket-Zek", size: 5 },
    3126: { name: "Ket-Zek", size: 5 },
    3127: { name: "TzTok-Jad", size: 5 },
    3128: { name: "Yt-HurKot", size: 1 },
    3129: { name: "K'ril Tsutsaroth", size: 5 },
    3130: { name: "Tstanon Karlak", size: 3 },
    3131: { name: "Zakl'n Gritch", size: 2 },
    3132: { name: "Balfrug Kreeyath", size: 3 },
    3133: { name: "Hellhound", size: 2 },
    3134: { name: "Imp", size: 1 },
    3135: { name: "Werewolf", size: 1 },
    3136: { name: "Werewolf", size: 1 },
    3137: { name: "Feral Vampyre", size: 1 },
    3138: { name: "Bloodveld", size: 2 },
    3139: { name: "Pyrefiend", size: 1 },
    3140: { name: "Icefiend", size: 1 },
    3141: { name: "Gorak", size: 2 },
    3142: { name: "Gnome Coach", size: 1 },
    3143: { name: "Gnome baller", size: 1 },
    3144: { name: "Gnome baller", size: 1 },
    3145: { name: "Gnome baller", size: 1 },
    3146: { name: "Gnome baller", size: 1 },
    3147: { name: "Gnome baller", size: 1 },
    3148: { name: "Gnome baller", size: 1 },
    3149: { name: "Gnome baller", size: 1 },
    3150: { name: "Gnome baller", size: 1 },
    3151: { name: "Gnome baller", size: 1 },
    3152: { name: "Gnome baller", size: 1 },
    3153: { name: "Gnome baller", size: 1 },
    3154: { name: "Gnome baller", size: 1 },
    3155: { name: "Gnome winger", size: 1 },
    3156: { name: "Gnome winger", size: 1 },
    3157: { name: "Gnome ball referee", size: 1 },
    3158: { name: "Cheerleader", size: 1 },
    3159: { name: "Spiritual warrior", size: 1 },
    3160: { name: "Spiritual ranger", size: 1 },
    3161: { name: "Spiritual mage", size: 1 },
    3162: { name: "Kree'arra", size: 5 },
    3163: { name: "Wingman Skree", size: 2 },
    3164: { name: "Flockleader Geerin", size: 2 },
    3165: { name: "Flight Kilisa", size: 2 },
    3166: { name: "Spiritual warrior", size: 2 },
    3167: { name: "Spiritual ranger", size: 2 },
    3168: { name: "Spiritual mage", size: 2 },
    3169: { name: "Aviansie", size: 2 },
    3170: { name: "Aviansie", size: 2 },
    3171: { name: "Aviansie", size: 2 },
    3172: { name: "Aviansie", size: 2 },
    3173: { name: "Aviansie", size: 2 },
    3174: { name: "Aviansie", size: 2 },
    3175: { name: "Aviansie", size: 2 },
    3176: { name: "Aviansie", size: 2 },
    3177: { name: "Aviansie", size: 2 },
    3178: { name: "Aviansie", size: 2 },
    3179: { name: "Aviansie", size: 2 },
    3180: { name: "Aviansie", size: 2 },
    3181: { name: "Aviansie", size: 2 },
    3182: { name: "Aviansie", size: 2 },
    3183: { name: "Aviansie", size: 2 },
    3184: { name: "Dagannoth spawn", size: 1 },
    3185: { name: "Dagannoth", size: 1 },
    3186: { name: "null", size: 1 },
    3187: { name: "null", size: 1 },
    3188: { name: "null", size: 1 },
    3189: { name: "Brian O'Richard", size: 1 },
    3190: { name: "Rogue Guard", size: 1 },
    3191: { name: "Rogue Guard", size: 1 },
    3192: { name: "Rogue Guard", size: 1 },
    3193: { name: "Martin Thwait", size: 1 },
    3194: { name: "Emerald Benedict", size: 1 },
    3195: { name: "Spin Blades", size: 1 },
    3196: { name: "Spin Blades", size: 1 },
    3197: { name: "null", size: 1 },
    3198: { name: "null", size: 1 },
    3199: { name: "Candle maker", size: 1 },
    3200: { name: "Arhein", size: 1 },
    3201: { name: "Jukat", size: 1 },
    3202: { name: "Lunderwin", size: 1 },
    3203: { name: "Irksol", size: 1 },
    3204: { name: "Fairy", size: 1 },
    3205: { name: "Surok Magis", size: 3 },
    3206: { name: "null", size: 1 },
    3207: { name: "Gem merchant", size: 1 },
    3208: { name: "Baker", size: 1 },
    3209: { name: "Spice seller", size: 1 },
    3210: { name: "Fur trader", size: 1 },
    3211: { name: "Silk merchant", size: 1 },
    3212: { name: "Hickton", size: 1 },
    3213: { name: "Harry", size: 1 },
    3214: { name: "Cassie", size: 1 },
    3215: { name: "Frincos", size: 1 },
    3216: { name: "Melee combat tutor", size: 1 },
    3217: { name: "Ranged combat tutor", size: 1 },
    3218: { name: "Magic combat tutor", size: 1 },
    3219: { name: "Cooking tutor", size: 1 },
    3220: { name: "Crafting tutor", size: 1 },
    3221: { name: "Fishing tutor", size: 1 },
    3222: { name: "Mining tutor", size: 1 },
    3223: { name: "Prayer tutor", size: 1 },
    3224: { name: "Smithing apprentice", size: 1 },
    3225: { name: "Master smithing tutor", size: 1 },
    3226: { name: "Woodsman tutor", size: 1 },
    3227: { name: "Banker tutor", size: 1 },
    3228: { name: "null", size: 1 },
    3229: { name: "null", size: 1 },
    3230: { name: "null", size: 1 },
    3231: { name: "Ellis", size: 1 },
    3232: { name: "Wizard Jalarast", size: 1 },
    3233: { name: "Leech", size: 1 },
    3234: { name: "Feral Vampyre", size: 1 },
    3235: { name: "Bee keeper", size: 1 },
    3236: { name: "Mist", size: 2 },
    3237: { name: "Feral Vampyre", size: 1 },
    3238: { name: "Vampyric hound", size: 2 },
    3239: { name: "Feral Vampyre", size: 1 },
    3240: { name: "Tree", size: 1 },
    3241: { name: "Blandebir", size: 1 },
    3242: { name: "Metarialus", size: 1 },
    3243: { name: "Farmer", size: 1 },
    3244: { name: "Farmer", size: 1 },
    3245: { name: "Farmer", size: 1 },
    3246: { name: "Wizard Frumscone", size: 1 },
    3247: { name: "Wizard Akutha", size: 1 },
    3248: { name: "null", size: 1 },
    3249: { name: "Wizard Sinina", size: 1 },
    3250: { name: "Farmer", size: 1 },
    3251: { name: "Farmer", size: 1 },
    3252: { name: "Thief", size: 1 },
    3253: { name: "Thief", size: 1 },
    3254: { name: "Guard", size: 1 },
    3255: { name: "Tramp", size: 1 },
    3256: { name: "Barbarian", size: 1 },
    3257: { name: "Wizard", size: 1 },
    3258: { name: "Druid", size: 1 },
    3259: { name: "Druid", size: 1 },
    3260: { name: "Warrior", size: 1 },
    3261: { name: "Man", size: 1 },
    3262: { name: "Barbarian", size: 1 },
    3263: { name: "Drunken man", size: 1 },
    3264: { name: "Man", size: 1 },
    3265: { name: "Man", size: 1 },
    3266: { name: "Norman", size: 1 },
    3267: { name: "Cecilia", size: 1 },
    3268: { name: "Woman", size: 1 },
    3269: { name: "Guard", size: 1 },
    3270: { name: "Guard", size: 1 },
    3271: { name: "Guard", size: 1 },
    3272: { name: "Guard", size: 1 },
    3273: { name: "Guard", size: 1 },
    3274: { name: "Guard", size: 1 },
    3275: { name: "Gardener", size: 1 },
    3276: { name: "Gardener", size: 1 },
    3277: { name: "Apprentice workman", size: 1 },
    3278: { name: "Workman", size: 1 },
    3279: { name: "Cuffs", size: 1 },
    3280: { name: "Narf", size: 1 },
    3281: { name: "Rusty", size: 1 },
    3282: { name: "Jeff", size: 1 },
    3283: { name: "Guard", size: 1 },
    3284: { name: "Hengel", size: 1 },
    3285: { name: "Anja", size: 1 },
    3286: { name: "Hobgoblin", size: 1 },
    3287: { name: "Hobgoblin", size: 1 },
    3288: { name: "Hobgoblin", size: 1 },
    3289: { name: "Hobgoblin", size: 1 },
    3290: { name: "Frog", size: 1 },
    3291: { name: "Postie Pete", size: 1 },
    3292: { name: "Al Kharid warrior", size: 1 },
    3293: { name: "Paladin", size: 1 },
    3294: { name: "Paladin", size: 1 },
    3295: { name: "Hero", size: 1 },
    3296: { name: "Forester", size: 1 },
    3297: { name: "Knight of Ardougne", size: 1 },
    3298: { name: "Man", size: 1 },
    3299: { name: "Woman", size: 1 },
    3300: { name: "Knight of Ardougne", size: 1 },
    3301: { name: "Archer", size: 1 },
    3302: { name: "Zoo keeper", size: 1 },
    3303: { name: "Chuck", size: 1 },
    3304: { name: "Barman", size: 1 },
    3305: { name: "Master Chef", size: 1 },
    3306: { name: "Henja", size: 1 },
    3307: { name: "Combat Instructor", size: 1 },
    3308: { name: "Gielinor Guide", size: 1 },
    3309: { name: "Magic Instructor", size: 1 },
    3310: { name: "Account Guide", size: 1 },
    3311: { name: "Mining Instructor", size: 1 },
    3312: { name: "Quest Guide", size: 1 },
    3313: { name: "Giant rat", size: 2 },
    3314: { name: "Giant rat", size: 2 },
    3315: { name: "Giant rat", size: 2 },
    3316: { name: "Chicken", size: 1 },
    3317: { name: "Fishing spot", size: 1 },
    3318: { name: "Banker", size: 1 },
    3319: { name: "Brother Brace", size: 1 },
    3320: { name: "Skippy", size: 1 },
    3321: { name: "Larxus", size: 1 },
    3322: { name: "Mystery figure", size: 2 },
    3323: { name: "Mystery figure", size: 2 },
    3324: { name: "Mystery figure", size: 2 },
    3325: { name: "Mystery figure", size: 2 },
    3326: { name: "Mystery figure", size: 2 },
    3327: { name: "Mystery figure", size: 2 },
    3328: { name: "Earth Warrior Champion", size: 1 },
    3329: { name: "Giant Champion", size: 2 },
    3330: { name: "Ghoul Champion", size: 1 },
    3331: { name: "Mubariz", size: 1 },
    3332: { name: "Trees", size: 5 },
    3333: { name: "Trees", size: 5 },
    3334: { name: "Cavemouth", size: 5 },
    3335: { name: "Bullrush", size: 1 },
    3336: { name: "Bullrush", size: 1 },
    3337: { name: "Cave Scenery", size: 3 },
    3338: { name: "Cave Scenery", size: 3 },
    3339: { name: "Cave Scenery", size: 4 },
    3340: { name: "Fadli", size: 1 },
    3341: { name: "A'abla", size: 1 },
    3342: { name: "Sabreen", size: 1 },
    3343: { name: "Surgeon General Tafani", size: 1 },
    3344: { name: "Jaraah", size: 1 },
    3345: { name: "Zahwa", size: 1 },
    3346: { name: "Ima", size: 1 },
    3347: { name: "Sabeil", size: 1 },
    3348: { name: "Jadid", size: 1 },
    3349: { name: "Dalal", size: 1 },
    3350: { name: "Afrah", size: 1 },
    3351: { name: "Jeed", size: 1 },
    3352: { name: "Hamid", size: 1 },
    3353: { name: "Goblin Champion", size: 1 },
    3354: { name: "Hobgoblin Champion", size: 1 },
    3355: { name: "Imp Champion", size: 1 },
    3356: { name: "Jogre Champion", size: 2 },
    3357: { name: "Lesser Demon Champion", size: 2 },
    3358: { name: "Skeleton Champion", size: 1 },
    3359: { name: "Zombies Champion", size: 2 },
    3360: { name: "Leon d'Cour", size: 1 },
    3361: { name: "Guard", size: 1 },
    3362: { name: "Litara", size: 1 },
    3363: { name: "null", size: 1 },
    3364: { name: "Stankers", size: 1 },
    3365: { name: "null", size: 1 },
    3366: { name: "null", size: 1 },
    3367: { name: "null", size: 1 },
    3368: { name: "null", size: 1 },
    3369: { name: "Angel", size: 1 },
    3370: { name: "Elemental balance", size: 1 },
    3371: { name: "Elemental balance", size: 1 },
    3372: { name: "Elemental balance", size: 1 },
    3373: { name: "Elemental balance", size: 1 },
    3374: { name: "Elemental balance", size: 1 },
    3375: { name: "Elemental balance", size: 1 },
    3376: { name: "Elemental balance", size: 1 },
    3377: { name: "Elemental balance", size: 1 },
    3378: { name: "Elemental balance", size: 1 },
    3379: { name: "Elemental balance", size: 1 },
    3380: { name: "Elemental balance", size: 1 },
    3381: { name: "Elemental balance", size: 1 },
    3382: { name: "Elemental balance", size: 1 },
    3383: { name: "Elemental balance", size: 1 },
    3384: { name: "Elemental balance", size: 1 },
    3385: { name: "Elemental balance", size: 1 },
    3386: { name: "Elemental balance", size: 1 },
    3387: { name: "Elemental balance", size: 1 },
    3388: { name: "Osman", size: 1 },
    3389: { name: "Pirate Pete", size: 1 },
    3390: { name: "Mountain Dwarf", size: 1 },
    3391: { name: "General Wartface", size: 1 },
    3392: { name: "General Bentnoze", size: 1 },
    3393: { name: "Lumbridge Guide", size: 1 },
    3394: { name: "Evil Dave", size: 1 },
    3395: { name: "Sir Amik Varze", size: 1 },
    3396: { name: "Awowogei", size: 1 },
    3397: { name: "Awowogei", size: 1 },
    3398: { name: "Skrach Uglogwee", size: 2 },
    3399: { name: "null", size: 1 },
    3400: { name: "Culinaromancer", size: 1 },
    3401: { name: "Elemental balance", size: 1 },
    3402: { name: "Elemental balance", size: 1 },
    3403: { name: "Elemental balance", size: 1 },
    3404: { name: "Elemental balance", size: 1 },
    3405: { name: "Elemental balance", size: 1 },
    3406: { name: "Elemental balance", size: 1 },
    3407: { name: "Elemental balance", size: 1 },
    3408: { name: "Elemental balance", size: 1 },
    3409: { name: "Elemental balance", size: 1 },
    3410: { name: "Elemental balance", size: 1 },
    3411: { name: "Elemental balance", size: 1 },
    3412: { name: "Elemental balance", size: 1 },
    3413: { name: "Koftik", size: 1 },
    3414: { name: "Idris", size: 1 },
    3415: { name: "Essyllt", size: 1 },
    3416: { name: "Morvran", size: 1 },
    3417: { name: "Rod Fishing spot", size: 1 },
    3418: { name: "Rod Fishing spot", size: 1 },
    3419: { name: "Fishing spot", size: 1 },
    3420: { name: "Rabbit", size: 1 },
    3421: { name: "Rabbit", size: 1 },
    3422: { name: "Rabbit", size: 1 },
    3423: { name: "Grizzly bear", size: 2 },
    3424: { name: "Grizzly bear cub", size: 2 },
    3425: { name: "Grizzly bear cub", size: 1 },
    3426: { name: "Dire Wolf", size: 2 },
    3427: { name: "null", size: 1 },
    3428: { name: "Iorwerth Archer", size: 1 },
    3429: { name: "Iorwerth Warrior", size: 1 },
    3430: { name: "null", size: 1 },
    3431: { name: "null", size: 1 },
    3432: { name: "Arianwyn", size: 1 },
    3433: { name: "Tyras guard", size: 1 },
    3434: { name: "Tyras guard", size: 1 },
    3435: { name: "null", size: 1 },
    3436: { name: "Tyras guard", size: 1 },
    3437: { name: "General Hining", size: 1 },
    3438: { name: "Quartermaster", size: 1 },
    3439: { name: "King's Messenger", size: 1 },
    3440: { name: "Will o' the wisp", size: 1 },
    3441: { name: "Will o' the wisp", size: 1 },
    3442: { name: "Ali the dyer", size: 1 },
    3443: { name: "null", size: 1 },
    3444: { name: "null", size: 2 },
    3445: { name: "Guardian of Armadyl", size: 1 },
    3446: { name: "Guardian of Armadyl", size: 1 },
    3447: { name: "Winelda", size: 1 },
    3448: { name: "Fire Warrior of Lesarkus", size: 1 },
    3449: { name: "Shadow Hound", size: 1 },
    3450: { name: "Mysterious ghost", size: 1 },
    3451: { name: "Mysterious ghost", size: 1 },
    3452: { name: "Mysterious ghost", size: 1 },
    3453: { name: "Mysterious ghost", size: 1 },
    3454: { name: "Mysterious ghost", size: 1 },
    3455: { name: "Mysterious ghost", size: 1 },
    3456: { name: "Fareed", size: 1 },
    3457: { name: "Elemental balance", size: 1 },
    3458: { name: "Kamil", size: 1 },
    3459: { name: "Dessous", size: 1 },
    3460: { name: "Dessous", size: 1 },
    3461: { name: "Ruantun", size: 1 },
    3462: { name: "Cyrisus", size: 1 },
    3463: { name: "Cyrisus", size: 1 },
    3464: { name: "Cyrisus", size: 1 },
    3465: { name: "Fallen Man", size: 1 },
    3466: { name: "Fallen Man", size: 1 },
    3467: { name: "Cyrisus", size: 1 },
    3468: { name: "Cyrisus", size: 1 },
    3469: { name: "Cyrisus", size: 1 },
    3470: { name: "Cyrisus", size: 1 },
    3471: { name: "Cyrisus", size: 1 },
    3472: { name: "'Bird's-Eye' Jack", size: 1 },
    3473: { name: "The Inadequacy", size: 5 },
    3474: { name: "The Everlasting", size: 3 },
    3475: { name: "The Untouchable", size: 2 },
    3476: { name: "The Illusive", size: 1 },
    3477: { name: "A Doubt", size: 1 },
    3478: { name: "The Illusive", size: 1 },
    3479: { name: "Morgan", size: 1 },
    3480: { name: "Dr Harlow", size: 1 },
    3481: { name: "Count Draynor", size: 1 },
    3482: { name: "Count Draynor", size: 2 },
    3483: { name: "Will o' the wisp", size: 1 },
    3484: { name: "Monk of Zamorak", size: 1 },
    3485: { name: "Monk of Zamorak", size: 1 },
    3486: { name: "Monk of Zamorak", size: 1 },
    3487: { name: "null", size: 2 },
    3488: { name: "null", size: 1 },
    3489: { name: "null", size: 1 },
    3490: { name: "Jorral", size: 1 },
    3491: { name: "Melina", size: 1 },
    3492: { name: "Melina", size: 1 },
    3493: { name: "Droalak", size: 1 },
    3494: { name: "Droalak", size: 1 },
    3495: { name: "Dron", size: 1 },
    3496: { name: "Blanin", size: 1 },
    3497: { name: "Gertrude's cat", size: 1 },
    3498: { name: "Kitten", size: 1 },
    3499: { name: "Crate", size: 1 },
    3500: { name: "null", size: 1 },
    3501: { name: "Shilop", size: 1 },
    3502: { name: "Philop", size: 1 },
    3503: { name: "Wilough", size: 1 },
    3504: { name: "Kanel", size: 1 },
    3505: { name: "Civilian", size: 1 },
    3506: { name: "Civilian", size: 1 },
    3507: { name: "Civilian", size: 1 },
    3508: { name: "Bouncer", size: 2 },
    3509: { name: "Bouncer", size: 2 },
    3510: { name: "General Khazard", size: 2 },
    3511: { name: "Scout", size: 1 },
    3512: { name: "Scout", size: 1 },
    3513: { name: "Scout", size: 1 },
    3514: { name: "Scout", size: 1 },
    3515: { name: "Sin Seer", size: 1 },
    3516: { name: "Ghost", size: 1 },
    3517: { name: "Renegade Knight", size: 1 },
    3518: { name: "Thrantax the Mighty", size: 1 },
    3519: { name: "Sir Lancelot", size: 1 },
    3520: { name: "Sir Gawain", size: 1 },
    3521: { name: "Sir Kay", size: 1 },
    3522: { name: "Sir Bedivere", size: 1 },
    3523: { name: "Sir Tristram", size: 1 },
    3524: { name: "Sir Pelleas", size: 1 },
    3525: { name: "Sir Lucan", size: 1 },
    3526: { name: "Sir Palomedes", size: 1 },
    3527: { name: "Sir Mordred", size: 1 },
    3528: { name: "Morgan Le Faye", size: 1 },
    3529: { name: "Merlin", size: 1 },
    3530: { name: "The Lady of the Lake", size: 1 },
    3531: { name: "King Arthur", size: 1 },
    3532: { name: "Beggar", size: 1 },
    3533: { name: "Ali Morrisane", size: 1 },
    3534: { name: "Drunken Ali", size: 1 },
    3535: { name: "Ali the Barman", size: 1 },
    3536: { name: "Ali the Kebab seller", size: 1 },
    3537: { name: "Market seller", size: 1 },
    3538: { name: "Ali the Camel Man", size: 1 },
    3539: { name: "Street urchin", size: 1 },
    3540: { name: "Ali the Mayor", size: 1 },
    3541: { name: "Ali the Hag", size: 1 },
    3542: { name: "Ali the Snake Charmer", size: 1 },
    3543: { name: "Ali the Camel", size: 2 },
    3544: { name: "Snake", size: 1 },
    3545: { name: "Snake", size: 1 },
    3546: { name: "Blackjack seller", size: 1 },
    3547: { name: "Menaphite Leader", size: 1 },
    3548: { name: "Ali the Operator", size: 1 },
    3549: { name: "Menaphite Thug", size: 1 },
    3550: { name: "Menaphite Thug", size: 1 },
    3551: { name: "Tough Guy", size: 1 },
    3552: { name: "Villager", size: 1 },
    3553: { name: "Villager", size: 1 },
    3554: { name: "Villager", size: 1 },
    3555: { name: "Villager", size: 1 },
    3556: { name: "Villager", size: 1 },
    3557: { name: "Villager", size: 1 },
    3558: { name: "Villager", size: 1 },
    3559: { name: "Villager", size: 1 },
    3560: { name: "Villager", size: 1 },
    3561: { name: "Veronica", size: 1 },
    3562: { name: "Professor Oddenstein", size: 1 },
    3563: { name: "Ernest", size: 1 },
    3564: { name: "Lil' Destructor", size: 1 },
    3565: { name: "Skeleton", size: 1 },
    3566: { name: "Lil' Creator", size: 1 },
    3567: { name: "null", size: 1 },
    3568: { name: "Pentyn", size: 1 },
    3569: { name: "Aristarchus", size: 1 },
    3570: { name: "Boneguard", size: 2 },
    3571: { name: "Pile of bones", size: 2 },
    3572: { name: "Desert Spirit", size: 1 },
    3573: { name: "Crust of ice", size: 2 },
    3574: { name: "Furnace grate", size: 2 },
    3575: { name: "Enakhra", size: 1 },
    3576: { name: "Enakhra", size: 2 },
    3577: { name: "Boneguard", size: 2 },
    3578: { name: "Akthanakos", size: 1 },
    3579: { name: "null", size: 1 },
    3580: { name: "Lazim", size: 1 },
    3581: { name: "Enakhra", size: 1 },
    3582: { name: "Akthanakos", size: 1 },
    3583: { name: "Knight", size: 1 },
    3584: { name: "Skeleton", size: 1 },
    3585: { name: "Effigy", size: 1 },
    3586: { name: "Effigy", size: 1 },
    3587: { name: "Bonafido", size: 2 },
    3588: { name: "Homunculus", size: 3 },
    3589: { name: "Homunculus", size: 3 },
    3590: { name: "Homunculus", size: 3 },
    3591: { name: "Cage", size: 3 },
    3592: { name: "'Transmute' The Alchemist", size: 1 },
    3593: { name: "'Transmute' The Alchemist", size: 1 },
    3594: { name: "'Currency' The Alchemist", size: 1 },
    3595: { name: "'Currency' The Alchemist", size: 1 },
    3596: { name: "'Black-eye'", size: 1 },
    3597: { name: "'No fingers'", size: 1 },
    3598: { name: "'Gummy'", size: 1 },
    3599: { name: "'The Guns'", size: 1 },
    3600: { name: "Frogeel", size: 2 },
    3601: { name: "Unicow", size: 2 },
    3602: { name: "Spidine", size: 1 },
    3603: { name: "Swordchick", size: 1 },
    3604: { name: "Jubster", size: 3 },
    3605: { name: "Newtroost", size: 1 },
    3606: { name: "Brother Kojo", size: 1 },
    3607: { name: "Dungeon rat", size: 1 },
    3608: { name: "Dungeon rat", size: 1 },
    3609: { name: "Dungeon rat", size: 1 },
    3610: { name: "Mine cart", size: 1 },
    3611: { name: "Zealot", size: 1 },
    3612: { name: "Possessed pickaxe", size: 1 },
    3613: { name: "Iron pickaxe", size: 1 },
    3614: { name: "Corpse", size: 1 },
    3615: { name: "Skeletal miner", size: 1 },
    3616: { name: "Treus Dayth", size: 1 },
    3617: { name: "Ghost", size: 1 },
    3618: { name: "Loading crane", size: 3 },
    3619: { name: "Innocent-looking key", size: 1 },
    3620: { name: "Mine cart", size: 1 },
    3621: { name: "Mine cart", size: 1 },
    3622: { name: "Mine cart", size: 1 },
    3623: { name: "Mine cart", size: 1 },
    3624: { name: "Mine cart", size: 1 },
    3625: { name: "Ghost", size: 1 },
    3626: { name: "Haze", size: 1 },
    3627: { name: "Mischievous ghost", size: 1 },
    3628: { name: "Digsite workman", size: 1 },
    3629: { name: "Doug Deeping", size: 1 },
    3630: { name: "Digsite workman", size: 1 },
    3631: { name: "Digsite workman", size: 1 },
    3632: { name: "Student", size: 1 },
    3633: { name: "Student", size: 1 },
    3634: { name: "Student", size: 1 },
    3635: { name: "Examiner", size: 1 },
    3636: { name: "Examiner", size: 1 },
    3637: { name: "Examiner", size: 1 },
    3638: { name: "Researcher", size: 1 },
    3639: { name: "Terry Balando", size: 1 },
    3640: { name: "Panning guide", size: 1 },
    3641: { name: "Nick", size: 1 },
    3642: { name: "Nisha", size: 1 },
    3643: { name: "Redbeard Frank", size: 1 },
    3644: { name: "Captain Tobias", size: 1 },
    3645: { name: "Seaman Lorris", size: 1 },
    3646: { name: "Seaman Thresnor", size: 1 },
    3647: { name: "Luthas", size: 1 },
    3648: { name: "Customs officer", size: 1 },
    3649: { name: "Toy Soldier", size: 1 },
    3650: { name: "null", size: 1 },
    3651: { name: "Gardener", size: 1 },
    3652: { name: "Man", size: 1 },
    3653: { name: "Lumberjack Leif", size: 1 },
    3654: { name: "Miner Magnus", size: 1 },
    3655: { name: "Fisherman Frodi", size: 1 },
    3656: { name: "Gardener Gunnhild", size: 1 },
    3657: { name: "Fishing spot", size: 1 },
    3658: { name: "Carpenter Kjallak", size: 1 },
    3659: { name: "Farmer Fromund", size: 1 },
    3660: { name: "Reacher", size: 1 },
    3661: { name: "Chicken", size: 1 },
    3662: { name: "Chicken", size: 1 },
    3663: { name: "Rooster", size: 1 },
    3664: { name: "Rabbit", size: 1 },
    3665: { name: "Rabbit", size: 1 },
    3666: { name: "Prince Brand", size: 1 },
    3667: { name: "Princess Astrid", size: 1 },
    3668: { name: "King Vargas", size: 2 },
    3669: { name: "Guard", size: 1 },
    3670: { name: "null", size: 1 },
    3671: { name: "Derrik", size: 1 },
    3672: { name: "Farmer", size: 1 },
    3673: { name: "Flower Girl", size: 1 },
    3674: { name: "Ragnar", size: 1 },
    3675: { name: "Einar", size: 1 },
    3676: { name: "Alrik", size: 1 },
    3677: { name: "Thorhild", size: 1 },
    3678: { name: "Halla", size: 1 },
    3679: { name: "Yrsa", size: 1 },
    3680: { name: "Sailor", size: 1 },
    3681: { name: "Rannveig", size: 1 },
    3682: { name: "Thora", size: 1 },
    3683: { name: "Valgerd", size: 1 },
    3684: { name: "Skraeling", size: 1 },
    3685: { name: "Broddi", size: 1 },
    3686: { name: "Skraeling", size: 1 },
    3687: { name: "Ragnvald", size: 1 },
    3688: { name: "Fishmonger", size: 1 },
    3689: { name: "Greengrocer", size: 1 },
    3690: { name: "Vampyre Juvinate", size: 1 },
    3691: { name: "Vampyre Juvinate", size: 1 },
    3692: { name: "Vampyre Juvenile", size: 1 },
    3693: { name: "Vampyre Juvenile", size: 1 },
    3694: { name: "Vampyre Juvinate", size: 1 },
    3695: { name: "Vampyre Juvinate", size: 1 },
    3696: { name: "Vampyre Juvenile", size: 1 },
    3697: { name: "Vampyre Juvenile", size: 1 },
    3698: { name: "Vampyre Juvinate", size: 1 },
    3699: { name: "Vampyre Juvinate", size: 1 },
    3700: { name: "Vampyre Juvinate", size: 1 },
    3701: { name: "Former Vampyre", size: 1 },
    3702: { name: "Former Vampyre", size: 1 },
    3703: { name: "Former Vampyre", size: 1 },
    3704: { name: "Former Vampyre", size: 1 },
    3705: { name: "Former Vampyre", size: 1 },
    3706: { name: "Former Vampyre", size: 1 },
    3707: { name: "Feral Vampyre", size: 1 },
    3708: { name: "Feral Vampyre", size: 1 },
    3709: { name: "Vyrewatch", size: 1 },
    3710: { name: "Vyrewatch", size: 1 },
    3711: { name: "Vyrewatch", size: 1 },
    3712: { name: "Vyrewatch", size: 1 },
    3713: { name: "Vyrewatch", size: 3 },
    3714: { name: "Vyrewatch", size: 3 },
    3715: { name: "Vyrewatch", size: 3 },
    3716: { name: "Vyrewatch", size: 3 },
    3717: { name: "Vyrewatch", size: 3 },
    3718: { name: "Vyrewatch", size: 3 },
    3719: { name: "Vyrewatch", size: 3 },
    3720: { name: "Vyrewatch", size: 3 },
    3721: { name: "Vyrewatch", size: 1 },
    3722: { name: "Vyrewatch", size: 1 },
    3723: { name: "Vyrewatch", size: 1 },
    3724: { name: "Vyrewatch", size: 1 },
    3725: { name: "Vyrewatch", size: 3 },
    3726: { name: "Vyrewatch", size: 3 },
    3727: { name: "Vyrewatch", size: 3 },
    3728: { name: "Vyrewatch", size: 3 },
    3729: { name: "Vyrewatch", size: 3 },
    3730: { name: "Vyrewatch", size: 3 },
    3731: { name: "Vyrewatch", size: 3 },
    3732: { name: "Vyrewatch", size: 3 },
    3733: { name: "Vanstrom Klause", size: 1 },
    3734: { name: "Vanstrom Klause", size: 1 },
    3735: { name: "Vanstrom Klause", size: 1 },
    3736: { name: "Vanstrom Klause", size: 1 },
    3737: { name: "Vanstrom Klause", size: 3 },
    3738: { name: "Vanstrom Klause", size: 3 },
    3739: { name: "Vanstrom Klause", size: 3 },
    3740: { name: "Vanescula Drakan", size: 1 },
    3741: { name: "Vanescula Drakan", size: 1 },
    3742: { name: "Vanescula Drakan", size: 3 },
    3743: { name: "Vanescula Drakan", size: 3 },
    3744: { name: "Ranis Drakan", size: 1 },
    3745: { name: "Ranis Drakan", size: 1 },
    3746: { name: "Ranis Drakan", size: 3 },
    3747: { name: "Ranis Drakan", size: 3 },
    3748: { name: "Vyrewatch", size: 3 },
    3749: { name: "Vyrewatch", size: 3 },
    3750: { name: "Vyrewatch", size: 3 },
    3751: { name: "Vyrewatch", size: 3 },
    3752: { name: "Vyrewatch", size: 3 },
    3753: { name: "Vyrewatch", size: 3 },
    3754: { name: "Vyrewatch", size: 3 },
    3755: { name: "Vyrewatch", size: 3 },
    3756: { name: "Vyrewatch", size: 1 },
    3757: { name: "Vyrewatch", size: 1 },
    3758: { name: "Vyrewatch", size: 1 },
    3759: { name: "Vyrewatch", size: 1 },
    3760: { name: "Vyrewatch", size: 1 },
    3761: { name: "Vyrewatch", size: 1 },
    3762: { name: "Vyrewatch", size: 1 },
    3763: { name: "Vyrewatch", size: 1 },
    3764: { name: "Flying female vampire", size: 3 },
    3765: { name: "Flying female vampire", size: 3 },
    3766: { name: "Flying female vampire", size: 3 },
    3767: { name: "Flying female vampire", size: 3 },
    3768: { name: "Vyrewatch", size: 3 },
    3769: { name: "Vyrewatch", size: 3 },
    3770: { name: "Vyrewatch", size: 3 },
    3771: { name: "Vyrewatch", size: 3 },
    3772: { name: "Old Man Ral", size: 1 },
    3773: { name: "null", size: 1 },
    3774: { name: "Aeonisig Raispher", size: 1 },
    3775: { name: "Safalaan Hallow", size: 1 },
    3776: { name: "Sarius Guile", size: 1 },
    3777: { name: "Elemental balance", size: 1 },
    3778: { name: "Sarius Guile", size: 1 },
    3779: { name: "Trader Sven", size: 1 },
    3780: { name: "Meiyerditch citizen", size: 1 },
    3781: { name: "Meiyerditch citizen", size: 1 },
    3782: { name: "Meiyerditch citizen", size: 1 },
    3783: { name: "Meiyerditch citizen", size: 1 },
    3784: { name: "Meiyerditch citizen", size: 1 },
    3785: { name: "Meiyerditch citizen", size: 1 },
    3786: { name: "Meiyerditch citizen", size: 1 },
    3787: { name: "Meiyerditch citizen", size: 1 },
    3788: { name: "Meiyerditch citizen", size: 1 },
    3789: { name: "Meiyerditch citizen", size: 1 },
    3790: { name: "Meiyerditch citizen", size: 1 },
    3791: { name: "Meiyerditch citizen", size: 1 },
    3792: { name: "Meiyerditch citizen", size: 1 },
    3793: { name: "Meiyerditch citizen", size: 1 },
    3794: { name: "Meiyerditch citizen", size: 1 },
    3795: { name: "Meiyerditch citizen", size: 1 },
    3796: { name: "Meiyerditch citizen", size: 1 },
    3797: { name: "Meiyerditch citizen", size: 1 },
    3798: { name: "Meiyerditch citizen", size: 1 },
    3799: { name: "Meiyerditch citizen", size: 1 },
    3800: { name: "Meiyerditch citizen", size: 1 },
    3801: { name: "Meiyerditch citizen", size: 1 },
    3802: { name: "Meiyerditch citizen", size: 1 },
    3803: { name: "Meiyerditch citizen", size: 1 },
    3804: { name: "Meiyerditch citizen", size: 1 },
    3805: { name: "Meiyerditch citizen", size: 1 },
    3806: { name: "Meiyerditch citizen", size: 1 },
    3807: { name: "Meiyerditch citizen", size: 1 },
    3808: { name: "Meiyerditch citizen", size: 1 },
    3809: { name: "Child", size: 1 },
    3810: { name: "Child", size: 1 },
    3811: { name: "Child", size: 1 },
    3812: { name: "Child", size: 1 },
    3813: { name: "Child", size: 1 },
    3814: { name: "Child", size: 1 },
    3815: { name: "Child", size: 1 },
    3816: { name: "Child", size: 1 },
    3817: { name: "Child", size: 1 },
    3818: { name: "Child", size: 1 },
    3819: { name: "Meiyerditch miner", size: 1 },
    3820: { name: "Meiyerditch miner", size: 1 },
    3821: { name: "Meiyerditch miner", size: 1 },
    3822: { name: "Meiyerditch miner", size: 1 },
    3823: { name: "Meiyerditch miner", size: 1 },
    3824: { name: "Meiyerditch miner", size: 1 },
    3825: { name: "Shadowy figure", size: 1 },
    3826: { name: "Shadowy figure", size: 1 },
    3827: { name: "Shadowy figure", size: 1 },
    3828: { name: "null", size: 1 },
    3829: { name: "Stray dog", size: 1 },
    3830: { name: "Stray dog", size: 1 },
    3831: { name: "Cat", size: 1 },
    3832: { name: "Cat", size: 1 },
    3833: { name: "<col=00ffff>Boat</col>", size: 4 },
    3834: { name: "Boat", size: 3 },
    3835: { name: "Oneiromancer", size: 1 },
    3836: { name: "House", size: 5 },
    3837: { name: "Baba Yaga", size: 1 },
    3838: { name: "Pauline Polaris", size: 1 },
    3839: { name: "Meteora", size: 1 },
    3840: { name: "Melana Moonlander", size: 1 },
    3841: { name: "Selene", size: 1 },
    3842: { name: "Rimae Sirsalis", size: 1 },
    3843: { name: "Sirsal Banker", size: 1 },
    3844: { name: "Clan Guard", size: 1 },
    3845: { name: "Enchanted Broom", size: 1 },
    3846: { name: "Enchanted Broom", size: 1 },
    3847: { name: "Enchanted Broom", size: 1 },
    3848: { name: "Enchanted Bucket", size: 1 },
    3849: { name: "Enchanted Bucket", size: 1 },
    3850: { name: "Bouquet Mac Hyacinth", size: 1 },
    3851: { name: "Moss giant", size: 2 },
    3852: { name: "Moss giant", size: 2 },
    3853: { name: "Parrot", size: 1 },
    3854: { name: "null", size: 1 },
    3855: { name: "Lokar Searunner", size: 1 },
    3856: { name: "Cabin boy", size: 1 },
    3857: { name: "null", size: 1 },
    3858: { name: "'Beefy' Burns", size: 1 },
    3859: { name: "'Eagle-eye' Shultz", size: 1 },
    3860: { name: "First mate 'Davey-boy'", size: 1 },
    3861: { name: "'Birds-Eye' Jack", size: 1 },
    3862: { name: "'Picarron' Pete", size: 1 },
    3863: { name: "Jake", size: 1 },
    3864: { name: "Bedread the bold", size: 1 },
    3865: { name: "Wilson", size: 1 },
    3866: { name: "Tommy 2-times", size: 1 },
    3867: { name: "Murky Pat", size: 1 },
    3868: { name: "Jack Sails", size: 1 },
    3869: { name: "Palmer", size: 1 },
    3870: { name: "'Betty' B.Boppin", size: 1 },
    3871: { name: "'Beedy-eye' Jones", size: 1 },
    3872: { name: "Jenny Blade", size: 1 },
    3873: { name: "'Lecherous' Lee", size: 1 },
    3874: { name: "'Sticky' Sanders", size: 1 },
    3875: { name: "Jex", size: 1 },
    3876: { name: "Maisa", size: 1 },
    3877: { name: "Osman", size: 1 },
    3878: { name: "Osman", size: 1 },
    3879: { name: "Osman", size: 1 },
    3880: { name: "Osman", size: 1 },
    3881: { name: "Sophanem Guard", size: 1 },
    3882: { name: "Sophanem Guard", size: 1 },
    3883: { name: "Sophanem Guard", size: 1 },
    3884: { name: "Sophanem Guard", size: 1 },
    3885: { name: "null", size: 1 },
    3886: { name: "null", size: 1 },
    3887: { name: "Banker", size: 1 },
    3888: { name: "Banker", size: 1 },
    3889: { name: "Stonemason", size: 1 },
    3890: { name: "Nathifa", size: 1 },
    3891: { name: "Urbi", size: 1 },
    3892: { name: "Jamila", size: 1 },
    3893: { name: "Doric", size: 1 },
    3894: { name: "Sigmund The Merchant", size: 1 },
    3895: { name: "Peer the Seer", size: 1 },
    3896: { name: "Thorvald the Warrior", size: 1 },
    3897: { name: "Koschei the deathless", size: 1 },
    3898: { name: "Koschei the deathless", size: 1 },
    3899: { name: "Koschei the deathless", size: 1 },
    3900: { name: "Koschei the deathless", size: 1 },
    3901: { name: "Fox", size: 1 },
    3902: { name: "Bunny", size: 1 },
    3903: { name: "Bunny", size: 1 },
    3904: { name: "Gull", size: 1 },
    3905: { name: "Gull", size: 1 },
    3906: { name: "Gull", size: 1 },
    3907: { name: "Gull", size: 1 },
    3908: { name: "Bear Cub", size: 2 },
    3909: { name: "Bear Cub", size: 1 },
    3910: { name: "Unicorn Foal", size: 2 },
    3911: { name: "Black unicorn Foal", size: 2 },
    3912: { name: "Wolf", size: 2 },
    3913: { name: "Fishing spot", size: 1 },
    3914: { name: "Fishing spot", size: 1 },
    3915: { name: "Fishing spot", size: 1 },
    3916: { name: "Bjorn", size: 1 },
    3917: { name: "Eldgrim", size: 1 },
    3918: { name: "Prince Brand", size: 1 },
    3919: { name: "Princess Astrid", size: 1 },
    3920: { name: "Manni the Reveller", size: 1 },
    3921: { name: "Council workman", size: 1 },
    3922: { name: "The Draugen", size: 1 },
    3923: { name: "Butterfly", size: 1 },
    3924: { name: "Sigli the Huntsman", size: 1 },
    3925: { name: "Swensen the Navigator", size: 1 },
    3926: { name: "null", size: 1 },
    3927: { name: "null", size: 1 },
    3928: { name: "Guard", size: 1 },
    3929: { name: "Guard", size: 1 },
    3930: { name: "Town Guard", size: 1 },
    3931: { name: "Town Guard", size: 1 },
    3932: { name: "Thora the Barkeep", size: 1 },
    3933: { name: "Yrsa", size: 1 },
    3934: { name: "Fisherman", size: 1 },
    3935: { name: "Skulgrimen", size: 1 },
    3936: { name: "Sailor", size: 1 },
    3937: { name: "Agnar", size: 1 },
    3938: { name: "Freidir", size: 1 },
    3939: { name: "Borrokar", size: 1 },
    3940: { name: "Lanzig", size: 1 },
    3941: { name: "Pontak", size: 1 },
    3942: { name: "Freygerd", size: 1 },
    3943: { name: "Lensa", size: 1 },
    3944: { name: "Jennella", size: 1 },
    3945: { name: "Sassilik", size: 1 },
    3946: { name: "Inga", size: 1 },
    3947: { name: "Fish monger", size: 1 },
    3948: { name: "Fur trader", size: 1 },
    3949: { name: "Market Guard", size: 1 },
    3950: { name: "Warrior", size: 1 },
    3951: { name: "Legends' Guard", size: 1 },
    3952: { name: "Legends' Guard", size: 1 },
    3953: { name: "Radimus Erkle", size: 1 },
    3954: { name: "Jungle forester", size: 1 },
    3955: { name: "Jungle forester", size: 1 },
    3956: { name: "Gujuo", size: 1 },
    3957: { name: "Ungadulu", size: 1 },
    3958: { name: "Ungadulu", size: 1 },
    3959: { name: "Tormented Warrior", size: 1 },
    3960: { name: "Fionella", size: 1 },
    3961: { name: "Siegfried Erkle", size: 1 },
    3962: { name: "Nezikchened", size: 3 },
    3963: { name: "Viyeldi", size: 1 },
    3964: { name: "San Tojalon", size: 1 },
    3965: { name: "Irvig Senay", size: 1 },
    3966: { name: "Ranalph Devere", size: 1 },
    3967: { name: "Boulder", size: 2 },
    3968: { name: "Echned Zekin", size: 1 },
    3969: { name: "Zombie rat", size: 2 },
    3970: { name: "Zombie rat", size: 2 },
    3971: { name: "Zombie rat", size: 1 },
    3972: { name: "Skeleton", size: 1 },
    3973: { name: "Skeleton", size: 1 },
    3974: { name: "Skeleton", size: 1 },
    3975: { name: "Ghost", size: 1 },
    3976: { name: "Ghost", size: 1 },
    3977: { name: "Ghost", size: 1 },
    3978: { name: "Ghost", size: 1 },
    3979: { name: "Ghost", size: 1 },
    3980: { name: "Zombie", size: 1 },
    3981: { name: "Zombie", size: 1 },
    3982: { name: "Lesser demon", size: 2 },
    3983: { name: "null", size: 1 },
    3984: { name: "Doctor Orbon", size: 1 },
    3985: { name: "Farmer Brumty", size: 1 },
    3986: { name: "Red Sheep", size: 1 },
    3987: { name: "Green Sheep", size: 1 },
    3988: { name: "Blue Sheep", size: 1 },
    3989: { name: "Yellow Sheep", size: 1 },
    3990: { name: "null", size: 1 },
    3991: { name: "null", size: 1 },
    3992: { name: "null", size: 1 },
    3993: { name: "null", size: 1 },
    3994: { name: "Boy", size: 1 },
    3995: { name: "Nora T. Hagg", size: 1 },
    3996: { name: "Witch's experiment", size: 1 },
    3997: { name: "Witch's experiment (second form)", size: 1 },
    3998: { name: "Witch's experiment (third form)", size: 2 },
    3999: { name: "Witch's experiment (fourth form)", size: 2 },
    4000: { name: "Mouse", size: 1 },
    4001: { name: "Chompy chick", size: 1 },
    4002: { name: "Chompy chick", size: 1 },
    4003: { name: "null", size: 1 },
    4004: { name: "Shadow", size: 1 },
    4005: { name: "Dark beast", size: 3 },
    4006: { name: "null", size: 1 },
    4007: { name: "null", size: 1 },
    4008: { name: "null", size: 1 },
    4009: { name: "null", size: 1 },
    4010: { name: "Thorgel", size: 1 },
    4011: { name: "Bill Teach", size: 1 },
    4012: { name: "Bill Teach", size: 1 },
    4013: { name: "null", size: 1 },
    4014: { name: "Bill Teach", size: 1 },
    4015: { name: "Bill Teach", size: 1 },
    4016: { name: "Bill Teach", size: 1 },
    4017: { name: "Charley", size: 1 },
    4018: { name: "Smith", size: 1 },
    4019: { name: "Joe", size: 1 },
    4020: { name: "Mama", size: 1 },
    4021: { name: "Mama", size: 1 },
    4022: { name: "Mike", size: 1 },
    4023: { name: "Pirate", size: 1 },
    4024: { name: "Pirate", size: 1 },
    4025: { name: "Pirate", size: 1 },
    4026: { name: "Pirate", size: 1 },
    4027: { name: "Pirate", size: 1 },
    4028: { name: "Pirate", size: 1 },
    4029: { name: "Pirate", size: 1 },
    4030: { name: "Pirate", size: 1 },
    4031: { name: "Pirate", size: 1 },
    4032: { name: "Pirate", size: 1 },
    4033: { name: "Pirate", size: 1 },
    4034: { name: "Pirate", size: 1 },
    4035: { name: "Pirate", size: 1 },
    4036: { name: "Pirate", size: 1 },
    4037: { name: "Pirate", size: 1 },
    4038: { name: "Pirate", size: 1 },
    4039: { name: "Pirate", size: 1 },
    4040: { name: "Pirate", size: 1 },
    4041: { name: "Pirate", size: 1 },
    4042: { name: "Pirate", size: 1 },
    4043: { name: "Pirate", size: 1 },
    4044: { name: "Pirate", size: 1 },
    4045: { name: "Pirate", size: 1 },
    4046: { name: "Pirate", size: 1 },
    4047: { name: "Pirate", size: 1 },
    4048: { name: "Pirate", size: 1 },
    4049: { name: "Pirate", size: 1 },
    4050: { name: "Pirate", size: 1 },
    4051: { name: "Pirate", size: 1 },
    4052: { name: "Pirate", size: 1 },
    4053: { name: "Gull", size: 1 },
    4054: { name: "Banker", size: 1 },
    4055: { name: "Banker", size: 1 },
    4056: { name: "Grail Maiden", size: 1 },
    4057: { name: "Sir Percival", size: 1 },
    4058: { name: "King Percival", size: 1 },
    4059: { name: "Merlin", size: 1 },
    4060: { name: "Peasant", size: 1 },
    4061: { name: "Peasant", size: 1 },
    4062: { name: "High Priest", size: 1 },
    4063: { name: "Crone", size: 1 },
    4064: { name: "Galahad", size: 1 },
    4065: { name: "Fisherman", size: 1 },
    4066: { name: "The Fisher King", size: 1 },
    4067: { name: "Black Knight Titan", size: 1 },
    4068: { name: "Monk", size: 1 },
    4069: { name: "Bonzo", size: 1 },
    4070: { name: "Sinister Stranger", size: 1 },
    4071: { name: "Sinister Stranger", size: 1 },
    4072: { name: "Morris", size: 1 },
    4073: { name: "Big Dave", size: 1 },
    4074: { name: "Joshua", size: 1 },
    4075: { name: "Grandpa Jack", size: 1 },
    4076: { name: "Forester", size: 1 },
    4077: { name: "Austri", size: 1 },
    4078: { name: "Vestri", size: 1 },
    4079: { name: "Fishing spot", size: 1 },
    4080: { name: "Fishing spot", size: 1 },
    4081: { name: "Fishing spot", size: 1 },
    4082: { name: "Fishing spot", size: 1 },
    4083: { name: "Denulth", size: 1 },
    4084: { name: "Sergeant", size: 1 },
    4085: { name: "Sergeant", size: 1 },
    4086: { name: "Soldier", size: 1 },
    4087: { name: "Soldier", size: 1 },
    4088: { name: "Soldier", size: 1 },
    4089: { name: "Soldier", size: 1 },
    4090: { name: "Soldier", size: 1 },
    4091: { name: "Soldier", size: 1 },
    4092: { name: "Soldier", size: 2 },
    4093: { name: "Saba", size: 1 },
    4094: { name: "Tenzing", size: 1 },
    4095: { name: "Eadburg", size: 1 },
    4096: { name: "Archer", size: 1 },
    4097: { name: "Archer", size: 1 },
    4098: { name: "Archer", size: 1 },
    4099: { name: "Guard", size: 1 },
    4100: { name: "Guard", size: 1 },
    4101: { name: "Harold", size: 1 },
    4102: { name: "Tostig", size: 1 },
    4103: { name: "Eohric", size: 1 },
    4104: { name: "Servant", size: 1 },
    4105: { name: "Dunstan", size: 1 },
    4106: { name: "Wistan", size: 1 },
    4107: { name: "Breoca", size: 1 },
    4108: { name: "Ocga", size: 1 },
    4109: { name: "Penda", size: 1 },
    4110: { name: "Hygd", size: 1 },
    4111: { name: "Ceolburg", size: 1 },
    4112: { name: "Hild", size: 1 },
    4113: { name: "null", size: 1 },
    4114: { name: "White Knight", size: 1 },
    4115: { name: "Fareed (hard)", size: 1 },
    4116: { name: "Billy", size: 1 },
    4117: { name: "Mountain goat", size: 1 },
    4118: { name: "Eadgar", size: 1 },
    4119: { name: "Godric", size: 1 },
    4120: { name: "Troll general", size: 2 },
    4121: { name: "Troll general", size: 2 },
    4122: { name: "Troll general", size: 2 },
    4123: { name: "Troll spectator", size: 1 },
    4124: { name: "Troll spectator", size: 1 },
    4125: { name: "Troll spectator", size: 1 },
    4126: { name: "Troll spectator", size: 1 },
    4127: { name: "Troll spectator", size: 1 },
    4128: { name: "Troll spectator", size: 1 },
    4129: { name: "Troll spectator", size: 1 },
    4130: { name: "Dad", size: 3 },
    4131: { name: "Twig", size: 2 },
    4132: { name: "Berry", size: 2 },
    4133: { name: "Twig", size: 2 },
    4134: { name: "Berry", size: 2 },
    4135: { name: "Thrower troll", size: 1 },
    4136: { name: "Thrower troll", size: 1 },
    4137: { name: "Thrower troll", size: 1 },
    4138: { name: "Thrower troll", size: 1 },
    4139: { name: "Thrower troll", size: 1 },
    4140: { name: "Cook", size: 2 },
    4141: { name: "Cook", size: 2 },
    4142: { name: "Cook", size: 2 },
    4143: { name: "Mountain troll", size: 1 },
    4144: { name: "Mushroom", size: 2 },
    4145: { name: "Mountain goat", size: 1 },
    4146: { name: "Mountain goat", size: 1 },
    4147: { name: "Mountain goat", size: 1 },
    4148: { name: "Guard", size: 1 },
    4149: { name: "Guard", size: 1 },
    4150: { name: "Guard", size: 1 },
    4151: { name: "Guard", size: 1 },
    4152: { name: "Guard", size: 1 },
    4153: { name: "Guard", size: 1 },
    4154: { name: "Guard", size: 1 },
    4155: { name: "Guard", size: 1 },
    4156: { name: "Guard", size: 1 },
    4157: { name: "Burntmeat", size: 2 },
    4158: { name: "Rat Burgiss", size: 1 },
    4159: { name: "Surok Magis", size: 1 },
    4160: { name: "Surok Magis", size: 1 },
    4161: { name: "Zaff", size: 1 },
    4162: { name: "Anna Jones", size: 1 },
    4163: { name: "King Roald", size: 1 },
    4164: { name: "Mishkal'un Dorn", size: 1 },
    4165: { name: "Dakh'thoulan Aegis", size: 1 },
    4166: { name: "Sil'as Dahcsnu", size: 1 },
    4167: { name: "Outlaw", size: 1 },
    4168: { name: "Outlaw", size: 1 },
    4169: { name: "Outlaw", size: 1 },
    4170: { name: "Outlaw", size: 1 },
    4171: { name: "Outlaw", size: 1 },
    4172: { name: "Outlaw", size: 1 },
    4173: { name: "Outlaw", size: 1 },
    4174: { name: "Outlaw", size: 1 },
    4175: { name: "Outlaw", size: 1 },
    4176: { name: "Outlaw", size: 1 },
    4177: { name: "Monkey", size: 1 },
    4178: { name: "Bench", size: 1 },
    4179: { name: "Hadley", size: 1 },
    4180: { name: "Gerald", size: 1 },
    4181: { name: "Almera", size: 1 },
    4182: { name: "Hudon", size: 1 },
    4183: { name: "Golrie", size: 1 },
    4184: { name: "Crocodile", size: 2 },
    4185: { name: "Jackal", size: 1 },
    4186: { name: "null", size: 1 },
    4187: { name: "<col=ff9040>Oddskull</col>", size: 1 },
    4188: { name: "null", size: 1 },
    4189: { name: "null", size: 1 },
    4190: { name: "null", size: 1 },
    4191: { name: "null", size: 1 },
    4192: { name: "Scarab Swarm", size: 1 },
    4193: { name: "Wanderer", size: 1 },
    4194: { name: "Wanderer", size: 1 },
    4195: { name: "Apparition", size: 1 },
    4196: { name: "Apparition", size: 1 },
    4197: { name: "Apparition", size: 1 },
    4198: { name: "Apparition", size: 1 },
    4199: { name: "Icthlarin", size: 1 },
    4200: { name: "null", size: 1 },
    4201: { name: "null", size: 1 },
    4202: { name: "Embalmer", size: 1 },
    4203: { name: "Carpenter", size: 1 },
    4204: { name: "null", size: 1 },
    4205: { name: "Siamun", size: 1 },
    4206: { name: "High Priest", size: 1 },
    4207: { name: "Priest", size: 1 },
    4208: { name: "Priest", size: 1 },
    4209: { name: "Sphinx", size: 2 },
    4210: { name: "Possessed Priest", size: 1 },
    4211: { name: "null", size: 1 },
    4212: { name: "Donovan the Family Handyman", size: 1 },
    4213: { name: "Pierre", size: 1 },
    4214: { name: "Hobbes", size: 1 },
    4215: { name: "Louisa", size: 1 },
    4216: { name: "Mary", size: 1 },
    4217: { name: "Stanford", size: 1 },
    4218: { name: "Guard", size: 1 },
    4219: { name: "Gossip", size: 1 },
    4220: { name: "Anna", size: 1 },
    4221: { name: "Bob", size: 1 },
    4222: { name: "Carol", size: 1 },
    4223: { name: "David", size: 1 },
    4224: { name: "Elizabeth", size: 1 },
    4225: { name: "Frank", size: 1 },
    4226: { name: "Sinclair", size: 1 },
    4227: { name: "Poison Salesman", size: 1 },
    4228: { name: "Sinclair Guard dog", size: 1 },
    4229: { name: "Love Cats", size: 1 },
    4230: { name: "Neite", size: 1 },
    4231: { name: "Bob", size: 1 },
    4232: { name: "Beite", size: 1 },
    4233: { name: "Gnome", size: 1 },
    4234: { name: "Gnome", size: 1 },
    4235: { name: "Odysseus", size: 2 },
    4236: { name: "Neite", size: 1 },
    4237: { name: "Unferth", size: 1 },
    4238: { name: "Unferth", size: 1 },
    4239: { name: "Unferth", size: 1 },
    4240: { name: "Unferth", size: 1 },
    4241: { name: "Unferth", size: 1 },
    4242: { name: "Reldo", size: 1 },
    4243: { name: "Reldo", size: 1 },
    4244: { name: "Brother Omad", size: 1 },
    4245: { name: "Brother Cedric", size: 1 },
    4246: { name: "Monk", size: 1 },
    4247: { name: "Thief", size: 1 },
    4248: { name: "Head Thief", size: 1 },
    4249: { name: "Alrena", size: 1 },
    4250: { name: "Alrena", size: 1 },
    4251: { name: "Alrena", size: 1 },
    4252: { name: "Bravek", size: 1 },
    4253: { name: "Bravek", size: 1 },
    4254: { name: "null", size: 1 },
    4255: { name: "Clerk", size: 1 },
    4256: { name: "Edmond", size: 1 },
    4257: { name: "Elena", size: 1 },
    4258: { name: "null", size: 1 },
    4259: { name: "null", size: 1 },
    4260: { name: "null", size: 1 },
    4261: { name: "null", size: 1 },
    4262: { name: "null", size: 1 },
    4263: { name: "Ted Rehnison", size: 1 },
    4264: { name: "Martha Rehnison", size: 1 },
    4265: { name: "Billy Rehnison", size: 1 },
    4266: { name: "Milli Rehnison", size: 1 },
    4267: { name: "null", size: 1 },
    4268: { name: "Man", size: 1 },
    4269: { name: "Man", size: 1 },
    4270: { name: "Man", size: 1 },
    4271: { name: "Man", size: 1 },
    4272: { name: "Man", size: 1 },
    4273: { name: "null", size: 1 },
    4274: { name: "Leela", size: 1 },
    4275: { name: "null", size: 1 },
    4276: { name: "Jail guard", size: 1 },
    4277: { name: "Jail guard", size: 1 },
    4278: { name: "Jail guard", size: 1 },
    4279: { name: "Jail guard", size: 1 },
    4280: { name: "Ned", size: 1 },
    4281: { name: "null", size: 1 },
    4282: { name: "null", size: 1 },
    4283: { name: "null", size: 1 },
    4284: { name: "Aggie", size: 1 },
    4285: { name: "Chancellor Hassan", size: 1 },
    4286: { name: "Osman", size: 1 },
    4287: { name: "Border Guard", size: 1 },
    4288: { name: "Border Guard", size: 1 },
    4289: { name: "Gull", size: 1 },
    4290: { name: "Gull", size: 1 },
    4291: { name: "Herman Caranos", size: 1 },
    4292: { name: "Franklin Caranos", size: 1 },
    4293: { name: "Arnold Lydspor", size: 1 },
    4294: { name: "Devin Mendelberg", size: 1 },
    4295: { name: "George Laxmeister", size: 1 },
    4296: { name: "Ramara du Croissant", size: 1 },
    4297: { name: "null", size: 1 },
    4298: { name: "Kathy Corkat", size: 1 },
    4299: { name: "Kathy Corkat", size: 1 },
    4300: { name: "null", size: 1 },
    4301: { name: "Elemental balance", size: 1 },
    4302: { name: "Elemental balance", size: 1 },
    4303: { name: "Kalphite Queen", size: 5 },
    4304: { name: "Kalphite Queen", size: 5 },
    4305: { name: "Drunken Dwarf", size: 1 },
    4306: { name: "Wise Old Man", size: 1 },
    4307: { name: "Wise Old Man", size: 1 },
    4308: { name: "Sea troll", size: 1 },
    4309: { name: "Sea troll", size: 1 },
    4310: { name: "Sea troll", size: 1 },
    4311: { name: "Sea troll", size: 1 },
    4312: { name: "Skeleton Mage", size: 1 },
    4313: { name: "Sea troll", size: 1 },
    4314: { name: "Sea Troll General", size: 3 },
    4315: { name: "Sea Troll Queen", size: 5 },
    4316: { name: "Fishing spot", size: 1 },
    4317: { name: "Caleb", size: 1 },
    4318: { name: "Skeleton Mage", size: 1 },
    4319: { name: "Skeleton Mage", size: 1 },
    4320: { name: "Morgan Le Faye", size: 1 },
    4321: { name: "Renegade knight", size: 1 },
    4322: { name: "Zanik", size: 1 },
    4323: { name: "Zanik", size: 1 },
    4324: { name: "Zanik", size: 1 },
    4325: { name: "Light creature", size: 1 },
    4326: { name: "Zanik", size: 1 },
    4327: { name: "HAM member", size: 1 },
    4328: { name: "Sigmund", size: 1 },
    4329: { name: "H.A.M. Deacon", size: 1 },
    4330: { name: "Johanhus Ulsbrecht", size: 1 },
    4331: { name: "Black Knight", size: 1 },
    4332: { name: "Guard", size: 1 },
    4333: { name: "Court judge", size: 1 },
    4334: { name: "Jury", size: 1 },
    4335: { name: "Guard", size: 1 },
    4336: { name: "Guard", size: 1 },
    4337: { name: "Prosecutor", size: 1 },
    4338: { name: "null", size: 1 },
    4339: { name: "null", size: 1 },
    4340: { name: "Arthur", size: 1 },
    4341: { name: "null", size: 1 },
    4342: { name: "Sir Lucan", size: 1 },
    4343: { name: "Sir Palomedes", size: 1 },
    4344: { name: "Sir Lancelot", size: 1 },
    4345: { name: "Sir Bedivere", size: 1 },
    4346: { name: "Sir Tristram", size: 1 },
    4347: { name: "Sir Pelleas", size: 1 },
    4348: { name: "Sir Gawain", size: 1 },
    4349: { name: "Sir Kay", size: 1 },
    4350: { name: "Sir Pelleas", size: 1 },
    4351: { name: "Sir Gawain", size: 1 },
    4352: { name: "Sir Kay", size: 1 },
    4353: { name: "Squire", size: 1 },
    4354: { name: "Sir Lancelot", size: 1 },
    4355: { name: "Sir Kay", size: 1 },
    4356: { name: "Sir Gawain", size: 1 },
    4357: { name: "Sir Lucan", size: 1 },
    4358: { name: "Sir Palomedes", size: 1 },
    4359: { name: "Sir Tristram", size: 1 },
    4360: { name: "Sir Pelleas", size: 1 },
    4361: { name: "Sir Bedivere", size: 1 },
    4362: { name: "Ogre chieftain", size: 2 },
    4363: { name: "Ogre chieftain", size: 2 },
    4364: { name: "Og", size: 2 },
    4365: { name: "Grew", size: 2 },
    4366: { name: "Toban", size: 2 },
    4367: { name: "Gorad", size: 2 },
    4368: { name: "Ogre guard", size: 2 },
    4369: { name: "Ogre guard", size: 2 },
    4370: { name: "Ogre guard", size: 2 },
    4371: { name: "Ogre guard", size: 2 },
    4372: { name: "Ogre guard", size: 2 },
    4373: { name: "City guard", size: 2 },
    4374: { name: "Scared skavid", size: 2 },
    4375: { name: "Mad skavid", size: 2 },
    4376: { name: "Skavid", size: 2 },
    4377: { name: "Skavid", size: 2 },
    4378: { name: "Skavid", size: 2 },
    4379: { name: "Skavid", size: 2 },
    4380: { name: "Skavid", size: 2 },
    4381: { name: "Enclave guard", size: 2 },
    4382: { name: "Ogre shaman", size: 2 },
    4383: { name: "Ogre shaman", size: 2 },
    4384: { name: "Ogre shaman", size: 2 },
    4385: { name: "Blue dragon", size: 4 },
    4386: { name: "Ogre shaman", size: 2 },
    4387: { name: "Ogre shaman", size: 2 },
    4388: { name: "Ogre shaman", size: 2 },
    4389: { name: "Ogre shaman", size: 2 },
    4390: { name: "Ogre shaman", size: 2 },
    4391: { name: "Ogre shaman", size: 2 },
    4392: { name: "Ogre shaman", size: 2 },
    4393: { name: "Ogre shaman", size: 2 },
    4394: { name: "Ogre shaman", size: 2 },
    4395: { name: "Ogre shaman", size: 2 },
    4396: { name: "Ogre shaman", size: 2 },
    4397: { name: "Watchtower Wizard", size: 1 },
    4398: { name: "Wizard", size: 1 },
    4399: { name: "Wizard", size: 1 },
    4400: { name: "Wizard", size: 1 },
    4401: { name: "Ogre trader", size: 2 },
    4402: { name: "Ogre merchant", size: 2 },
    4403: { name: "Ogre trader", size: 2 },
    4404: { name: "Ogre trader", size: 2 },
    4405: { name: "Tower guard", size: 1 },
    4406: { name: "Colonel Radick", size: 1 },
    4407: { name: "Ava", size: 1 },
    4408: { name: "null", size: 1 },
    4409: { name: "Witch", size: 1 },
    4410: { name: "null", size: 1 },
    4411: { name: "Malcolm", size: 1 },
    4412: { name: "Malcolm", size: 1 },
    4413: { name: "Malcolm", size: 1 },
    4414: { name: "Malcolm", size: 1 },
    4415: { name: "null", size: 1 },
    4416: { name: "Tree", size: 1 },
    4417: { name: "Undead tree", size: 1 },
    4418: { name: "null", size: 1 },
    4419: { name: " Sneaky undead fowl", size: 1 },
    4420: { name: "Cow31337Killer", size: 1 },
    4421: { name: "Undead cow", size: 2 },
    4422: { name: "Alice", size: 1 },
    4423: { name: "Jossik", size: 1 },
    4424: { name: "Jossik", size: 2 },
    4425: { name: "Larrissa", size: 1 },
    4426: { name: "Larrissa", size: 1 },
    4427: { name: "Vampyre Juvinate", size: 1 },
    4428: { name: "Vampyre Juvinate", size: 1 },
    4429: { name: "Vampyre Juvinate", size: 1 },
    4430: { name: "Vampyre Juvinate", size: 1 },
    4431: { name: "Feral Vampyre", size: 1 },
    4432: { name: "Vampyre Juvinate", size: 1 },
    4433: { name: "Vampyre Juvinate", size: 1 },
    4434: { name: "Vampyre Juvinate", size: 1 },
    4435: { name: "Mist", size: 3 },
    4436: { name: "Vampyre Juvenile", size: 1 },
    4437: { name: "Vampyre Juvenile", size: 1 },
    4438: { name: "Vampyre Juvenile", size: 1 },
    4439: { name: "Vampyre Juvenile", size: 1 },
    4440: { name: "Ivan Strom", size: 1 },
    4441: { name: "Ivan Strom", size: 1 },
    4442: { name: "Vampyre Juvinate", size: 1 },
    4443: { name: "Vampyre Juvinate", size: 1 },
    4444: { name: "Elisabeta", size: 1 },
    4445: { name: "Aurel", size: 1 },
    4446: { name: "Sorin", size: 1 },
    4447: { name: "Luscion", size: 1 },
    4448: { name: "Sergiu", size: 1 },
    4449: { name: "Radu", size: 1 },
    4450: { name: "Grigore", size: 1 },
    4451: { name: "Ileana", size: 1 },
    4452: { name: "Valeria", size: 1 },
    4453: { name: "Emilia", size: 1 },
    4454: { name: "Florin", size: 1 },
    4455: { name: "Catalina", size: 1 },
    4456: { name: "Ivan", size: 1 },
    4457: { name: "Victor", size: 1 },
    4458: { name: "Helena", size: 1 },
    4459: { name: "Teodor", size: 1 },
    4460: { name: "Marius", size: 2 },
    4461: { name: "Gabriela", size: 1 },
    4462: { name: "Vladimir", size: 2 },
    4463: { name: "Calin", size: 1 },
    4464: { name: "Mihail", size: 1 },
    4465: { name: "Nicoleta", size: 1 },
    4466: { name: "Simona", size: 1 },
    4467: { name: "Vasile", size: 1 },
    4468: { name: "Razvan", size: 1 },
    4469: { name: "Luminata", size: 1 },
    4470: { name: "Cornelius", size: 1 },
    4471: { name: "Cornelius", size: 1 },
    4472: { name: "Benjamin", size: 1 },
    4473: { name: "Liam", size: 1 },
    4474: { name: "Miala", size: 1 },
    4475: { name: "Verak", size: 1 },
    4476: { name: "Fishing spot", size: 1 },
    4477: { name: "Fishing spot", size: 1 },
    4478: { name: "Sorin", size: 1 },
    4479: { name: "null", size: 1 },
    4480: { name: "Wiskit", size: 1 },
    4481: { name: "Vampyre Juvinate", size: 1 },
    4482: { name: "Vampyre Juvinate", size: 1 },
    4483: { name: "Gadderanks", size: 1 },
    4484: { name: "Gadderanks", size: 1 },
    4485: { name: "Gadderanks", size: 2 },
    4486: { name: "Vampyre Juvinate", size: 1 },
    4487: { name: "Vampyre Juvinate", size: 1 },
    4488: { name: "Olaf Hradson", size: 1 },
    4489: { name: "Volf Olafson", size: 1 },
    4490: { name: "Ingrid Hradson", size: 1 },
    4491: { name: "Skeleton fremennik", size: 1 },
    4492: { name: "Skeleton fremennik", size: 1 },
    4493: { name: "Skeleton fremennik", size: 1 },
    4494: { name: "Skeleton fremennik", size: 1 },
    4495: { name: "Skeleton fremennik", size: 1 },
    4496: { name: "Skeleton fremennik", size: 1 },
    4497: { name: "Skeleton fremennik", size: 1 },
    4498: { name: "Skeleton fremennik", size: 1 },
    4499: { name: "Skeleton fremennik", size: 1 },
    4500: { name: "Ulfric", size: 1 },
    4501: { name: "Brine rat", size: 1 },
    4502: { name: "Boulder", size: 2 },
    4503: { name: "Boulder", size: 2 },
    4504: { name: "Giant bat", size: 1 },
    4505: { name: "Ulfric", size: 1 },
    4506: { name: "Zanik", size: 1 },
    4507: { name: "null", size: 1 },
    4508: { name: "Zanik", size: 1 },
    4509: { name: "Zanik", size: 1 },
    4510: { name: "Zanik", size: 1 },
    4511: { name: "Zanik", size: 1 },
    4512: { name: "Dwarf", size: 1 },
    4513: { name: "H.A.M. Member", size: 1 },
    4514: { name: "H.A.M. Member", size: 1 },
    4515: { name: "null", size: 1 },
    4516: { name: "Guard", size: 1 },
    4517: { name: "Guard", size: 1 },
    4518: { name: "Guard", size: 1 },
    4519: { name: "Guard", size: 1 },
    4520: { name: "Guard", size: 1 },
    4521: { name: "Guard", size: 1 },
    4522: { name: "Guard", size: 1 },
    4523: { name: "Guard", size: 1 },
    4524: { name: "Guard", size: 1 },
    4525: { name: "Guard", size: 1 },
    4526: { name: "Guard", size: 1 },
    4527: { name: "Chadwell", size: 1 },
    4528: { name: "null", size: 1 },
    4529: { name: "null", size: 1 },
    4530: { name: "null", size: 1 },
    4531: { name: "null", size: 1 },
    4532: { name: "null", size: 1 },
    4533: { name: "Blessed spider", size: 1 },
    4534: { name: "Blessed giant rat", size: 2 },
    4535: { name: "Blessed giant rat", size: 2 },
    4536: { name: "null", size: 1 },
    4537: { name: "null", size: 1 },
    4538: { name: "null", size: 1 },
    4539: { name: "null", size: 1 },
    4540: { name: "null", size: 1 },
    4541: { name: "null", size: 1 },
    4542: { name: "null", size: 1 },
    4543: { name: "<col=00ffff>Boulder</col>", size: 2 },
    4544: { name: "null", size: 1 },
    4545: { name: "null", size: 1 },
    4546: { name: "null", size: 1 },
    4547: { name: "null", size: 1 },
    4548: { name: "null", size: 1 },
    4549: { name: "null", size: 1 },
    4550: { name: "null", size: 1 },
    4551: { name: "Niloof", size: 1 },
    4552: { name: "Klank", size: 1 },
    4553: { name: "Kamen", size: 1 },
    4554: { name: "null", size: 1 },
    4555: { name: "null", size: 1 },
    4556: { name: "null", size: 1 },
    4557: { name: "null", size: 1 },
    4558: { name: "null", size: 1 },
    4559: { name: "null", size: 1 },
    4560: { name: "null", size: 1 },
    4561: { name: "Spider", size: 1 },
    4562: { name: "Giant bat", size: 2 },
    4563: { name: "Monk", size: 1 },
    4564: { name: "<col=00ffff>Dead Monk</col>", size: 2 },
    4565: { name: "High Priest", size: 1 },
    4566: { name: "Monk", size: 1 },
    4567: { name: "Monk", size: 1 },
    4568: { name: "Assassin", size: 1 },
    4569: { name: "Red Axe Henchman", size: 1 },
    4570: { name: "Red Axe Henchman", size: 1 },
    4571: { name: "Ogre shaman", size: 2 },
    4572: { name: "The Beast", size: 1 },
    4573: { name: "Bellemorde", size: 1 },
    4574: { name: "Pox", size: 1 },
    4575: { name: "Pox", size: 1 },
    4576: { name: "Bones", size: 1 },
    4577: { name: "null", size: 2 },
    4578: { name: "Councillor Halgrive", size: 1 },
    4579: { name: "Spice seller", size: 1 },
    4580: { name: "Fur trader", size: 1 },
    4581: { name: "Gem merchant", size: 1 },
    4582: { name: "Silver merchant", size: 1 },
    4583: { name: "Silk merchant", size: 1 },
    4584: { name: "Zenesha", size: 1 },
    4585: { name: "Ali Morrisane", size: 1 },
    4586: { name: "Grimesquit", size: 1 },
    4587: { name: "Phingspet", size: 1 },
    4588: { name: "Hooknosed Jack", size: 1 },
    4589: { name: "Jimmy Dazzler", size: 1 },
    4590: { name: "The Face", size: 1 },
    4591: { name: "Felkrash", size: 1 },
    4592: { name: "Smokin' Joe", size: 1 },
    4593: { name: "Rat", size: 1 },
    4594: { name: "Rat", size: 1 },
    4595: { name: "King rat", size: 1 },
    4596: { name: "Turbogroomer", size: 1 },
    4597: { name: "Pusskins", size: 1 },
    4598: { name: "Loki", size: 1 },
    4599: { name: "Captain Tom", size: 1 },
    4600: { name: "Treacle", size: 1 },
    4601: { name: "Mittens", size: 1 },
    4602: { name: "Claude", size: 1 },
    4603: { name: "Topsy", size: 1 },
    4604: { name: "Rauborn", size: 1 },
    4605: { name: "Vaeringk", size: 1 },
    4606: { name: "Oxi", size: 1 },
    4607: { name: "Fior", size: 1 },
    4608: { name: "Sagira", size: 1 },
    4609: { name: "Anleif", size: 1 },
    4610: { name: "Rat", size: 1 },
    4611: { name: "Rat", size: 1 },
    4612: { name: "Rat", size: 1 },
    4613: { name: "Rat", size: 1 },
    4614: { name: "Rat", size: 1 },
    4615: { name: "Rat", size: 1 },
    4616: { name: "Rat", size: 1 },
    4617: { name: "Rat", size: 1 },
    4618: { name: "Rat", size: 1 },
    4619: { name: "Hetty", size: 1 },
    4620: { name: "Elemental balance", size: 1 },
    4621: { name: "Elemental balance", size: 1 },
    4622: { name: "Elemental balance", size: 1 },
    4623: { name: "Elemental balance", size: 1 },
    4624: { name: "Elemental balance", size: 1 },
    4625: { name: "Trufitus", size: 1 },
    4626: { name: "Cook", size: 1 },
    4627: { name: "Millie Miller", size: 1 },
    4628: { name: "Gillie Groats", size: 1 },
    4629: { name: "Ana", size: 1 },
    4630: { name: "Ana", size: 1 },
    4631: { name: "Female slave", size: 1 },
    4632: { name: "Male slave", size: 1 },
    4633: { name: "Escaping slave", size: 1 },
    4634: { name: "Rowdy slave", size: 1 },
    4635: { name: "Mercenary Captain", size: 1 },
    4636: { name: "Captain Siad", size: 1 },
    4637: { name: "Al Shabim", size: 1 },
    4638: { name: "Bedabin Nomad", size: 1 },
    4639: { name: "Bedabin Nomad Guard", size: 1 },
    4640: { name: "Irena", size: 1 },
    4641: { name: "Irena", size: 1 },
    4642: { name: "Shantay", size: 1 },
    4643: { name: "Shantay Guard", size: 1 },
    4644: { name: "Oaknock the Engineer", size: 1 },
    4645: { name: "Glouphrie the Untrusted", size: 1 },
    4646: { name: "King Healthorg", size: 1 },
    4647: { name: "Hazelmere", size: 1 },
    4648: { name: "Shantay Guard", size: 1 },
    4649: { name: "Desert Wolf", size: 2 },
    4650: { name: "Desert Wolf", size: 2 },
    4651: { name: "Desert Wolf", size: 2 },
    4652: { name: "Ugthanki", size: 2 },
    4653: { name: "Mine cart driver", size: 1 },
    4654: { name: "Rowdy Guard", size: 1 },
    4655: { name: "Bedabin Nomad Fighter", size: 1 },
    4656: { name: "Mercenary", size: 1 },
    4657: { name: "Mercenary", size: 1 },
    4658: { name: "Mercenary", size: 1 },
    4659: { name: "Mercenary", size: 1 },
    4660: { name: "Guard", size: 1 },
    4661: { name: "Guard", size: 1 },
    4662: { name: "Guard", size: 1 },
    4663: { name: "Guard", size: 1 },
    4664: { name: "Guard", size: 1 },
    4665: { name: "Guard", size: 1 },
    4666: { name: "Guard", size: 1 },
    4667: { name: "Guard", size: 1 },
    4668: { name: "Guard", size: 1 },
    4669: { name: "Guard", size: 1 },
    4670: { name: "Male slave", size: 1 },
    4671: { name: "Male slave", size: 1 },
    4672: { name: "Female slave", size: 1 },
    4673: { name: "Female slave", size: 1 },
    4674: { name: "Cart Camel", size: 4 },
    4675: { name: "Mine Cart", size: 1 },
    4676: { name: "Mine Cart", size: 1 },
    4677: { name: "Ana", size: 1 },
    4678: { name: "Mercenary", size: 1 },
    4679: { name: "Sir Spishyus", size: 1 },
    4680: { name: "Lady Table", size: 1 },
    4681: { name: "Sir Kuam Ferentse", size: 1 },
    4682: { name: "Sir Leye", size: 1 },
    4683: { name: "Sir Tinley", size: 1 },
    4684: { name: "Sir Ren Itchood", size: 1 },
    4685: { name: "Miss Cheevers", size: 1 },
    4686: { name: "Ms. Hynn Terprett", size: 2 },
    4687: { name: "Sir Tiffy Cashien", size: 1 },
    4688: { name: "Angry unicorn", size: 2 },
    4689: { name: "Angry giant rat", size: 1 },
    4690: { name: "Angry giant rat", size: 1 },
    4691: { name: "Angry goblin", size: 1 },
    4692: { name: "Angry bear", size: 2 },
    4693: { name: "Fear reaper", size: 2 },
    4694: { name: "Confusion beast", size: 2 },
    4695: { name: "Hopeless creature", size: 2 },
    4696: { name: "Hopeless beast", size: 2 },
    4697: { name: "Hopeless beast", size: 2 },
    4698: { name: "Timfraku", size: 1 },
    4699: { name: "Tiadeche", size: 1 },
    4700: { name: "Tiadeche", size: 1 },
    4701: { name: "Tinsay", size: 1 },
    4702: { name: "Tinsay", size: 1 },
    4703: { name: "Tamayu", size: 1 },
    4704: { name: "Tamayu", size: 1 },
    4705: { name: "Tamayu", size: 1 },
    4706: { name: "Tamayu", size: 1 },
    4707: { name: "Lubufu", size: 1 },
    4708: { name: "The Shaikahan", size: 3 },
    4709: { name: "The Shaikahan", size: 3 },
    4710: { name: "Fishing spot", size: 1 },
    4711: { name: "Fishing spot", size: 1 },
    4712: { name: "Fishing spot", size: 1 },
    4713: { name: "Fishing spot", size: 1 },
    4714: { name: "Fishing spot", size: 1 },
    4715: { name: "Auguste", size: 1 },
    4716: { name: "Auguste", size: 1 },
    4717: { name: "Auguste", size: 1 },
    4718: { name: "Auguste", size: 1 },
    4719: { name: "Assistant Serf", size: 1 },
    4720: { name: "Assistant Brock", size: 1 },
    4721: { name: "Assistant Marrow", size: 1 },
    4722: { name: "Assistant Le Smith", size: 1 },
    4723: { name: "Assistant Stan", size: 1 },
    4724: { name: "Bob", size: 1 },
    4725: { name: "Curly", size: 1 },
    4726: { name: "Moe", size: 1 },
    4727: { name: "Larry", size: 1 },
    4728: { name: "null", size: 1 },
    4729: { name: "null", size: 1 },
    4730: { name: "null", size: 1 },
    4731: { name: "null", size: 1 },
    4732: { name: "null", size: 1 },
    4733: { name: "Thurgo", size: 1 },
    4734: { name: "Gull", size: 1 },
    4735: { name: "Gull", size: 1 },
    4736: { name: "Sir Vyvin", size: 1 },
    4737: { name: "Squire", size: 1 },
    4738: { name: "Genie", size: 1 },
    4739: { name: "Nirrie", size: 1 },
    4740: { name: "Tirrie", size: 1 },
    4741: { name: "Hallak", size: 1 },
    4742: { name: "Black golem", size: 1 },
    4743: { name: "White golem", size: 1 },
    4744: { name: "Grey golem", size: 1 },
    4745: { name: "Ghaslor the Elder", size: 1 },
    4746: { name: "Ali the Carter", size: 1 },
    4747: { name: "Usi", size: 1 },
    4748: { name: "Nkuku", size: 1 },
    4749: { name: "Garai", size: 1 },
    4750: { name: "Habibah", size: 1 },
    4751: { name: "Meskhenet", size: 1 },
    4752: { name: "Zahra", size: 1 },
    4753: { name: "Zahur", size: 1 },
    4754: { name: "Seddu", size: 1 },
    4755: { name: "Kazemde", size: 1 },
    4756: { name: "Awusah the Mayor", size: 1 },
    4757: { name: "Tarik", size: 1 },
    4758: { name: "Poltenip", size: 1 },
    4759: { name: "Radat", size: 1 },
    4760: { name: "Shiratti the Custodian", size: 1 },
    4761: { name: "Rokuh", size: 1 },
    4762: { name: "Nardah Banker", size: 1 },
    4763: { name: "Target", size: 1 },
    4764: { name: "Target", size: 1 },
    4765: { name: "null", size: 1 },
    4766: { name: "Tegid", size: 2 },
    4767: { name: "Thistle", size: 1 },
    4768: { name: "Parrots", size: 1 },
    4769: { name: "Parroty Pete", size: 1 },
    4770: { name: "Fake Man", size: 1 },
    4771: { name: "Sir Amik Varze", size: 1 },
    4772: { name: "Fortress Guard", size: 1 },
    4773: { name: "Fortress Guard", size: 1 },
    4774: { name: "Fortress Guard", size: 1 },
    4775: { name: "Fortress Guard", size: 1 },
    4776: { name: "Fortress Guard", size: 1 },
    4777: { name: "Black Knight Captain", size: 1 },
    4778: { name: "Witch", size: 1 },
    4779: { name: "Greldo", size: 1 },
    4780: { name: "Black Cat", size: 1 },
    4781: { name: "Col. O'Niall", size: 1 },
    4782: { name: "Col. O'Niall", size: 1 },
    4783: { name: "Mayor Hobb", size: 1 },
    4784: { name: "Mayor Hobb", size: 1 },
    4785: { name: "null", size: 1 },
    4786: { name: "null", size: 1 },
    4787: { name: "Brother Maledict", size: 1 },
    4788: { name: "Brother Maledict", size: 1 },
    4789: { name: "Ezekial Lovecraft", size: 1 },
    4790: { name: "Witchaven villager", size: 1 },
    4791: { name: "Witchaven villager", size: 1 },
    4792: { name: "Witchaven villager", size: 1 },
    4793: { name: "Witchaven villager", size: 1 },
    4794: { name: "Witchaven villager", size: 1 },
    4795: { name: "Witchaven villager", size: 1 },
    4796: { name: "Mother Mallum", size: 4 },
    4797: { name: "Slug Prince", size: 2 },
    4798: { name: "Slug Prince", size: 2 },
    4799: { name: "Giant lobster", size: 2 },
    4800: { name: "Giant Lobster", size: 2 },
    4801: { name: "Sea slug", size: 1 },
    4802: { name: "Jeb", size: 1 },
    4803: { name: "Jeb", size: 1 },
    4804: { name: "Sir Tinley", size: 1 },
    4805: { name: "Hobgoblin", size: 1 },
    4806: { name: "Evil Dave", size: 1 },
    4807: { name: "Evil Dave", size: 1 },
    4808: { name: "Doris", size: 1 },
    4809: { name: "Hell-Rat", size: 1 },
    4810: { name: "An old Dwarf", size: 1 },
    4811: { name: "Rohak", size: 1 },
    4812: { name: "Rohak", size: 1 },
    4813: { name: "Icefiend", size: 1 },
    4814: { name: "Pirate Pete", size: 1 },
    4815: { name: "null", size: 1 },
    4816: { name: "Pirate Pete", size: 1 },
    4817: { name: "Mogre Guard", size: 2 },
    4818: { name: "Nung", size: 2 },
    4819: { name: "Crab", size: 2 },
    4820: { name: "Mudskipper", size: 1 },
    4821: { name: "Mudskipper", size: 2 },
    4822: { name: "Crab", size: 1 },
    4823: { name: "Fish", size: 1 },
    4824: { name: "Fish", size: 1 },
    4825: { name: "Fish", size: 1 },
    4826: { name: "Fish", size: 1 },
    4827: { name: "Fish", size: 1 },
    4828: { name: "Fish", size: 1 },
    4829: { name: "Fish", size: 1 },
    4830: { name: "Fish", size: 1 },
    4831: { name: "Fish", size: 1 },
    4832: { name: "Fish", size: 1 },
    4833: { name: "Fish", size: 1 },
    4834: { name: "Fish", size: 1 },
    4835: { name: "Fish", size: 1 },
    4836: { name: "Fish", size: 1 },
    4837: { name: "Fish", size: 1 },
    4838: { name: "Fish", size: 1 },
    4839: { name: "Fish", size: 1 },
    4840: { name: "Fish", size: 1 },
    4841: { name: "Fish", size: 2 },
    4842: { name: "Fish", size: 2 },
    4843: { name: "Fish", size: 3 },
    4844: { name: "Fish", size: 2 },
    4845: { name: "Fish", size: 2 },
    4846: { name: "Fish", size: 3 },
    4847: { name: "null", size: 1 },
    4848: { name: "null", size: 1 },
    4849: { name: "Culinaromancer", size: 1 },
    4850: { name: "Goblin Cook", size: 1 },
    4851: { name: "Goblin Cook", size: 1 },
    4852: { name: "Goblin Cook", size: 1 },
    4853: { name: "Skrach Uglogwee", size: 2 },
    4854: { name: "Skrach Uglogwee", size: 2 },
    4855: { name: "Rantz", size: 2 },
    4856: { name: "Rantz", size: 2 },
    4857: { name: "Rantz", size: 2 },
    4858: { name: "Ogre boat", size: 5 },
    4859: { name: "Ogre boat", size: 5 },
    4860: { name: "Balloon Toad", size: 1 },
    4861: { name: "Balloon Toad", size: 1 },
    4862: { name: "Balloon Toad", size: 1 },
    4863: { name: "Jubbly bird", size: 3 },
    4864: { name: "Jubbly bird", size: 3 },
    4865: { name: "Elemental balance", size: 1 },
    4866: { name: "Elemental balance", size: 1 },
    4867: { name: "Elemental balance", size: 1 },
    4868: { name: "Elemental balance", size: 1 },
    4869: { name: "Elemental balance", size: 1 },
    4870: { name: "Elemental balance", size: 1 },
    4871: { name: "Elemental balance", size: 1 },
    4872: { name: "Culinaromancer", size: 1 },
    4873: { name: "Culinaromancer", size: 1 },
    4874: { name: "Culinaromancer", size: 1 },
    4875: { name: "Culinaromancer", size: 1 },
    4876: { name: "Culinaromancer", size: 1 },
    4877: { name: "Culinaromancer", size: 1 },
    4878: { name: "Culinaromancer", size: 1 },
    4879: { name: "Culinaromancer", size: 1 },
    4880: { name: "Agrith-Na-Na", size: 3 },
    4881: { name: "Flambeed", size: 5 },
    4882: { name: "Karamel", size: 1 },
    4883: { name: "Dessourt", size: 1 },
    4884: { name: "Gelatinnoth Mother", size: 3 },
    4885: { name: "Gelatinnoth Mother", size: 3 },
    4886: { name: "Gelatinnoth Mother", size: 3 },
    4887: { name: "Gelatinnoth Mother", size: 3 },
    4888: { name: "Gelatinnoth Mother", size: 3 },
    4889: { name: "Gelatinnoth Mother", size: 3 },
    4890: { name: "Dondakan the Dwarf", size: 1 },
    4891: { name: "Dondakan the Dwarf", size: 1 },
    4892: { name: "Dondakan the Dwarf", size: 1 },
    4893: { name: "Dwarven Engineer", size: 1 },
    4894: { name: "Rolad", size: 1 },
    4895: { name: "Khorvak, a dwarven engineer", size: 1 },
    4896: { name: "Dwarven Ferryman", size: 1 },
    4897: { name: "Dwarven Ferryman", size: 1 },
    4898: { name: "Dwarven Boatman", size: 1 },
    4899: { name: "Miodvetnir", size: 1 },
    4900: { name: "Dernu", size: 1 },
    4901: { name: "Derni", size: 1 },
    4902: { name: "Goblin", size: 1 },
    4903: { name: "Goblin", size: 1 },
    4904: { name: "Goblin", size: 1 },
    4905: { name: "Goblin", size: 1 },
    4906: { name: "Goblin", size: 1 },
    4907: { name: "Gnome soldier", size: 1 },
    4908: { name: "Gnome soldier", size: 1 },
    4909: { name: "Gnome soldier", size: 1 },
    4910: { name: "Gnome soldier", size: 1 },
    4911: { name: "Gnome soldier", size: 1 },
    4912: { name: "Healthorg and tortoise", size: 3 },
    4913: { name: "null", size: 1 },
    4914: { name: "Brimstail", size: 1 },
    4915: { name: "Garv", size: 1 },
    4916: { name: "Grubor", size: 1 },
    4917: { name: "Trobert", size: 1 },
    4918: { name: "Seth", size: 1 },
    4919: { name: "Grip", size: 1 },
    4920: { name: "Alfonse the waiter", size: 1 },
    4921: { name: "Charlie the cook", size: 1 },
    4922: { name: "Ice Queen", size: 1 },
    4923: { name: "Achietties", size: 1 },
    4924: { name: "Helemos", size: 1 },
    4925: { name: "Velrak the explorer", size: 1 },
    4926: { name: "Pirate Guard", size: 1 },
    4927: { name: "Entrana firebird", size: 2 },
    4928: { name: "Fishing spot", size: 1 },
    4929: { name: "Lord Daquarius", size: 1 },
    4930: { name: "Solus Dellagar", size: 1 },
    4931: { name: "Savant", size: 1 },
    4932: { name: "Lord Daquarius", size: 1 },
    4933: { name: "Solus Dellagar", size: 1 },
    4934: { name: "Black Knight", size: 1 },
    4935: { name: "Lord Daquarius", size: 1 },
    4936: { name: "Mage of Zamorak", size: 1 },
    4937: { name: "Mage of Zamorak", size: 1 },
    4938: { name: "Mage of Zamorak", size: 1 },
    4939: { name: "null", size: 1 },
    4940: { name: "null", size: 1 },
    4941: { name: "null", size: 1 },
    4942: { name: "null", size: 1 },
    4943: { name: "null", size: 1 },
    4944: { name: "null", size: 1 },
    4945: { name: "null", size: 1 },
    4946: { name: "null", size: 1 },
    4947: { name: "null", size: 1 },
    4948: { name: "null", size: 1 },
    4949: { name: "null", size: 1 },
    4950: { name: "null", size: 1 },
    4951: { name: "null", size: 1 },
    4952: { name: "null", size: 1 },
    4953: { name: "null", size: 1 },
    4954: { name: "null", size: 1 },
    4955: { name: "null", size: 1 },
    4956: { name: "null", size: 1 },
    4957: { name: "null", size: 1 },
    4958: { name: "Woman", size: 1 },
    4959: { name: "Black Knight", size: 1 },
    4960: { name: "Black Knight", size: 1 },
    4961: { name: "Ranger", size: 1 },
    4962: { name: "Solus Dellagar", size: 1 },
    4963: { name: "King Bolren", size: 1 },
    4964: { name: "Commander Montai", size: 1 },
    4965: { name: "Bolkoy", size: 1 },
    4966: { name: "Remsai", size: 1 },
    4967: { name: "Elkoy", size: 1 },
    4968: { name: "Elkoy", size: 1 },
    4969: { name: "Khazard trooper", size: 1 },
    4970: { name: "Khazard trooper", size: 1 },
    4971: { name: "null", size: 1 },
    4972: { name: "Khazard commander", size: 1 },
    4973: { name: "Gnome troop", size: 1 },
    4974: { name: "Gnome troop", size: 1 },
    4975: { name: "Tracker gnome 1", size: 1 },
    4976: { name: "Tracker gnome 2", size: 1 },
    4977: { name: "Tracker gnome 3", size: 1 },
    4978: { name: "Local Gnome", size: 1 },
    4979: { name: "Local Gnome", size: 1 },
    4980: { name: "Kalron", size: 1 },
    4981: { name: "Spirit tree", size: 2 },
    4982: { name: "Spirit tree", size: 4 },
    4983: { name: "null", size: 1 },
    4984: { name: "Dimintheis", size: 1 },
    4985: { name: "Boot", size: 1 },
    4986: { name: "null", size: 1 },
    4987: { name: "Chronozon", size: 3 },
    4988: { name: "null", size: 1 },
    4989: { name: "Elemental balance", size: 1 },
    4990: { name: "Elemental balance", size: 1 },
    4991: { name: "Elemental balance", size: 1 },
    4992: { name: "Elemental balance", size: 1 },
    4993: { name: "Elemental balance", size: 1 },
    4994: { name: "Elemental balance", size: 1 },
    4995: { name: "Elemental balance", size: 1 },
    4996: { name: "Elemental balance", size: 1 },
    4997: { name: "Elemental balance", size: 1 },
    4998: { name: "Elemental balance", size: 1 },
    4999: { name: "Elemental balance", size: 1 },
    5000: { name: "Elemental balance", size: 1 },
    5001: { name: "Elemental balance", size: 1 },
    5002: { name: "Elemental balance", size: 1 },
    5003: { name: "Elemental balance", size: 1 },
    5004: { name: "Elemental balance", size: 1 },
    5005: { name: "null", size: 1 },
    5006: { name: "Wizard Grayzag", size: 1 },
    5007: { name: "Imp", size: 1 },
    5008: { name: "Lil' Destructor", size: 1 },
    5009: { name: "Elemental balance", size: 1 },
    5010: { name: "Elemental balance", size: 1 },
    5011: { name: "Elemental balance", size: 1 },
    5012: { name: "Elemental balance", size: 1 },
    5013: { name: "Elemental balance", size: 1 },
    5014: { name: "Elemental balance", size: 1 },
    5015: { name: "Elemental balance", size: 1 },
    5016: { name: "Elemental balance", size: 1 },
    5017: { name: "Elemental balance", size: 1 },
    5018: { name: "Elemental balance", size: 1 },
    5019: { name: "Elemental balance", size: 1 },
    5020: { name: "Elemental balance", size: 1 },
    5021: { name: "Elemental balance", size: 1 },
    5022: { name: "Combat stone", size: 1 },
    5023: { name: "Combat stone", size: 1 },
    5024: { name: "Combat stone", size: 1 },
    5025: { name: "Combat stone", size: 1 },
    5026: { name: "Combat stone", size: 1 },
    5027: { name: "Combat stone", size: 1 },
    5028: { name: "Combat stone", size: 1 },
    5029: { name: "Combat stone", size: 1 },
    5030: { name: "Combat stone", size: 1 },
    5031: { name: "Combat stone", size: 1 },
    5032: { name: "Combat stone", size: 1 },
    5033: { name: "Combat stone", size: 1 },
    5034: { name: "null", size: 1 },
    5035: { name: "Juliet", size: 1 },
    5036: { name: "Apothecary", size: 1 },
    5037: { name: "Romeo", size: 1 },
    5038: { name: "Father Lawrence", size: 1 },
    5039: { name: "Draul Leptoc", size: 1 },
    5040: { name: "Phillipa", size: 1 },
    5041: { name: "Martina Scorsby", size: 1 },
    5042: { name: "Jeremy Clerksin", size: 1 },
    5043: { name: "Suit of armour", size: 1 },
    5044: { name: "Sanfew", size: 1 },
    5045: { name: "Kaqemeex", size: 1 },
    5046: { name: "Cyreg Paddlehorn", size: 1 },
    5047: { name: "null", size: 1 },
    5048: { name: "Veliaf Hurtz", size: 1 },
    5049: { name: "null", size: 1 },
    5050: { name: "null", size: 1 },
    5051: { name: "Radigad Ponfit", size: 1 },
    5052: { name: "Polmafi Ferdygris", size: 1 },
    5053: { name: "Ivan Strom", size: 1 },
    5054: { name: "Skeleton Hellhound", size: 2 },
    5055: { name: "Stranger", size: 1 },
    5056: { name: "Vanstrom Klause", size: 1 },
    5057: { name: "Mist", size: 3 },
    5058: { name: "null", size: 1 },
    5059: { name: "null", size: 1 },
    5060: { name: "null", size: 1 },
    5061: { name: "Sea slug", size: 1 },
    5062: { name: "Kennith", size: 1 },
    5063: { name: "Kennith", size: 1 },
    5064: { name: "null", size: 1 },
    5065: { name: "null", size: 1 },
    5066: { name: "Bailey", size: 1 },
    5067: { name: "Caroline", size: 1 },
    5068: { name: "Holgart", size: 1 },
    5069: { name: "Holgart", size: 1 },
    5070: { name: "Holgart", size: 1 },
    5071: { name: "null", size: 1 },
    5072: { name: "Holgart", size: 1 },
    5073: { name: "Holgart", size: 1 },
    5074: { name: "Kent", size: 1 },
    5075: { name: "Fisherman", size: 1 },
    5076: { name: "Fisherman", size: 1 },
    5077: { name: "Fisherman", size: 1 },
    5078: { name: "Fisherman", size: 1 },
    5079: { name: "Delrith", size: 2 },
    5080: { name: "Weakened Delrith", size: 2 },
    5081: { name: "Wizard Traiborn", size: 1 },
    5082: { name: "null", size: 1 },
    5083: { name: "Sir Prysin", size: 1 },
    5084: { name: "Sir Prysin", size: 1 },
    5085: { name: "Captain Rovin", size: 1 },
    5086: { name: "Dark wizard", size: 1 },
    5087: { name: "Dark wizard", size: 1 },
    5088: { name: "Dark wizard", size: 1 },
    5089: { name: "Dark wizard", size: 1 },
    5090: { name: "Denath", size: 1 },
    5091: { name: "Denath", size: 1 },
    5092: { name: "Wally", size: 1 },
    5093: { name: "Combat stone", size: 1 },
    5094: { name: "Combat stone", size: 1 },
    5095: { name: "Combat stone", size: 1 },
    5096: { name: "Combat stone", size: 1 },
    5097: { name: "Combat stone", size: 1 },
    5098: { name: "Combat stone", size: 1 },
    5099: { name: "Combat stone", size: 1 },
    5100: { name: "Combat stone", size: 1 },
    5101: { name: "Combat stone", size: 1 },
    5102: { name: "Combat stone", size: 1 },
    5103: { name: "Combat stone", size: 1 },
    5104: { name: "Combat stone", size: 1 },
    5105: { name: "Combat stone", size: 1 },
    5106: { name: "Combat stone", size: 1 },
    5107: { name: "Combat stone", size: 1 },
    5108: { name: "Combat stone", size: 1 },
    5109: { name: "Combat stone", size: 1 },
    5110: { name: "Combat stone", size: 1 },
    5111: { name: "Combat stone", size: 1 },
    5112: { name: "Combat stone", size: 1 },
    5113: { name: "Combat stone", size: 1 },
    5114: { name: "Combat stone", size: 1 },
    5115: { name: "Combat stone", size: 1 },
    5116: { name: "Combat stone", size: 1 },
    5117: { name: "Combat stone", size: 1 },
    5118: { name: "Sylas", size: 1 },
    5119: { name: "Grimgnash", size: 3 },
    5120: { name: "Rupert the Beard", size: 1 },
    5121: { name: "Rupert the Beard", size: 1 },
    5122: { name: "Drain pipe", size: 1 },
    5123: { name: "Rupert the Beard", size: 1 },
    5124: { name: "Rupert the Beard", size: 1 },
    5125: { name: "Miazrqa", size: 1 },
    5126: { name: "Experiment No.2", size: 2 },
    5127: { name: "Mouse", size: 4 },
    5128: { name: "Mouse", size: 4 },
    5129: { name: "Glod", size: 4 },
    5130: { name: "Gnome", size: 1 },
    5131: { name: "Winkin", size: 1 },
    5132: { name: "Gnome", size: 1 },
    5133: { name: "Cage", size: 1 },
    5134: { name: "Broken clay golem", size: 1 },
    5135: { name: "Damaged clay golem", size: 1 },
    5136: { name: "Clay golem", size: 1 },
    5137: { name: "Desert Phoenix", size: 2 },
    5138: { name: "Elissa", size: 1 },
    5139: { name: "Sigmund", size: 1 },
    5140: { name: "Zanik", size: 1 },
    5141: { name: "Guard", size: 1 },
    5142: { name: "Sigmund", size: 1 },
    5143: { name: "Sigmund", size: 1 },
    5144: { name: "Sigmund", size: 1 },
    5145: { name: "Sigmund", size: 1 },
    5146: { name: "Sigmund", size: 1 },
    5147: { name: "Zanik", size: 1 },
    5148: { name: "Zanik", size: 1 },
    5149: { name: "General Bentnoze", size: 1 },
    5150: { name: "General Wartface", size: 1 },
    5151: { name: "Grubfoot", size: 1 },
    5152: { name: "Goblin", size: 1 },
    5153: { name: "Goblin", size: 1 },
    5154: { name: "Goblin", size: 1 },
    5155: { name: "Zanik", size: 1 },
    5156: { name: "Ur-tag", size: 1 },
    5157: { name: "H.A.M. Archer", size: 1 },
    5158: { name: "H.A.M. Mage", size: 1 },
    5159: { name: "Zanik", size: 2 },
    5160: { name: "Sigmund and Zanik", size: 2 },
    5161: { name: "Sergeant Mossfists", size: 1 },
    5162: { name: "Sergeant Slimetoes", size: 1 },
    5163: { name: "Cave goblin", size: 1 },
    5164: { name: "Cave goblin", size: 1 },
    5165: { name: "Cave goblin", size: 1 },
    5166: { name: "Cave goblin", size: 1 },
    5167: { name: "Cave goblin", size: 1 },
    5168: { name: "Cave goblin", size: 1 },
    5169: { name: "Ticket goblin", size: 1 },
    5170: { name: "Dwarf", size: 1 },
    5171: { name: "Dwarf", size: 1 },
    5172: { name: "Dwarf", size: 1 },
    5173: { name: "Dwarf", size: 1 },
    5174: { name: "Dwarf", size: 1 },
    5175: { name: "Dwarf", size: 1 },
    5176: { name: "Ticket dwarf", size: 1 },
    5177: { name: "Ambassador Alvijar", size: 1 },
    5178: { name: "Builder", size: 1 },
    5179: { name: "Builder", size: 1 },
    5180: { name: "Builder", size: 1 },
    5181: { name: "Builder", size: 1 },
    5182: { name: "Tegdak", size: 1 },
    5183: { name: "null", size: 1 },
    5184: { name: "Zanik", size: 1 },
    5185: { name: "Guard", size: 1 },
    5186: { name: "Guard", size: 1 },
    5187: { name: "Guard", size: 1 },
    5188: { name: "Guard", size: 1 },
    5189: { name: "Guard", size: 1 },
    5190: { name: "Lollk", size: 1 },
    5191: { name: "Captain Lawgof", size: 1 },
    5192: { name: "Goblin", size: 1 },
    5193: { name: "Goblin", size: 1 },
    5194: { name: "Baby green dragon", size: 2 },
    5195: { name: "Goblin", size: 1 },
    5196: { name: "Goblin", size: 1 },
    5197: { name: "Goblin", size: 1 },
    5198: { name: "Goblin", size: 1 },
    5199: { name: "Goblin", size: 1 },
    5200: { name: "Goblin", size: 1 },
    5201: { name: "Goblin", size: 1 },
    5202: { name: "Goblin", size: 1 },
    5203: { name: "Goblin", size: 1 },
    5204: { name: "Goblin", size: 1 },
    5205: { name: "Goblin", size: 1 },
    5206: { name: "Goblin", size: 1 },
    5207: { name: "Goblin", size: 1 },
    5208: { name: "Goblin", size: 1 },
    5209: { name: "Charlie the Tramp", size: 1 },
    5210: { name: "Katrine", size: 1 },
    5211: { name: "null", size: 1 },
    5212: { name: "Straven", size: 1 },
    5213: { name: "null", size: 1 },
    5214: { name: "Curator Haig Halen", size: 1 },
    5215: { name: "King Roald", size: 1 },
    5216: { name: "Benny", size: 1 },
    5217: { name: "Thief", size: 1 },
    5218: { name: "Thief", size: 1 },
    5219: { name: "Thief", size: 1 },
    5220: { name: "Thief", size: 1 },
    5221: { name: "Jig cart", size: 1 },
    5222: { name: "Jig cart", size: 1 },
    5223: { name: "Jig cart", size: 1 },
    5224: { name: "Jig cart", size: 1 },
    5225: { name: "Jig cart", size: 1 },
    5226: { name: "Jig cart", size: 1 },
    5227: { name: "null", size: 1 },
    5228: { name: "Kharid Scorpion", size: 1 },
    5229: { name: "Kharid Scorpion", size: 1 },
    5230: { name: "Kharid Scorpion", size: 1 },
    5231: { name: "Seer", size: 1 },
    5232: { name: "Thormac", size: 1 },
    5233: { name: "Fishing spot", size: 1 },
    5234: { name: "Fishing spot", size: 1 },
    5235: { name: "Monkey minder", size: 1 },
    5236: { name: "null", size: 1 },
    5237: { name: "Skeleton", size: 1 },
    5238: { name: "Spider", size: 1 },
    5239: { name: "Spider", size: 1 },
    5240: { name: "Bird", size: 1 },
    5241: { name: "Bird", size: 1 },
    5242: { name: "Scorpion", size: 1 },
    5243: { name: "Jungle spider", size: 1 },
    5244: { name: "Snake", size: 1 },
    5245: { name: "Dugopul", size: 1 },
    5246: { name: "Salenab", size: 1 },
    5247: { name: "Trefaji", size: 2 },
    5248: { name: "Aberab", size: 2 },
    5249: { name: "Solihib", size: 1 },
    5250: { name: "Daga", size: 1 },
    5251: { name: "Tutab", size: 1 },
    5252: { name: "Ifaba", size: 1 },
    5253: { name: "Hamab", size: 1 },
    5254: { name: "Hafuba", size: 2 },
    5255: { name: "Denadu", size: 1 },
    5256: { name: "Lofu", size: 1 },
    5257: { name: "Kruk", size: 1 },
    5258: { name: "Duke", size: 1 },
    5259: { name: "Oipuis", size: 1 },
    5260: { name: "Uyoro", size: 1 },
    5261: { name: "Ouhai", size: 1 },
    5262: { name: "Uodai", size: 1 },
    5263: { name: "Padulah", size: 1 },
    5264: { name: "Awowogei", size: 1 },
    5265: { name: "Uwogo", size: 1 },
    5266: { name: "Muruwoi", size: 1 },
    5267: { name: "Sleeping Monkey", size: 1 },
    5268: { name: "Monkey Child", size: 1 },
    5269: { name: "The Monkey's Uncle", size: 1 },
    5270: { name: "The Monkey's Aunt", size: 1 },
    5271: { name: "Monkey Guard", size: 1 },
    5272: { name: "Monkey Archer", size: 1 },
    5273: { name: "Monkey Archer", size: 1 },
    5274: { name: "Monkey Archer", size: 1 },
    5275: { name: "Monkey Guard", size: 2 },
    5276: { name: "Monkey Guard", size: 2 },
    5277: { name: "Elder Guard", size: 2 },
    5278: { name: "Elder Guard", size: 2 },
    5279: { name: "Monkey", size: 1 },
    5280: { name: "Monkey", size: 1 },
    5281: { name: "Monkey Zombie", size: 1 },
    5282: { name: "Monkey Zombie", size: 1 },
    5283: { name: "Monkey Zombie", size: 1 },
    5284: { name: "Bonzara", size: 1 },
    5285: { name: "null", size: 1 },
    5286: { name: "null", size: 1 },
    5287: { name: "null", size: 1 },
    5288: { name: "null", size: 1 },
    5289: { name: "null", size: 1 },
    5290: { name: "null", size: 1 },
    5291: { name: "null", size: 1 },
    5292: { name: "null", size: 1 },
    5293: { name: "Elf Warrior", size: 1 },
    5294: { name: "Elf Warrior", size: 1 },
    5295: { name: "Elf Archer", size: 1 },
    5296: { name: "Elf Archer", size: 1 },
    5297: { name: "Goreu", size: 1 },
    5298: { name: "null", size: 1 },
    5299: { name: "Arvel", size: 1 },
    5300: { name: "Mawrth", size: 1 },
    5301: { name: "null", size: 1 },
    5302: { name: "Eoin", size: 1 },
    5303: { name: "Iona", size: 1 },
    5304: { name: "Eluned", size: 1 },
    5305: { name: "Red Sheep", size: 1 },
    5306: { name: "Green Sheep", size: 1 },
    5307: { name: "Blue Sheep", size: 1 },
    5308: { name: "Yellow Sheep", size: 1 },
    5309: { name: "Gnome", size: 1 },
    5310: { name: "null", size: 1 },
    5311: { name: "null", size: 1 },
    5312: { name: "null", size: 1 },
    5313: { name: "GPDT employee", size: 1 },
    5314: { name: "null", size: 1 },
    5315: { name: "Horacio", size: 1 },
    5316: { name: "Kangai Mau", size: 1 },
    5317: { name: "Eagle", size: 3 },
    5318: { name: "Eagle", size: 5 },
    5319: { name: "Eagle", size: 5 },
    5320: { name: "Eagle", size: 5 },
    5321: { name: "Combat stone", size: 1 },
    5322: { name: "Sigmund", size: 1 },
    5323: { name: "Sigmund", size: 1 },
    5324: { name: "null", size: 1 },
    5325: { name: "null", size: 1 },
    5326: { name: "Ur-tag", size: 1 },
    5327: { name: "Duke Horacio", size: 1 },
    5328: { name: "Mistag", size: 1 },
    5329: { name: "Sigmund", size: 1 },
    5330: { name: "Cave goblin miner", size: 1 },
    5331: { name: "Cave goblin miner", size: 1 },
    5332: { name: "Cave goblin miner", size: 1 },
    5333: { name: "Cave goblin miner", size: 1 },
    5334: { name: "Cave goblin guard", size: 1 },
    5335: { name: "Cave goblin guard", size: 1 },
    5336: { name: "Cave goblin miner", size: 1 },
    5337: { name: "Cave goblin miner", size: 1 },
    5338: { name: "Cave goblin miner", size: 1 },
    5339: { name: "Cave goblin miner", size: 1 },
    5340: { name: "Mosol Rei", size: 1 },
    5341: { name: "Spirit of Zadimus", size: 1 },
    5342: { name: "Undead one", size: 1 },
    5343: { name: "Undead one", size: 1 },
    5344: { name: "Undead one", size: 1 },
    5345: { name: "Undead one", size: 1 },
    5346: { name: "Undead one", size: 1 },
    5347: { name: "Undead one", size: 1 },
    5348: { name: "Undead one", size: 1 },
    5349: { name: "Undead one", size: 1 },
    5350: { name: "Undead one", size: 1 },
    5351: { name: "Undead one", size: 1 },
    5352: { name: "Rashiliyia", size: 1 },
    5353: { name: "Nazastarool", size: 2 },
    5354: { name: "Nazastarool", size: 2 },
    5355: { name: "Nazastarool", size: 2 },
    5356: { name: "Hajedy", size: 1 },
    5357: { name: "Vigroy", size: 1 },
    5358: { name: "Kaleb Paramaya", size: 1 },
    5359: { name: "Yohnus", size: 1 },
    5360: { name: "Seravel", size: 1 },
    5361: { name: "Yanni Salika", size: 1 },
    5362: { name: "Obli", size: 1 },
    5363: { name: "Fernahei", size: 1 },
    5364: { name: "Captain Shanks", size: 1 },
    5365: { name: "Observatory assistant", size: 2 },
    5366: { name: "Observatory professor", size: 1 },
    5367: { name: "Observatory professor", size: 1 },
    5368: { name: "Sleeping guard", size: 1 },
    5369: { name: "Goblin guard", size: 1 },
    5370: { name: "Ghost", size: 1 },
    5371: { name: "Spirit of Scorpius", size: 1 },
    5372: { name: "Grave scorpion", size: 1 },
    5373: { name: "Poison spider", size: 1 },
    5374: { name: "Naghead", size: 1 },
    5375: { name: "Wagchin", size: 1 },
    5376: { name: "Goblin", size: 1 },
    5377: { name: "Goblin", size: 1 },
    5378: { name: "Greasycheeks", size: 1 },
    5379: { name: "Smellytoes", size: 1 },
    5380: { name: "Creakyknees", size: 1 },
    5381: { name: "Clothears", size: 1 },
    5382: { name: "null", size: 1 },
    5383: { name: "Guard Captain", size: 1 },
    5384: { name: "Sandy", size: 1 },
    5385: { name: "Sandy", size: 1 },
    5386: { name: "Mazion", size: 1 },
    5387: { name: "Blaec", size: 1 },
    5388: { name: "Reeso", size: 1 },
    5389: { name: "Combat stone", size: 1 },
    5390: { name: "Combat stone", size: 1 },
    5391: { name: "Combat stone", size: 1 },
    5392: { name: "Combat stone", size: 1 },
    5393: { name: "Combat stone", size: 1 },
    5394: { name: "Combat stone", size: 1 },
    5395: { name: "Combat stone", size: 1 },
    5396: { name: "Combat stone", size: 1 },
    5397: { name: "Combat stone", size: 1 },
    5398: { name: "Combat stone", size: 1 },
    5399: { name: "Combat stone", size: 1 },
    5400: { name: "Combat stone", size: 1 },
    5401: { name: "Combat stone", size: 1 },
    5402: { name: "Combat stone", size: 1 },
    5403: { name: "Combat stone", size: 1 },
    5404: { name: "Combat stone", size: 1 },
    5405: { name: "Combat stone", size: 1 },
    5406: { name: "Combat stone", size: 1 },
    5407: { name: "Combat stone", size: 1 },
    5408: { name: "Combat stone", size: 1 },
    5409: { name: "Combat stone", size: 1 },
    5410: { name: "Combat stone", size: 1 },
    5411: { name: "null", size: 1 },
    5412: { name: "Combat stone", size: 1 },
    5413: { name: "Combat stone", size: 1 },
    5414: { name: "Combat stone", size: 1 },
    5415: { name: "Combat stone", size: 1 },
    5416: { name: "Combat stone", size: 1 },
    5417: { name: "Priest", size: 1 },
    5418: { name: "Guard", size: 1 },
    5419: { name: "Door man", size: 1 },
    5420: { name: "Watchman", size: 1 },
    5421: { name: "Soldier", size: 1 },
    5422: { name: "Wyson the gardener", size: 1 },
    5423: { name: "Sigbert the Adventurer", size: 1 },
    5424: { name: "null", size: 1 },
    5425: { name: "null", size: 1 },
    5426: { name: "Capt' Arnav", size: 1 },
    5427: { name: "Flippa", size: 2 },
    5428: { name: "Tilt", size: 2 },
    5429: { name: "Frog", size: 1 },
    5430: { name: "Frog", size: 1 },
    5431: { name: "Frog", size: 1 },
    5432: { name: "Frog", size: 1 },
    5433: { name: "Caleb", size: 1 },
    5434: { name: "Light creature", size: 1 },
    5435: { name: "Light creature", size: 1 },
    5436: { name: "Niles", size: 1 },
    5437: { name: "Miles", size: 1 },
    5438: { name: "Giles", size: 1 },
    5439: { name: "Niles", size: 1 },
    5440: { name: "Miles", size: 1 },
    5441: { name: "Giles", size: 1 },
    5442: { name: "Security Guard", size: 1 },
    5443: { name: "Johnathon", size: 1 },
    5444: { name: "Cap'n Hand", size: 1 },
    5445: { name: "Johnathon", size: 1 },
    5446: { name: "null", size: 1 },
    5447: { name: "Advisor Ghrim", size: 1 },
    5448: { name: "Advisor Ghrim", size: 1 },
    5449: { name: "Bob Barter (herbs)", size: 1 },
    5450: { name: "Murky Matt (runes)", size: 1 },
    5451: { name: "Relobo Blinyo (logs)", size: 1 },
    5452: { name: "Hofuthand (weapons and armour)", size: 1 },
    5453: { name: "Reshi", size: 1 },
    5454: { name: "Thumpy", size: 1 },
    5455: { name: "Thomdril", size: 1 },
    5456: { name: "Kendall", size: 1 },
    5457: { name: "Shipyard worker", size: 1 },
    5458: { name: "Suspect", size: 1 },
    5459: { name: "Suspect", size: 1 },
    5460: { name: "Suspect", size: 1 },
    5461: { name: "Suspect", size: 1 },
    5462: { name: "Suspect", size: 1 },
    5463: { name: "Suspect", size: 1 },
    5464: { name: "Molly", size: 1 },
    5465: { name: "Suspect", size: 1 },
    5466: { name: "Suspect", size: 1 },
    5467: { name: "Molly", size: 1 },
    5468: { name: "Suspect", size: 1 },
    5469: { name: "Suspect", size: 1 },
    5470: { name: "Suspect", size: 1 },
    5471: { name: "Molly", size: 1 },
    5472: { name: "Suspect", size: 1 },
    5473: { name: "Suspect", size: 1 },
    5474: { name: "Molly", size: 1 },
    5475: { name: "Suspect", size: 1 },
    5476: { name: "Molly", size: 1 },
    5477: { name: "Suspect", size: 1 },
    5478: { name: "Molly", size: 1 },
    5479: { name: "Suspect", size: 1 },
    5480: { name: "Molly", size: 1 },
    5481: { name: "Suspect", size: 1 },
    5482: { name: "Suspect", size: 1 },
    5483: { name: "Suspect", size: 1 },
    5484: { name: "Suspect", size: 1 },
    5485: { name: "Molly", size: 1 },
    5486: { name: "Molly", size: 1 },
    5487: { name: "Molly", size: 1 },
    5488: { name: "Balloon Animal", size: 2 },
    5489: { name: "Balloon Animal", size: 2 },
    5490: { name: "null", size: 2 },
    5491: { name: "Balloon Animal", size: 2 },
    5492: { name: "Balloon Animal", size: 2 },
    5493: { name: "Balloon Animal", size: 2 },
    5494: { name: "null", size: 2 },
    5495: { name: "null", size: 2 },
    5496: { name: "null", size: 2 },
    5497: { name: "null", size: 1 },
    5498: { name: "null", size: 1 },
    5499: { name: "null", size: 1 },
    5500: { name: "Pheasant", size: 1 },
    5501: { name: "null", size: 1 },
    5502: { name: "Pheasant", size: 1 },
    5503: { name: "Dunce", size: 1 },
    5504: { name: "Mr. Mordaut", size: 5 },
    5505: { name: "Giant", size: 2 },
    5506: { name: "Mummy", size: 2 },
    5507: { name: "Zombie", size: 2 },
    5508: { name: "Goblin", size: 2 },
    5509: { name: "Goblin", size: 2 },
    5510: { name: "Sandwich lady", size: 1 },
    5511: { name: "Guard Recruiter", size: 1 },
    5512: { name: "Gardener", size: 1 },
    5513: { name: "Elite Void Knight", size: 1 },
    5514: { name: "Lesser Fanatic", size: 1 },
    5515: { name: "Luna", size: 1 },
    5516: { name: "null", size: 1 },
    5517: { name: "The 'Wedge'", size: 1 },
    5518: { name: "Elder Gnome child", size: 1 },
    5519: { name: "Two-pints", size: 1 },
    5520: { name: "Jarr", size: 1 },
    5521: { name: "Le-sabrè", size: 1 },
    5522: { name: "Flax keeper", size: 1 },
    5523: { name: "Hatius Cosaintus", size: 1 },
    5524: { name: "Sir Rebral", size: 1 },
    5525: { name: "Toby", size: 1 },
    5526: { name: "Thorodin", size: 1 },
    5527: { name: "Twiggy O'Korn", size: 1 },
    5528: { name: "Squirrel", size: 1 },
    5529: { name: "Hunting expert", size: 1 },
    5530: { name: "null", size: 3 },
    5531: { name: "Spotted kebbit", size: 1 },
    5532: { name: "Dark kebbit", size: 1 },
    5533: { name: "Dashing kebbit", size: 1 },
    5534: { name: "Whirlpool", size: 2 },
    5535: { name: "Enormous Tentacle", size: 2 },
    5536: { name: "Vet'ion Jr.", size: 1 },
    5537: { name: "Vet'ion Jr.", size: 1 },
    5538: { name: "Egg", size: 1 },
    5539: { name: "Egg", size: 1 },
    5540: { name: "Egg", size: 1 },
    5541: { name: "Egg", size: 1 },
    5542: { name: "Egg", size: 1 },
    5543: { name: "Egg", size: 1 },
    5544: { name: "Jack", size: 1 },
    5545: { name: "Jill", size: 1 },
    5546: { name: "Jeff", size: 1 },
    5547: { name: "Scorpia's offspring", size: 1 },
    5548: { name: "Tropical wagtail", size: 1 },
    5549: { name: "Crimson swift", size: 1 },
    5550: { name: "Cerulean twitch", size: 1 },
    5551: { name: "Golden warbler", size: 1 },
    5552: { name: "Copper longtail", size: 1 },
    5553: { name: "Black warlock", size: 1 },
    5554: { name: "Snowy knight", size: 1 },
    5555: { name: "Sapphire glacialis", size: 1 },
    5556: { name: "Ruby harvest", size: 1 },
    5557: { name: "Venenatis spiderling", size: 1 },
    5558: { name: "Callisto cub", size: 1 },
    5559: { name: "Vet'ion Jr.", size: 1 },
    5560: { name: "Vet'ion Jr.", size: 1 },
    5561: { name: "Scorpia's offspring", size: 2 },
    5562: { name: "Mercy", size: 1 },
    5563: { name: "Angry barbarian spirit", size: 1 },
    5564: { name: "Enraged barbarian spirit", size: 1 },
    5565: { name: "Berserk barbarian spirit", size: 1 },
    5566: { name: "Ferocious barbarian spirit", size: 1 },
    5567: { name: "Death", size: 1 },
    5568: { name: "Zombie", size: 1 },
    5569: { name: "Most of a Zombie", size: 1 },
    5570: { name: "Most of a Zombie", size: 1 },
    5571: { name: "Zombie", size: 1 },
    5572: { name: "Most of a Zombie", size: 1 },
    5573: { name: "Zombie Head", size: 1 },
    5574: { name: "Zombie", size: 1 },
    5575: { name: "Half-Zombie", size: 1 },
    5576: { name: "Other Half-Zombie", size: 1 },
    5577: { name: "Child", size: 1 },
    5578: { name: "Child", size: 1 },
    5579: { name: "Child", size: 1 },
    5580: { name: "Child", size: 1 },
    5581: { name: "Child", size: 1 },
    5582: { name: "Child", size: 1 },
    5583: { name: "Zombie", size: 1 },
    5584: { name: "Wily cat", size: 1 },
    5585: { name: "Wily cat", size: 1 },
    5586: { name: "Wily cat", size: 1 },
    5587: { name: "Wily cat", size: 1 },
    5588: { name: "Wily cat", size: 1 },
    5589: { name: "Wily cat", size: 1 },
    5590: { name: "Wily hellcat", size: 1 },
    5591: { name: "Kitten", size: 1 },
    5592: { name: "Kitten", size: 1 },
    5593: { name: "Kitten", size: 1 },
    5594: { name: "Kitten", size: 1 },
    5595: { name: "Kitten", size: 1 },
    5596: { name: "Kitten", size: 1 },
    5597: { name: "Hell-kitten", size: 1 },
    5598: { name: "Overgrown cat", size: 1 },
    5599: { name: "Overgrown cat", size: 1 },
    5600: { name: "Overgrown cat", size: 1 },
    5601: { name: "Overgrown cat", size: 1 },
    5602: { name: "Overgrown cat", size: 1 },
    5603: { name: "Overgrown cat", size: 1 },
    5604: { name: "Overgrown hellcat", size: 1 },
    5605: { name: "Peaceful barbarian spirit", size: 1 },
    5606: { name: "Miner", size: 1 },
    5607: { name: "Murphy", size: 1 },
    5608: { name: "Murphy", size: 1 },
    5609: { name: "Murphy", size: 1 },
    5610: { name: "Murphy", size: 1 },
    5611: { name: "Shark", size: 1 },
    5612: { name: "Shark", size: 1 },
    5613: { name: "Sam", size: 1 },
    5614: { name: "Rachael", size: 1 },
    5615: { name: "Swamp snake", size: 3 },
    5616: { name: "Swamp snake", size: 4 },
    5617: { name: "Swamp snake", size: 5 },
    5618: { name: "Swamp snake", size: 3 },
    5619: { name: "Dead swamp snake", size: 3 },
    5620: { name: "Dead swamp snake", size: 4 },
    5621: { name: "Dead swamp snake", size: 5 },
    5622: { name: "Ghast", size: 2 },
    5623: { name: "Ghast", size: 2 },
    5624: { name: "Ghast", size: 2 },
    5625: { name: "Ghast", size: 2 },
    5626: { name: "Ghast", size: 2 },
    5627: { name: "Ghast", size: 2 },
    5628: { name: "Giant snail", size: 3 },
    5629: { name: "Giant snail", size: 3 },
    5630: { name: "Giant snail", size: 3 },
    5631: { name: "Riyl shadow", size: 1 },
    5632: { name: "Asyn shadow", size: 1 },
    5633: { name: "Shade", size: 1 },
    5634: { name: "Vampyre Juvinate", size: 1 },
    5635: { name: "Vampyre Juvinate", size: 1 },
    5636: { name: "Vampyre Juvinate", size: 1 },
    5637: { name: "Vampyre Juvinate", size: 1 },
    5638: { name: "Vampyre Juvinate", size: 1 },
    5639: { name: "Vampyre Juvinate", size: 1 },
    5640: { name: "Feral Vampyre", size: 1 },
    5641: { name: "Feral Vampyre", size: 1 },
    5642: { name: "Feral Vampyre", size: 1 },
    5643: { name: "Tentacle", size: 1 },
    5644: { name: "Head", size: 1 },
    5645: { name: "Head", size: 1 },
    5646: { name: "Tentacle", size: 1 },
    5647: { name: "Zombie", size: 1 },
    5648: { name: "Undead Lumberjack", size: 1 },
    5649: { name: "Undead Lumberjack", size: 1 },
    5650: { name: "Undead Lumberjack", size: 1 },
    5651: { name: "Undead Lumberjack", size: 1 },
    5652: { name: "Undead Lumberjack", size: 1 },
    5653: { name: "Undead Lumberjack", size: 1 },
    5654: { name: "Undead Lumberjack", size: 1 },
    5655: { name: "Undead Lumberjack", size: 1 },
    5656: { name: "Undead Lumberjack", size: 1 },
    5657: { name: "Undead Lumberjack", size: 1 },
    5658: { name: "Undead Lumberjack", size: 1 },
    5659: { name: "Undead Lumberjack", size: 1 },
    5660: { name: "Undead Lumberjack", size: 1 },
    5661: { name: "Undead Lumberjack", size: 1 },
    5662: { name: "Undead Lumberjack", size: 1 },
    5663: { name: "Undead Lumberjack", size: 1 },
    5664: { name: "null", size: 1 },
    5665: { name: "Undead Lumberjack", size: 1 },
    5666: { name: "Undead Lumberjack", size: 1 },
    5667: { name: "Undead Lumberjack", size: 1 },
    5668: { name: "Undead Lumberjack", size: 1 },
    5669: { name: "Undead Lumberjack", size: 1 },
    5670: { name: "Undead Lumberjack", size: 1 },
    5671: { name: "Undead Lumberjack", size: 1 },
    5672: { name: "Undead Lumberjack", size: 1 },
    5673: { name: "Undead Lumberjack", size: 1 },
    5674: { name: "Undead Lumberjack", size: 1 },
    5675: { name: "Undead Lumberjack", size: 1 },
    5676: { name: "Undead Lumberjack", size: 1 },
    5677: { name: "Undead Lumberjack", size: 1 },
    5678: { name: "Undead Lumberjack", size: 1 },
    5679: { name: "Undead Lumberjack", size: 1 },
    5680: { name: "Undead Lumberjack", size: 1 },
    5681: { name: "Undead Lumberjack", size: 1 },
    5682: { name: "Undead Lumberjack", size: 1 },
    5683: { name: "Undead Lumberjack", size: 1 },
    5684: { name: "Undead Lumberjack", size: 1 },
    5685: { name: "Undead Lumberjack", size: 1 },
    5686: { name: "Undead Lumberjack", size: 1 },
    5687: { name: "Undead Lumberjack", size: 1 },
    5688: { name: "Undead Lumberjack", size: 1 },
    5689: { name: "Undead Lumberjack", size: 1 },
    5690: { name: "Undead Lumberjack", size: 1 },
    5691: { name: "Undead Lumberjack", size: 1 },
    5692: { name: "Undead Lumberjack", size: 1 },
    5693: { name: "Undead Lumberjack", size: 1 },
    5694: { name: "Undead Lumberjack", size: 1 },
    5695: { name: "Undead Lumberjack", size: 1 },
    5696: { name: "Undead Lumberjack", size: 1 },
    5697: { name: "Undead Lumberjack", size: 1 },
    5698: { name: "Undead Lumberjack", size: 1 },
    5699: { name: "Undead Lumberjack", size: 1 },
    5700: { name: "Undead Lumberjack", size: 1 },
    5701: { name: "Undead Lumberjack", size: 1 },
    5702: { name: "Undead Lumberjack", size: 1 },
    5703: { name: "Undead Lumberjack", size: 1 },
    5704: { name: "Undead Lumberjack", size: 1 },
    5705: { name: "Undead Lumberjack", size: 1 },
    5706: { name: "Undead Lumberjack", size: 1 },
    5707: { name: "Undead Lumberjack", size: 1 },
    5708: { name: "Undead Lumberjack", size: 1 },
    5709: { name: "Undead Lumberjack", size: 1 },
    5710: { name: "Undead Lumberjack", size: 1 },
    5711: { name: "Undead Lumberjack", size: 1 },
    5712: { name: "Undead Lumberjack", size: 1 },
    5713: { name: "Undead Lumberjack", size: 1 },
    5714: { name: "Undead Lumberjack", size: 1 },
    5715: { name: "Undead Lumberjack", size: 1 },
    5716: { name: "Undead Lumberjack", size: 1 },
    5717: { name: "Undead Lumberjack", size: 1 },
    5718: { name: "Undead Lumberjack", size: 1 },
    5719: { name: "Undead Lumberjack", size: 1 },
    5720: { name: "Undead Lumberjack", size: 1 },
    5721: { name: "null", size: 1 },
    5722: { name: "Barricade", size: 1 },
    5723: { name: "Barricade", size: 1 },
    5724: { name: "Barricade", size: 1 },
    5725: { name: "Barricade", size: 1 },
    5726: { name: "Sheep", size: 1 },
    5727: { name: "Rabbit", size: 1 },
    5728: { name: "Imp", size: 1 },
    5729: { name: "Shipyard worker", size: 1 },
    5730: { name: "Master Farmer", size: 1 },
    5731: { name: "Master Farmer", size: 1 },
    5732: { name: "Market Guard", size: 1 },
    5733: { name: "null", size: 1 },
    5734: { name: "Elnock Inquisitor", size: 1 },
    5735: { name: "Immenizz", size: 1 },
    5736: { name: "Fairy Aeryka", size: 1 },
    5737: { name: "Wandering impling", size: 1 },
    5738: { name: "Imp defender", size: 1 },
    5739: { name: "Penance Fighter", size: 1 },
    5740: { name: "Penance Fighter", size: 1 },
    5741: { name: "Penance Fighter", size: 1 },
    5742: { name: "Penance Fighter", size: 1 },
    5743: { name: "Penance Fighter", size: 1 },
    5744: { name: "Penance Fighter", size: 1 },
    5745: { name: "Penance Fighter", size: 1 },
    5746: { name: "Penance Fighter", size: 1 },
    5747: { name: "Penance Fighter", size: 1 },
    5748: { name: "Penance Runner", size: 1 },
    5749: { name: "Penance Runner", size: 1 },
    5750: { name: "Penance Runner", size: 1 },
    5751: { name: "Penance Runner", size: 1 },
    5752: { name: "Penance Runner", size: 1 },
    5753: { name: "Penance Runner", size: 1 },
    5754: { name: "Penance Runner", size: 1 },
    5755: { name: "Penance Runner", size: 1 },
    5756: { name: "Penance Runner", size: 1 },
    5757: { name: "Penance Ranger", size: 1 },
    5758: { name: "Penance Ranger", size: 1 },
    5759: { name: "Penance Ranger", size: 1 },
    5760: { name: "Penance Ranger", size: 1 },
    5761: { name: "Penance Ranger", size: 1 },
    5762: { name: "Penance Ranger", size: 1 },
    5763: { name: "Penance Ranger", size: 1 },
    5764: { name: "Penance Ranger", size: 1 },
    5765: { name: "Penance Ranger", size: 1 },
    5766: { name: "Penance Healer", size: 1 },
    5767: { name: "Penance Healer", size: 1 },
    5768: { name: "Penance Healer", size: 1 },
    5769: { name: "Penance Healer", size: 1 },
    5770: { name: "Penance Healer", size: 1 },
    5771: { name: "Penance Healer", size: 1 },
    5772: { name: "Penance Healer", size: 1 },
    5773: { name: "Penance Healer", size: 1 },
    5774: { name: "Penance Healer", size: 1 },
    5775: { name: "Penance Queen", size: 5 },
    5776: { name: "Queen spawn", size: 1 },
    5777: { name: "Egg launcher", size: 1 },
    5778: { name: "Egg launcher", size: 1 },
    5779: { name: "Giant Mole", size: 3 },
    5780: { name: "Baby Mole", size: 1 },
    5781: { name: "Baby Mole", size: 1 },
    5782: { name: "Baby Mole", size: 1 },
    5783: { name: "null", size: 1 },
    5784: { name: "Light creature", size: 3 },
    5785: { name: "Juna", size: 1 },
    5786: { name: "Simon Templeton", size: 1 },
    5787: { name: "Pyramid block", size: 2 },
    5788: { name: "Pyramid block", size: 2 },
    5789: { name: "Cap'n Izzy No-Beard", size: 1 },
    5790: { name: "Raulyn", size: 1 },
    5791: { name: "Giant bat", size: 2 },
    5792: { name: "Party Pete", size: 1 },
    5793: { name: "Knight", size: 1 },
    5794: { name: "Megan", size: 1 },
    5795: { name: "Lucy", size: 1 },
    5796: { name: "Winter Elemental", size: 1 },
    5797: { name: "Winter Elemental", size: 1 },
    5798: { name: "Winter Elemental", size: 1 },
    5799: { name: "Winter Elemental", size: 1 },
    5800: { name: "Winter Elemental", size: 1 },
    5801: { name: "Winter Elemental", size: 1 },
    5802: { name: "Autumn Elemental", size: 1 },
    5803: { name: "Autumn Elemental", size: 1 },
    5804: { name: "Autumn Elemental", size: 1 },
    5805: { name: "Autumn Elemental", size: 1 },
    5806: { name: "Autumn Elemental", size: 1 },
    5807: { name: "Autumn Elemental", size: 1 },
    5808: { name: "Reacher", size: 1 },
    5809: { name: "Tanner", size: 1 },
    5810: { name: "Master Crafter", size: 1 },
    5811: { name: "Master Crafter", size: 1 },
    5812: { name: "Master Crafter", size: 1 },
    5813: { name: "Miner", size: 1 },
    5814: { name: "Miner", size: 1 },
    5815: { name: "Bert", size: 1 },
    5816: { name: "Yak", size: 2 },
    5817: { name: "Iceberg", size: 1 },
    5818: { name: "Iceberg", size: 2 },
    5819: { name: "Bert", size: 1 },
    5820: { name: "Fishing spot", size: 1 },
    5821: { name: "Fishing spot", size: 1 },
    5822: { name: "Ice Troll King", size: 2 },
    5823: { name: "Frenzied ice troll runt", size: 1 },
    5824: { name: "Frenzied ice troll male", size: 1 },
    5825: { name: "Frenzied ice troll female", size: 1 },
    5826: { name: "Frenzied ice troll grunt", size: 1 },
    5827: { name: "Bork Sigmundson", size: 1 },
    5828: { name: "Ice troll runt", size: 1 },
    5829: { name: "Ice troll male", size: 1 },
    5830: { name: "Ice troll female", size: 1 },
    5831: { name: "Ice troll grunt", size: 1 },
    5832: { name: "Martin the Master Gardener", size: 1 },
    5833: { name: "Frog", size: 1 },
    5834: { name: "Storm cloud", size: 3 },
    5835: { name: "Co-ordinator", size: 1 },
    5836: { name: "Fairy Nuff", size: 1 },
    5837: { name: "Fairy Godfather", size: 1 },
    5838: { name: "Slim Louie", size: 1 },
    5839: { name: "Fat Rocco", size: 1 },
    5840: { name: "Gatekeeper", size: 1 },
    5841: { name: "Zandar Horfyre", size: 1 },
    5842: { name: "Cow", size: 2 },
    5843: { name: "Sheep", size: 1 },
    5844: { name: "Sheep", size: 1 },
    5845: { name: "Sheep", size: 1 },
    5846: { name: "Sheep", size: 1 },
    5847: { name: "Zanaris choir", size: 1 },
    5848: { name: "Tanglefoot", size: 1 },
    5849: { name: "null", size: 1 },
    5850: { name: "null", size: 1 },
    5851: { name: "null", size: 1 },
    5852: { name: "null", size: 1 },
    5853: { name: "Baby tanglefoot", size: 1 },
    5854: { name: "Baby tanglefoot", size: 1 },
    5855: { name: "Gatekeeper", size: 1 },
    5856: { name: "Fairy chef", size: 1 },
    5857: { name: "null", size: 1 },
    5858: { name: "null", size: 1 },
    5859: { name: "null", size: 1 },
    5862: { name: "Cerberus", size: 5 },
    5863: { name: "Cerberus", size: 5 },
    5864: { name: "Captain Ned", size: 1 },
    5865: { name: "null", size: 1 },
    5866: { name: "Cerberus", size: 5 },
    5867: { name: "Summoned Soul", size: 1 },
    5868: { name: "Summoned Soul", size: 1 },
    5869: { name: "Summoned Soul", size: 1 },
    5870: { name: "Patchy", size: 1 },
    5871: { name: "King Arthur", size: 1 },
    5872: { name: "Baby green dragon", size: 2 },
    5873: { name: "Baby green dragon", size: 2 },
    5874: { name: "Black demon", size: 3 },
    5875: { name: "Black demon", size: 3 },
    5876: { name: "Black demon", size: 3 },
    5877: { name: "Black demon", size: 3 },
    5878: { name: "Blue dragon", size: 4 },
    5879: { name: "Blue dragon", size: 4 },
    5880: { name: "Blue dragon", size: 4 },
    5881: { name: "Blue dragon", size: 4 },
    5882: { name: "Blue dragon", size: 4 },
    5883: { name: "Abyssal orphan", size: 1 },
    5884: { name: "Abyssal orphan", size: 1 },
    5885: { name: "null", size: 1 },
    5886: { name: "Abyssal Sire", size: 6 },
    5887: { name: "Abyssal Sire", size: 6 },
    5888: { name: "Abyssal Sire", size: 6 },
    5889: { name: "Abyssal Sire", size: 6 },
    5890: { name: "Abyssal Sire", size: 6 },
    5891: { name: "Abyssal Sire", size: 6 },
    5892: { name: "TzRek-Jad", size: 1 },
    5893: { name: "TzRek-Jad", size: 1 },
    5894: { name: "Bast", size: 1 },
    5895: { name: "Drogo dwarf", size: 1 },
    5896: { name: "Flynn", size: 1 },
    5897: { name: "Wayne", size: 1 },
    5898: { name: "null", size: 1 },
    5899: { name: "null", size: 1 },
    5900: { name: "null", size: 1 },
    5901: { name: "null", size: 1 },
    5902: { name: "null", size: 1 },
    5903: { name: "null", size: 1 },
    5904: { name: "Dwarf", size: 1 },
    5905: { name: "Betty", size: 1 },
    5906: { name: "Probita", size: 1 },
    5907: { name: "Chaos Elemental Jr.", size: 1 },
    5908: { name: "Abyssal Sire", size: 6 },
    5909: { name: "Tentacle", size: 9 },
    5910: { name: "Tentacle", size: 9 },
    5911: { name: "Tentacle", size: 9 },
    5912: { name: "Tentacle", size: 9 },
    5913: { name: "Tentacle", size: 9 },
    5914: { name: "Respiratory system", size: 2 },
    5915: { name: "<col=00ffff>Vent</col>", size: 2 },
    5916: { name: "Spawn", size: 1 },
    5917: { name: "Spawn", size: 1 },
    5918: { name: "Scion", size: 2 },
    5919: { name: "Grace", size: 1 },
    5920: { name: "Mark", size: 1 },
    5921: { name: "BigRedJapan", size: 1 },
    5922: { name: "Skullball", size: 1 },
    5923: { name: "Skullball Boss", size: 1 },
    5924: { name: "Agility Boss", size: 1 },
    5925: { name: "Skullball Trainer", size: 1 },
    5926: { name: "Agility Trainer", size: 1 },
    5927: { name: "Agility Trainer", size: 1 },
    5928: { name: "Werewolf", size: 1 },
    5929: { name: "Knight", size: 2 },
    5930: { name: "null", size: 2 },
    5931: { name: "null", size: 1 },
    5932: { name: "Egg", size: 1 },
    5933: { name: "Egg", size: 1 },
    5934: { name: "Egg", size: 1 },
    5935: { name: "Sand Crab", size: 1 },
    5936: { name: "Sandy rocks", size: 1 },
    5937: { name: "Jarvald", size: 1 },
    5938: { name: "Wallasalki", size: 1 },
    5939: { name: "Wallasalki", size: 1 },
    5940: { name: "Giant Rock Crab", size: 2 },
    5941: { name: "Boulder", size: 2 },
    5942: { name: "Dagannoth", size: 1 },
    5943: { name: "Dagannoth", size: 1 },
    5944: { name: "Rock lobster", size: 2 },
    5945: { name: "Rock", size: 2 },
    5946: { name: "Suspicious water", size: 1 },
    5947: { name: "Spinolyp", size: 1 },
    5948: { name: "Suspicious water", size: 1 },
    5949: { name: "Al the Camel", size: 2 },
    5950: { name: "Elly the Camel", size: 2 },
    5951: { name: "Ollie the Camel", size: 2 },
    5952: { name: "Cam the Camel", size: 2 },
    5953: { name: "null", size: 2 },
    5954: { name: "Alice the Camel", size: 2 },
    5955: { name: "Neferti the Camel", size: 2 },
    5956: { name: "Ali the Leaflet Dropper", size: 1 },
    5957: { name: "Ali the Smith", size: 1 },
    5958: { name: "Ali the Farmer", size: 1 },
    5959: { name: "Ali the Tailor", size: 1 },
    5960: { name: "Ali the Guard", size: 1 },
    5961: { name: "Spinolyp", size: 1 },
    5962: { name: "Suspicious water", size: 1 },
    5963: { name: "Spinolyp", size: 1 },
    5964: { name: "Khazard trooper", size: 1 },
    5965: { name: "Khazard trooper", size: 1 },
    5966: { name: "Gnome troop", size: 1 },
    5967: { name: "Gnome troop", size: 1 },
    5968: { name: "Gnome", size: 1 },
    5969: { name: "Gnome", size: 1 },
    5970: { name: "Gnome", size: 1 },
    5971: { name: "Mounted terrorbird gnome", size: 2 },
    5972: { name: "Mounted terrorbird gnome", size: 2 },
    5973: { name: "Mounted terrorbird gnome", size: 2 },
    5974: { name: "Sweeper", size: 1 },
    5975: { name: "Flying Book", size: 1 },
    5976: { name: "Flying Book", size: 1 },
    5977: { name: "Justiciar Zachariah", size: 3 },
    5978: { name: "Entrance Guardian", size: 1 },
    5979: { name: "Telekinetic Guardian", size: 1 },
    5980: { name: "Alchemy Guardian", size: 2 },
    5981: { name: "Enchantment Guardian", size: 2 },
    5982: { name: "Graveyard Guardian", size: 2 },
    5983: { name: "<col=ff9040>Pet rock</col>", size: 1 },
    5984: { name: "Rewards Guardian", size: 2 },
    5985: { name: "Charmed Warrior", size: 1 },
    5986: { name: "Charmed Warrior", size: 1 },
    5987: { name: "Charmed Warrior", size: 1 },
    5988: { name: "Charmed Warrior", size: 1 },
    5989: { name: "Secretary", size: 1 },
    5990: { name: "Purple Pewter Secretary", size: 1 },
    5991: { name: "Yellow Fortune Secretary", size: 1 },
    5992: { name: "Blue Opal Secretary", size: 1 },
    5993: { name: "Green Gemstone Secretary", size: 1 },
    5994: { name: "White Chisel Secretary", size: 1 },
    5995: { name: "Silver Cog Secretary", size: 1 },
    5996: { name: "Brown Engine Secretary", size: 1 },
    5997: { name: "Red Axe Secretary", size: 1 },
    5998: { name: "Purple Pewter Director", size: 1 },
    5999: { name: "Blue Opal Director", size: 1 },
    6000: { name: "Yellow Fortune Director", size: 1 },
    6001: { name: "Orlando Smith", size: 1 },
    6002: { name: "Natural historian", size: 1 },
    6003: { name: "Natural historian", size: 1 },
    6004: { name: "Natural historian", size: 1 },
    6005: { name: "Natural historian", size: 1 },
    6006: { name: "Natural historian", size: 1 },
    6007: { name: "Leech display", size: 3 },
    6008: { name: "Sea slugs display", size: 3 },
    6009: { name: "Snail display", size: 3 },
    6010: { name: "Monkey display", size: 3 },
    6011: { name: "Lizard display", size: 4 },
    6012: { name: "Penguin display", size: 3 },
    6013: { name: "Camel display", size: 3 },
    6014: { name: "Terrorbird display", size: 3 },
    6015: { name: "Dragon display", size: 5 },
    6016: { name: "Wyvern display", size: 4 },
    6017: { name: "Battle tortoise display", size: 5 },
    6018: { name: "Mole display", size: 3 },
    6019: { name: "Torrcs", size: 1 },
    6020: { name: "Marfet", size: 1 },
    6021: { name: "Green Gemstone Director", size: 1 },
    6022: { name: "White Chisel Director", size: 1 },
    6023: { name: "Silver Cog Director", size: 1 },
    6024: { name: "Brown Engine Director", size: 1 },
    6025: { name: "Red Axe Director", size: 1 },
    6026: { name: "Red Axe Cat", size: 1 },
    6027: { name: "Trader", size: 1 },
    6028: { name: "Trader", size: 1 },
    6029: { name: "Trader", size: 1 },
    6030: { name: "Trader", size: 1 },
    6031: { name: "Trader", size: 1 },
    6032: { name: "Trader", size: 1 },
    6033: { name: "Trader", size: 1 },
    6034: { name: "Trader", size: 1 },
    6035: { name: "Trader", size: 1 },
    6036: { name: "Trader", size: 1 },
    6037: { name: "Trader", size: 1 },
    6038: { name: "Trader", size: 1 },
    6039: { name: "Trader", size: 1 },
    6040: { name: "Trader", size: 1 },
    6041: { name: "Trader", size: 1 },
    6042: { name: "Trader", size: 1 },
    6043: { name: "Trade Referee", size: 1 },
    6044: { name: "Supreme Commander", size: 1 },
    6045: { name: "Commander Veldaban", size: 1 },
    6046: { name: "Black Guard", size: 1 },
    6047: { name: "Black Guard", size: 1 },
    6048: { name: "Black Guard", size: 1 },
    6049: { name: "Black Guard", size: 1 },
    6050: { name: "Black Guard Berserker", size: 1 },
    6051: { name: "Black Guard Berserker", size: 1 },
    6052: { name: "Black Guard Berserker", size: 1 },
    6053: { name: "Gnome emissary", size: 1 },
    6054: { name: "Gnome traveller", size: 1 },
    6055: { name: "Gnome traveller", size: 1 },
    6056: { name: "Guard", size: 1 },
    6057: { name: "Ranging Guild Doorman", size: 1 },
    6058: { name: "Leatherworker", size: 1 },
    6059: { name: "Armour salesman", size: 1 },
    6060: { name: "Bow and Arrow salesman", size: 1 },
    6061: { name: "Tower Advisor", size: 1 },
    6062: { name: "Tower Advisor", size: 1 },
    6063: { name: "Tower Advisor", size: 1 },
    6064: { name: "Tower Advisor", size: 1 },
    6065: { name: "Tower Archer", size: 1 },
    6066: { name: "Tower Archer", size: 1 },
    6067: { name: "Tower Archer", size: 1 },
    6068: { name: "Tower Archer", size: 1 },
    6069: { name: "Tribal Weapon Salesman", size: 1 },
    6070: { name: "Competition Judge", size: 1 },
    6071: { name: "Ticket Merchant", size: 1 },
    6072: { name: "Jimmy", size: 1 },
    6073: { name: "Ref", size: 1 },
    6074: { name: "Ref", size: 1 },
    6075: { name: "Tortoise", size: 3 },
    6076: { name: "Tortoise", size: 3 },
    6077: { name: "Gnome child", size: 1 },
    6078: { name: "Gnome child", size: 1 },
    6079: { name: "Gnome child", size: 1 },
    6080: { name: "Gnome trainer", size: 1 },
    6081: { name: "Gnome guard", size: 1 },
    6082: { name: "Gnome guard", size: 1 },
    6083: { name: "Gnome shop keeper", size: 1 },
    6084: { name: "Gnome banker", size: 1 },
    6085: { name: "Gnome baller", size: 1 },
    6086: { name: "Gnome woman", size: 1 },
    6087: { name: "Gnome woman", size: 1 },
    6088: { name: "Captain Errdo", size: 1 },
    6089: { name: "null", size: 1 },
    6090: { name: "null", size: 1 },
    6091: { name: "null", size: 1 },
    6092: { name: "null", size: 1 },
    6093: { name: "null", size: 1 },
    6094: { name: "Gnome", size: 1 },
    6095: { name: "Gnome", size: 1 },
    6096: { name: "Gnome", size: 1 },
    6097: { name: "Gnome Archer", size: 1 },
    6098: { name: "Gnome Driver", size: 1 },
    6099: { name: "Gnome Mage", size: 1 },
    6100: { name: "Lieutenant Schepbur", size: 1 },
    6101: { name: "Trainer Nacklepen", size: 1 },
    6102: { name: "Bush snake", size: 1 },
    6103: { name: "Bush snake", size: 1 },
    6104: { name: "null", size: 1 },
    6105: { name: "null", size: 1 },
    6106: { name: "null", size: 1 },
    6107: { name: "null", size: 1 },
    6108: { name: "null", size: 1 },
    6109: { name: "null", size: 1 },
    6110: { name: "null", size: 1 },
    6111: { name: "null", size: 1 },
    6112: { name: "null", size: 1 },
    6113: { name: "null", size: 1 },
    6114: { name: "null", size: 1 },
    6115: { name: "null", size: 1 },
    6116: { name: "null", size: 1 },
    6117: { name: "null", size: 1 },
    6118: { name: "Elvarg (hard)", size: 4 },
    6119: { name: "The Inadequacy (hard)", size: 5 },
    6120: { name: "The Everlasting (hard)", size: 3 },
    6121: { name: "The Untouchable (hard)", size: 2 },
    6122: { name: "null", size: 1 },
    6123: { name: "null", size: 1 },
    6124: { name: "null", size: 1 },
    6125: { name: "null", size: 1 },
    6126: { name: "null", size: 1 },
    6127: { name: "null", size: 1 },
    6128: { name: "null", size: 1 },
    6129: { name: "null", size: 2 },
    6130: { name: "null", size: 1 },
    6131: { name: "null", size: 1 },
    6132: { name: "null", size: 1 },
    6133: { name: "null", size: 1 },
    6134: { name: "null", size: 1 },
    6135: { name: "null", size: 1 },
    6136: { name: "null", size: 1 },
    6137: { name: "null", size: 1 },
    6138: { name: "null", size: 1 },
    6139: { name: "null", size: 1 },
    6140: { name: "null", size: 1 },
    6141: { name: "null", size: 1 },
    6142: { name: "null", size: 1 },
    6143: { name: "Urium Shadow", size: 1 },
    6144: { name: "null", size: 1 },
    6145: { name: "null", size: 2 },
    6146: { name: "null", size: 2 },
    6147: { name: "null", size: 1 },
    6148: { name: "null", size: 1 },
    6149: { name: "null", size: 1 },
    6150: { name: "null", size: 1 },
    6151: { name: "null", size: 1 },
    6152: { name: "null", size: 1 },
    6153: { name: "null", size: 1 },
    6154: { name: "null", size: 1 },
    6155: { name: "null", size: 3 },
    6156: { name: "null", size: 3 },
    6157: { name: "null", size: 1 },
    6158: { name: "null", size: 1 },
    6159: { name: "null", size: 1 },
    6160: { name: "null", size: 1 },
    6161: { name: "null", size: 1 },
    6162: { name: "null", size: 1 },
    6163: { name: "null", size: 1 },
    6164: { name: "null", size: 1 },
    6165: { name: "null", size: 1 },
    6166: { name: "null", size: 1 },
    6167: { name: "null", size: 1 },
    6168: { name: "null", size: 1 },
    6169: { name: "null", size: 1 },
    6170: { name: "null", size: 1 },
    6171: { name: "null", size: 1 },
    6172: { name: "null", size: 1 },
    6173: { name: "null", size: 1 },
    6174: { name: "null", size: 1 },
    6175: { name: "null", size: 1 },
    6176: { name: "null", size: 1 },
    6177: { name: "Scion", size: 2 },
    6179: { name: "null", size: 1 },
    6180: { name: "null", size: 1 },
    6181: { name: "null", size: 1 },
    6182: { name: "null", size: 1 },
    6183: { name: "null", size: 1 },
    6184: { name: "null", size: 1 },
    6185: { name: "null", size: 1 },
    6186: { name: "null", size: 1 },
    6187: { name: "null", size: 1 },
    6188: { name: "null", size: 1 },
    6189: { name: "null", size: 1 },
    6190: { name: "null", size: 1 },
    6191: { name: "null", size: 1 },
    6192: { name: "null", size: 1 },
    6193: { name: "null", size: 1 },
    6194: { name: "null", size: 1 },
    6195: { name: "null", size: 1 },
    6196: { name: "null", size: 1 },
    6197: { name: "null", size: 1 },
    6198: { name: "null", size: 1 },
    6199: { name: "null", size: 1 },
    6200: { name: "null", size: 1 },
    6201: { name: "null", size: 1 },
    6202: { name: "null", size: 1 },
    6203: { name: "null", size: 1 },
    6204: { name: "null", size: 1 },
    6205: { name: "null", size: 1 },
    6206: { name: "null", size: 1 },
    6207: { name: "null", size: 1 },
    6208: { name: "null", size: 2 },
    6209: { name: "null", size: 2 },
    6210: { name: "null", size: 2 },
    6211: { name: "null", size: 2 },
    6212: { name: "null", size: 2 },
    6213: { name: "null", size: 2 },
    6214: { name: "null", size: 1 },
    6215: { name: "null", size: 1 },
    6216: { name: "null", size: 1 },
    6217: { name: "null", size: 1 },
    6218: { name: "null", size: 1 },
    6219: { name: "null", size: 1 },
    6220: { name: "null", size: 1 },
    6221: { name: "null", size: 1 },
    6222: { name: "null", size: 1 },
    6223: { name: "null", size: 2 },
    6224: { name: "null", size: 1 },
    6225: { name: "null", size: 1 },
    6226: { name: "null", size: 1 },
    6227: { name: "null", size: 1 },
    6228: { name: "null", size: 1 },
    6229: { name: "null", size: 1 },
    6230: { name: "null", size: 1 },
    6231: { name: "null", size: 1 },
    6232: { name: "null", size: 1 },
    6233: { name: "null", size: 1 },
    6234: { name: "null", size: 1 },
    6235: { name: "null", size: 1 },
    6236: { name: "null", size: 1 },
    6237: { name: "null", size: 1 },
    6238: { name: "null", size: 1 },
    6239: { name: "null", size: 1 },
    6240: { name: "null", size: 1 },
    6241: { name: "null", size: 1 },
    6242: { name: "null", size: 1 },
    6243: { name: "null", size: 1 },
    6244: { name: "null", size: 1 },
    6245: { name: "null", size: 1 },
    6246: { name: "null", size: 1 },
    6247: { name: "null", size: 1 },
    6248: { name: "null", size: 1 },
    6249: { name: "null", size: 1 },
    6250: { name: "null", size: 1 },
    6251: { name: "null", size: 2 },
    6252: { name: "null", size: 1 },
    6253: { name: "null", size: 1 },
    6254: { name: "null", size: 1 },
    6255: { name: "null", size: 1 },
    6256: { name: "null", size: 1 },
    6257: { name: "Tempoross", size: 5 },
    6258: { name: "null", size: 1 },
    6259: { name: "null", size: 1 },
    6260: { name: "null", size: 5 },
    6261: { name: "null", size: 5 },
    6262: { name: "null", size: 1 },
    6263: { name: "null", size: 1 },
    6264: { name: "null", size: 1 },
    6265: { name: "null", size: 1 },
    6266: { name: "null", size: 1 },
    6267: { name: "Jungle spider", size: 1 },
    6268: { name: "null", size: 1 },
    6269: { name: "null", size: 1 },
    6270: { name: "null", size: 1 },
    6271: { name: "Jungle spider", size: 1 },
    6272: { name: "Large mosquito", size: 1 },
    6273: { name: "Mosquito swarm", size: 1 },
    6274: { name: "null", size: 3 },
    6275: { name: "null", size: 1 },
    6276: { name: "null", size: 1 },
    6277: { name: "null", size: 1 },
    6278: { name: "null", size: 1 },
    6279: { name: "null", size: 1 },
    6280: { name: "null", size: 1 },
    6281: { name: "null", size: 1 },
    6282: { name: "null", size: 1 },
    6283: { name: "null", size: 1 },
    6284: { name: "null", size: 1 },
    6285: { name: "null", size: 1 },
    6286: { name: "null", size: 1 },
    6287: { name: "null", size: 1 },
    6288: { name: "null", size: 1 },
    6289: { name: "null", size: 1 },
    6290: { name: "null", size: 1 },
    6291: { name: "Tanglefoot (hard)", size: 1 },
    6292: { name: "Chronozon (hard)", size: 3 },
    6293: { name: "Bouncer (hard)", size: 2 },
    6294: { name: "Ice Troll King (hard)", size: 2 },
    6295: { name: "Black demon (hard)", size: 3 },
    6296: { name: "Bloodhound", size: 1 },
    6297: { name: "Glod (hard)", size: 4 },
    6298: { name: "Treus Dayth (hard)", size: 1 },
    6299: { name: "Black Knight Titan (hard)", size: 1 },
    6300: { name: "Dagannoth mother (hard)", size: 3 },
    6301: { name: "Dagannoth mother (hard)", size: 3 },
    6302: { name: "Dagannoth mother (hard)", size: 3 },
    6303: { name: "Dagannoth mother (hard)", size: 3 },
    6304: { name: "Dagannoth mother (hard)", size: 3 },
    6305: { name: "Dagannoth mother (hard)", size: 3 },
    6306: { name: "Evil Chicken (hard)", size: 1 },
    6307: { name: "Culinaromancer (hard)", size: 1 },
    6308: { name: "Agrith-Na-Na (hard)", size: 3 },
    6309: { name: "Flambeed (hard)", size: 5 },
    6310: { name: "Karamel (hard)", size: 1 },
    6311: { name: "Dessourt (hard)", size: 1 },
    6312: { name: "Gelatinnoth Mother (hard)", size: 3 },
    6313: { name: "Gelatinnoth Mother (hard)", size: 3 },
    6314: { name: "Gelatinnoth Mother (hard)", size: 3 },
    6315: { name: "Gelatinnoth Mother (hard)", size: 3 },
    6316: { name: "Gelatinnoth Mother (hard)", size: 3 },
    6317: { name: "Gelatinnoth Mother (hard)", size: 3 },
    6318: { name: "Nezikchened (hard)", size: 3 },
    6319: { name: "Tree spirit (hard)", size: 1 },
    6320: { name: "Me (hard)", size: 1 },
    6321: { name: "Jungle Demon (hard)", size: 3 },
    6322: { name: "The Kendal (hard)", size: 2 },
    6323: { name: "Giant Roc (hard)", size: 5 },
    6324: { name: "Slagilith (hard)", size: 2 },
    6325: { name: "Moss Guardian (hard)", size: 2 },
    6326: { name: "Skeleton Hellhound (hard)", size: 2 },
    6327: { name: "Agrith Naar (hard)", size: 3 },
    6328: { name: "King Roald (hard)", size: 1 },
    6329: { name: "Khazard warlord (hard)", size: 1 },
    6330: { name: "Dad (hard)", size: 3 },
    6331: { name: "Arrg (hard)", size: 2 },
    6332: { name: "Count Draynor (hard)", size: 1 },
    6333: { name: "Witch's experiment (hard)", size: 1 },
    6334: { name: "Witch's experiment (second form) (hard)", size: 1 },
    6335: { name: "Witch's experiment (third form) (hard)", size: 2 },
    6336: { name: "Witch's experiment (fourth form) (hard)", size: 2 },
    6337: { name: "Nazastarool (hard)", size: 2 },
    6338: { name: "Nazastarool (hard)", size: 2 },
    6339: { name: "Nazastarool (hard)", size: 2 },
    6340: { name: "Cow (hard)", size: 2 },
    6341: { name: "<col=ff9040>Zapper", size: 1 },
    6342: { name: "Barrelchest", size: 3 },
    6343: { name: "Giant Scarab", size: 3 },
    6344: { name: "Dessous", size: 1 },
    6345: { name: "Kamil", size: 1 },
    6346: { name: "Damis", size: 1 },
    6347: { name: "Damis", size: 1 },
    6348: { name: "Fareed", size: 1 },
    6349: { name: "Elvarg", size: 4 },
    6350: { name: "The Inadequacy", size: 5 },
    6351: { name: "The Everlasting", size: 3 },
    6352: { name: "The Untouchable", size: 2 },
    6353: { name: "Tanglefoot", size: 1 },
    6354: { name: "Chronozon", size: 3 },
    6355: { name: "Bouncer", size: 2 },
    6356: { name: "Ice Troll King", size: 2 },
    6357: { name: "Black demon", size: 3 },
    6358: { name: "Glod", size: 4 },
    6359: { name: "Treus Dayth", size: 1 },
    6360: { name: "Black Knight Titan", size: 1 },
    6361: { name: "Dagannoth mother", size: 3 },
    6362: { name: "Dagannoth mother", size: 3 },
    6363: { name: "Dagannoth mother", size: 3 },
    6364: { name: "Dagannoth mother", size: 3 },
    6365: { name: "Dagannoth mother", size: 3 },
    6366: { name: "Dagannoth mother", size: 3 },
    6367: { name: "Evil Chicken", size: 1 },
    6368: { name: "Culinaromancer", size: 1 },
    6369: { name: "Agrith-Na-Na", size: 3 },
    6370: { name: "Flambeed", size: 5 },
    6371: { name: "Karamel", size: 1 },
    6372: { name: "Dessourt", size: 1 },
    6373: { name: "Gelatinnoth Mother", size: 3 },
    6374: { name: "Gelatinnoth Mother", size: 3 },
    6375: { name: "Gelatinnoth Mother", size: 3 },
    6376: { name: "Gelatinnoth Mother", size: 3 },
    6377: { name: "Gelatinnoth Mother", size: 3 },
    6378: { name: "Gelatinnoth Mother", size: 3 },
    6379: { name: "Nezikchened", size: 3 },
    6380: { name: "Tree spirit", size: 1 },
    6381: { name: "Me", size: 1 },
    6382: { name: "Jungle Demon", size: 3 },
    6383: { name: "The Kendal", size: 2 },
    6384: { name: "Giant Roc", size: 5 },
    6385: { name: "Slagilith", size: 2 },
    6386: { name: "Moss Guardian", size: 2 },
    6387: { name: "Skeleton Hellhound", size: 2 },
    6388: { name: "Agrith Naar", size: 3 },
    6389: { name: "King Roald", size: 1 },
    6390: { name: "Khazard warlord", size: 1 },
    6391: { name: "Dad", size: 3 },
    6392: { name: "Arrg", size: 2 },
    6393: { name: "Count Draynor", size: 1 },
    6394: { name: "Witch's experiment", size: 1 },
    6395: { name: "Witch's experiment (second form)", size: 1 },
    6396: { name: "Witch's experiment (third form)", size: 2 },
    6397: { name: "Witch's experiment (fourth form)", size: 2 },
    6398: { name: "Nazastarool", size: 2 },
    6399: { name: "Nazastarool", size: 2 },
    6400: { name: "Nazastarool", size: 2 },
    6401: { name: "Cow", size: 2 },
    6402: { name: "Mosquito swarm", size: 1 },
    6403: { name: "null", size: 1 },
    6404: { name: "null", size: 1 },
    6405: { name: "null", size: 1 },
    6406: { name: "Tribesman", size: 1 },
    6407: { name: "Tribesman", size: 1 },
    6408: { name: "Broodoo victim", size: 1 },
    6409: { name: "Broodoo victim", size: 1 },
    6410: { name: "Broodoo victim", size: 1 },
    6411: { name: "Broodoo victim", size: 1 },
    6412: { name: "Broodoo victim", size: 1 },
    6413: { name: "Broodoo victim", size: 1 },
    6414: { name: "Sharimika", size: 1 },
    6415: { name: "Sharimika", size: 1 },
    6416: { name: "Mamma Bufetta", size: 1 },
    6417: { name: "Mamma Bufetta", size: 1 },
    6418: { name: "Layleen", size: 1 },
    6419: { name: "Layleen", size: 1 },
    6420: { name: "Karaday", size: 1 },
    6421: { name: "Karaday", size: 1 },
    6422: { name: "Safta Doc", size: 1 },
    6423: { name: "Safta Doc", size: 1 },
    6424: { name: "Gabooty", size: 1 },
    6425: { name: "Gabooty", size: 1 },
    6426: { name: "Fanellaman", size: 1 },
    6427: { name: "Fanellaman", size: 1 },
    6428: { name: "Jagbakoba", size: 1 },
    6429: { name: "Jagbakoba", size: 1 },
    6430: { name: "Murcaily", size: 1 },
    6431: { name: "Murcaily", size: 1 },
    6432: { name: "Rionasta", size: 1 },
    6433: { name: "Rionasta", size: 1 },
    6434: { name: "Cave goblin", size: 1 },
    6435: { name: "Cave goblin", size: 1 },
    6436: { name: "Cave goblin", size: 1 },
    6437: { name: "Cave goblin", size: 1 },
    6438: { name: "Animated steel armour", size: 1 },
    6439: { name: "Amy", size: 1 },
    6440: { name: "Giant skeleton", size: 2 },
    6441: { name: "Skeleton", size: 1 },
    6442: { name: "Skeleton", size: 1 },
    6443: { name: "Skeleton", size: 1 },
    6444: { name: "Skeleton", size: 1 },
    6445: { name: "Skeleton", size: 1 },
    6446: { name: "Skeleton", size: 1 },
    6447: { name: "Skeleton", size: 1 },
    6448: { name: "Skeleton", size: 1 },
    6449: { name: "Zombie", size: 1 },
    6450: { name: "Zombie", size: 1 },
    6451: { name: "Zombie", size: 1 },
    6452: { name: "Zombie", size: 1 },
    6453: { name: "Zombie", size: 1 },
    6454: { name: "Zombie", size: 1 },
    6455: { name: "Zombie", size: 1 },
    6456: { name: "Zombie", size: 1 },
    6457: { name: "Zombie", size: 1 },
    6458: { name: "Zombie", size: 1 },
    6459: { name: "Zombie", size: 1 },
    6460: { name: "Zombie", size: 1 },
    6461: { name: "Zombie", size: 1 },
    6462: { name: "Zombie", size: 1 },
    6463: { name: "Zombie", size: 1 },
    6464: { name: "Zombie", size: 1 },
    6465: { name: "Zombie", size: 1 },
    6466: { name: "Zombie", size: 1 },
    6467: { name: "Skeleton", size: 1 },
    6468: { name: "Skeleton", size: 1 },
    6469: { name: "Possessed pickaxe", size: 1 },
    6470: { name: "Animated spade", size: 1 },
    6471: { name: "Terror dog statue", size: 2 },
    6472: { name: "Terror dog statue", size: 3 },
    6473: { name: "Terror dog", size: 2 },
    6474: { name: "Terror dog", size: 1 },
    6475: { name: "Tarn", size: 1 },
    6476: { name: "Tarn", size: 1 },
    6477: { name: "Mutant tarn", size: 3 },
    6478: { name: "Rufus", size: 1 },
    6479: { name: "<col=00ffff>Eye</col>", size: 1 },
    6480: { name: "<col=00ffff>Eye</col>", size: 1 },
    6481: { name: "Jack Frost", size: 1 },
    6482: { name: "Boulder", size: 2 },
    6483: { name: "Monkey", size: 1 },
    6484: { name: "Gelin", size: 1 },
    6485: { name: "Financial Seer", size: 1 },
    6486: { name: "null", size: 1 },
    6487: { name: "null", size: 1 },
    6488: { name: "Fishing spot", size: 1 },
    6489: { name: "null", size: 1 },
    6492: { name: "Kree'arra", size: 5 },
    6493: { name: "Commander Zilyana", size: 2 },
    6494: { name: "General Graardor", size: 4 },
    6495: { name: "K'ril Tsutsaroth", size: 5 },
    6496: { name: "Dagannoth Supreme", size: 3 },
    6497: { name: "Dagannoth Prime", size: 3 },
    6498: { name: "Dagannoth Rex", size: 3 },
    6499: { name: "Giant Mole", size: 3 },
    6500: { name: "Kalphite Queen", size: 5 },
    6501: { name: "Kalphite Queen", size: 5 },
    6502: { name: "King Black Dragon", size: 5 },
    6503: { name: "Callisto", size: 5 },
    6504: { name: "Venenatis", size: 4 },
    6505: { name: "Chaos Elemental", size: 3 },
    6506: { name: "TzTok-Jad", size: 5 },
    6507: { name: "null", size: 1 },
    6508: { name: "null", size: 1 },
    6509: { name: "Squire", size: 1 },
    6510: { name: "Financial Wizard", size: 1 },
    6511: { name: "null", size: 1 },
    6512: { name: "Magnus Gram", size: 1 },
    6513: { name: "Magnus Gram", size: 1 },
    6514: { name: "Financial Wizard", size: 1 },
    6515: { name: "Financial Wizard", size: 1 },
    6516: { name: "Financial Wizard", size: 1 },
    6517: { name: "Financial Wizard", size: 1 },
    6518: { name: "Financial Wizard", size: 1 },
    6519: { name: "Financial Wizard", size: 1 },
    6520: { name: "null", size: 1 },
    6521: { name: "null", size: 1 },
    6522: { name: "null", size: 1 },
    6523: { name: "null", size: 1 },
    6524: { name: "Barker", size: 1 },
    6525: { name: "Fidelio", size: 1 },
    6526: { name: "Sbott", size: 1 },
    6527: { name: "Roavar", size: 1 },
    6528: { name: "null", size: 1 },
    6529: { name: "Herquin", size: 1 },
    6530: { name: "Rommik", size: 1 },
    6531: { name: "Blurberry", size: 1 },
    6532: { name: "Barman", size: 1 },
    6533: { name: "Romily Weaklax", size: 1 },
    6534: { name: "null", size: 1 },
    6535: { name: "null", size: 1 },
    6536: { name: "null", size: 1 },
    6537: { name: "null", size: 1 },
    6538: { name: "null", size: 1 },
    6539: { name: "null", size: 1 },
    6540: { name: "null", size: 1 },
    6541: { name: "null", size: 1 },
    6542: { name: "null", size: 1 },
    6543: { name: "null", size: 1 },
    6544: { name: "null", size: 1 },
    6545: { name: "null", size: 1 },
    6546: { name: "null", size: 1 },
    6547: { name: "null", size: 1 },
    6548: { name: "null", size: 1 },
    6549: { name: "null", size: 1 },
    6550: { name: "null", size: 1 },
    6551: { name: "null", size: 1 },
    6552: { name: "null", size: 1 },
    6553: { name: "null", size: 1 },
    6554: { name: "null", size: 1 },
    6555: { name: "null", size: 1 },
    6556: { name: "null", size: 1 },
    6557: { name: "null", size: 1 },
    6558: { name: "null", size: 1 },
    6559: { name: "null", size: 1 },
    6560: { name: "null", size: 1 },
    6561: { name: "Guard", size: 1 },
    6562: { name: "Prospector Percy", size: 1 },
    6563: { name: "null", size: 1 },
    6564: { name: "<col=ff9040>Pay-dirt</col>", size: 1 },
    6565: { name: "Miner", size: 1 },
    6566: { name: "Runite Minor", size: 1 },
    6567: { name: "Miner", size: 1 },
    6568: { name: "Miner", size: 1 },
    6569: { name: "Miner", size: 1 },
    6570: { name: "Miner", size: 1 },
    6571: { name: "Miner", size: 1 },
    6572: { name: "Miner", size: 1 },
    6573: { name: "null", size: 1 },
    6574: { name: "Gnome guard", size: 1 },
    6575: { name: "Guard", size: 1 },
    6576: { name: "Guard", size: 1 },
    6577: { name: "null", size: 1 },
    6578: { name: "null", size: 1 },
    6579: { name: "Guard", size: 1 },
    6580: { name: "Guard", size: 1 },
    6581: { name: "Guard", size: 1 },
    6582: { name: "Guard", size: 1 },
    6583: { name: "Guard", size: 1 },
    6584: { name: "Uri", size: 1 },
    6585: { name: "null", size: 1 },
    6586: { name: "Sherlock", size: 1 },
    6587: { name: "Armadylean guard", size: 2 },
    6588: { name: "Bandosian guard", size: 1 },
    6589: { name: "Dr Ford", size: 1 },
    6590: { name: "Sister Scarophia", size: 1 },
    6593: { name: "Lava dragon", size: 4 },
    6594: { name: "Ent", size: 2 },
    6595: { name: "Prifddinas guard", size: 1 },
    6596: { name: "Zombie", size: 1 },
    6597: { name: "Zombie", size: 1 },
    6598: { name: "Zombie", size: 1 },
    6599: { name: "Mandrith", size: 1 },
    6600: { name: "Runite Golem", size: 1 },
    6601: { name: "<col=00ffff>Runite rocks</col>", size: 1 },
    6602: { name: "Numpty", size: 1 },
    6603: { name: "Rogue", size: 1 },
    6604: { name: "Mammoth", size: 3 },
    6605: { name: "Bandit", size: 1 },
    6606: { name: "Dark warrior", size: 1 },
    6607: { name: "Elder Chaos druid", size: 1 },
    6608: { name: "Ankou", size: 1 },
    6609: { name: "Callisto", size: 5 },
    6610: { name: "Venenatis", size: 4 },
    6611: { name: "Vet'ion", size: 3 },
    6612: { name: "Vet'ion", size: 3 },
    6613: { name: "Skeleton Hellhound", size: 2 },
    6614: { name: "Greater Skeleton Hellhound", size: 2 },
    6615: { name: "Scorpia", size: 5 },
    6616: { name: "Scorpia's offspring", size: 1 },
    6617: { name: "Scorpia's guardian", size: 1 },
    6618: { name: "Crazy archaeologist", size: 1 },
    6619: { name: "Chaos Fanatic", size: 1 },
    6620: { name: "Miniature chaotic clouds", size: 1 },
    6621: { name: "<col=00ffff>Boulder</col>", size: 2 },
    6622: { name: "null", size: 2 },
    6623: { name: "null", size: 1 },
    6624: { name: "Energy sprite", size: 1 },
    6625: { name: "Reacher", size: 1 },
    6626: { name: "Dagannoth Supreme Jr.", size: 1 },
    6627: { name: "Dagannoth Prime Jr.", size: 1 },
    6628: { name: "Dagannoth Supreme Jr.", size: 1 },
    6629: { name: "Dagannoth Prime Jr.", size: 1 },
    6630: { name: "Dagannoth Rex Jr.", size: 1 },
    6631: { name: "Kree'arra Jr.", size: 1 },
    6632: { name: "General Graardor Jr.", size: 1 },
    6633: { name: "Zilyana Jr.", size: 1 },
    6634: { name: "K'ril Tsutsaroth Jr.", size: 1 },
    6635: { name: "Baby Mole", size: 1 },
    6636: { name: "Prince Black Dragon", size: 1 },
    6637: { name: "Kalphite Princess", size: 1 },
    6638: { name: "Kalphite Princess", size: 2 },
    6639: { name: "Smoke Devil", size: 1 },
    6640: { name: "Kraken", size: 1 },
    6641: { name: "Dagannoth Rex Jr.", size: 1 },
    6642: { name: "Penance Pet", size: 1 },
    6643: { name: "Kree'arra Jr.", size: 1 },
    6644: { name: "General Graardor Jr.", size: 1 },
    6645: { name: "Miner", size: 1 },
    6646: { name: "Zilyana Jr.", size: 1 },
    6647: { name: "K'ril Tsutsaroth Jr.", size: 1 },
    6648: { name: "Lokar Searunner", size: 1 },
    6649: { name: "Captain Bentley", size: 1 },
    6650: { name: "Captain Bentley", size: 1 },
    6651: { name: "Baby Mole", size: 1 },
    6652: { name: "Prince Black Dragon", size: 1 },
    6653: { name: "Kalphite Princess", size: 1 },
    6654: { name: "Kalphite Princess", size: 2 },
    6655: { name: "Smoke Devil", size: 1 },
    6656: { name: "Kraken", size: 1 },
    6657: { name: "Pet rock", size: 1 },
    6658: { name: "Fishbowl", size: 1 },
    6659: { name: "Fishbowl", size: 1 },
    6660: { name: "Fishbowl", size: 1 },
    6661: { name: "Clockwork cat", size: 1 },
    6662: { name: "Cat", size: 1 },
    6663: { name: "Cat", size: 1 },
    6664: { name: "Cat", size: 1 },
    6665: { name: "Cat", size: 1 },
    6666: { name: "Cat", size: 1 },
    6667: { name: "Cat", size: 1 },
    6668: { name: "Hellcat", size: 1 },
    6669: { name: "John", size: 1 },
    6670: { name: "Reacher", size: 1 },
    6671: { name: "John", size: 1 },
    6672: { name: "John", size: 1 },
    6673: { name: "Reacher", size: 1 },
    6674: { name: "Penance Pet", size: 1 },
    6675: { name: "Waydar", size: 1 },
    6676: { name: "Overgrown cat", size: 1 },
    6677: { name: "Overgrown cat", size: 1 },
    6678: { name: "Overgrown cat", size: 1 },
    6679: { name: "Overgrown cat", size: 1 },
    6680: { name: "Overgrown cat", size: 1 },
    6681: { name: "Overgrown cat", size: 1 },
    6682: { name: "Overgrown hellcat", size: 1 },
    6683: { name: "Lazy cat", size: 1 },
    6684: { name: "Lazy cat", size: 1 },
    6685: { name: "Lazy cat", size: 1 },
    6686: { name: "Lazy cat", size: 1 },
    6687: { name: "Lazy cat", size: 1 },
    6688: { name: "Lazy cat", size: 1 },
    6689: { name: "Lazy hellcat", size: 1 },
    6690: { name: "Wily cat", size: 1 },
    6691: { name: "Wily cat", size: 1 },
    6692: { name: "Wily cat", size: 1 },
    6693: { name: "Wily cat", size: 1 },
    6694: { name: "Wily cat", size: 1 },
    6695: { name: "Wily cat", size: 1 },
    6696: { name: "Wily hellcat", size: 1 },
    6698: { name: "Ghost guard", size: 1 },
    6699: { name: "Guard", size: 1 },
    6700: { name: "Guard", size: 1 },
    6701: { name: "Guard", size: 1 },
    6702: { name: "Guard", size: 1 },
    6703: { name: "Financial Wizard", size: 1 },
    6704: { name: "null", size: 1 },
    6705: { name: "null", size: 1 },
    6706: { name: "null", size: 1 },
    6707: { name: "null", size: 1 },
    6708: { name: "null", size: 1 },
    6709: { name: "null", size: 1 },
    6710: { name: "null", size: 1 },
    6711: { name: "null", size: 1 },
    6712: { name: "null", size: 1 },
    6713: { name: "null", size: 1 },
    6714: { name: "null", size: 1 },
    6715: { name: "Heron", size: 1 },
    6716: { name: "Chaotic death spawn", size: 1 },
    6717: { name: "null", size: 1 },
    6718: { name: "Baby Chinchompa", size: 1 },
    6719: { name: "Baby Chinchompa", size: 1 },
    6720: { name: "Baby Chinchompa", size: 1 },
    6721: { name: "Baby Chinchompa", size: 1 },
    6722: { name: "Heron", size: 1 },
    6723: { name: "Chaotic death spawn", size: 1 },
    6724: { name: "Friendly Forester", size: 1 },
    6725: { name: "Rock Golem", size: 1 },
    6726: { name: "Rock Golem", size: 1 },
    6727: { name: "Rock Golem", size: 1 },
    6728: { name: "Rock Golem", size: 1 },
    6729: { name: "Rock Golem", size: 1 },
    6730: { name: "Rock Golem", size: 1 },
    6731: { name: "Fishing spot", size: 1 },
    6732: { name: "River troll", size: 1 },
    6733: { name: "River troll", size: 1 },
    6734: { name: "River troll", size: 1 },
    6735: { name: "River troll", size: 1 },
    6736: { name: "River troll", size: 1 },
    6737: { name: "River troll", size: 1 },
    6738: { name: "Postie Pete", size: 1 },
    6739: { name: "Evil Chicken", size: 1 },
    6740: { name: "Shade", size: 1 },
    6741: { name: "Zombie", size: 1 },
    6742: { name: "Mysterious Old Man", size: 1 },
    6743: { name: "Sergeant Damien", size: 1 },
    6744: { name: "Flippa", size: 1 },
    6745: { name: "Leo", size: 1 },
    6746: { name: "Leo", size: 1 },
    6747: { name: "Bee keeper", size: 1 },
    6748: { name: "Freaky Forester", size: 1 },
    6749: { name: "Dunce", size: 1 },
    6750: { name: "Mysterious Old Man", size: 1 },
    6751: { name: "Mysterious Old Man", size: 1 },
    6752: { name: "Mysterious Old Man", size: 1 },
    6753: { name: "Mysterious Old Man", size: 1 },
    6754: { name: "Evil Bob", size: 1 },
    6755: { name: "Quiz Master", size: 1 },
    6756: { name: "Baby Chinchompa", size: 1 },
    6757: { name: "Baby Chinchompa", size: 1 },
    6758: { name: "Baby Chinchompa", size: 1 },
    6759: { name: "Baby Chinchompa", size: 1 },
    6760: { name: "null", size: 1 },
    6761: { name: "null", size: 1 },
    6762: { name: "Pyrelord", size: 2 },
    6763: { name: "null", size: 1 },
    6764: { name: "null", size: 1 },
    6765: { name: "null", size: 1 },
    6766: { name: "Lizardman shaman", size: 3 },
    6767: { name: "Lizardman shaman", size: 3 },
    6768: { name: "Spawn", size: 1 },
    6769: { name: "Osten", size: 1 },
    6770: { name: "null", size: 1 },
    6771: { name: "Drew", size: 1 },
    6772: { name: "null", size: 1 },
    6773: { name: "Doomsayer", size: 1 },
    6774: { name: "Doomsayer", size: 1 },
    6775: { name: "Gallow", size: 1 },
    6776: { name: "Man", size: 1 },
    6777: { name: "<col=ff9040>Maze Guardian</col>", size: 1 },
    6778: { name: "null", size: 1 },
    6779: { name: "Maze Guardian", size: 1 },
    6780: { name: "null", size: 1 },
    6781: { name: "null", size: 1 },
    6782: { name: "null", size: 1 },
    6783: { name: "null", size: 1 },
    6784: { name: "Fishing spot", size: 1 },
    6785: { name: "null", size: 1 },
    6786: { name: "null", size: 1 },
    6787: { name: "null", size: 1 },
    6788: { name: "null", size: 1 },
    6789: { name: "null", size: 1 },
    6790: { name: "null", size: 1 },
    6791: { name: "null", size: 1 },
    6793: { name: "Hell-Rat Behemoth", size: 2 },
    6794: { name: "Monkey Archer", size: 1 },
    6795: { name: "Pyrelord", size: 2 },
    6796: { name: "null", size: 1 },
    6797: { name: "Nieve", size: 1 },
    6798: { name: "Steve", size: 1 },
    6799: { name: "Steve", size: 1 },
    6801: { name: "Pieve", size: 1 },
    6802: { name: "Combat Test", size: 1 },
    6803: { name: "Maniacal monkey", size: 1 },
    6804: { name: "Kruk", size: 1 },
    6805: { name: "Kruk", size: 1 },
    6806: { name: "Assistant Le Smith", size: 1 },
    6807: { name: "null", size: 1 },
    6808: { name: "null", size: 1 },
    6809: { name: "null", size: 1 },
    6810: { name: "null", size: 1 },
    6811: { name: "Monkey guard", size: 1 },
    6812: { name: "Awowogei", size: 1 },
    6813: { name: "Monkey Archer", size: 1 },
    6814: { name: "Lammy Langle", size: 1 },
    6815: { name: "Man", size: 1 },
    6816: { name: "Gee", size: 1 },
    6817: { name: "Great blue heron", size: 1 },
    6818: { name: "Man", size: 1 },
    6819: { name: "null", size: 1 },
    6820: { name: "null", size: 1 },
    6821: { name: "null", size: 1 },
    6822: { name: "null", size: 1 },
    6823: { name: "Town Crier", size: 1 },
    6824: { name: "Giant bat", size: 2 },
    6825: { name: "Rod Fishing spot", size: 1 },
    6826: { name: "Wounded soldier", size: 2 },
    6827: { name: "Wounded soldier", size: 2 },
    6828: { name: "Wounded soldier", size: 2 },
    6829: { name: "Wounded soldier", size: 2 },
    6830: { name: "Wounded soldier", size: 2 },
    6831: { name: "Wounded soldier", size: 2 },
    6832: { name: "Wounded soldier", size: 2 },
    6833: { name: "Wounded soldier", size: 2 },
    6834: { name: "Wounded soldier", size: 2 },
    6835: { name: "Wounded soldier", size: 2 },
    6836: { name: "Wounded soldier", size: 2 },
    6837: { name: "Wounded soldier", size: 2 },
    6838: { name: "Wounded soldier", size: 2 },
    6839: { name: "Wounded soldier", size: 2 },
    6840: { name: "Wounded soldier", size: 2 },
    6841: { name: "Wounded soldier", size: 2 },
    6842: { name: "Wounded soldier", size: 2 },
    6843: { name: "Wounded soldier", size: 2 },
    6844: { name: "Wounded soldier", size: 2 },
    6845: { name: "Wounded soldier", size: 2 },
    6846: { name: "Wounded soldier", size: 2 },
    6847: { name: "Wounded soldier", size: 2 },
    6848: { name: "Wounded soldier", size: 2 },
    6849: { name: "Wounded soldier", size: 2 },
    6850: { name: "Wounded soldier", size: 2 },
    6851: { name: "Wounded soldier", size: 2 },
    6852: { name: "Wounded soldier", size: 2 },
    6853: { name: "Wounded soldier", size: 2 },
    6854: { name: "Wounded soldier", size: 2 },
    6855: { name: "Wounded soldier", size: 2 },
    6856: { name: "Wounded soldier", size: 2 },
    6857: { name: "Wounded soldier", size: 2 },
    6858: { name: "Look-out", size: 1 },
    6859: { name: "Banker", size: 1 },
    6860: { name: "Banker", size: 1 },
    6861: { name: "Banker", size: 1 },
    6862: { name: "Banker", size: 1 },
    6863: { name: "Banker", size: 1 },
    6864: { name: "Banker", size: 1 },
    6865: { name: "General Salara", size: 1 },
    6866: { name: "General Babacus", size: 1 },
    6867: { name: "General Kilian", size: 1 },
    6868: { name: "Soldier", size: 1 },
    6869: { name: "Soldier", size: 1 },
    6870: { name: "Soldier", size: 1 },
    6871: { name: "Soldier", size: 1 },
    6872: { name: "New Recruit Tony", size: 1 },
    6873: { name: "Nurse Wooned", size: 1 },
    6874: { name: "Nurse Inn'Juree", size: 1 },
    6875: { name: "Nurse Boubou", size: 1 },
    6876: { name: "Captain Rachelle", size: 1 },
    6877: { name: "Soldier", size: 2 },
    6878: { name: "Soldier", size: 1 },
    6879: { name: "Soldier", size: 1 },
    6880: { name: "Soldier", size: 1 },
    6881: { name: "Ranger", size: 1 },
    6882: { name: "Drill Sergeant", size: 1 },
    6883: { name: "Soldier", size: 1 },
    6884: { name: "Soldier", size: 1 },
    6885: { name: "Soldier", size: 1 },
    6886: { name: "Soldier", size: 1 },
    6887: { name: "Soldier", size: 1 },
    6888: { name: "Soldier", size: 1 },
    6889: { name: "Soldier", size: 1 },
    6890: { name: "Soldier", size: 1 },
    6891: { name: "Soldier", size: 1 },
    6892: { name: "Soldier", size: 1 },
    6893: { name: "Soldier", size: 1 },
    6894: { name: "Soldier", size: 1 },
    6895: { name: "Captain Ginea", size: 1 },
    6896: { name: "Gangster", size: 1 },
    6897: { name: "Gangster", size: 1 },
    6898: { name: "Gangster", size: 1 },
    6899: { name: "Gangster", size: 1 },
    6900: { name: "Gang boss", size: 1 },
    6901: { name: "Gang boss", size: 1 },
    6902: { name: "Gang boss", size: 1 },
    6903: { name: "Gang boss", size: 1 },
    6904: { name: "Soldier (tier 1)", size: 1 },
    6905: { name: "Soldier (tier 1)", size: 1 },
    6906: { name: "Soldier (tier 2)", size: 1 },
    6907: { name: "Soldier (tier 2)", size: 1 },
    6908: { name: "Soldier (tier 3)", size: 1 },
    6909: { name: "Soldier (tier 3)", size: 1 },
    6910: { name: "Soldier (tier 4)", size: 1 },
    6911: { name: "Soldier (tier 4)", size: 1 },
    6912: { name: "Soldier (tier 5)", size: 1 },
    6913: { name: "Soldier (tier 5)", size: 1 },
    6914: { name: "Lizardman", size: 1 },
    6915: { name: "Lizardman", size: 1 },
    6916: { name: "Lizardman", size: 1 },
    6917: { name: "Lizardman", size: 1 },
    6918: { name: "Lizardman brute", size: 1 },
    6919: { name: "Lizardman brute", size: 1 },
    6920: { name: "Farmer Gricoller", size: 1 },
    6921: { name: "Marisi", size: 1 },
    6922: { name: "Konoo", size: 1 },
    6923: { name: "null", size: 1 },
    6924: { name: "null", size: 1 },
    6925: { name: "null", size: 1 },
    6926: { name: "Ewesey", size: 1 },
    6927: { name: "Servery assistant", size: 1 },
    6928: { name: "Servery assistant", size: 1 },
    6929: { name: "Soldier", size: 1 },
    6930: { name: "Soldier", size: 1 },
    6931: { name: "Soldier", size: 1 },
    6932: { name: "Soldier", size: 1 },
    6933: { name: "Soldier", size: 1 },
    6934: { name: "Soldier", size: 1 },
    6935: { name: "Soldier", size: 1 },
    6936: { name: "Soldier", size: 1 },
    6937: { name: "Ramocean", size: 1 },
    6938: { name: "Talia", size: 1 },
    6939: { name: "Banker", size: 1 },
    6940: { name: "Banker", size: 1 },
    6941: { name: "Banker", size: 1 },
    6942: { name: "Banker", size: 1 },
    6943: { name: "Horace", size: 1 },
    6944: { name: "Vannah", size: 1 },
    6945: { name: "Logava", size: 1 },
    6946: { name: "null", size: 1 },
    6947: { name: "Farmer", size: 1 },
    6948: { name: "Farmer", size: 1 },
    6949: { name: "Farmer", size: 1 },
    6950: { name: "Farmer", size: 1 },
    6951: { name: "Farmer", size: 1 },
    6952: { name: "Farmer", size: 1 },
    6953: { name: "Golova", size: 1 },
    6954: { name: "Richard", size: 1 },
    6955: { name: "Father Jean", size: 1 },
    6956: { name: "Monk", size: 1 },
    6957: { name: "Dale", size: 1 },
    6958: { name: "null", size: 1 },
    6959: { name: "Farmer", size: 1 },
    6960: { name: "Farmer", size: 1 },
    6961: { name: "Farmer", size: 1 },
    6962: { name: "Farmer Hayfield", size: 1 },
    6963: { name: "Frankie", size: 1 },
    6964: { name: "Tynan", size: 1 },
    6965: { name: "Nicholas", size: 1 },
    6966: { name: "Dockmaster", size: 1 },
    6967: { name: "Dock worker", size: 1 },
    6968: { name: "Dock worker", size: 1 },
    6969: { name: "Banker", size: 1 },
    6970: { name: "Banker", size: 1 },
    6971: { name: "Captain Khaled", size: 1 },
    6972: { name: "Captain Khaled", size: 1 },
    6973: { name: "Patrolman", size: 1 },
    6974: { name: "Patrolman", size: 1 },
    6975: { name: "Patrolman", size: 1 },
    6976: { name: "Patrolwoman", size: 1 },
    6977: { name: "Patrolman", size: 1 },
    6978: { name: "Patrolwoman", size: 1 },
    6979: { name: "Patrolman", size: 1 },
    6980: { name: "Patrolman", size: 1 },
    6981: { name: "Fisherman", size: 1 },
    6982: { name: "Poor looking man", size: 1 },
    6983: { name: "Poor looking man", size: 1 },
    6984: { name: "Poor looking woman", size: 1 },
    6985: { name: "Poor looking woman", size: 1 },
    6986: { name: "Leenz", size: 1 },
    6987: { name: "Man", size: 1 },
    6988: { name: "Man", size: 1 },
    6989: { name: "Man", size: 1 },
    6990: { name: "Woman", size: 1 },
    6991: { name: "Woman", size: 1 },
    6992: { name: "Woman", size: 1 },
    6993: { name: "Pirate", size: 1 },
    6994: { name: "Pirate", size: 1 },
    6995: { name: "Pirate", size: 1 },
    6996: { name: "Mugger", size: 1 },
    6997: { name: "Dark wizard", size: 1 },
    6998: { name: "Pirate", size: 1 },
    6999: { name: "Port Official", size: 1 },
    7000: { name: "Captain Janaway", size: 1 },
    7001: { name: "Port worker", size: 1 },
    7002: { name: "Mark", size: 1 },
    7003: { name: "Cheryl", size: 1 },
    7004: { name: "Charles", size: 1 },
    7005: { name: "Sarah", size: 1 },
    7006: { name: "Darren", size: 1 },
    7007: { name: "Melvin", size: 1 },
    7008: { name: "Simon", size: 1 },
    7009: { name: "Andrea", size: 1 },
    7010: { name: "Elizabeth", size: 1 },
    7011: { name: "Lorraine", size: 1 },
    7012: { name: "Ross and Ben", size: 1 },
    7013: { name: "Dobwinkle", size: 1 },
    7014: { name: "Alexander", size: 1 },
    7015: { name: "Charlie Brown", size: 1 },
    7016: { name: "null", size: 1 },
    7017: { name: "null", size: 1 },
    7018: { name: "Reanimated goblin", size: 1 },
    7019: { name: "Reanimated monkey", size: 1 },
    7020: { name: "Reanimated imp", size: 1 },
    7021: { name: "Reanimated minotaur", size: 1 },
    7022: { name: "Reanimated scorpion", size: 2 },
    7023: { name: "Reanimated bear", size: 2 },
    7024: { name: "Reanimated unicorn", size: 2 },
    7025: { name: "Reanimated dog", size: 1 },
    7026: { name: "Reanimated chaos druid", size: 1 },
    7027: { name: "Reanimated giant", size: 2 },
    7028: { name: "Reanimated ogre", size: 2 },
    7029: { name: "Reanimated elf", size: 1 },
    7030: { name: "Reanimated troll", size: 1 },
    7031: { name: "Reanimated horror", size: 2 },
    7032: { name: "Reanimated kalphite", size: 4 },
    7033: { name: "Reanimated dagannoth", size: 1 },
    7034: { name: "Reanimated bloodveld", size: 2 },
    7035: { name: "Reanimated TzHaar", size: 1 },
    7036: { name: "Reanimated demon", size: 2 },
    7037: { name: "Reanimated aviansie", size: 2 },
    7038: { name: "Reanimated abyssal", size: 1 },
    7039: { name: "Reanimated dragon", size: 5 },
    7040: { name: "Clerris", size: 1 },
    7041: { name: "Okina", size: 1 },
    7042: { name: "Aretha", size: 1 },
    7043: { name: "Sister Soul Jar", size: 1 },
    7044: { name: "Logosia", size: 1 },
    7045: { name: "Biblia", size: 1 },
    7046: { name: "Horphis", size: 1 },
    7047: { name: "Villia", size: 1 },
    7048: { name: "Professor Gracklebone", size: 1 },
    7049: { name: "Sam", size: 1 },
    7050: { name: "Tyss", size: 1 },
    7051: { name: "Trossa", size: 1 },
    7052: { name: "Tower Mage", size: 1 },
    7053: { name: "Rassain", size: 1 },
    7054: { name: "Mofina", size: 1 },
    7055: { name: "Novice", size: 1 },
    7056: { name: "Regath", size: 1 },
    7057: { name: "Banker", size: 1 },
    7058: { name: "Banker", size: 1 },
    7059: { name: "Banker", size: 1 },
    7060: { name: "Banker", size: 1 },
    7061: { name: "Batt Mellamy", size: 2 },
    7062: { name: "Freald", size: 1 },
    7063: { name: "Cob", size: 1 },
    7064: { name: "Dark wizard", size: 1 },
    7065: { name: "Dark wizard", size: 1 },
    7066: { name: "Wizard", size: 1 },
    7067: { name: "Wizard", size: 1 },
    7068: { name: "Ouditor", size: 1 },
    7069: { name: "Smoggy", size: 1 },
    7070: { name: "null", size: 2 },
    7071: { name: "Toothy", size: 1 },
    7072: { name: "Operator", size: 1 },
    7073: { name: "Operator", size: 1 },
    7074: { name: "<col=ff9040>Blasted ore</col>", size: 1 },
    7075: { name: "Mine supervisor", size: 1 },
    7076: { name: "Mine supervisor", size: 1 },
    7077: { name: "Banker", size: 1 },
    7078: { name: "Banker", size: 1 },
    7079: { name: "Banker", size: 1 },
    7080: { name: "Banker", size: 1 },
    7081: { name: "Banker", size: 1 },
    7082: { name: "Banker", size: 1 },
    7083: { name: "null", size: 1 },
    7084: { name: "null", size: 1 },
    7085: { name: "null", size: 1 },
    7086: { name: "null", size: 1 },
    7087: { name: "null", size: 1 },
    7088: { name: "Munty", size: 1 },
    7089: { name: "Moggy", size: 1 },
    7090: { name: "Fuggy", size: 1 },
    7091: { name: "Miner", size: 1 },
    7092: { name: "Miner", size: 1 },
    7093: { name: "Miner", size: 1 },
    7094: { name: "Miner", size: 1 },
    7095: { name: "Tortured gorilla", size: 2 },
    7096: { name: "Tortured gorilla", size: 2 },
    7097: { name: "Tortured gorilla", size: 2 },
    7098: { name: "Stunted demonic gorilla", size: 1 },
    7099: { name: "Kruk", size: 1 },
    7100: { name: "Glough", size: 2 },
    7101: { name: "Glough", size: 2 },
    7102: { name: "Glough", size: 2 },
    7103: { name: "Glough", size: 2 },
    7104: { name: "Keef", size: 2 },
    7105: { name: "Keef", size: 2 },
    7106: { name: "Kob", size: 2 },
    7107: { name: "Kob", size: 2 },
    7108: { name: "Nieve", size: 1 },
    7109: { name: "Nieve", size: 1 },
    7110: { name: "Nieve", size: 1 },
    7111: { name: "Garkor", size: 1 },
    7112: { name: "Lumo", size: 1 },
    7113: { name: "Zooknock", size: 1 },
    7114: { name: "Carado", size: 1 },
    7115: { name: "Glough", size: 1 },
    7116: { name: "Looming shadow", size: 8 },
    7117: { name: "Kineer", size: 1 },
    7118: { name: "Maniacal monkey", size: 1 },
    7119: { name: "Maniacal Monkey Archer", size: 1 },
    7120: { name: "Oobapohk", size: 1 },
    7121: { name: "Jumaane", size: 1 },
    7122: { name: "Monkey guard", size: 1 },
    7123: { name: "Monkey guard", size: 1 },
    7124: { name: "Monkey guard", size: 1 },
    7125: { name: "Monkey guard", size: 1 },
    7126: { name: "Monkey guard", size: 1 },
    7127: { name: "Monkey guard", size: 1 },
    7128: { name: "Monkey guard", size: 1 },
    7129: { name: "Monkey guard", size: 1 },
    7130: { name: "Monkey guard", size: 1 },
    7131: { name: "Monkey guard", size: 1 },
    7132: { name: "Monkey guard", size: 1 },
    7133: { name: "Monkey guard", size: 1 },
    7134: { name: "Monkey guard", size: 1 },
    7135: { name: "Monkey guard", size: 1 },
    7136: { name: "Monkey guard", size: 1 },
    7137: { name: "Monkey guard", size: 1 },
    7138: { name: "Monkey guard", size: 1 },
    7139: { name: "Monkey guard", size: 1 },
    7140: { name: "Monkey guard", size: 1 },
    7141: { name: "Monkey guard", size: 1 },
    7142: { name: "Monkey guard", size: 1 },
    7143: { name: "Monkey guard", size: 1 },
    7144: { name: "Demonic gorilla", size: 2 },
    7145: { name: "Demonic gorilla", size: 2 },
    7146: { name: "Demonic gorilla", size: 2 },
    7147: { name: "Demonic gorilla", size: 2 },
    7148: { name: "Demonic gorilla", size: 2 },
    7149: { name: "Demonic gorilla", size: 2 },
    7150: { name: "Tortured gorilla", size: 2 },
    7151: { name: "Tortured gorilla", size: 2 },
    7152: { name: "Demonic gorilla", size: 2 },
    7153: { name: "Tortured gorilla", size: 2 },
    7154: { name: "Assistant Lori", size: 1 },
    7155: { name: "Fishing spot", size: 1 },
    7156: { name: "Anita", size: 1 },
    7157: { name: "Anita", size: 1 },
    7158: { name: "Garkor", size: 1 },
    7159: { name: "Garkor", size: 1 },
    7160: { name: "Lumo", size: 1 },
    7161: { name: "Lumo", size: 1 },
    7162: { name: "Bunkdo", size: 1 },
    7163: { name: "Bunkdo", size: 1 },
    7164: { name: "Carado", size: 1 },
    7165: { name: "Carado", size: 1 },
    7166: { name: "Bunkwicket", size: 1 },
    7167: { name: "Bunkwicket", size: 1 },
    7168: { name: "Waymottin", size: 1 },
    7169: { name: "Waymottin", size: 1 },
    7170: { name: "Zooknock", size: 1 },
    7171: { name: "Zooknock", size: 1 },
    7172: { name: "Karam", size: 1 },
    7173: { name: "Karam", size: 1 },
    7174: { name: "Karam", size: 1 },
    7175: { name: "null", size: 1 },
    7176: { name: "Duke", size: 1 },
    7177: { name: "null", size: 1 },
    7178: { name: "Captain Shoracks", size: 1 },
    7179: { name: "null", size: 1 },
    7180: { name: "null", size: 1 },
    7181: { name: "null", size: 1 },
    7182: { name: "null", size: 1 },
    7183: { name: "null", size: 1 },
    7184: { name: "null", size: 1 },
    7185: { name: "null", size: 1 },
    7186: { name: "null", size: 1 },
    7187: { name: "null", size: 1 },
    7188: { name: "null", size: 1 },
    7189: { name: "null", size: 1 },
    7190: { name: "null", size: 1 },
    7191: { name: "null", size: 1 },
    7192: { name: "null", size: 1 },
    7193: { name: "null", size: 1 },
    7194: { name: "null", size: 1 },
    7195: { name: "null", size: 1 },
    7196: { name: "null", size: 1 },
    7197: { name: "null", size: 1 },
    7198: { name: "null", size: 1 },
    7199: { name: "Fishing spot", size: 1 },
    7200: { name: "Fishing spot", size: 1 },
    7201: { name: "Briget", size: 1 },
    7202: { name: "Kenelme", size: 1 },
    7203: { name: "Thyria", size: 1 },
    7204: { name: "Filamina", size: 1 },
    7205: { name: "Jarvald", size: 1 },
    7206: { name: "Sand Crab", size: 1 },
    7207: { name: "Sandy rocks", size: 1 },
    7208: { name: "null", size: 1 },
    7209: { name: "Guard dog", size: 1 },
    7210: { name: "null", size: 1 },
    7211: { name: "null", size: 1 },
    7212: { name: "Maniacal monkey", size: 1 },
    7213: { name: "Maniacal monkey", size: 1 },
    7214: { name: "Maniacal monkey", size: 1 },
    7215: { name: "Maniacal monkey", size: 1 },
    7216: { name: "Maniacal monkey", size: 1 },
    7217: { name: "null", size: 1 },
    7218: { name: "null", size: 1 },
    7219: { name: "Scurry", size: 1 },
    7220: { name: "null", size: 1 },
    7221: { name: "Scurrius", size: 3 },
    7222: { name: "Scurrius", size: 3 },
    7223: { name: "Giant rat", size: 2 },
    7225: { name: "null", size: 1 },
    7226: { name: "Historian Aldo", size: 1 },
    7227: { name: "Miriam", size: 1 },
    7228: { name: "Traxi", size: 1 },
    7229: { name: "Raeli", size: 1 },
    7230: { name: "Mogrim", size: 1 },
    7231: { name: "Loinur", size: 1 },
    7232: { name: "Bloodhound", size: 1 },
    7233: { name: "Lucky impling", size: 1 },
    7234: { name: "Ent", size: 2 },
    7235: { name: "Berry", size: 1 },
    7236: { name: "Guildmaster Lars", size: 1 },
    7237: { name: "Murfet", size: 1 },
    7238: { name: "Forester", size: 1 },
    7239: { name: "Kai", size: 1 },
    7240: { name: "Perry", size: 1 },
    7241: { name: "Abyssal demon", size: 1 },
    7242: { name: "Black demon", size: 3 },
    7243: { name: "Black demon", size: 3 },
    7244: { name: "Greater demon", size: 3 },
    7245: { name: "Greater demon", size: 3 },
    7246: { name: "Greater demon", size: 3 },
    7247: { name: "Lesser demon", size: 2 },
    7248: { name: "Lesser demon", size: 2 },
    7249: { name: "Dust devil", size: 1 },
    7250: { name: "Dark beast", size: 3 },
    7251: { name: "Fire giant", size: 2 },
    7252: { name: "Fire giant", size: 2 },
    7253: { name: "Bronze dragon", size: 4 },
    7254: { name: "Iron dragon", size: 4 },
    7255: { name: "Steel dragon", size: 4 },
    7256: { name: "Hellhound", size: 2 },
    7257: { name: "Ankou", size: 1 },
    7258: { name: "Shade", size: 1 },
    7259: { name: "Dagannoth", size: 1 },
    7260: { name: "Dagannoth", size: 2 },
    7261: { name: "Hill Giant", size: 2 },
    7262: { name: "Moss giant", size: 2 },
    7263: { name: "Ghost", size: 1 },
    7264: { name: "Ghost", size: 1 },
    7265: { name: "Skeleton", size: 1 },
    7266: { name: "King Sand Crab", size: 2 },
    7267: { name: "Sandy Boulder", size: 2 },
    7268: { name: "Possessed pickaxe", size: 1 },
    7269: { name: "Magic axe", size: 1 },
    7270: { name: "Cyclops", size: 2 },
    7271: { name: "Cyclops", size: 2 },
    7272: { name: "Twisted Banshee", size: 2 },
    7273: { name: "Brutal blue dragon", size: 4 },
    7274: { name: "Brutal red dragon", size: 4 },
    7275: { name: "Brutal black dragon", size: 4 },
    7276: { name: "Mutated Bloodveld", size: 2 },
    7277: { name: "Warped Jelly", size: 1 },
    7278: { name: "Greater Nechryael", size: 1 },
    7279: { name: "Deviant spectre", size: 2 },
    7280: { name: "Kineer", size: 1 },
    7281: { name: "Man", size: 1 },
    7282: { name: "Pirate", size: 1 },
    7283: { name: "Lillia", size: 1 },
    7284: { name: "Gertrude", size: 1 },
    7285: { name: "Barbarian guard", size: 1 },
    7286: { name: "Skotizo", size: 5 },
    7287: { name: "Reanimated demon spawn", size: 1 },
    7288: { name: "Awakened Altar", size: 1 },
    7289: { name: "Altar", size: 1 },
    7290: { name: "Awakened Altar", size: 1 },
    7291: { name: "Altar", size: 1 },
    7292: { name: "Awakened Altar", size: 1 },
    7293: { name: "Altar", size: 1 },
    7294: { name: "Awakened Altar", size: 1 },
    7295: { name: "Altar", size: 1 },
    7296: { name: "Dark Ankou", size: 1 },
    7297: { name: "Mistag", size: 1 },
    7298: { name: "Mistag", size: 1 },
    7299: { name: "Mistag", size: 1 },
    7300: { name: "Kazgar", size: 1 },
    7301: { name: "Kazgar", size: 1 },
    7302: { name: "Lucky impling", size: 1 },
    7303: { name: "Watson", size: 1 },
    7304: { name: "Watson", size: 1 },
    7305: { name: "Gruff McScruff", size: 1 },
    7306: { name: "Falo the Bard", size: 1 },
    7307: { name: "Ancient Wizard", size: 1 },
    7308: { name: "Ancient Wizard", size: 1 },
    7309: { name: "Ancient Wizard", size: 1 },
    7310: { name: "Brassican Mage", size: 1 },
    7311: { name: "Uri", size: 1 },
    7312: { name: "Double agent", size: 1 },
    7313: { name: "null", size: 1 },
    7314: { name: "null", size: 1 },
    7315: { name: "null", size: 1 },
    7316: { name: "Lisa", size: 1 },
    7317: { name: "Lisa", size: 1 },
    7318: { name: "null", size: 1 },
    7319: { name: "null", size: 1 },
    7320: { name: "null", size: 1 },
    7321: { name: "Nesty", size: 1 },
    7322: { name: "null", size: 1 },
    7323: { name: "Fishing spot", size: 1 },
    7324: { name: "Holgart", size: 1 },
    7325: { name: "null", size: 1 },
    7326: { name: "null", size: 1 },
    7327: { name: "Rick", size: 1 },
    7328: { name: "Maid", size: 1 },
    7329: { name: "Cook", size: 1 },
    7330: { name: "Butler", size: 1 },
    7331: { name: "Demon butler", size: 1 },
    7332: { name: "Fairy Fixit", size: 1 },
    7333: { name: "Fairy Fixit", size: 1 },
    7334: { name: "Giant Squirrel", size: 1 },
    7335: { name: "Tangleroot", size: 1 },
    7336: { name: "Rocky", size: 1 },
    7337: { name: "Rift guardian", size: 1 },
    7338: { name: "Rift guardian", size: 1 },
    7339: { name: "Rift guardian", size: 1 },
    7340: { name: "Rift guardian", size: 1 },
    7341: { name: "Rift guardian", size: 1 },
    7342: { name: "Rift guardian", size: 1 },
    7343: { name: "Rift guardian", size: 1 },
    7344: { name: "Rift guardian", size: 1 },
    7345: { name: "Rift guardian", size: 1 },
    7346: { name: "Rift guardian", size: 1 },
    7347: { name: "Rift guardian", size: 1 },
    7348: { name: "Rift guardian", size: 1 },
    7349: { name: "Rift guardian", size: 1 },
    7350: { name: "Rift guardian", size: 1 },
    7351: { name: "Giant Squirrel", size: 1 },
    7352: { name: "Tangleroot", size: 1 },
    7353: { name: "Rocky", size: 1 },
    7354: { name: "Rift guardian", size: 1 },
    7355: { name: "Rift guardian", size: 1 },
    7356: { name: "Rift guardian", size: 1 },
    7357: { name: "Rift guardian", size: 1 },
    7358: { name: "Rift guardian", size: 1 },
    7359: { name: "Rift guardian", size: 1 },
    7360: { name: "Rift guardian", size: 1 },
    7361: { name: "Rift guardian", size: 1 },
    7362: { name: "Rift guardian", size: 1 },
    7363: { name: "Rift guardian", size: 1 },
    7364: { name: "Rift guardian", size: 1 },
    7365: { name: "Rift guardian", size: 1 },
    7366: { name: "Rift guardian", size: 1 },
    7367: { name: "Rift guardian", size: 1 },
    7368: { name: "Phoenix", size: 1 },
    7369: { name: "Wesley", size: 1 },
    7370: { name: "Phoenix", size: 1 },
    7371: { name: "Pyromancer", size: 1 },
    7372: { name: "Incapacitated Pyromancer", size: 1 },
    7373: { name: "null", size: 1 },
    7374: { name: "Ignisia", size: 1 },
    7375: { name: "null", size: 1 },
    7376: { name: "Esther", size: 1 },
    7377: { name: "Captain Kalt", size: 1 },
    7378: { name: "Ish the Navigator", size: 1 },
    7379: { name: "Winter Soldier", size: 1 },
    7380: { name: "Cat", size: 1 },
    7381: { name: "Wintertoad", size: 1 },
    7382: { name: "null", size: 1 },
    7383: { name: "Snow", size: 1 },
    7384: { name: "Stumpy", size: 1 },
    7385: { name: "Pumpy", size: 1 },
    7386: { name: "Dumpy", size: 1 },
    7387: { name: "Dumpy", size: 1 },
    7388: { name: "Crushing hand", size: 3 },
    7389: { name: "Chasm Crawler", size: 3 },
    7390: { name: "Screaming banshee", size: 3 },
    7391: { name: "Screaming twisted banshee", size: 3 },
    7392: { name: "Giant rockslug", size: 3 },
    7393: { name: "Cockathrice", size: 3 },
    7394: { name: "Flaming pyrelord", size: 2 },
    7395: { name: "Monstrous basilisk", size: 3 },
    7396: { name: "Malevolent Mage", size: 2 },
    7397: { name: "Insatiable Bloodveld", size: 3 },
    7398: { name: "Insatiable mutated Bloodveld", size: 3 },
    7399: { name: "Vitreous Jelly", size: 2 },
    7400: { name: "Vitreous warped Jelly", size: 3 },
    7401: { name: "Cave abomination", size: 2 },
    7402: { name: "Abhorrent spectre", size: 3 },
    7403: { name: "Repugnant spectre", size: 3 },
    7404: { name: "Choke devil", size: 2 },
    7405: { name: "King kurask", size: 5 },
    7406: { name: "Nuclear smoke devil", size: 2 },
    7407: { name: "Marble gargoyle", size: 3 },
    7408: { name: "Marble gargoyle", size: 3 },
    7409: { name: "Night beast", size: 4 },
    7410: { name: "Greater abyssal demon", size: 1 },
    7411: { name: "Nechryarch", size: 2 },
    7412: { name: "Lieve McCracken", size: 1 },
    7413: { name: "<col=00ffff>Undead Combat dummy</col>", size: 1 },
    7414: { name: "Count Check", size: 1 },
    7415: { name: "Bologa", size: 1 },
    7416: { name: "Obor", size: 2 },
    7417: { name: "Amy", size: 1 },
    7418: { name: "Zamorak warrior", size: 1 },
    7419: { name: "Zamorak warrior", size: 1 },
    7420: { name: "Zamorak ranger", size: 1 },
    7421: { name: "Zamorak ranger", size: 1 },
    7422: { name: "Zamorak mage", size: 1 },
    7423: { name: "Zamorak mage", size: 1 },
    7424: { name: "Cave lizard", size: 1 },
    7425: { name: "Mage of Zamorak", size: 1 },
    7426: { name: "Zamorak crafter", size: 1 },
    7427: { name: "Zamorak crafter", size: 1 },
    7428: { name: "null", size: 1 },
    7429: { name: "null", size: 2 },
    7430: { name: "null", size: 1 },
    7431: { name: "null", size: 1 },
    7432: { name: "null", size: 1 },
    7433: { name: "null", size: 1 },
    7434: { name: "null", size: 1 },
    7435: { name: "null", size: 1 },
    7436: { name: "null", size: 1 },
    7437: { name: "Guard", size: 1 },
    7438: { name: "Guard", size: 1 },
    7439: { name: "Rock Golem", size: 1 },
    7440: { name: "Rock Golem", size: 1 },
    7441: { name: "Rock Golem", size: 1 },
    7442: { name: "Rock Golem", size: 1 },
    7443: { name: "Rock Golem", size: 1 },
    7444: { name: "Rock Golem", size: 1 },
    7445: { name: "Rock Golem", size: 1 },
    7446: { name: "Rock Golem", size: 1 },
    7447: { name: "Rock Golem", size: 1 },
    7448: { name: "Rock Golem", size: 1 },
    7449: { name: "Rock Golem", size: 1 },
    7450: { name: "Rock Golem", size: 1 },
    7451: { name: "Rock Golem", size: 1 },
    7452: { name: "Rock Golem", size: 1 },
    7453: { name: "Rock Golem", size: 1 },
    7454: { name: "Rock Golem", size: 1 },
    7455: { name: "Rock Golem", size: 1 },
    7456: { name: "Perdu", size: 1 },
    7457: { name: "null", size: 1 },
    7458: { name: "null", size: 1 },
    7459: { name: "Fishing spot", size: 1 },
    7460: { name: "Fishing spot", size: 1 },
    7461: { name: "Fishing spot", size: 1 },
    7462: { name: "Fishing spot", size: 1 },
    7463: { name: "Rod Fishing spot", size: 1 },
    7464: { name: "Rod Fishing spot", size: 1 },
    7465: { name: "Fishing spot", size: 1 },
    7466: { name: "Fishing spot", size: 1 },
    7467: { name: "Fishing spot", size: 1 },
    7468: { name: "Rod Fishing spot", size: 1 },
    7469: { name: "Fishing spot", size: 1 },
    7470: { name: "Fishing spot", size: 1 },
    7471: { name: "Captain Magoro", size: 1 },
    7472: { name: "Ranger", size: 1 },
    7473: { name: "Duck", size: 1 },
    7474: { name: "Reginald", size: 1 },
    7475: { name: "Roy jr.", size: 1 },
    7476: { name: "Robert Boss", size: 1 },
    7477: { name: "Knight of Varlamore", size: 1 },
    7478: { name: "Hugor", size: 1 },
    7479: { name: "Lan the Butcher", size: 1 },
    7480: { name: "Rakkar", size: 1 },
    7481: { name: "Hopleez", size: 1 },
    7482: { name: "Captain Sdiar", size: 1 },
    7483: { name: "Sandicrahb", size: 1 },
    7484: { name: "Sandicrahb", size: 1 },
    7485: { name: "Zombie", size: 1 },
    7486: { name: "Zombie", size: 1 },
    7487: { name: "Zombie", size: 1 },
    7488: { name: "Zombie", size: 1 },
    7489: { name: "null", size: 1 },
    7492: { name: "null", size: 1 },
    7493: { name: "null", size: 1 },
    7494: { name: "null", size: 1 },
    7495: { name: "null", size: 1 },
    7496: { name: "null", size: 1 },
    7497: { name: "null", size: 1 },
    7498: { name: "null", size: 1 },
    7499: { name: "null", size: 1 },
    7500: { name: "null", size: 1 },
    7501: { name: "null", size: 1 },
    7502: { name: "null", size: 1 },
    7503: { name: "null", size: 1 },
    7504: { name: "null", size: 1 },
    7505: { name: "null", size: 1 },
    7506: { name: "null", size: 3 },
    7507: { name: "null", size: 3 },
    7508: { name: "null", size: 3 },
    7509: { name: "Jardric", size: 1 },
    7510: { name: "Annette", size: 1 },
    7511: { name: "Shield master", size: 1 },
    7512: { name: "Pizazz Hat", size: 1 },
    7513: { name: "Derwen", size: 3 },
    7514: { name: "<col=ff9040>Energy ball</col>", size: 1 },
    7515: { name: "Porazdir", size: 3 },
    7516: { name: "Gnormadium Avlafrim", size: 1 },
    7517: { name: "Gnormadium Avlafrim", size: 1 },
    7518: { name: "Jelly", size: 2 },
    7519: { name: "Olmlet", size: 1 },
    7520: { name: "Olmlet", size: 1 },
    7521: { name: "Soldier", size: 1 },
    7522: { name: "Soldier", size: 1 },
    7523: { name: "Soldier", size: 1 },
    7524: { name: "Soldier", size: 1 },
    7525: { name: "Vanguard", size: 3 },
    7526: { name: "Vanguard", size: 3 },
    7527: { name: "Vanguard", size: 3 },
    7528: { name: "Vanguard", size: 3 },
    7529: { name: "Vanguard", size: 3 },
    7530: { name: "Vespula", size: 5 },
    7531: { name: "Vespula", size: 5 },
    7532: { name: "Vespula", size: 5 },
    7533: { name: "<col=00ffff>Abyssal portal</col>", size: 4 },
    7534: { name: "Lux grub", size: 3 },
    7535: { name: "Lux grub", size: 3 },
    7536: { name: "Lux grub", size: 3 },
    7537: { name: "Lux grub", size: 3 },
    7538: { name: "Vespine soldier", size: 3 },
    7539: { name: "Vespine soldier", size: 3 },
    7540: { name: "Tekton", size: 4 },
    7541: { name: "Tekton", size: 4 },
    7542: { name: "Tekton", size: 4 },
    7543: { name: "Tekton (enraged)", size: 4 },
    7544: { name: "Tekton (enraged)", size: 4 },
    7545: { name: "Tekton", size: 4 },
    7546: { name: "Bartender", size: 1 },
    7547: { name: "null", size: 1 },
    7548: { name: "Scavenger beast", size: 2 },
    7549: { name: "Scavenger beast", size: 2 },
    7550: { name: "Great Olm (Right claw)", size: 5 },
    7551: { name: "Great Olm", size: 5 },
    7552: { name: "Great Olm (Left claw)", size: 5 },
    7553: { name: "Great Olm (Right claw)", size: 5 },
    7554: { name: "Great Olm", size: 5 },
    7555: { name: "Great Olm (Left claw)", size: 5 },
    7556: { name: "null", size: 5 },
    7557: { name: "null", size: 5 },
    7558: { name: "<col=00ffff>Fire</col>", size: 1 },
    7559: { name: "Deathly ranger", size: 1 },
    7560: { name: "Deathly mage", size: 1 },
    7561: { name: "Muttadile", size: 5 },
    7562: { name: "Muttadile", size: 3 },
    7563: { name: "Muttadile", size: 5 },
    7564: { name: "<col=00ffff>Meat tree</col>", size: 2 },
    7565: { name: "<col=00ffff>Rocks</col>", size: 5 },
    7566: { name: "Vasa Nistirio", size: 5 },
    7567: { name: "Vasa Nistirio", size: 5 },
    7568: { name: "<col=00ffff>Glowing crystal</col>", size: 4 },
    7569: { name: "<col=00ffff>Guardian</col>", size: 2 },
    7570: { name: "<col=00ffff>Guardian</col>", size: 2 },
    7571: { name: "<col=00ffff>Guardian</col>", size: 2 },
    7572: { name: "<col=00ffff>Guardian</col>", size: 2 },
    7573: { name: "Lizardman shaman", size: 3 },
    7574: { name: "Lizardman shaman", size: 3 },
    7575: { name: "Spawn", size: 1 },
    7576: { name: "Jewelled Crab", size: 1 },
    7577: { name: "Jewelled Crab (red)", size: 1 },
    7578: { name: "Jewelled Crab (green)", size: 1 },
    7579: { name: "Jewelled Crab (blue)", size: 1 },
    7580: { name: "Energy focus (white)", size: 1 },
    7581: { name: "Energy focus (red)", size: 1 },
    7582: { name: "Energy focus (green)", size: 1 },
    7583: { name: "Energy focus (blue)", size: 1 },
    7584: { name: "Ice demon", size: 2 },
    7585: { name: "Ice demon", size: 2 },
    7586: { name: "Icefiend", size: 1 },
    7587: { name: "Guanic bat", size: 1 },
    7588: { name: "Prael bat", size: 1 },
    7589: { name: "Giral bat", size: 1 },
    7590: { name: "Phluxia bat", size: 1 },
    7591: { name: "Kryket bat", size: 1 },
    7592: { name: "Murng bat", size: 1 },
    7593: { name: "Psykk bat", size: 1 },
    7594: { name: "Cave snake", size: 3 },
    7595: { name: "Captain Rimor", size: 1 },
    7596: { name: "null", size: 1 },
    7597: { name: "Lizard", size: 1 },
    7598: { name: "<col=00ffff>Strange Device</col>", size: 2 },
    7599: { name: "Mountain Guide", size: 1 },
    7600: { name: "Mountain Guide", size: 1 },
    7601: { name: "Swamp Priest", size: 1 },
    7602: { name: "Corrupted scavenger", size: 2 },
    7603: { name: "Corrupted scavenger", size: 2 },
    7604: { name: "Skeletal Mystic", size: 2 },
    7605: { name: "Skeletal Mystic", size: 2 },
    7606: { name: "Skeletal Mystic", size: 2 },
    7607: { name: "Imerominia", size: 1 },
    7608: { name: "Pagida", size: 1 },
    7609: { name: "null", size: 1 },
    7610: { name: "Logios", size: 1 },
    7611: { name: "Meleti", size: 1 },
    7612: { name: "Krato", size: 1 },
    7613: { name: "Ektheme", size: 1 },
    7614: { name: "Archeio", size: 1 },
    7615: { name: "Historian Aldo", size: 1 },
    7616: { name: "Scurry", size: 1 },
    7617: { name: "null", size: 1 },
    7618: { name: "null", size: 1 },
    7619: { name: "Stuliette", size: 1 },
    7620: { name: "Temple Guardian", size: 2 },
    7621: { name: "Khazard warlord", size: 1 },
    7622: { name: "Khazard warlord", size: 1 },
    7623: { name: "Abigale", size: 1 },
    7624: { name: "Hewey", size: 2 },
    7625: { name: "Sid", size: 1 },
    7626: { name: "Sid", size: 1 },
    7627: { name: "Tayten", size: 1 },
    7628: { name: "Lacey", size: 1 },
    7629: { name: "Mandy", size: 1 },
    7630: { name: "Mandy", size: 1 },
    7631: { name: "Mandy", size: 1 },
    7632: { name: "Killer", size: 1 },
    7633: { name: "Abigale", size: 1 },
    7634: { name: "null", size: 1 },
    7635: { name: "Abigale", size: 1 },
    7636: { name: "Killer", size: 1 },
    7637: { name: "Hewey", size: 1 },
    7638: { name: "Shady figure", size: 1 },
    7639: { name: "null", size: 1 },
    7640: { name: "<col=00ffff>Mirror</col>", size: 1 },
    7641: { name: "<col=00ffff>Mirror</col>", size: 1 },
    7642: { name: "Rock Golem", size: 1 },
    7643: { name: "Rock Golem", size: 1 },
    7644: { name: "Rock Golem", size: 1 },
    7645: { name: "Rock Golem", size: 1 },
    7646: { name: "Rock Golem", size: 1 },
    7647: { name: "Rock Golem", size: 1 },
    7648: { name: "Rock Golem", size: 1 },
    7649: { name: "Chaotic death spawn", size: 1 },
    7650: { name: "Pirate Jackie the Fruit", size: 1 },
    7651: { name: "Pirate Jackie the Fruit", size: 1 },
    7652: { name: "TzHaar-Mej", size: 1 },
    7653: { name: "Slieve", size: 1 },
    7654: { name: "Breive", size: 1 },
    7655: { name: "null", size: 1 },
    7656: { name: "Lesser demon", size: 2 },
    7657: { name: "Lesser demon", size: 2 },
    7658: { name: "Mummy", size: 1 },
    7659: { name: "Mummy", size: 1 },
    7660: { name: "Mummy", size: 1 },
    7661: { name: "Mummy", size: 1 },
    7662: { name: "Mummy", size: 1 },
    7663: { name: "Krystilia", size: 1 },
    7664: { name: "Lesser demon", size: 2 },
    7665: { name: "Banisoch", size: 1 },
    7666: { name: "Banisoch", size: 1 },
    7667: { name: "Hieve", size: 1 },
    7668: { name: "Voice of Yama", size: 2 },
    7669: { name: "Disciple of Yama", size: 1 },
    7670: { name: "Disciple of Yama", size: 1 },
    7671: { name: "Skotos", size: 1 },
    7672: { name: "Eve", size: 1 },
    7673: { name: "Solztun", size: 1 },
    7674: { name: "Jal-Nib-Rek", size: 1 },
    7675: { name: "Jal-Nib-Rek", size: 1 },
    7676: { name: "Rod Fishing spot", size: 1 },
    7677: { name: "TzHaar-Ket-Zuh", size: 1 },
    7678: { name: "TzHaar-Ket-Yil", size: 1 },
    7679: { name: "TzHaar-Ket", size: 1 },
    7680: { name: "TzHaar-Mej-Dir", size: 1 },
    7681: { name: "TzHaar-Mej-Bal", size: 1 },
    7682: { name: "TzHaar-Hur", size: 1 },
    7683: { name: "TzHaar-Hur", size: 1 },
    7684: { name: "TzHaar-Hur", size: 1 },
    7685: { name: "TzHaar-Hur", size: 1 },
    7686: { name: "TzHaar-Hur", size: 1 },
    7687: { name: "TzHaar-Hur", size: 1 },
    7688: { name: "TzHaar-Hur-Zal", size: 1 },
    7689: { name: "TzHaar-Hur-Rin", size: 1 },
    7690: { name: "null", size: 2 },
    7691: { name: "Jal-Nib", size: 1 },
    7692: { name: "Jal-MejRah", size: 2 },
    7693: { name: "Jal-Ak", size: 3 },
    7694: { name: "Jal-AkRek-Mej", size: 1 },
    7695: { name: "Jal-AkRek-Xil", size: 1 },
    7696: { name: "Jal-AkRek-Ket", size: 1 },
    7697: { name: "Jal-ImKot", size: 4 },
    7698: { name: "Jal-Xil", size: 3 },
    7699: { name: "Jal-Zek", size: 4 },
    7700: { name: "JalTok-Jad", size: 5 },
    7701: { name: "Yt-HurKot", size: 1 },
    7702: { name: "Jal-Xil", size: 3 },
    7703: { name: "Jal-Zek", size: 4 },
    7704: { name: "JalTok-Jad", size: 5 },
    7705: { name: "Yt-HurKot", size: 1 },
    7706: { name: "TzKal-Zuk", size: 7 },
    7707: { name: "<col=00ffff>Ancestral Glyph</col>", size: 3 },
    7708: { name: "Jal-MejJak", size: 1 },
    7709: { name: "<col=00ffff>Rocky support</col>", size: 3 },
    7710: { name: "<col=00ffff>Rocky support</col>", size: 3 },
    7711: { name: "Rock Golem", size: 1 },
    7712: { name: "Dwarf", size: 1 },
    7713: { name: "Dwarf", size: 1 },
    7714: { name: "Dwarf", size: 1 },
    7715: { name: "Dwarf", size: 1 },
    7716: { name: "Gadrin", size: 1 },
    7717: { name: "Hendor", size: 1 },
    7718: { name: "Yarsul", size: 1 },
    7719: { name: "Belona", size: 1 },
    7720: { name: "Utrec", size: 1 },
    7721: { name: "Dwarf", size: 1 },
    7722: { name: "<col=00ffff>Rocks</col>", size: 1 },
    7723: { name: "Gertrude", size: 1 },
    7724: { name: "Barbarian guard", size: 1 },
    7725: { name: "Dwarven Boatman", size: 1 },
    7726: { name: "Dwarven Boatman", size: 1 },
    7727: { name: "Kylie Minnow", size: 1 },
    7728: { name: "Kylie Minnow", size: 1 },
    7730: { name: "Fishing spot", size: 1 },
    7731: { name: "Fishing spot", size: 1 },
    7732: { name: "Fishing spot", size: 1 },
    7733: { name: "Fishing spot", size: 1 },
    7734: { name: "Recruiter", size: 1 },
    7735: { name: "null", size: 1 },
    7736: { name: "Rock Golem", size: 1 },
    7737: { name: "Rock Golem", size: 1 },
    7738: { name: "Rock Golem", size: 1 },
    7739: { name: "Rock Golem", size: 1 },
    7740: { name: "Rock Golem", size: 1 },
    7741: { name: "Rock Golem", size: 1 },
    7742: { name: "Captain Cleive", size: 1 },
    7743: { name: "Soldier", size: 1 },
    7744: { name: "Lizardman shaman", size: 3 },
    7745: { name: "Lizardman shaman", size: 3 },
    7746: { name: "Wizard Mizgog", size: 1 },
    7747: { name: "Wizard Mizgog", size: 1 },
    7748: { name: "Fairy", size: 1 },
    7750: { name: "Armoured Foe", size: 5 },
    7751: { name: "Well-armed Foe", size: 3 },
    7752: { name: "Dark Mage", size: 1 },
    7753: { name: "Dark Mage", size: 1 },
    7754: { name: "Squirrel", size: 1 },
    7755: { name: "Squirrel", size: 1 },
    7756: { name: "Squirrel", size: 1 },
    7757: { name: "Tool Leprechaun", size: 1 },
    7758: { name: "Mernia", size: 1 },
    7759: { name: "Herbi", size: 1 },
    7760: { name: "Herbi", size: 1 },
    7761: { name: "Lead Navigator", size: 1 },
    7762: { name: "Lead Navigator", size: 1 },
    7763: { name: "Junior Navigator", size: 1 },
    7764: { name: "Junior Navigator", size: 1 },
    7765: { name: "John", size: 1 },
    7766: { name: "David", size: 1 },
    7767: { name: "null", size: 1 },
    7768: { name: "Barge guard", size: 1 },
    7769: { name: "Shop keeper", size: 1 },
    7770: { name: "Fossil Collector", size: 1 },
    7771: { name: "Dog", size: 1 },
    7772: { name: "Peter", size: 1 },
    7773: { name: "Charles", size: 1 },
    7774: { name: "John", size: 1 },
    7775: { name: "Dunce", size: 1 },
    7776: { name: "Petrified Pete", size: 1 },
    7777: { name: "Weve", size: 1 },
    7778: { name: "Irene", size: 1 },
    7779: { name: "Bobbing fossil", size: 1 },
    7780: { name: "Islwyn", size: 1 },
    7781: { name: "Puffer Fish", size: 1 },
    7782: { name: "Fish shoal", size: 1 },
    7783: { name: "Ceto", size: 1 },
    7784: { name: "Mairin", size: 2 },
    7785: { name: "Herbiboar", size: 1 },
    7786: { name: "Herbiboar", size: 1 },
    7787: { name: "Mattimeo", size: 1 },
    7788: { name: "Charles Charlington", size: 1 },
    7789: { name: "Holgart", size: 1 },
    7790: { name: "John", size: 1 },
    7791: { name: "David", size: 1 },
    7792: { name: "Long-tailed Wyvern", size: 3 },
    7793: { name: "Taloned Wyvern", size: 3 },
    7794: { name: "Spitting Wyvern", size: 3 },
    7795: { name: "Ancient Wyvern", size: 5 },
    7796: { name: "Lobstrosity", size: 3 },
    7797: { name: "Ancient Zygomite", size: 2 },
    7798: { name: "Gaius", size: 1 },
    7799: { name: "Ammonite Crab", size: 2 },
    7800: { name: "Fossil Rock", size: 2 },
    7801: { name: "Tar Bubbles", size: 1 },
    7802: { name: "Hoop Snake", size: 1 },
    7803: { name: "Stunned Hoop Snake", size: 1 },
    7804: { name: "Tar Monster", size: 2 },
    7805: { name: "Passive Tar Monster", size: 2 },
    7806: { name: "Deranged archaeologist", size: 1 },
    7807: { name: "<col=00ffff>Giant boulder</col>", size: 8 },
    7808: { name: "<col=00ffff>Giant boulder</col>", size: 8 },
    7809: { name: "<col=00ffff>Giant boulder</col>", size: 8 },
    7810: { name: "<col=00ffff>Large boulder</col>", size: 6 },
    7811: { name: "<col=00ffff>Large boulder</col>", size: 6 },
    7812: { name: "<col=00ffff>Medium boulder</col>", size: 4 },
    7813: { name: "<col=00ffff>Medium boulder</col>", size: 4 },
    7814: { name: "<col=00ffff>Small boulder</col>", size: 2 },
    7815: { name: "<col=00ffff>Small boulder</col>", size: 2 },
    7816: { name: "<col=00ffff>Small boulder</col>", size: 1 },
    7817: { name: "Lava beast", size: 2 },
    7818: { name: "David", size: 1 },
    7819: { name: "null", size: 1 },
    7820: { name: "null", size: 1 },
    7821: { name: "null", size: 1 },
    7822: { name: "null", size: 1 },
    7823: { name: "null", size: 1 },
    7824: { name: "null", size: 1 },
    7825: { name: "null", size: 1 },
    7826: { name: "null", size: 1 },
    7827: { name: "null", size: 1 },
    7828: { name: "null", size: 1 },
    7829: { name: "null", size: 1 },
    7830: { name: "null", size: 1 },
    7831: { name: "null", size: 1 },
    7832: { name: "null", size: 1 },
    7833: { name: "null", size: 1 },
    7834: { name: "null", size: 1 },
    7835: { name: "null", size: 1 },
    7836: { name: "null", size: 1 },
    7837: { name: "null", size: 1 },
    7838: { name: "null", size: 1 },
    7839: { name: "null", size: 1 },
    7840: { name: "null", size: 1 },
    7841: { name: "null", size: 1 },
    7842: { name: "null", size: 1 },
    7843: { name: "null", size: 1 },
    7844: { name: "null", size: 1 },
    7845: { name: "null", size: 1 },
    7846: { name: "null", size: 1 },
    7847: { name: "null", size: 1 },
    7848: { name: "null", size: 1 },
    7849: { name: "Dusk", size: 4 },
    7850: { name: "Dawn", size: 4 },
    7851: { name: "Dusk", size: 4 },
    7852: { name: "Dawn", size: 4 },
    7853: { name: "Dawn", size: 4 },
    7854: { name: "Dusk", size: 4 },
    7855: { name: "Dusk", size: 6 },
    7856: { name: "null", size: 1 },
    7857: { name: "null", size: 1 },
    7858: { name: "Justiciar Zachariah", size: 3 },
    7859: { name: "Derwen", size: 3 },
    7860: { name: "Porazdir", size: 3 },
    7861: { name: "Black dragon", size: 4 },
    7862: { name: "Black dragon", size: 4 },
    7863: { name: "Black dragon", size: 4 },
    7864: { name: "Ankou", size: 1 },
    7865: { name: "Lesser demon", size: 2 },
    7866: { name: "Lesser demon", size: 2 },
    7867: { name: "Lesser demon", size: 2 },
    7868: { name: "Green dragon", size: 4 },
    7869: { name: "Green dragon", size: 4 },
    7870: { name: "Green dragon", size: 4 },
    7871: { name: "Greater demon", size: 3 },
    7872: { name: "Greater demon", size: 3 },
    7873: { name: "Greater demon", size: 3 },
    7874: { name: "Black demon", size: 3 },
    7875: { name: "Black demon", size: 3 },
    7876: { name: "Black demon", size: 3 },
    7877: { name: "Hellhound", size: 2 },
    7878: { name: "Ice giant", size: 2 },
    7879: { name: "Ice giant", size: 2 },
    7880: { name: "Ice giant", size: 2 },
    7881: { name: "Revenant imp", size: 1 },
    7882: { name: "Dusk", size: 4 },
    7883: { name: "Dusk", size: 4 },
    7884: { name: "Dawn", size: 4 },
    7885: { name: "Dawn", size: 4 },
    7886: { name: "Dusk", size: 4 },
    7887: { name: "Dusk", size: 6 },
    7888: { name: "Dusk", size: 6 },
    7889: { name: "Dusk", size: 6 },
    7890: { name: "Midnight", size: 1 },
    7891: { name: "Noon", size: 1 },
    7892: { name: "Noon", size: 1 },
    7893: { name: "Midnight", size: 1 },
    7894: { name: "Sand Snake (hard)", size: 3 },
    7895: { name: "Sand Snake", size: 3 },
    7896: { name: "null", size: 1 },
    7897: { name: "null", size: 1 },
    7898: { name: "Artur Hosidius", size: 1 },
    7899: { name: "Artur Hosidius", size: 1 },
    7900: { name: "Butler Jarvis", size: 1 },
    7901: { name: "Chef Olivia", size: 1 },
    7902: { name: "Galana", size: 1 },
    7903: { name: "Sand Snake", size: 3 },
    7904: { name: "Tomas Lawry", size: 1 },
    7905: { name: "Robert O'Reilly", size: 1 },
    7906: { name: "Devan Rutter", size: 1 },
    7907: { name: "Conrad King", size: 1 },
    7908: { name: "The Queen of Thieves", size: 1 },
    7909: { name: "Lady Shauna Piscarilius", size: 1 },
    7910: { name: "Sophia Hughes", size: 1 },
    7911: { name: "Bartender", size: 1 },
    7912: { name: "Fish monger", size: 1 },
    7913: { name: "Shop keeper", size: 1 },
    7914: { name: "Thief", size: 1 },
    7915: { name: "Thief", size: 1 },
    7916: { name: "Thief", size: 1 },
    7917: { name: "Pirate", size: 1 },
    7918: { name: "Pirate", size: 1 },
    7919: { name: "Man", size: 1 },
    7920: { name: "Man", size: 1 },
    7921: { name: "Woman", size: 1 },
    7922: { name: "Woman", size: 1 },
    7923: { name: "Poor looking woman", size: 1 },
    7924: { name: "null", size: 1 },
    7925: { name: "null", size: 1 },
    7926: { name: "null", size: 1 },
    7927: { name: "null", size: 1 },
    7928: { name: "null", size: 1 },
    7929: { name: "null", size: 1 },
    7930: { name: "null", size: 1 },
    7931: { name: "Revenant goblin", size: 1 },
    7932: { name: "Revenant pyrefiend", size: 1 },
    7933: { name: "Revenant hobgoblin", size: 1 },
    7934: { name: "Revenant cyclops", size: 3 },
    7935: { name: "Revenant hellhound", size: 3 },
    7936: { name: "Revenant demon", size: 3 },
    7937: { name: "Revenant ork", size: 3 },
    7938: { name: "Revenant dark beast", size: 3 },
    7939: { name: "Revenant knight", size: 1 },
    7940: { name: "Revenant dragon", size: 5 },
    7941: { name: "Ironman tutor", size: 1 },
    7942: { name: "null", size: 1 },
    7943: { name: "Emblem Trader", size: 1 },
    7944: { name: "null", size: 1 },
    7945: { name: "null", size: 1 },
    7946: { name: "Fishing spot", size: 1 },
    7947: { name: "Fishing spot", size: 1 },
    7948: { name: "Corsair Traitor (hard)", size: 1 },
    7949: { name: "Corsair Traitor", size: 1 },
    7950: { name: "Alec Kincade", size: 1 },
    7951: { name: "Ponts the Bridgemaster", size: 1 },
    7952: { name: "Erdan", size: 1 },
    7953: { name: "Primula", size: 1 },
    7954: { name: "Mysterious Adventurer", size: 1 },
    7955: { name: "Baby black dragon", size: 2 },
    7956: { name: "Captain Tock", size: 1 },
    7957: { name: "Captain Tock", size: 1 },
    7958: { name: "Captain Tock", size: 1 },
    7959: { name: "null", size: 1 },
    7960: { name: "null", size: 1 },
    7961: { name: "Ithoi the Navigator", size: 1 },
    7962: { name: "null", size: 2 },
    7963: { name: "Ithoi the Navigator", size: 1 },
    7964: { name: "Ithoi the Navigator", size: 1 },
    7965: { name: "Cabin Boy Colin", size: 1 },
    7966: { name: "Cabin Boy Colin", size: 1 },
    7967: { name: "Cabin Boy Colin", size: 1 },
    7968: { name: "null", size: 2 },
    7969: { name: "Bugs", size: 5 },
    7970: { name: "Gnocci the Cook", size: 1 },
    7971: { name: "Gnocci the Cook", size: 1 },
    7972: { name: "Gnocci the Cook", size: 1 },
    7973: { name: "null", size: 2 },
    7974: { name: "null", size: 1 },
    7975: { name: "Doll", size: 1 },
    7976: { name: "Arsen the Thief", size: 1 },
    7977: { name: "null", size: 2 },
    7978: { name: "null", size: 1 },
    7979: { name: "The Mimic", size: 5 },
    7980: { name: "null", size: 1 },
    7981: { name: "Yusuf", size: 1 },
    7982: { name: "Yusuf", size: 1 },
    7983: { name: "Francois", size: 1 },
    7984: { name: "Madame Caldarium", size: 1 },
    7985: { name: "Haris", size: 1 },
    7986: { name: "Altarkiz", size: 1 },
    7987: { name: "Lord Marshal Brogan", size: 1 },
    7988: { name: "Chief Tess", size: 2 },
    7989: { name: "Ogress Warrior", size: 2 },
    7990: { name: "Ogress Warrior", size: 2 },
    7991: { name: "Ogress Shaman", size: 2 },
    7992: { name: "Ogress Shaman", size: 2 },
    7993: { name: "null", size: 1 },
    7994: { name: "null", size: 1 },
    7995: { name: "Elder Chaos druid", size: 1 },
    7996: { name: "Corrupt Lizardman (hard)", size: 1 },
    7997: { name: "Corrupt Lizardman", size: 1 },
    7998: { name: "null", size: 1 },
    7999: { name: "Phileas Rimor", size: 1 },
    8000: { name: "Corrupt Lizardman", size: 1 },
    8001: { name: "Crunchy", size: 1 },
    8002: { name: "Tim", size: 1 },
    8003: { name: "Dave", size: 1 },
    8004: { name: "Gene", size: 1 },
    8005: { name: "Art", size: 1 },
    8006: { name: "Gnosi", size: 1 },
    8007: { name: "Historian Duffy", size: 1 },
    8008: { name: "Corporeal Critter", size: 1 },
    8009: { name: "TzRek-Zuk", size: 1 },
    8010: { name: "Corporeal Critter", size: 1 },
    8011: { name: "TzRek-Zuk", size: 1 },
    8012: { name: "Barge guard", size: 1 },
    8013: { name: "Barge guard", size: 1 },
    8014: { name: "null", size: 1 },
    8015: { name: "null", size: 2 },
    8016: { name: "null", size: 2 },
    8017: { name: "null", size: 2 },
    8018: { name: "null", size: 2 },
    8019: { name: "King Narnode Shareen", size: 1 },
    8020: { name: "King Narnode Shareen", size: 1 },
    8021: { name: "Natural Historian", size: 1 },
    8022: { name: "Mysterious Voice", size: 1 },
    8023: { name: "Gnosi", size: 1 },
    8024: { name: "Rift guardian", size: 1 },
    8025: { name: "Vorki", size: 1 },
    8026: { name: "Vorkath", size: 1 },
    8027: { name: "Rune dragon", size: 4 },
    8028: { name: "Rift guardian", size: 1 },
    8029: { name: "Vorki", size: 1 },
    8030: { name: "Adamant dragon", size: 5 },
    8031: { name: "Rune dragon", size: 5 },
    8032: { name: "null", size: 1 },
    8033: { name: "Elvarg", size: 1 },
    8034: { name: "Bob", size: 1 },
    8035: { name: "Neite", size: 1 },
    8036: { name: "Diana", size: 1 },
    8037: { name: "Jack", size: 1 },
    8038: { name: "Ellen", size: 1 },
    8039: { name: "Freja", size: 1 },
    8040: { name: "Lutwidge", size: 1 },
    8041: { name: "Dog", size: 1 },
    8042: { name: "King Roald", size: 1 },
    8043: { name: "Aeonisig Raispher", size: 1 },
    8044: { name: "Sir Amik Varze", size: 1 },
    8045: { name: "Sir Tiffy Cashien", size: 1 },
    8046: { name: "King Lathas", size: 1 },
    8047: { name: "King Arthur", size: 1 },
    8048: { name: "Brundt the Chieftain", size: 1 },
    8049: { name: "Oneiromancer", size: 1 },
    8050: { name: "Denulth", size: 1 },
    8051: { name: "Duke Horacio", size: 1 },
    8052: { name: "Wise Old Man", size: 1 },
    8053: { name: "Jardric", size: 1 },
    8054: { name: "Achietties", size: 1 },
    8055: { name: "Bob", size: 1 },
    8056: { name: "Spawn", size: 1 },
    8057: { name: "Robert the Strong", size: 1 },
    8058: { name: "Vorkath", size: 7 },
    8059: { name: "Vorkath", size: 7 },
    8060: { name: "Vorkath", size: 7 },
    8061: { name: "Vorkath", size: 7 },
    8062: { name: "Zombified Spawn", size: 1 },
    8063: { name: "Zombified Spawn", size: 1 },
    8064: { name: "Stone Guardian", size: 1 },
    8065: { name: "Stone Guardian", size: 1 },
    8066: { name: "Stone Guardian", size: 1 },
    8067: { name: "Zombie", size: 1 },
    8068: { name: "Zombie", size: 1 },
    8069: { name: "Zombie", size: 1 },
    8070: { name: "Skeleton", size: 1 },
    8071: { name: "Skeleton", size: 1 },
    8072: { name: "Skeleton", size: 1 },
    8073: { name: "Green dragon", size: 5 },
    8074: { name: "Blue dragon", size: 5 },
    8075: { name: "Red dragon", size: 5 },
    8076: { name: "Green dragon", size: 5 },
    8077: { name: "Blue dragon", size: 5 },
    8078: { name: "Red dragon", size: 5 },
    8079: { name: "Red dragon", size: 4 },
    8080: { name: "Iron dragon", size: 4 },
    8081: { name: "Brutal green dragon", size: 4 },
    8082: { name: "Green dragon", size: 4 },
    8083: { name: "Blue dragon", size: 4 },
    8084: { name: "Black dragon", size: 5 },
    8085: { name: "Black dragon", size: 4 },
    8086: { name: "Steel dragon", size: 4 },
    8087: { name: "Brutal red dragon", size: 4 },
    8088: { name: "Mithril dragon", size: 5 },
    8089: { name: "Mithril dragon", size: 4 },
    8090: { name: "Adamant dragon", size: 5 },
    8091: { name: "Rune dragon", size: 5 },
    8092: { name: "Brutal black dragon", size: 5 },
    8093: { name: "Brutal black dragon", size: 4 },
    8094: { name: "Galvek", size: 7 },
    8095: { name: "Galvek", size: 7 },
    8096: { name: "Galvek", size: 7 },
    8097: { name: "Galvek", size: 7 },
    8098: { name: "Galvek", size: 7 },
    8099: { name: "<col=00ffff>Tsunami</col>", size: 1 },
    8100: { name: "Dallas Jones", size: 1 },
    8101: { name: "Dallas Jones", size: 1 },
    8102: { name: "Dallas Jones", size: 1 },
    8103: { name: "Dallas Jones", size: 1 },
    8104: { name: "Dallas Jones", size: 1 },
    8105: { name: "Jardric", size: 1 },
    8106: { name: "Jardric", size: 1 },
    8107: { name: "Jardric", size: 1 },
    8108: { name: "Jardric", size: 1 },
    8109: { name: "null", size: 1 },
    8110: { name: "null", size: 1 },
    8111: { name: "Bob", size: 1 },
    8112: { name: "Bob", size: 1 },
    8113: { name: "Bob", size: 1 },
    8114: { name: "Bob", size: 1 },
    8115: { name: "Bob", size: 1 },
    8116: { name: "Not Bob", size: 1 },
    8117: { name: "Not Bob", size: 1 },
    8118: { name: "Neite", size: 1 },
    8119: { name: "Unferth", size: 1 },
    8120: { name: "Dragonkin", size: 3 },
    8121: { name: "Camorra", size: 1 },
    8122: { name: "Tristan", size: 1 },
    8123: { name: "Aivas", size: 1 },
    8124: { name: "Robert the Strong", size: 1 },
    8125: { name: "Dragonkin", size: 2 },
    8126: { name: "Camorra", size: 1 },
    8127: { name: "Tristan", size: 1 },
    8128: { name: "Aivas", size: 1 },
    8129: { name: "Robert the Strong", size: 1 },
    8130: { name: "Odysseus", size: 2 },
    8131: { name: "Torfinn", size: 1 },
    8132: { name: "Eniola", size: 1 },
    8133: { name: "Odovacar", size: 1 },
    8134: { name: "Sarah", size: 1 },
    8135: { name: "Dragonkin", size: 2 },
    8136: { name: "Zorgoth", size: 2 },
    8137: { name: "Spider", size: 1 },
    8138: { name: "Spider", size: 1 },
    8139: { name: "Skeleton", size: 2 },
    8140: { name: "Skeleton", size: 2 },
    8141: { name: "null", size: 1 },
    8142: { name: "<col=00ffff>Strange Egg</col>", size: 3 },
    8143: { name: "<col=00ffff>Dragon head</col>", size: 1 },
    8144: { name: "<col=00ffff>Dragon head</col>", size: 1 },
    8145: { name: "Brundt the Chieftain", size: 1 },
    8146: { name: "Thorvald the Warrior", size: 1 },
    8147: { name: "Peer the Seer", size: 1 },
    8148: { name: "Swensen the Navigator", size: 1 },
    8149: { name: "White Knight", size: 1 },
    8150: { name: "Paladin", size: 1 },
    8151: { name: "Koschei the Deathless", size: 1 },
    8152: { name: "Koschei the Deathless", size: 1 },
    8153: { name: "Brundt the Chieftain", size: 1 },
    8154: { name: "Wise Old Man", size: 1 },
    8155: { name: "Jardric", size: 1 },
    8156: { name: "Achietties", size: 1 },
    8157: { name: "Sir Tiffy Cashien", size: 1 },
    8158: { name: "Oneiromancer", size: 1 },
    8159: { name: "Bob", size: 1 },
    8160: { name: "Soldier", size: 1 },
    8161: { name: "Brundt the Chieftain", size: 1 },
    8162: { name: "Wise Old Man", size: 1 },
    8163: { name: "Historian Duffy", size: 1 },
    8164: { name: "Achietties", size: 1 },
    8165: { name: "Sir Tiffy Cashien", size: 1 },
    8166: { name: "Oneiromancer", size: 1 },
    8167: { name: "Zorgoth", size: 2 },
    8168: { name: "Soldier", size: 1 },
    8169: { name: "Brundt the Chieftain", size: 1 },
    8170: { name: "Wise Old Man", size: 1 },
    8171: { name: "Jardric", size: 1 },
    8172: { name: "Achietties", size: 1 },
    8173: { name: "Sir Tiffy Cashien", size: 1 },
    8174: { name: "Oneiromancer", size: 1 },
    8175: { name: "Zorgoth", size: 2 },
    8176: { name: "Zorgoth", size: 3 },
    8177: { name: "Galvek", size: 7 },
    8178: { name: "Galvek", size: 7 },
    8179: { name: "Galvek", size: 7 },
    8180: { name: "Amelia", size: 1 },
    8181: { name: "Jonathan", size: 1 },
    8182: { name: "Natural Historian", size: 1 },
    8183: { name: "Little Parasite", size: 1 },
    8184: { name: "null", size: 1 },
    8185: { name: "null", size: 1 },
    8186: { name: "null", size: 1 },
    8187: { name: "null", size: 1 },
    8188: { name: "<col=00ffff>Boulder</col>", size: 3 },
    8189: { name: "Grave digger", size: 1 },
    8190: { name: "null", size: 1 },
    8191: { name: "null", size: 1 },
    8192: { name: "null", size: 1 },
    8193: { name: "James", size: 1 },
    8194: { name: "Growthling", size: 1 },
    8195: { name: "Bryophyta", size: 3 },
    8196: { name: "Puppadile", size: 1 },
    8197: { name: "Tektiny", size: 1 },
    8198: { name: "Vanguard", size: 1 },
    8199: { name: "Vasa Minirio", size: 1 },
    8200: { name: "Vespina", size: 1 },
    8201: { name: "Puppadile", size: 1 },
    8202: { name: "Tektiny", size: 1 },
    8203: { name: "Vanguard", size: 1 },
    8204: { name: "Vasa Minirio", size: 1 },
    8205: { name: "Vespina", size: 1 },
    8206: { name: "Garth", size: 1 },
    8207: { name: "Garth", size: 1 },
    8208: { name: "Mysterious Stranger", size: 1 },
    8209: { name: "Vyrelord", size: 1 },
    8210: { name: "Vyrelady", size: 1 },
    8211: { name: "Meiyerditch citizen", size: 1 },
    8212: { name: "Harpert", size: 1 },
    8213: { name: "Mercenary", size: 1 },
    8214: { name: "Mercenary", size: 1 },
    8215: { name: "Mercenary", size: 1 },
    8216: { name: "Safalaan Hallow", size: 1 },
    8217: { name: "Safalaan Hallow", size: 1 },
    8218: { name: "Safalaan Hallow", size: 1 },
    8219: { name: "Safalaan Hallow", size: 1 },
    8220: { name: "Vertida Sefalatis", size: 1 },
    8221: { name: "Vertida Sefalatis", size: 1 },
    8222: { name: "Vertida Sefalatis", size: 1 },
    8223: { name: "Vertida Sefalatis", size: 1 },
    8224: { name: "Flaygian Screwte", size: 1 },
    8225: { name: "Flaygian Screwte", size: 1 },
    8226: { name: "Mekritus A'hara", size: 1 },
    8227: { name: "Mekritus A'hara", size: 1 },
    8228: { name: "Andiess Juip", size: 1 },
    8229: { name: "Andiess Juip", size: 1 },
    8230: { name: "Kael Forshaw", size: 1 },
    8231: { name: "Kael Forshaw", size: 1 },
    8232: { name: "Kael Forshaw", size: 1 },
    8233: { name: "Kael Forshaw", size: 1 },
    8234: { name: "null", size: 1 },
    8235: { name: "Meiyerditch citizen", size: 1 },
    8236: { name: "Meiyerditch citizen", size: 1 },
    8237: { name: "Meiyerditch citizen", size: 1 },
    8238: { name: "Vanstrom Klause", size: 1 },
    8239: { name: "Vanstrom Klause", size: 1 },
    8240: { name: "Vanstrom Klause", size: 3 },
    8241: { name: "Ranis Drakan", size: 1 },
    8242: { name: "Ranis Drakan", size: 1 },
    8243: { name: "Ranis Drakan", size: 3 },
    8244: { name: "Ranis Drakan", size: 1 },
    8245: { name: "Ranis Drakan", size: 1 },
    8246: { name: "Ranis Drakan", size: 3 },
    8247: { name: "Ranis Drakan", size: 3 },
    8248: { name: "Ranis Drakan", size: 1 },
    8249: { name: "Vanescula Drakan", size: 1 },
    8250: { name: "Verzik Vitur", size: 5 },
    8251: { name: "Vyrewatch", size: 1 },
    8252: { name: "Vyrewatch", size: 1 },
    8253: { name: "Vyrewatch", size: 1 },
    8254: { name: "Vyrewatch", size: 1 },
    8255: { name: "Vyrewatch", size: 1 },
    8256: { name: "Vyrewatch", size: 1 },
    8257: { name: "Vyrewatch", size: 1 },
    8258: { name: "Vyrewatch", size: 1 },
    8259: { name: "Vyrewatch", size: 1 },
    8260: { name: "Abomination", size: 2 },
    8261: { name: "Abomination", size: 2 },
    8262: { name: "Abomination", size: 2 },
    8263: { name: "Nylocas Ischyros", size: 1 },
    8264: { name: "Nylocas Toxobolos", size: 1 },
    8265: { name: "null", size: 1 },
    8266: { name: "null", size: 1 },
    8267: { name: "Andras", size: 1 },
    8268: { name: "Andras", size: 1 },
    8269: { name: "Yenrab", size: 1 },
    8270: { name: "Lahsram", size: 1 },
    8271: { name: "Erodoeht", size: 1 },
    8272: { name: "null", size: 1 },
    8273: { name: "Lector Gura", size: 1 },
    8274: { name: "Sister Sevi", size: 1 },
    8275: { name: "Sister Toen", size: 1 },
    8276: { name: "Sister Yram", size: 1 },
    8277: { name: "null", size: 1 },
    8278: { name: "null", size: 1 },
    8279: { name: "null", size: 1 },
    8280: { name: "null", size: 1 },
    8281: { name: "null", size: 1 },
    8282: { name: "null", size: 1 },
    8283: { name: "null", size: 1 },
    8284: { name: "null", size: 1 },
    8285: { name: "null", size: 1 },
    8286: { name: "null", size: 1 },
    8287: { name: "Lady Crombwick", size: 2 },
    8288: { name: "Nemishka", size: 2 },
    8289: { name: "null", size: 1 },
    8290: { name: "null", size: 1 },
    8291: { name: "null", size: 1 },
    8292: { name: "null", size: 1 },
    8293: { name: "null", size: 1 },
    8294: { name: "null", size: 1 },
    8295: { name: "null", size: 1 },
    8296: { name: "null", size: 1 },
    8297: { name: "Swamp Crab", size: 1 },
    8298: { name: "Swamp Crab", size: 1 },
    8299: { name: "Swampy log", size: 1 },
    8300: { name: "Vyrewatch", size: 1 },
    8301: { name: "Vyrewatch", size: 1 },
    8302: { name: "Vyrewatch", size: 1 },
    8303: { name: "Vyrewatch", size: 1 },
    8304: { name: "Vyrewatch", size: 1 },
    8305: { name: "Vyrewatch", size: 1 },
    8306: { name: "Vyrewatch", size: 1 },
    8307: { name: "Vyrewatch", size: 1 },
    8308: { name: "null", size: 1 },
    8309: { name: "null", size: 1 },
    8310: { name: "null", size: 1 },
    8311: { name: "null", size: 1 },
    8312: { name: "null", size: 1 },
    8313: { name: "null", size: 1 },
    8314: { name: "null", size: 1 },
    8315: { name: "null", size: 1 },
    8316: { name: "null", size: 1 },
    8317: { name: "null", size: 1 },
    8318: { name: "null", size: 1 },
    8319: { name: "null", size: 1 },
    8320: { name: "Meiyerditch citizen", size: 1 },
    8321: { name: "Banker", size: 1 },
    8322: { name: "Banker", size: 1 },
    8323: { name: "Vyre Orator", size: 2 },
    8324: { name: "Vyre Orator", size: 2 },
    8325: { name: "null", size: 1 },
    8326: { name: "Vampyre Juvenile", size: 1 },
    8327: { name: "Vampyre Juvenile", size: 1 },
    8328: { name: "Meiyerditch citizen", size: 1 },
    8329: { name: "Meiyerditch citizen", size: 1 },
    8330: { name: "Meiyerditch citizen", size: 1 },
    8331: { name: "Meiyerditch citizen", size: 1 },
    8332: { name: "Vyrelord", size: 1 },
    8333: { name: "Vyrelady", size: 1 },
    8334: { name: "Vyrelord", size: 1 },
    8335: { name: "Vyrelady", size: 1 },
    8336: { name: "Lil' Zik", size: 1 },
    8337: { name: "Lil' Zik", size: 1 },
    8338: { name: "Xarpus", size: 3 },
    8339: { name: "Xarpus", size: 3 },
    8340: { name: "Xarpus", size: 5 },
    8341: { name: "Xarpus", size: 5 },
    8342: { name: "Nylocas Ischyros", size: 1 },
    8343: { name: "Nylocas Toxobolos", size: 1 },
    8344: { name: "Nylocas Hagios", size: 1 },
    8345: { name: "Nylocas Ischyros", size: 2 },
    8346: { name: "Nylocas Toxobolos", size: 2 },
    8347: { name: "Nylocas Hagios", size: 2 },
    8348: { name: "Nylocas Ischyros", size: 1 },
    8349: { name: "Nylocas Toxobolos", size: 1 },
    8350: { name: "Nylocas Hagios", size: 1 },
    8351: { name: "Nylocas Ischyros", size: 2 },
    8352: { name: "Nylocas Toxobolos", size: 2 },
    8353: { name: "Nylocas Hagios", size: 2 },
    8354: { name: "Nylocas Vasilias", size: 4 },
    8355: { name: "Nylocas Vasilias", size: 4 },
    8356: { name: "Nylocas Vasilias", size: 4 },
    8357: { name: "Nylocas Vasilias", size: 4 },
    8358: { name: "null", size: 3 },
    8359: { name: "Pestilent Bloat", size: 5 },
    8360: { name: "The Maiden of Sugadinti", size: 6 },
    8361: { name: "The Maiden of Sugadinti", size: 6 },
    8362: { name: "The Maiden of Sugadinti", size: 6 },
    8363: { name: "The Maiden of Sugadinti", size: 6 },
    8364: { name: "The Maiden of Sugadinti", size: 6 },
    8365: { name: "The Maiden of Sugadinti", size: 6 },
    8366: { name: "Nylocas Matomenos", size: 2 },
    8367: { name: "Blood spawn", size: 1 },
    8368: { name: "Abigaila", size: 1 },
    8369: { name: "Verzik Vitur", size: 5 },
    8370: { name: "Verzik Vitur", size: 5 },
    8371: { name: "Verzik Vitur", size: 5 },
    8372: { name: "Verzik Vitur", size: 3 },
    8373: { name: "Verzik Vitur", size: 7 },
    8374: { name: "Verzik Vitur", size: 7 },
    8375: { name: "Verzik Vitur", size: 3 },
    8376: { name: "<col=00ffff>Web</col>", size: 1 },
    8377: { name: "<col=00ffff>Collapsing Pillar</col>", size: 5 },
    8378: { name: "<col=00ffff>Collapsing Pillar</col>", size: 5 },
    8379: { name: "<col=00ffff>Supporting Pillar</col>", size: 3 },
    8380: { name: "null", size: 3 },
    8381: { name: "Nylocas Ischyros", size: 2 },
    8382: { name: "Nylocas Toxobolos", size: 2 },
    8383: { name: "Nylocas Hagios", size: 2 },
    8384: { name: "Nylocas Athanatos", size: 3 },
    8385: { name: "Nylocas Matomenos", size: 2 },
    8386: { name: "null", size: 1 },
    8387: { name: "Sotetseg", size: 5 },
    8388: { name: "Sotetseg", size: 5 },
    8389: { name: "null", size: 3 },
    8390: { name: "Nigel", size: 1 },
    8391: { name: "Nigel", size: 1 },
    8392: { name: "null", size: 2 },
    8393: { name: "null", size: 2 },
    8394: { name: "null", size: 2 },
    8395: { name: "null", size: 1 },
    8396: { name: "null", size: 1 },
    8397: { name: "null", size: 1 },
    8398: { name: "null", size: 1 },
    8399: { name: "null", size: 1 },
    8400: { name: "Monk of Zamorak", size: 1 },
    8401: { name: "Monk of Zamorak", size: 1 },
    8402: { name: "Askeladden", size: 1 },
    8403: { name: "Askeladden", size: 1 },
    8404: { name: "Askeladden", size: 1 },
    8405: { name: "Askeladden", size: 1 },
    8406: { name: "null", size: 1 },
    8407: { name: "Wise Old Man", size: 1 },
    8408: { name: "null", size: 1 },
    8409: { name: "Wise Old Man", size: 2 },
    8410: { name: "Wise Young Man", size: 1 },
    8411: { name: "My Arm", size: 1 },
    8412: { name: "null", size: 1 },
    8413: { name: "null", size: 1 },
    8414: { name: "null", size: 1 },
    8415: { name: "null", size: 1 },
    8416: { name: "null", size: 1 },
    8417: { name: "null", size: 1 },
    8418: { name: "null", size: 1 },
    8419: { name: "Drunken Dwarf's Leg", size: 1 },
    8420: { name: "null", size: 1 },
    8421: { name: "null", size: 1 },
    8422: { name: "Squirrel", size: 1 },
    8423: { name: "null", size: 2 },
    8424: { name: "Wolfbone", size: 2 },
    8425: { name: "Mother", size: 3 },
    8426: { name: "Mother", size: 3 },
    8427: { name: "null", size: 3 },
    8428: { name: "Mother", size: 3 },
    8429: { name: "Mother", size: 3 },
    8430: { name: "Mother", size: 3 },
    8431: { name: "Snowflake", size: 1 },
    8432: { name: "null", size: 1 },
    8433: { name: "null", size: 1 },
    8434: { name: "Odd Mushroom", size: 2 },
    8435: { name: "Odd Mushroom", size: 2 },
    8436: { name: "null", size: 2 },
    8437: { name: "null", size: 2 },
    8438: { name: "Don't Know What", size: 3 },
    8439: { name: "Don't Know What", size: 3 },
    8440: { name: "Don't Know What", size: 3 },
    8441: { name: "null", size: 3 },
    8442: { name: "Boulder", size: 2 },
    8443: { name: "null", size: 2 },
    8444: { name: "Root", size: 2 },
    8445: { name: "Root", size: 2 },
    8446: { name: "Icicle", size: 2 },
    8447: { name: "Icicle", size: 2 },
    8448: { name: "Driftwood", size: 2 },
    8449: { name: "Driftwood", size: 2 },
    8450: { name: "Pebble", size: 2 },
    8451: { name: "Pebble", size: 2 },
    8452: { name: "Goat Poo", size: 2 },
    8453: { name: "Goat Poo", size: 2 },
    8454: { name: "Yellow Snow", size: 2 },
    8455: { name: "Yellow Snow", size: 2 },
    8456: { name: "Butterfly", size: 2 },
    8457: { name: "null", size: 2 },
    8458: { name: "null", size: 2 },
    8459: { name: "Butterfly", size: 2 },
    8460: { name: "Odd Stone", size: 2 },
    8461: { name: "null", size: 2 },
    8462: { name: "null", size: 2 },
    8463: { name: "Odd Stone", size: 2 },
    8464: { name: "Squirrel", size: 2 },
    8465: { name: "null", size: 2 },
    8466: { name: "null", size: 2 },
    8467: { name: "Squirrel", size: 2 },
    8468: { name: "null", size: 2 },
    8469: { name: "null", size: 3 },
    8470: { name: "Troll", size: 2 },
    8471: { name: "Troll", size: 2 },
    8472: { name: "Troll", size: 2 },
    8473: { name: "Troll", size: 2 },
    8474: { name: "Black Guard", size: 1 },
    8475: { name: "Black Guard", size: 1 },
    8476: { name: "Black Guard", size: 1 },
    8477: { name: "null", size: 3 },
    8478: { name: "null", size: 3 },
    8479: { name: "Combat Test (maxhit)", size: 1 },
    8480: { name: "Wizard Cromperty", size: 1 },
    8481: { name: "Wizard Cromperty", size: 1 },
    8482: { name: "Smoke Devil", size: 1 },
    8483: { name: "Smoke Devil", size: 1 },
    8484: { name: "Veos", size: 1 },
    8485: { name: "Eek", size: 1 },
    8486: { name: "null", size: 1 },
    8487: { name: "Makeover Mage", size: 1 },
    8488: { name: "null", size: 2 },
    8489: { name: "null", size: 2 },
    8490: { name: "null", size: 2 },
    8491: { name: "The Collector", size: 1 },
    8492: { name: "Ikkle Hydra", size: 1 },
    8493: { name: "Ikkle Hydra", size: 1 },
    8494: { name: "Ikkle Hydra", size: 1 },
    8495: { name: "Ikkle Hydra", size: 1 },
    8496: { name: "Dwarf", size: 1 },
    8497: { name: "null", size: 1 },
    8498: { name: "null", size: 1 },
    8499: { name: "null", size: 1 },
    8500: { name: "Old dwarf", size: 1 },
    8501: { name: "Mori", size: 1 },
    8502: { name: "Mori", size: 1 },
    8503: { name: "Survival Expert", size: 1 },
    8504: { name: "Lord Trobin Arceuus", size: 2 },
    8505: { name: "Lord Trobin Arceuus", size: 1 },
    8506: { name: "null", size: 1 },
    8507: { name: "Tower Mage", size: 1 },
    8508: { name: "Tower Mage", size: 1 },
    8509: { name: "Tower Mage", size: 1 },
    8510: { name: "Tower Mage", size: 1 },
    8511: { name: "Tower Mage", size: 1 },
    8512: { name: "Tormented Soul", size: 1 },
    8513: { name: "Tormented Soul", size: 1 },
    8514: { name: "Trapped Soul", size: 1 },
    8515: { name: "Alyssa", size: 1 },
    8516: { name: "null", size: 1 },
    8517: { name: "Ikkle Hydra", size: 1 },
    8518: { name: "Ikkle Hydra", size: 1 },
    8519: { name: "Ikkle Hydra", size: 1 },
    8520: { name: "Ikkle Hydra", size: 1 },
    8521: { name: "Alry the Angler", size: 1 },
    8522: { name: "Cormorant", size: 1 },
    8523: { name: "Fishing spot", size: 1 },
    8524: { name: "Rod Fishing spot", size: 1 },
    8525: { name: "Fishing spot", size: 1 },
    8526: { name: "Fishing spot", size: 1 },
    8527: { name: "Fishing spot", size: 1 },
    8528: { name: "Trapped Soul", size: 1 },
    8529: { name: "Trapped Soul (hard)", size: 1 },
    8530: { name: "Amelia", size: 1 },
    8531: { name: "Allanna", size: 1 },
    8532: { name: "Jatix", size: 1 },
    8533: { name: "Nikkie", size: 1 },
    8534: { name: "Rosie", size: 1 },
    8535: { name: "Alan", size: 1 },
    8536: { name: "Alexandra", size: 1 },
    8537: { name: "Latlink Fastbell", size: 1 },
    8538: { name: "Elise", size: 1 },
    8539: { name: "arc_test_01", size: 1 },
    8540: { name: "arc_test_02", size: 1 },
    8541: { name: "Little Parasite", size: 1 },
    8542: { name: "Royal Guard", size: 1 },
    8543: { name: "Royal Guard", size: 1 },
    8544: { name: "Undor", size: 1 },
    8545: { name: "Undor", size: 1 },
    8546: { name: "Thirus", size: 1 },
    8547: { name: "Thirus", size: 1 },
    8548: { name: "Darcor quo Narga", size: 1 },
    8549: { name: "Uurrak quo Narga", size: 1 },
    8550: { name: "Marmor quo Narga", size: 1 },
    8551: { name: "Lornor quo Narga", size: 1 },
    8552: { name: "Gorhak quo Narga", size: 1 },
    8553: { name: "Fornek quo Maten", size: 1 },
    8554: { name: "Vornas quo Maten", size: 1 },
    8555: { name: "Xorrah quo Sihar", size: 1 },
    8556: { name: "Corkat quo Sihar", size: 1 },
    8557: { name: "Lokraa quo Sihar", size: 1 },
    8558: { name: "Wengra quo Sihar", size: 1 },
    8559: { name: "Haldor quo Keran", size: 1 },
    8560: { name: "Vortas quo Keran", size: 1 },
    8561: { name: "Mallak quo Keran", size: 1 },
    8562: { name: "Richard Flintmaul", size: 1 },
    8563: { name: "Lizardman", size: 1 },
    8564: { name: "Lizardman brute", size: 1 },
    8565: { name: "Lizardman shaman", size: 2 },
    8566: { name: "Ranger", size: 1 },
    8567: { name: "Soldier", size: 1 },
    8568: { name: "Soldier", size: 1 },
    8569: { name: "Doyen", size: 1 },
    8570: { name: "Doyen", size: 1 },
    8571: { name: "Nurse Emma Gentsy", size: 1 },
    8572: { name: "Wounded soldier", size: 2 },
    8573: { name: "Wounded soldier", size: 2 },
    8574: { name: "Wounded soldier", size: 2 },
    8575: { name: "Soldier", size: 1 },
    8576: { name: "Lovakengj Engineer", size: 1 },
    8577: { name: "General Vir", size: 1 },
    8578: { name: "Swamp frog", size: 1 },
    8579: { name: "Shayzien Infiltrator", size: 1 },
    8580: { name: "null", size: 1 },
    8581: { name: "Ket'sal K'uk", size: 2 },
    8582: { name: "Ek'ans Chan", size: 2 },
    8583: { name: "Hespori", size: 3 },
    8584: { name: "Flower", size: 1 },
    8585: { name: "<col=00ffff>Flower</col>", size: 1 },
    8586: { name: "Guildmaster Jane", size: 1 },
    8587: { name: "Guildmaster Jane", size: 1 },
    8588: { name: "Arno", size: 1 },
    8589: { name: "Banker", size: 1 },
    8590: { name: "Banker", size: 1 },
    8591: { name: "Rabbit", size: 1 },
    8592: { name: "Rabbit", size: 1 },
    8593: { name: "Rabbit", size: 1 },
    8594: { name: "Cat", size: 1 },
    8595: { name: "Felfiz Yaryus", size: 1 },
    8596: { name: "Keith", size: 1 },
    8597: { name: "null", size: 1 },
    8598: { name: "<col=00ffff>Ornate combat dummy</col>", size: 1 },
    8599: { name: "Soldier", size: 1 },
    8600: { name: "Sergeant", size: 1 },
    8601: { name: "Ranger", size: 1 },
    8602: { name: "Kaal-Ket-Jor", size: 4 },
    8603: { name: "Kaal-Mej-San", size: 4 },
    8604: { name: "Kaal-Xil-Dar", size: 4 },
    8605: { name: "Tarfol quo Maten", size: 1 },
    8606: { name: "Kormar quo Maten", size: 1 },
    8607: { name: "Garbek quo Maten", size: 1 },
    8608: { name: "Orrvor quo Maten", size: 2 },
    8609: { name: "Hydra", size: 3 },
    8610: { name: "Wyrm", size: 3 },
    8611: { name: "Wyrm", size: 3 },
    8612: { name: "Drake", size: 5 },
    8613: { name: "Drake", size: 5 },
    8614: { name: "Sulphur Lizard", size: 2 },
    8615: { name: "Alchemical Hydra", size: 6 },
    8616: { name: "Alchemical Hydra", size: 6 },
    8617: { name: "Alchemical Hydra", size: 6 },
    8618: { name: "Alchemical Hydra", size: 6 },
    8619: { name: "Alchemical Hydra", size: 6 },
    8620: { name: "Alchemical Hydra", size: 6 },
    8621: { name: "Alchemical Hydra", size: 6 },
    8622: { name: "Alchemical Hydra", size: 6 },
    8623: { name: "Konar quo Maten", size: 1 },
    8624: { name: "null", size: 2 },
    8625: { name: "null", size: 1 },
    8626: { name: "null", size: 1 },
    8627: { name: "null", size: 1 },
    8628: { name: "null", size: 1 },
    8629: { name: "Taylor", size: 1 },
    8630: { name: "Veos", size: 1 },
    8631: { name: "Seaman Morris", size: 1 },
    8632: { name: "null", size: 1 },
    8633: { name: "The Mimic", size: 5 },
    8634: { name: "Alchemical Hydra", size: 1 },
    8635: { name: "Third Age Warrior", size: 1 },
    8636: { name: "Third Age Ranger", size: 1 },
    8637: { name: "Third Age Mage", size: 1 },
    8638: { name: "Uri", size: 1 },
    8639: { name: "null", size: 1 },
    8640: { name: "null", size: 1 },
    8641: { name: "null", size: 1 },
    8642: { name: "null", size: 1 },
    8643: { name: "<col=00ffff>Fire</col>", size: 2 },
    8644: { name: "Saradominist recruiter", size: 1 },
    8645: { name: "null", size: 1 },
    8646: { name: "null", size: 1 },
    8647: { name: "null", size: 1 },
    8648: { name: "null", size: 1 },
    8649: { name: "null", size: 1 },
    8650: { name: "null", size: 1 },
    8651: { name: "null", size: 1 },
    8652: { name: "null", size: 1 },
    8653: { name: "null", size: 1 },
    8654: { name: "null", size: 1 },
    8655: { name: "null", size: 1 },
    8656: { name: "null", size: 1 },
    8657: { name: "null", size: 1 },
    8658: { name: "null", size: 1 },
    8659: { name: "null", size: 1 },
    8660: { name: "null", size: 1 },
    8661: { name: "null", size: 1 },
    8662: { name: "null", size: 1 },
    8663: { name: "null", size: 1 },
    8664: { name: "null", size: 1 },
    8665: { name: "Arthur the Clue Hunter", size: 1 },
    8666: { name: "Banker", size: 1 },
    8667: { name: "Puffer Fish", size: 1 },
    8668: { name: "null", size: 1 },
    8669: { name: "null", size: 1 },
    8670: { name: "null", size: 1 },
    8671: { name: "null", size: 1 },
    8672: { name: "null", size: 1 },
    8673: { name: "null", size: 1 },
    8674: { name: "null", size: 1 },
    8675: { name: "Islwyn", size: 1 },
    8676: { name: "Ilfeen", size: 1 },
    8677: { name: "null", size: 1 },
    8678: { name: "Feral Vampyre", size: 1 },
    8679: { name: "Abidor Crank", size: 1 },
    8680: { name: "Davon", size: 1 },
    8681: { name: "Zenesha", size: 1 },
    8682: { name: "Aemad", size: 1 },
    8683: { name: "Kortan", size: 1 },
    8684: { name: "Roachey", size: 1 },
    8685: { name: "Frenita", size: 1 },
    8686: { name: "Nurmof", size: 1 },
    8687: { name: "Tea Seller", size: 1 },
    8688: { name: "Fat Tony", size: 1 },
    8689: { name: "null", size: 1 },
    8690: { name: "Ancient Fungi", size: 1 },
    8691: { name: "Ancient Fungi", size: 1 },
    8692: { name: "Noterazzo", size: 1 },
    8693: { name: "Diango", size: 1 },
    8694: { name: "Brian", size: 1 },
    8695: { name: "null", size: 1 },
    8696: { name: "Mosol Rei", size: 1 },
    8697: { name: "Leke quo Keran", size: 1 },
    8698: { name: "Monk of Zamorak", size: 1 },
    8699: { name: "Larran", size: 1 },
    8700: { name: "Giant frog", size: 2 },
    8701: { name: "Big frog", size: 1 },
    8702: { name: "Frog", size: 1 },
    8703: { name: "Temple Spider", size: 1 },
    8704: { name: "Brother Aimeri", size: 1 },
    8705: { name: "Brother Aimeri", size: 1 },
    8706: { name: "Olbertus", size: 1 },
    8707: { name: "Olbertus", size: 1 },
    8708: { name: "Olbertus", size: 1 },
    8709: { name: "Shaeded Beast", size: 3 },
    8710: { name: "Shaeded Beast", size: 1 },
    8711: { name: "Eodan", size: 1 },
    8712: { name: "Knight of Varlamore", size: 1 },
    8713: { name: "Sarachnis", size: 5 },
    8714: { name: "Spawn of Sarachnis", size: 2 },
    8715: { name: "Spawn of Sarachnis", size: 2 },
    8716: { name: "null", size: 1 },
    8717: { name: "null", size: 1 },
    8718: { name: "null", size: 1 },
    8719: { name: "null", size: 3 },
    8720: { name: "null", size: 1 },
    8721: { name: "Justine", size: 1 },
    8722: { name: "Silver merchant", size: 1 },
    8723: { name: "Gem merchant", size: 1 },
    8724: { name: "Baker", size: 1 },
    8725: { name: "Baker", size: 1 },
    8726: { name: "Spice seller", size: 1 },
    8727: { name: "Fur trader", size: 1 },
    8728: { name: "Silk merchant", size: 1 },
    8729: { name: "Youngllef", size: 1 },
    8730: { name: "Corrupted Youngllef", size: 1 },
    8731: { name: "Smolcano", size: 1 },
    8732: { name: "Mugger", size: 1 },
    8733: { name: "Crab", size: 1 },
    8734: { name: "null", size: 1 },
    8735: { name: "null", size: 1 },
    8736: { name: "Moss Giant", size: 2 },
    8737: { name: "Youngllef", size: 1 },
    8738: { name: "Corrupted Youngllef", size: 1 },
    8739: { name: "Smolcano", size: 1 },
    8740: { name: "null", size: 1 },
    8741: { name: "Crystal impling", size: 1 },
    8742: { name: "Crystal impling", size: 1 },
    8743: { name: "Crystal impling", size: 1 },
    8744: { name: "Crystal impling", size: 1 },
    8745: { name: "Crystal impling", size: 1 },
    8746: { name: "Crystal impling", size: 1 },
    8747: { name: "Crystal impling", size: 1 },
    8748: { name: "Crystal impling", size: 1 },
    8749: { name: "Crystal impling", size: 1 },
    8750: { name: "Crystal impling", size: 1 },
    8751: { name: "Crystal impling", size: 1 },
    8752: { name: "Crystal impling", size: 1 },
    8753: { name: "Crystal impling", size: 1 },
    8754: { name: "Crystal impling", size: 1 },
    8755: { name: "Crystal impling", size: 1 },
    8756: { name: "Crystal impling", size: 1 },
    8757: { name: "Crystal impling", size: 1 },
    8758: { name: "Lord Iorwerth", size: 1 },
    8759: { name: "Iorwerth Warrior", size: 1 },
    8760: { name: "Iorwerth Archer", size: 1 },
    8761: { name: "Elf Tracker", size: 1 },
    8762: { name: "Tyras guard", size: 1 },
    8763: { name: "Captain Barnaby", size: 1 },
    8764: { name: "Captain Barnaby", size: 1 },
    8765: { name: "Councillor Halgrive", size: 1 },
    8766: { name: "Eluned", size: 1 },
    8767: { name: "Eluned", size: 1 },
    8768: { name: "Elven Scout", size: 1 },
    8769: { name: "Ilfeen", size: 1 },
    8770: { name: "Elven Scout", size: 1 },
    8771: { name: "Mourner", size: 1 },
    8772: { name: "Slave", size: 1 },
    8773: { name: "Slave", size: 1 },
    8774: { name: "Slave", size: 1 },
    8775: { name: "Seren", size: 1 },
    8776: { name: "Memory of Seren", size: 1 },
    8777: { name: "Memory of Seren", size: 3 },
    8778: { name: "Memory of Seren", size: 3 },
    8779: { name: "Memory of Seren", size: 3 },
    8780: { name: "Memory of Seren", size: 3 },
    8781: { name: "Memory of Seren", size: 3 },
    8782: { name: "Memory of Seren", size: 3 },
    8783: { name: "Memory of Seren", size: 3 },
    8784: { name: "Memory of Seren", size: 3 },
    8785: { name: "null", size: 1 },
    8786: { name: "null", size: 1 },
    8787: { name: "null", size: 1 },
    8788: { name: "null", size: 1 },
    8789: { name: "null", size: 1 },
    8790: { name: "null", size: 1 },
    8791: { name: "Elena", size: 1 },
    8792: { name: "Elena", size: 1 },
    8793: { name: "Elena", size: 1 },
    8794: { name: "Elena", size: 1 },
    8795: { name: "Elena", size: 1 },
    8796: { name: "null", size: 1 },
    8797: { name: "Elena", size: 1 },
    8798: { name: "Elena", size: 1 },
    8799: { name: "Knight of Ardougne", size: 1 },
    8800: { name: "Knight of Ardougne", size: 1 },
    8801: { name: "Knight of Ardougne", size: 1 },
    8802: { name: "Councillor Halgrive", size: 1 },
    8803: { name: "Councillor Halgrive", size: 1 },
    8804: { name: "Omart", size: 1 },
    8805: { name: "Kilron", size: 1 },
    8806: { name: "Jethick", size: 1 },
    8807: { name: "Carla", size: 1 },
    8808: { name: "Baxtorian", size: 1 },
    8809: { name: "Baxtorian", size: 1 },
    8810: { name: "Baxtorian", size: 1 },
    8811: { name: "Baxtorian", size: 1 },
    8812: { name: "Baxtorian", size: 1 },
    8813: { name: "Baxtorian", size: 1 },
    8814: { name: "Ysgawyn", size: 1 },
    8815: { name: "Ysgawyn", size: 1 },
    8816: { name: "Ysgawyn", size: 1 },
    8817: { name: "null", size: 1 },
    8818: { name: "Islwyn", size: 1 },
    8819: { name: "Islwyn", size: 1 },
    8820: { name: "null", size: 1 },
    8821: { name: "Islwyn", size: 1 },
    8822: { name: "Islwyn", size: 1 },
    8823: { name: "Islwyn", size: 1 },
    8824: { name: "null", size: 1 },
    8825: { name: "Ilfeen", size: 1 },
    8826: { name: "null", size: 1 },
    8827: { name: "Ilfeen", size: 1 },
    8828: { name: "null", size: 1 },
    8829: { name: "Eluned", size: 1 },
    8830: { name: "Eluned", size: 1 },
    8831: { name: "Resistance Fighter", size: 1 },
    8832: { name: "Resistance Fighter", size: 1 },
    8833: { name: "Resistance Fighter", size: 1 },
    8834: { name: "Resistance Fighter", size: 1 },
    8835: { name: "Resistance Fighter", size: 1 },
    8836: { name: "Omart", size: 1 },
    8837: { name: "Kilron", size: 1 },
    8838: { name: "Elena", size: 1 },
    8839: { name: "Jethick", size: 1 },
    8840: { name: "Omart", size: 1 },
    8841: { name: "Kilron", size: 1 },
    8842: { name: "King Lathas", size: 1 },
    8843: { name: "Lathas", size: 1 },
    8844: { name: "Mourner", size: 1 },
    8845: { name: "Mourner", size: 1 },
    8846: { name: "Mourner", size: 1 },
    8847: { name: "Essyllt", size: 1 },
    8848: { name: "Gnome", size: 1 },
    8849: { name: "Paladin", size: 1 },
    8850: { name: "Paladin", size: 1 },
    8851: { name: "Knight of Ardougne", size: 1 },
    8852: { name: "Knight of Ardougne", size: 1 },
    8853: { name: "Paladin", size: 1 },
    8854: { name: "Knight of Ardougne", size: 1 },
    8855: { name: "Knight of Ardougne", size: 1 },
    8856: { name: "Executioner", size: 1 },
    8857: { name: "Hooded Figure", size: 1 },
    8858: { name: "Man", size: 1 },
    8859: { name: "Man", size: 1 },
    8860: { name: "Man", size: 1 },
    8861: { name: "Man", size: 1 },
    8862: { name: "Man", size: 1 },
    8863: { name: "Woman", size: 1 },
    8864: { name: "Woman", size: 1 },
    8865: { name: "Arianwyn", size: 1 },
    8866: { name: "Arianwyn", size: 1 },
    8867: { name: "Arianwyn", size: 1 },
    8868: { name: "Arianwyn", size: 1 },
    8869: { name: "Morvran", size: 1 },
    8870: { name: "Morvran", size: 1 },
    8871: { name: "Essyllt", size: 1 },
    8872: { name: "Essyllt", size: 1 },
    8873: { name: "Lord Iorwerth", size: 1 },
    8874: { name: "Lord Iorwerth", size: 2 },
    8875: { name: "Lord Iorwerth", size: 1 },
    8876: { name: "Lord Iorwerth", size: 1 },
    8877: { name: "Iorwerth Warrior", size: 1 },
    8878: { name: "Iorwerth Archer", size: 1 },
    8879: { name: "Iorwerth Warrior", size: 1 },
    8880: { name: "Iorwerth Archer", size: 1 },
    8881: { name: "Iorwerth Warrior", size: 1 },
    8882: { name: "Iorwerth Warrior", size: 1 },
    8883: { name: "Iorwerth Warrior", size: 1 },
    8884: { name: "Iorwerth Warrior", size: 1 },
    8885: { name: "Iorwerth Archer", size: 1 },
    8886: { name: "Iorwerth Archer", size: 1 },
    8887: { name: "Rebel Archer", size: 1 },
    8888: { name: "Rebel Archer", size: 1 },
    8889: { name: "Rebel Archer", size: 1 },
    8890: { name: "Rebel Warrior", size: 1 },
    8891: { name: "Rebel Warrior", size: 1 },
    8892: { name: "Lady Tangwen Trahaearn", size: 1 },
    8893: { name: "Elderly Elf", size: 1 },
    8894: { name: "Elderly Elf", size: 1 },
    8895: { name: "Lady Tangwen Trahaearn", size: 1 },
    8896: { name: "null", size: 1 },
    8897: { name: "Mysterious Figure", size: 1 },
    8898: { name: "Lord Ieuan Amlodd", size: 1 },
    8899: { name: "Lord Ieuan Amlodd", size: 1 },
    8900: { name: "null", size: 1 },
    8901: { name: "<col=00ffff>Tree</col>", size: 1 },
    8902: { name: "<col=00ffff>Tree</col>", size: 1 },
    8903: { name: "Lord Piquan Crwys", size: 1 },
    8904: { name: "Lord Piquan Crwys", size: 1 },
    8905: { name: "null", size: 1 },
    8906: { name: "Elf Hermit", size: 1 },
    8907: { name: "Lady Carys Hefin", size: 1 },
    8908: { name: "Lady Carys Hefin", size: 1 },
    8909: { name: "null", size: 1 },
    8910: { name: "Lady Ffion Meilyr", size: 1 },
    8911: { name: "Lady Ffion Meilyr", size: 1 },
    8912: { name: "null", size: 1 },
    8913: { name: "Lady Kelyn Ithell", size: 1 },
    8914: { name: "Kelyn", size: 1 },
    8915: { name: "Lady Kelyn Ithell", size: 1 },
    8916: { name: "null", size: 1 },
    8917: { name: "Fragment of Seren", size: 3 },
    8918: { name: "Fragment of Seren", size: 3 },
    8919: { name: "Fragment of Seren", size: 3 },
    8920: { name: "Fragment of Seren", size: 3 },
    8921: { name: "<col=00ffff>Crystal Whirlwind</col>", size: 1 },
    8922: { name: "Iorwerth Warrior", size: 1 },
    8923: { name: "Iorwerth Archer", size: 1 },
    8924: { name: "Iestin", size: 1 },
    8925: { name: "Iestin", size: 1 },
    8926: { name: "Iestin", size: 1 },
    8927: { name: "Mawrth", size: 1 },
    8928: { name: "Mawrth", size: 1 },
    8929: { name: "Iona", size: 1 },
    8930: { name: "Eoin", size: 1 },
    8931: { name: "Eoin", size: 1 },
    8932: { name: "Rebel Archer", size: 1 },
    8933: { name: "Rebel Archer", size: 1 },
    8934: { name: "Rebel Warrior", size: 1 },
    8935: { name: "Rebel Warrior", size: 1 },
    8936: { name: "Iorwerth Archer", size: 1 },
    8937: { name: "Iorwerth Archer", size: 1 },
    8938: { name: "Iorwerth Warrior", size: 1 },
    8939: { name: "Iorwerth Warrior", size: 1 },
    8940: { name: "Klank", size: 1 },
    8941: { name: "Niloof", size: 1 },
    8942: { name: "Kamen", size: 1 },
    8943: { name: "Thorgel", size: 1 },
    8944: { name: "General Hining", size: 1 },
    8945: { name: "Tyras Guard", size: 1 },
    8946: { name: "Rebel Archer", size: 1 },
    8947: { name: "Rebel Archer", size: 1 },
    8948: { name: "Rebel Warrior", size: 1 },
    8949: { name: "Rebel Warrior", size: 1 },
    8950: { name: "Essyllt", size: 1 },
    8951: { name: "<col=00ffff>Barrier</col>", size: 2 },
    8952: { name: "<col=00ffff>Barrier</col>", size: 2 },
    8953: { name: "Iorwerth Archer", size: 1 },
    8954: { name: "Iorwerth Archer", size: 1 },
    8955: { name: "Iorwerth Warrior", size: 1 },
    8956: { name: "Iorwerth Warrior", size: 1 },
    8957: { name: "Rebel Archer", size: 1 },
    8958: { name: "Rebel Archer", size: 1 },
    8959: { name: "Rebel Warrior", size: 1 },
    8960: { name: "Rebel Warrior", size: 1 },
    8961: { name: "Tyras Guard", size: 1 },
    8962: { name: "Tyras Guard", size: 1 },
    8963: { name: "Tyras Guard", size: 1 },
    8964: { name: "Tyras Guard", size: 1 },
    8965: { name: "Rebel Scout", size: 1 },
    8966: { name: "Rebel Scout", size: 1 },
    8967: { name: "Carla", size: 1 },
    8968: { name: "Clerk", size: 1 },
    8969: { name: "Head Mourner", size: 1 },
    8970: { name: "Mourner", size: 1 },
    8971: { name: "Mourner", size: 1 },
    8972: { name: "Mourner", size: 1 },
    8973: { name: "Recruiter", size: 1 },
    8974: { name: "Jethick", size: 1 },
    8975: { name: "Mourner", size: 1 },
    8976: { name: "Koftik", size: 1 },
    8977: { name: "null", size: 1 },
    8978: { name: "Blessed spider", size: 1 },
    8979: { name: "Slave", size: 1 },
    8980: { name: "Slave", size: 1 },
    8981: { name: "Slave", size: 1 },
    8982: { name: "Slave", size: 1 },
    8983: { name: "Slave", size: 1 },
    8984: { name: "Slave", size: 1 },
    8985: { name: "Slave", size: 1 },
    8986: { name: "Unicorn", size: 2 },
    8987: { name: "Sir Jerro", size: 1 },
    8988: { name: "Sir Carl", size: 1 },
    8989: { name: "Sir Harry", size: 1 },
    8990: { name: "Half-soulless", size: 2 },
    8991: { name: "Kardia", size: 1 },
    8992: { name: "Witch's cat", size: 1 },
    8993: { name: "Kalrag", size: 3 },
    8994: { name: "Othainian", size: 3 },
    8995: { name: "Doomion", size: 3 },
    8996: { name: "Holthion", size: 3 },
    8997: { name: "Disciple of Iban", size: 1 },
    8998: { name: "Iban", size: 2 },
    8999: { name: "Mourner", size: 1 },
    9000: { name: "Mourner", size: 1 },
    9001: { name: "Kilron", size: 1 },
    9002: { name: "Omart", size: 1 },
    9003: { name: "Mourner", size: 1 },
    9004: { name: "Mourner", size: 1 },
    9005: { name: "King Lathas", size: 1 },
    9006: { name: "King Thoros", size: 1 },
    9007: { name: "Mourner", size: 1 },
    9008: { name: "Mourner", size: 1 },
    9009: { name: "Mourner", size: 1 },
    9010: { name: "Mourner", size: 1 },
    9011: { name: "King Thoros", size: 1 },
    9012: { name: "null", size: 1 },
    9013: { name: "Mourner", size: 1 },
    9014: { name: "Arianwyn", size: 1 },
    9015: { name: "Kelyn", size: 1 },
    9016: { name: "Essyllt", size: 1 },
    9017: { name: "Mourner", size: 1 },
    9018: { name: "Mourner", size: 1 },
    9019: { name: "Ed", size: 1 },
    9020: { name: "Bryn", size: 1 },
    9021: { name: "Crystalline Hunllef", size: 5 },
    9022: { name: "Crystalline Hunllef", size: 5 },
    9023: { name: "Crystalline Hunllef", size: 5 },
    9024: { name: "Crystalline Hunllef", size: 5 },
    9025: { name: "null", size: 1 },
    9026: { name: "Crystalline Rat", size: 2 },
    9027: { name: "Crystalline Spider", size: 1 },
    9028: { name: "Crystalline Bat", size: 2 },
    9029: { name: "Crystalline Unicorn", size: 2 },
    9030: { name: "Crystalline Scorpion", size: 2 },
    9031: { name: "Crystalline Wolf", size: 2 },
    9032: { name: "Crystalline Bear", size: 3 },
    9033: { name: "Crystalline Dragon", size: 4 },
    9034: { name: "Crystalline Dark Beast", size: 3 },
    9035: { name: "Corrupted Hunllef", size: 5 },
    9036: { name: "Corrupted Hunllef", size: 5 },
    9037: { name: "Corrupted Hunllef", size: 5 },
    9038: { name: "Corrupted Hunllef", size: 5 },
    9039: { name: "null", size: 1 },
    9040: { name: "Corrupted Rat", size: 2 },
    9041: { name: "Corrupted Spider", size: 1 },
    9042: { name: "Corrupted Bat", size: 2 },
    9043: { name: "Corrupted Unicorn", size: 2 },
    9044: { name: "Corrupted Scorpion", size: 2 },
    9045: { name: "Corrupted Wolf", size: 2 },
    9046: { name: "Corrupted Bear", size: 3 },
    9047: { name: "Corrupted Dragon", size: 4 },
    9048: { name: "Corrupted Dark Beast", size: 3 },
    9049: { name: "Zalcano", size: 5 },
    9050: { name: "Zalcano", size: 5 },
    9051: { name: "Golem", size: 1 },
    9052: { name: "Rhiannon", size: 1 },
    9053: { name: "Amrod", size: 1 },
    9054: { name: "Miriel", size: 1 },
    9055: { name: "Curufin", size: 1 },
    9056: { name: "Enerdhil", size: 1 },
    9057: { name: "Tatie", size: 1 },
    9058: { name: "Finduilas", size: 1 },
    9059: { name: "Gelmir", size: 1 },
    9060: { name: "Mithrellas", size: 1 },
    9061: { name: "Erestor", size: 1 },
    9062: { name: "Lindir", size: 1 },
    9063: { name: "Idril", size: 1 },
    9064: { name: "Ingwion", size: 1 },
    9065: { name: "Thingol", size: 1 },
    9066: { name: "Elenwe", size: 1 },
    9067: { name: "Orophin", size: 1 },
    9068: { name: "Vaire", size: 1 },
    9069: { name: "Elladan", size: 1 },
    9070: { name: "Guilin", size: 1 },
    9071: { name: "Ingwe", size: 1 },
    9072: { name: "Cirdan", size: 1 },
    9073: { name: "Glorfindel", size: 1 },
    9074: { name: "Aredhel", size: 1 },
    9075: { name: "Celegorm", size: 1 },
    9076: { name: "Anaire", size: 1 },
    9077: { name: "Maeglin", size: 1 },
    9078: { name: "Edrahil", size: 1 },
    9079: { name: "Fingon", size: 1 },
    9080: { name: "Salgant", size: 1 },
    9081: { name: "Celebrian", size: 1 },
    9082: { name: "Imin", size: 1 },
    9083: { name: "Oropher", size: 1 },
    9084: { name: "Fingolfin", size: 1 },
    9085: { name: "Mahtan", size: 1 },
    9086: { name: "Indis", size: 1 },
    9087: { name: "Iminye", size: 1 },
    9088: { name: "Feanor", size: 1 },
    9089: { name: "Saeros", size: 1 },
    9090: { name: "Nellas", size: 1 },
    9091: { name: "Rhyfel", size: 1 },
    9092: { name: "Gwyl", size: 1 },
    9093: { name: "Enilly", size: 1 },
    9094: { name: "Ffoni", size: 1 },
    9095: { name: "Ymladd", size: 1 },
    9096: { name: "Sadwrn", size: 1 },
    9097: { name: "Diol", size: 1 },
    9098: { name: "Ysbeid", size: 1 },
    9099: { name: "Cleddyf", size: 1 },
    9100: { name: "Saeth", size: 1 },
    9101: { name: "Nimrodel", size: 1 },
    9102: { name: "Maedhros", size: 1 },
    9103: { name: "Finarfin", size: 1 },
    9104: { name: "Gwindor", size: 1 },
    9105: { name: "Eldalote", size: 1 },
    9106: { name: "Enelye", size: 1 },
    9107: { name: "Nerdanel", size: 1 },
    9108: { name: "Nimloth", size: 1 },
    9109: { name: "Findis", size: 1 },
    9110: { name: "Earwen", size: 1 },
    9111: { name: "Caranthir", size: 1 },
    9112: { name: "Enel", size: 1 },
    9113: { name: "Hendor", size: 1 },
    9114: { name: "Galathil", size: 1 },
    9115: { name: "Turgon", size: 1 },
    9116: { name: "Lenwe", size: 1 },
    9117: { name: "Aranwe", size: 1 },
    9118: { name: "Rabbit", size: 1 },
    9119: { name: "Lord Ieuan Amlodd", size: 1 },
    9120: { name: "Lord Baxtorian Cadarn", size: 1 },
    9121: { name: "Lady Kelyn Ithell", size: 1 },
    9122: { name: "Lady Tangwen Trahaearn", size: 1 },
    9123: { name: "Lord Piquan Crwys", size: 1 },
    9124: { name: "Lady Ffion Meilyr", size: 1 },
    9125: { name: "Lady Carys Hefin", size: 1 },
    9126: { name: "Lord Iestin Iorwerth", size: 1 },
    9127: { name: "Banker", size: 1 },
    9128: { name: "Banker", size: 1 },
    9129: { name: "Banker", size: 1 },
    9130: { name: "Banker", size: 1 },
    9131: { name: "Banker", size: 1 },
    9132: { name: "Banker", size: 1 },
    9133: { name: "Reese", size: 1 },
    9134: { name: "Reese", size: 1 },
    9135: { name: "Conwenna", size: 1 },
    9136: { name: "Conwenna", size: 1 },
    9137: { name: "Alwyn", size: 1 },
    9138: { name: "Oswallt", size: 1 },
    9139: { name: "Eira", size: 1 },
    9140: { name: "Sawmill Operator", size: 1 },
    9141: { name: "Pennant", size: 1 },
    9142: { name: "Pennant", size: 1 },
    9143: { name: "Gladiator", size: 1 },
    9144: { name: "Gladiator", size: 1 },
    9145: { name: "Eluned", size: 1 },
    9146: { name: "Islwyn", size: 1 },
    9147: { name: "null", size: 1 },
    9148: { name: "Elena", size: 1 },
    9149: { name: "Morvran", size: 1 },
    9150: { name: "Lilifang", size: 1 },
    9151: { name: "Crefydd", size: 1 },
    9152: { name: "Ystwyth", size: 1 },
    9153: { name: "Iwan", size: 1 },
    9154: { name: "Derwen", size: 1 },
    9155: { name: "Elgan", size: 1 },
    9156: { name: "Celyn", size: 1 },
    9157: { name: "Aneirin", size: 1 },
    9158: { name: "Gwallter", size: 1 },
    9159: { name: "Gwyn", size: 1 },
    9160: { name: "Osian", size: 1 },
    9161: { name: "Caerwyn", size: 1 },
    9162: { name: "Anwen", size: 1 },
    9163: { name: "Glenda", size: 1 },
    9164: { name: "Guinevere", size: 1 },
    9165: { name: "Nia", size: 1 },
    9166: { name: "Sian", size: 1 },
    9167: { name: "Branwen", size: 1 },
    9168: { name: "Llio", size: 1 },
    9169: { name: "Efa", size: 1 },
    9170: { name: "Lliann", size: 1 },
    9171: { name: "Fishing spot", size: 1 },
    9172: { name: "Fishing spot", size: 1 },
    9173: { name: "Fishing spot", size: 1 },
    9174: { name: "Fishing spot", size: 1 },
    9175: { name: "Rhoddwr Tan", size: 1 },
    9176: { name: "Meddwl Ymlaen Llaw", size: 1 },
    9177: { name: "Lleidr Golau", size: 1 },
    9178: { name: "Gwerin Hapus", size: 1 },
    9179: { name: "Preswylwyr Dall", size: 1 },
    9180: { name: "Marwolaeth Dawnsio", size: 1 },
    9181: { name: "Dire Wolf", size: 2 },
    9182: { name: "Guard", size: 1 },
    9183: { name: "Guard", size: 1 },
    9184: { name: "Guard", size: 1 },
    9185: { name: "Guard", size: 1 },
    9186: { name: "Guard", size: 1 },
    9187: { name: "Guard", size: 1 },
    9188: { name: "Guard", size: 1 },
    9189: { name: "Guard", size: 1 },
    9190: { name: "Guard", size: 1 },
    9191: { name: "Guard", size: 1 },
    9192: { name: "Raven", size: 1 },
    9193: { name: "Lenny", size: 1 },
    9194: { name: "Ghost", size: 1 },
    9195: { name: "Floki", size: 1 },
    9196: { name: "Surma", size: 1 },
    9197: { name: "Slippers", size: 1 },
    9198: { name: "Red Panda", size: 1 },
    9199: { name: "Bear Cub", size: 2 },
    9200: { name: "Ed", size: 1 },
    9201: { name: "Crab", size: 1 },
    9202: { name: "Tide", size: 1 },
    9203: { name: "null", size: 1 },
    9204: { name: "null", size: 1 },
    9205: { name: "null", size: 1 },
    9206: { name: "null", size: 1 },
    9207: { name: "null", size: 1 },
    9208: { name: "null", size: 1 },
    9209: { name: "null", size: 2 },
    9210: { name: "null", size: 1 },
    9211: { name: "null", size: 1 },
    9212: { name: "null", size: 1 },
    9213: { name: "null", size: 2 },
    9214: { name: "null", size: 1 },
    9215: { name: "null", size: 1 },
    9216: { name: "null", size: 3 },
    9217: { name: "null", size: 3 },
    9218: { name: "null", size: 3 },
    9219: { name: "null", size: 3 },
    9220: { name: "null", size: 1 },
    9221: { name: "null", size: 2 },
    9222: { name: "null", size: 1 },
    9223: { name: "null", size: 1 },
    9224: { name: "null", size: 1 },
    9225: { name: "null", size: 1 },
    9226: { name: "null", size: 1 },
    9227: { name: "null", size: 1 },
    9228: { name: "null", size: 1 },
    9229: { name: "null", size: 1 },
    9230: { name: "null", size: 1 },
    9231: { name: "null", size: 1 },
    9232: { name: "null", size: 1 },
    9233: { name: "null", size: 1 },
    9234: { name: "null", size: 1 },
    9235: { name: "null", size: 1 },
    9236: { name: "null", size: 1 },
    9237: { name: "null", size: 1 },
    9238: { name: "null", size: 1 },
    9239: { name: "null", size: 1 },
    9240: { name: "null", size: 1 },
    9241: { name: "null", size: 1 },
    9242: { name: "null", size: 1 },
    9243: { name: "null", size: 1 },
    9244: { name: "Adventurer Jon", size: 1 },
    9245: { name: "null", size: 1 },
    9246: { name: "Arianwyn (hard)", size: 1 },
    9247: { name: "Essyllt (hard)", size: 1 },
    9248: { name: "Arianwyn", size: 1 },
    9249: { name: "Essyllt", size: 1 },
    9250: { name: "Captain Barnaby", size: 1 },
    9251: { name: "Toy Soldier", size: 1 },
    9252: { name: "Toy Doll", size: 1 },
    9253: { name: "Toy Doll", size: 1 },
    9254: { name: "Toy Mouse", size: 1 },
    9255: { name: "Toy Mouse", size: 1 },
    9256: { name: "null", size: 1 },
    9257: { name: "Penguin suit", size: 1 },
    9258: { name: "Basilisk Sentinel", size: 3 },
    9259: { name: "Ospak", size: 1 },
    9260: { name: "Styrmir", size: 1 },
    9261: { name: "Torbrund", size: 1 },
    9262: { name: "Fridgeir", size: 1 },
    9263: { name: "Brundt the Chieftain", size: 1 },
    9264: { name: "Thora the Barkeep", size: 1 },
    9265: { name: "Brundt the Chieftain", size: 1 },
    9266: { name: "Brundt the Chieftain", size: 1 },
    9267: { name: "Brundt the Chieftain", size: 1 },
    9268: { name: "Brundt the Chieftain", size: 1 },
    9269: { name: "null", size: 1 },
    9270: { name: "Haskell", size: 1 },
    9271: { name: "Haskell", size: 1 },
    9272: { name: "Haskell", size: 1 },
    9273: { name: "Agnar", size: 1 },
    9274: { name: "Olaf the Bard", size: 1 },
    9275: { name: "Manni the Reveller", size: 1 },
    9276: { name: "Fremennik Warrior", size: 1 },
    9277: { name: "Fremennik Warrior", size: 1 },
    9278: { name: "Brundt the Chieftain", size: 1 },
    9279: { name: "Brundt the Chieftain", size: 1 },
    9280: { name: "Thorvald the Warrior", size: 1 },
    9281: { name: "Koschei the Deathless", size: 1 },
    9282: { name: "Basilisk Youngling", size: 1 },
    9283: { name: "Basilisk", size: 2 },
    9284: { name: "Basilisk", size: 2 },
    9285: { name: "Basilisk", size: 2 },
    9286: { name: "Basilisk", size: 2 },
    9287: { name: "Monstrous Basilisk", size: 3 },
    9288: { name: "Monstrous Basilisk", size: 3 },
    9289: { name: "The Jormungand", size: 5 },
    9290: { name: "The Jormungand", size: 5 },
    9291: { name: "The Jormungand", size: 5 },
    9292: { name: "The Jormungand", size: 5 },
    9293: { name: "Basilisk Knight", size: 3 },
    9294: { name: "Bakuna", size: 3 },
    9295: { name: "Typhor", size: 3 },
    9296: { name: "Typhor", size: 3 },
    9297: { name: "Vritra", size: 3 },
    9298: { name: "Maz", size: 1 },
    9299: { name: "Trader Stan", size: 1 },
    9300: { name: "Trader Stan", size: 1 },
    9301: { name: "Trader Stan", size: 1 },
    9302: { name: "Trader Stan", size: 1 },
    9303: { name: "Trader Stan", size: 1 },
    9304: { name: "Trader Stan", size: 1 },
    9305: { name: "Trader Stan", size: 1 },
    9306: { name: "Lokar Searunner", size: 1 },
    9307: { name: "Trader Stan", size: 1 },
    9308: { name: "Trader Stan", size: 1 },
    9309: { name: "Trader Stan", size: 1 },
    9310: { name: "Trader Stan", size: 1 },
    9311: { name: "Trader Stan", size: 1 },
    9312: { name: "Trader Crewmember", size: 1 },
    9313: { name: "Trader Crewmember", size: 1 },
    9314: { name: "Trader Crewmember", size: 1 },
    9315: { name: "Trader Crewmember", size: 1 },
    9316: { name: "Trader Crewmember", size: 1 },
    9317: { name: "Trader Crewmember", size: 1 },
    9318: { name: "Trader Crewmember", size: 1 },
    9319: { name: "Trader Crewmember", size: 1 },
    9320: { name: "Trader Crewmember", size: 1 },
    9321: { name: "Trader Crewmember", size: 1 },
    9322: { name: "Trader Crewmember", size: 1 },
    9323: { name: "Trader Crewmember", size: 1 },
    9324: { name: "Trader Crewmember", size: 1 },
    9325: { name: "Trader Crewmember", size: 1 },
    9326: { name: "Trader Crewmember", size: 1 },
    9327: { name: "Trader Crewmember", size: 1 },
    9328: { name: "Trader Crewmember", size: 1 },
    9329: { name: "Trader Crewmember", size: 1 },
    9330: { name: "Trader Crewmember", size: 1 },
    9331: { name: "Trader Crewmember", size: 1 },
    9332: { name: "Trader Crewmember", size: 1 },
    9333: { name: "Trader Crewmember", size: 1 },
    9334: { name: "Trader Crewmember", size: 1 },
    9335: { name: "Trader Crewmember", size: 1 },
    9336: { name: "Trader Crewmember", size: 1 },
    9337: { name: "Trader Crewmember", size: 1 },
    9338: { name: "Trader Crewmember", size: 1 },
    9339: { name: "Trader Crewmember", size: 1 },
    9340: { name: "Trader Crewmember", size: 1 },
    9341: { name: "Trader Crewmember", size: 1 },
    9342: { name: "Trader Crewmember", size: 1 },
    9343: { name: "Trader Crewmember", size: 1 },
    9344: { name: "Trader Crewmember", size: 1 },
    9345: { name: "Trader Crewmember", size: 1 },
    9346: { name: "Trader Crewmember", size: 1 },
    9347: { name: "Trader Crewmember", size: 1 },
    9348: { name: "Trader Crewmember", size: 1 },
    9349: { name: "Trader Crewmember", size: 1 },
    9350: { name: "Trader Crewmember", size: 1 },
    9351: { name: "Trader Crewmember", size: 1 },
    9352: { name: "Trader Crewmember", size: 1 },
    9353: { name: "Trader Crewmember", size: 1 },
    9354: { name: "Trader Crewmember", size: 1 },
    9355: { name: "Trader Crewmember", size: 1 },
    9356: { name: "Trader Crewmember", size: 1 },
    9357: { name: "Trader Crewmember", size: 1 },
    9358: { name: "Trader Crewmember", size: 1 },
    9359: { name: "Trader Crewmember", size: 1 },
    9360: { name: "Trader Crewmember", size: 1 },
    9361: { name: "Trader Crewmember", size: 1 },
    9362: { name: "Trader Crewmember", size: 1 },
    9363: { name: "Trader Crewmember", size: 1 },
    9364: { name: "Trader Crewmember", size: 1 },
    9365: { name: "Trader Crewmember", size: 1 },
    9366: { name: "Trader Crewmember", size: 1 },
    9367: { name: "Trader Crewmember", size: 1 },
    9368: { name: "Trader Crewmember", size: 1 },
    9369: { name: "Trader Crewmember", size: 1 },
    9370: { name: "Trader Crewmember", size: 1 },
    9371: { name: "Trader Crewmember", size: 1 },
    9372: { name: "Trader Crewmember", size: 1 },
    9373: { name: "Trader Crewmember", size: 1 },
    9374: { name: "Trader Crewmember", size: 1 },
    9375: { name: "Trader Crewmember", size: 1 },
    9376: { name: "Trader Crewmember", size: 1 },
    9377: { name: "Trader Crewmember", size: 1 },
    9378: { name: "Trader Crewmember", size: 1 },
    9379: { name: "Trader Crewmember", size: 1 },
    9380: { name: "Trader Crewmember", size: 1 },
    9381: { name: "Trader Crewmember", size: 1 },
    9382: { name: "Trader Crewmember", size: 1 },
    9383: { name: "Trader Crewmember", size: 1 },
    9384: { name: "null", size: 1 },
    9385: { name: "null", size: 1 },
    9386: { name: "null", size: 1 },
    9387: { name: "null", size: 1 },
    9388: { name: "null", size: 1 },
    9389: { name: "null", size: 1 },
    9390: { name: "null", size: 1 },
    9391: { name: "null", size: 1 },
    9392: { name: "null", size: 1 },
    9393: { name: "null", size: 1 },
    9394: { name: "null", size: 1 },
    9395: { name: "null", size: 1 },
    9396: { name: "null", size: 1 },
    9397: { name: "null", size: 1 },
    9398: { name: "Little Nightmare", size: 1 },
    9399: { name: "Little Nightmare", size: 1 },
    9400: { name: "Oath Lord D'rows", size: 1 },
    9401: { name: "Oathbreaker M'als", size: 1 },
    9402: { name: "Oathbreaker E'piws", size: 1 },
    9403: { name: "Oathbreaker B'ats", size: 1 },
    9404: { name: "Lithil", size: 1 },
    9405: { name: "Sister Aseret", size: 1 },
    9406: { name: "Sister Naoj", size: 1 },
    9407: { name: "Sister Salohcin", size: 1 },
    9408: { name: "Lumiere", size: 1 },
    9409: { name: "Daer Krand", size: 1 },
    9410: { name: "Strong Ronny", size: 1 },
    9411: { name: "null", size: 1 },
    9412: { name: "null", size: 1 },
    9413: { name: "Shura", size: 1 },
    9414: { name: "Shura", size: 1 },
    9415: { name: "Acorn", size: 1 },
    9416: { name: "Phosani's Nightmare", size: 5 },
    9417: { name: "Phosani's Nightmare", size: 5 },
    9418: { name: "Phosani's Nightmare", size: 5 },
    9419: { name: "Phosani's Nightmare", size: 5 },
    9420: { name: "Phosani's Nightmare", size: 5 },
    9421: { name: "Phosani's Nightmare", size: 5 },
    9422: { name: "Phosani's Nightmare", size: 5 },
    9423: { name: "Phosani's Nightmare", size: 5 },
    9424: { name: "Phosani's Nightmare", size: 5 },
    9425: { name: "The Nightmare", size: 5 },
    9426: { name: "The Nightmare", size: 5 },
    9427: { name: "The Nightmare", size: 5 },
    9428: { name: "The Nightmare", size: 5 },
    9429: { name: "The Nightmare", size: 5 },
    9430: { name: "The Nightmare", size: 5 },
    9431: { name: "The Nightmare", size: 5 },
    9432: { name: "The Nightmare", size: 5 },
    9433: { name: "The Nightmare", size: 5 },
    9434: { name: "<col=00ffff>Totem</col>", size: 3 },
    9435: { name: "<col=00ffff>Totem</col>", size: 3 },
    9436: { name: "<col=00ffff>Totem</col>", size: 3 },
    9437: { name: "<col=00ffff>Totem</col>", size: 3 },
    9438: { name: "<col=00ffff>Totem</col>", size: 3 },
    9439: { name: "<col=00ffff>Totem</col>", size: 3 },
    9440: { name: "<col=00ffff>Totem</col>", size: 3 },
    9441: { name: "<col=00ffff>Totem</col>", size: 3 },
    9442: { name: "<col=00ffff>Totem</col>", size: 3 },
    9443: { name: "<col=00ffff>Totem</col>", size: 3 },
    9444: { name: "<col=00ffff>Totem</col>", size: 3 },
    9445: { name: "<col=00ffff>Totem</col>", size: 3 },
    9446: { name: "Sleepwalker", size: 1 },
    9447: { name: "Sleepwalker", size: 1 },
    9448: { name: "Sleepwalker", size: 1 },
    9449: { name: "Sleepwalker", size: 1 },
    9450: { name: "Sleepwalker", size: 1 },
    9451: { name: "Sleepwalker", size: 1 },
    9452: { name: "Parasite", size: 1 },
    9453: { name: "Parasite", size: 1 },
    9454: { name: "Husk", size: 1 },
    9455: { name: "Husk", size: 1 },
    9456: { name: "null", size: 1 },
    9457: { name: "null", size: 1 },
    9458: { name: "null", size: 1 },
    9459: { name: "null", size: 1 },
    9460: { name: "The Nightmare", size: 5 },
    9461: { name: "The Nightmare", size: 5 },
    9462: { name: "The Nightmare", size: 5 },
    9463: { name: "The Nightmare", size: 5 },
    9464: { name: "The Nightmare", size: 5 },
    9465: { name: "Infernal pyrelord", size: 3 },
    9466: { name: "Husk", size: 1 },
    9467: { name: "Husk", size: 1 },
    9468: { name: "Parasite", size: 1 },
    9469: { name: "Parasite", size: 1 },
    9470: { name: "Sleepwalker", size: 1 },
    9471: { name: "Sister Senga", size: 1 },
    9472: { name: "Sister Senga", size: 1 },
    9473: { name: "null", size: 1 },
    9474: { name: "<col=00ffff>Ent trunk</col>", size: 2 },
    9475: { name: "null", size: 1 },
    9476: { name: "Gielinor Guide", size: 1 },
    9477: { name: "Survival Expert", size: 1 },
    9478: { name: "Fishing spot", size: 1 },
    9479: { name: "Master Navigator", size: 1 },
    9480: { name: "Quest Guide", size: 1 },
    9481: { name: "Mining Instructor", size: 1 },
    9482: { name: "Combat Instructor", size: 1 },
    9483: { name: "Giant rat", size: 2 },
    9484: { name: "Banker", size: 1 },
    9485: { name: "Brother Brace", size: 1 },
    9486: { name: "Ironman tutor", size: 1 },
    9487: { name: "Magic Instructor", size: 1 },
    9488: { name: "Chicken", size: 1 },
    9489: { name: "Veliaf Hurtz", size: 1 },
    9490: { name: "Hameln the Jester", size: 1 },
    9491: { name: "Hanchen the Hound", size: 2 },
    9492: { name: "Tangleroot", size: 1 },
    9493: { name: "Tangleroot", size: 1 },
    9494: { name: "Tangleroot", size: 1 },
    9495: { name: "Tangleroot", size: 1 },
    9496: { name: "Tangleroot", size: 1 },
    9497: { name: "Tangleroot", size: 1 },
    9498: { name: "Tangleroot", size: 1 },
    9499: { name: "Tangleroot", size: 1 },
    9500: { name: "Tangleroot", size: 1 },
    9501: { name: "Tangleroot", size: 1 },
    9502: { name: "Iorwerth Warrior", size: 1 },
    9503: { name: "Iorwerth Warrior", size: 1 },
    9504: { name: "Agitated bees", size: 1 },
    9505: { name: "Hameln the Jester", size: 1 },
    9506: { name: "Hanchen the Hound", size: 1 },
    9507: { name: "null", size: 1 },
    9508: { name: "null", size: 2 },
    9509: { name: "null", size: 1 },
    9510: { name: "null", size: 1 },
    9511: { name: "Enraged Tektiny", size: 1 },
    9512: { name: "Flying Vespina", size: 1 },
    9513: { name: "Enraged Tektiny", size: 1 },
    9514: { name: "Flying Vespina", size: 1 },
    9515: { name: "null", size: 1 },
    9516: { name: "null", size: 1 },
    9517: { name: "null", size: 1 },
    9518: { name: "null", size: 1 },
    9519: { name: "null", size: 1 },
    9520: { name: "null", size: 1 },
    9521: { name: "Veliaf Hurtz", size: 1 },
    9522: { name: "Veliaf Hurtz", size: 1 },
    9523: { name: "Veliaf Hurtz", size: 1 },
    9524: { name: "Veliaf Hurtz", size: 1 },
    9525: { name: "Veliaf Hurtz", size: 1 },
    9526: { name: "Veliaf Hurtz", size: 1 },
    9527: { name: "Veliaf Hurtz", size: 1 },
    9528: { name: "Veliaf Hurtz", size: 1 },
    9529: { name: "Veliaf Hurtz", size: 1 },
    9530: { name: "Ivan Strom", size: 1 },
    9531: { name: "Ivan Strom", size: 1 },
    9532: { name: "Ivan Strom", size: 1 },
    9533: { name: "Ivan Strom", size: 1 },
    9534: { name: "Ivan Strom", size: 1 },
    9535: { name: "Ivan Strom", size: 1 },
    9536: { name: "Ivan Strom", size: 1 },
    9537: { name: "Safalaan Hallow", size: 1 },
    9538: { name: "Safalaan Hallow", size: 1 },
    9539: { name: "Safalaan Hallow", size: 1 },
    9540: { name: "Safalaan Hallow", size: 1 },
    9541: { name: "Safalaan Hallow", size: 1 },
    9542: { name: "Safalaan Hallow", size: 1 },
    9543: { name: "Kael Forshaw", size: 1 },
    9544: { name: "Kael Forshaw", size: 1 },
    9545: { name: "Kael Forshaw", size: 1 },
    9546: { name: "Kael Forshaw", size: 1 },
    9547: { name: "Vertida Sefalatis", size: 1 },
    9548: { name: "Vertida Sefalatis", size: 1 },
    9549: { name: "Vertida Sefalatis", size: 1 },
    9550: { name: "Vertida Sefalatis", size: 1 },
    9551: { name: "Radigad Ponfit", size: 1 },
    9552: { name: "Radigad Ponfit", size: 1 },
    9553: { name: "Radigad Ponfit", size: 1 },
    9554: { name: "Polmafi Ferdygris", size: 1 },
    9555: { name: "Polmafi Ferdygris", size: 1 },
    9556: { name: "Polmafi Ferdygris", size: 1 },
    9557: { name: "Carl", size: 1 },
    9558: { name: "Carl", size: 1 },
    9559: { name: "Kroy", size: 1 },
    9560: { name: "Kroy", size: 1 },
    9561: { name: "Damien Leucurte", size: 1 },
    9562: { name: "Damien Leucurte", size: 1 },
    9563: { name: "Damien Leucurte", size: 1 },
    9564: { name: "Damien Leucurte", size: 1 },
    9565: { name: "Lord Crombwick", size: 1 },
    9566: { name: "Vanstrom Klause", size: 1 },
    9567: { name: "Vanstrom Klause", size: 1 },
    9568: { name: "Vanstrom Klause", size: 1 },
    9569: { name: "Vanstrom Klause", size: 1 },
    9570: { name: "Vanstrom Klause", size: 1 },
    9571: { name: "Vanstrom Klause", size: 1 },
    9572: { name: "Mist", size: 3 },
    9573: { name: "Acidic Bloodveld", size: 1 },
    9574: { name: "Vanescula Drakan", size: 1 },
    9575: { name: "Vanescula Drakan", size: 1 },
    9576: { name: "Vanescula Drakan", size: 1 },
    9577: { name: "Vanescula Drakan", size: 1 },
    9578: { name: "Lord Alexei Jovkai", size: 1 },
    9579: { name: "Lowerniel Drakan", size: 1 },
    9580: { name: "Werewolf", size: 1 },
    9581: { name: "Werewolf", size: 1 },
    9582: { name: "Werewolf", size: 1 },
    9583: { name: "Prisoner", size: 1 },
    9584: { name: "Prisoner", size: 1 },
    9585: { name: "Prisoner", size: 1 },
    9586: { name: "Vampyre Juvinate", size: 1 },
    9587: { name: "Vampyre Juvinate", size: 1 },
    9588: { name: "Desmodus Lasiurus", size: 1 },
    9589: { name: "Mordan Nikazsi", size: 1 },
    9590: { name: "Vyrewatch", size: 1 },
    9591: { name: "Vyrewatch", size: 1 },
    9592: { name: "Prisoner", size: 1 },
    9593: { name: "Prisoner", size: 1 },
    9594: { name: "Maria Gadderanks", size: 1 },
    9595: { name: "Maria Gadderanks", size: 1 },
    9596: { name: "Ron Gadderanks", size: 1 },
    9597: { name: "Ron Gadderanks", size: 1 },
    9598: { name: "Sarius Guile", size: 1 },
    9599: { name: "Vyrewatch Sentinel", size: 1 },
    9600: { name: "Vyrewatch Sentinel", size: 1 },
    9601: { name: "Vyrewatch Sentinel", size: 1 },
    9602: { name: "Vyrewatch Sentinel", size: 1 },
    9603: { name: "Vyrewatch Sentinel", size: 1 },
    9604: { name: "Vyrewatch Sentinel", size: 1 },
    9605: { name: "Vyrewatch", size: 1 },
    9606: { name: "Vyrewatch", size: 3 },
    9607: { name: "Vyrewatch", size: 1 },
    9608: { name: "Vyrewatch", size: 3 },
    9609: { name: "Seagull", size: 1 },
    9610: { name: "Mutated Bloodveld", size: 2 },
    9611: { name: "Mutated Bloodveld", size: 2 },
    9612: { name: "Nail beast", size: 2 },
    9613: { name: "Nail beast", size: 2 },
    9614: { name: "Vampyre Juvinate", size: 1 },
    9615: { name: "Vampyre Juvinate", size: 1 },
    9616: { name: "Vampyre Juvinate", size: 1 },
    9617: { name: "Vampyre Juvinate", size: 1 },
    9618: { name: "null", size: 1 },
    9619: { name: "Curpile Fyod", size: 1 },
    9620: { name: "null", size: 1 },
    9621: { name: "Veliaf Hurtz", size: 1 },
    9622: { name: "Sani Piliu", size: 1 },
    9623: { name: "Sani Piliu", size: 1 },
    9624: { name: "Harold Evans", size: 1 },
    9625: { name: "Harold Evans", size: 1 },
    9626: { name: "null", size: 1 },
    9627: { name: "Radigad Ponfit", size: 1 },
    9628: { name: "null", size: 1 },
    9629: { name: "Polmafi Ferdygris", size: 1 },
    9630: { name: "null", size: 1 },
    9631: { name: "Ivan Strom", size: 1 },
    9632: { name: "Vanstrom Klause", size: 1 },
    9633: { name: "Veliaf Hurtz", size: 1 },
    9634: { name: "Wiskit", size: 1 },
    9635: { name: "Gadderanks", size: 1 },
    9636: { name: "Drezel", size: 1 },
    9637: { name: "Dark Squirrel", size: 1 },
    9638: { name: "Dark Squirrel", size: 1 },
    9639: { name: "Mysterious Stranger", size: 1 },
    9640: { name: "Mysterious Stranger", size: 1 },
    9641: { name: "Bat", size: 1 },
    9642: { name: "Bat", size: 1 },
    9643: { name: "Spider", size: 1 },
    9644: { name: "Spider", size: 1 },
    9645: { name: "Fish", size: 1 },
    9646: { name: "Fish", size: 1 },
    9647: { name: "null", size: 1 },
    9648: { name: "Knight of the Owl", size: 1 },
    9649: { name: "null", size: 1 },
    9650: { name: "Knight of the Unicorn", size: 1 },
    9651: { name: "null", size: 1 },
    9652: { name: "Knight of the Wolf", size: 1 },
    9653: { name: "null", size: 1 },
    9654: { name: "Knight of the Lion", size: 1 },
    9655: { name: "null", size: 1 },
    9656: { name: "Archpriest of the Unicorn", size: 1 },
    9657: { name: "Darkmeyer Slave", size: 1 },
    9658: { name: "Darkmeyer Slave", size: 1 },
    9659: { name: "mad melvin96", size: 1 },
    9660: { name: "r2t2pnsh0Ty", size: 1 },
    9661: { name: "Jyn", size: 1 },
    9662: { name: "c4ssi4n", size: 1 },
    9663: { name: "fishrunner82", size: 1 },
    9664: { name: "weast side49", size: 1 },
    9665: { name: "c0lect0r890", size: 1 },
    9666: { name: "Giant Squirrel", size: 1 },
    9667: { name: "Owl", size: 2 },
    9668: { name: "Owl", size: 2 },
    9669: { name: "null", size: 3 },
    9670: { name: "null", size: 3 },
    9671: { name: "null", size: 3 },
    9672: { name: "null", size: 1 },
    9673: { name: "null", size: 1 },
    9674: { name: "null", size: 1 },
    9675: { name: "Noranna Tytanin", size: 1 },
    9676: { name: "Noranna Tytanin", size: 1 },
    9677: { name: "Slave", size: 1 },
    9678: { name: "Slave", size: 1 },
    9679: { name: "Slave", size: 1 },
    9680: { name: "Slave", size: 1 },
    9681: { name: "Slave", size: 1 },
    9682: { name: "Slave", size: 1 },
    9683: { name: "Vampyre Juvinate", size: 1 },
    9684: { name: "Vampyre Juvinate", size: 1 },
    9685: { name: "Valentin Rasputin", size: 1 },
    9686: { name: "Von van Von", size: 1 },
    9687: { name: "Vlad Bechstein", size: 1 },
    9688: { name: "Draconis Sanguine", size: 1 },
    9689: { name: "Mort Nightshade", size: 1 },
    9690: { name: "Vampyrus Diaemus", size: 1 },
    9691: { name: "Carnivus Belamorta", size: 1 },
    9692: { name: "Vormar Vakan", size: 1 },
    9693: { name: "Misdrievus Shadum", size: 1 },
    9694: { name: "Vlad Diaemus", size: 1 },
    9695: { name: "Noctillion Lugosi", size: 1 },
    9696: { name: "Alek Constantine", size: 1 },
    9697: { name: "Grigor Rasputin", size: 1 },
    9698: { name: "Haemas Lamescus", size: 1 },
    9699: { name: "Remus Kaninus", size: 1 },
    9700: { name: "Vallessia Dracyula", size: 1 },
    9701: { name: "Violetta Sanguine", size: 1 },
    9702: { name: "Diphylla Bechstein", size: 1 },
    9703: { name: "Episcula Helsing", size: 1 },
    9704: { name: "Vampyressa van Von", size: 1 },
    9705: { name: "Vallessia von Pitt", size: 1 },
    9706: { name: "Vonnetta Varnis", size: 1 },
    9707: { name: "Natalidae Shadum", size: 1 },
    9708: { name: "Mortina Daubenton", size: 1 },
    9709: { name: "Lasenna Rasputin", size: 1 },
    9710: { name: "Caninelle Draynar", size: 1 },
    9711: { name: "Valentina Diaemus", size: 1 },
    9712: { name: "Nakasa Jovkai", size: 1 },
    9713: { name: "Crimsonette van Marr", size: 1 },
    9714: { name: "Pipistrelle Draynar", size: 1 },
    9715: { name: "Lady Nadezhda Shadum", size: 1 },
    9716: { name: "Lord Mischa Myrmel", size: 1 },
    9717: { name: "Lord Alexei Jovkai", size: 1 },
    9718: { name: "Banker", size: 1 },
    9719: { name: "Banker", size: 1 },
    9720: { name: "Grinka Krast", size: 1 },
    9721: { name: "Drasdan Ranor", size: 1 },
    9722: { name: "Despoina Callidra", size: 1 },
    9723: { name: "Lenyig Karna", size: 1 },
    9724: { name: "Varrian Sobak", size: 1 },
    9725: { name: "Slave", size: 1 },
    9726: { name: "Slave", size: 1 },
    9727: { name: "Vampyre Juvinate", size: 1 },
    9728: { name: "Vampyre Juvinate", size: 1 },
    9729: { name: "Vampyre Juvinate", size: 1 },
    9730: { name: "Vampyre Juvinate", size: 1 },
    9731: { name: "Vampyre Juvenile", size: 1 },
    9732: { name: "Vampyre Juvenile", size: 1 },
    9733: { name: "Vampyre Juvenile", size: 1 },
    9734: { name: "Vampyre Juvenile", size: 1 },
    9735: { name: "Vyrewatch", size: 1 },
    9736: { name: "Vyrewatch", size: 1 },
    9737: { name: "Vyrewatch", size: 1 },
    9738: { name: "Vyrewatch", size: 1 },
    9739: { name: "Vyrewatch", size: 1 },
    9740: { name: "Vyrewatch", size: 1 },
    9741: { name: "Vyrewatch", size: 1 },
    9742: { name: "Vyrewatch", size: 1 },
    9743: { name: "Werewolf", size: 1 },
    9744: { name: "Werewolf", size: 1 },
    9745: { name: "Werewolf", size: 1 },
    9746: { name: "Auctioneer", size: 1 },
    9747: { name: "null", size: 1 },
    9748: { name: "null", size: 1 },
    9749: { name: "Frank", size: 1 },
    9750: { name: "Spectator", size: 1 },
    9751: { name: "Spectator", size: 1 },
    9752: { name: "Spectator", size: 1 },
    9753: { name: "Spectator", size: 1 },
    9754: { name: "Spectator", size: 1 },
    9755: { name: "Spectator", size: 1 },
    9756: { name: "Vyrewatch Sentinel", size: 1 },
    9757: { name: "Vyrewatch Sentinel", size: 1 },
    9758: { name: "Vyrewatch Sentinel", size: 1 },
    9759: { name: "Vyrewatch Sentinel", size: 1 },
    9760: { name: "Vyrewatch Sentinel", size: 1 },
    9761: { name: "Vyrewatch Sentinel", size: 1 },
    9762: { name: "Vyrewatch Sentinel", size: 1 },
    9763: { name: "Vyrewatch Sentinel", size: 1 },
    9764: { name: "Yenrab", size: 1 },
    9765: { name: "Lahsram", size: 1 },
    9766: { name: "Erodoeht", size: 1 },
    9767: { name: "Carl", size: 1 },
    9768: { name: "Roy", size: 1 },
    9769: { name: "Lector Gura", size: 1 },
    9770: { name: "Sister Sevi", size: 1 },
    9771: { name: "Sister Toen", size: 1 },
    9772: { name: "Sister Yram", size: 1 },
    9773: { name: "Kroy", size: 1 },
    9774: { name: "Damien Leucurte", size: 1 },
    9775: { name: "Lord Crombwick", size: 1 },
    9776: { name: "Painted One", size: 1 },
    9777: { name: "Painted One", size: 1 },
    9778: { name: "Kurt", size: 1 },
    9779: { name: "Don", size: 1 },
    9780: { name: "Debra", size: 1 },
    9781: { name: "Tanya", size: 1 },
    9782: { name: "Kurt", size: 1 },
    9783: { name: "Don", size: 1 },
    9784: { name: "Debra", size: 1 },
    9785: { name: "Tanya", size: 1 },
    9786: { name: "Child", size: 1 },
    9787: { name: "Child", size: 1 },
    9788: { name: "Child", size: 1 },
    9789: { name: "Child", size: 1 },
    9790: { name: "Child", size: 1 },
    9791: { name: "Child", size: 1 },
    9792: { name: "Child", size: 1 },
    9793: { name: "Child", size: 1 },
    9794: { name: "Child", size: 1 },
    9795: { name: "Child", size: 1 },
    9796: { name: "Child", size: 1 },
    9797: { name: "Child", size: 1 },
    9798: { name: "Child", size: 1 },
    9799: { name: "Child", size: 1 },
    9801: { name: "Sleepwalker", size: 1 },
    9802: { name: "Sleepwalker", size: 1 },
    9803: { name: "null", size: 1 },
    9804: { name: "null", size: 1 },
    9805: { name: "null", size: 1 },
    9806: { name: "null", size: 1 },
    9807: { name: "null", size: 1 },
    9808: { name: "null", size: 1 },
    9809: { name: "null", size: 1 },
    9810: { name: "null", size: 1 },
    9811: { name: "null", size: 1 },
    9812: { name: "null", size: 1 },
    9813: { name: "null", size: 1 },
    9814: { name: "null", size: 1 },
    9815: { name: "null", size: 1 },
    9816: { name: "null", size: 1 },
    9817: { name: "null", size: 1 },
    9818: { name: "null", size: 2 },
    9819: { name: "null", size: 1 },
    9820: { name: "null", size: 1 },
    9821: { name: "null", size: 1 },
    9822: { name: "null", size: 1 },
    9823: { name: "null", size: 1 },
    9824: { name: "null", size: 1 },
    9825: { name: "null", size: 1 },
    9826: { name: "null", size: 1 },
    9827: { name: "null", size: 1 },
    9828: { name: "null", size: 1 },
    9829: { name: "null", size: 1 },
    9830: { name: "null", size: 1 },
    9831: { name: "null", size: 1 },
    9832: { name: "null", size: 1 },
    9833: { name: "null", size: 1 },
    9834: { name: "null", size: 1 },
    9835: { name: "null", size: 1 },
    9836: { name: "null", size: 1 },
    9837: { name: "null", size: 1 },
    9838: { name: "null", size: 1 },
    9839: { name: "null", size: 1 },
    9840: { name: "null", size: 1 },
    9841: { name: "null", size: 1 },
    9842: { name: "null", size: 1 },
    9843: { name: "null", size: 1 },
    9844: { name: "null", size: 1 },
    9845: { name: "null", size: 1 },
    9846: { name: "null", size: 1 },
    9847: { name: "null", size: 1 },
    9848: { name: "null", size: 1 },
    9849: { name: "null", size: 1 },
    9850: { name: "Red", size: 1 },
    9851: { name: "Ziggy", size: 1 },
    9852: { name: "Red", size: 1 },
    9853: { name: "Ziggy", size: 1 },
    9854: { name: "null", size: 1 },
    9855: { name: "Death", size: 2 },
    9856: { name: "Grave", size: 1 },
    9857: { name: "Grave", size: 1 },
    9858: { name: "null", size: 1 },
    9859: { name: "null", size: 1 },
    9860: { name: "null", size: 1 },
    9861: { name: "null", size: 1 },
    9862: { name: "null", size: 1 },
    9863: { name: "null", size: 1 },
    9864: { name: "null", size: 1 },
    9865: { name: "null", size: 1 },
    9866: { name: "null", size: 1 },
    9867: { name: "null", size: 1 },
    9868: { name: "null", size: 1 },
    9869: { name: "null", size: 1 },
    9870: { name: "null", size: 1 },
    9871: { name: "null", size: 1 },
    9872: { name: "null", size: 1 },
    9873: { name: "null", size: 1 },
    9874: { name: "null", size: 1 },
    9875: { name: "null", size: 1 },
    9876: { name: "null", size: 1 },
    9877: { name: "null", size: 1 },
    9878: { name: "null", size: 1 },
    9879: { name: "null", size: 1 },
    9880: { name: "null", size: 1 },
    9881: { name: "null", size: 1 },
    9882: { name: "null", size: 1 },
    9883: { name: "null", size: 1 },
    9884: { name: "null", size: 1 },
    9885: { name: "null", size: 1 },
    9886: { name: "null", size: 1 },
    9887: { name: "null", size: 1 },
    9888: { name: "null", size: 1 },
    9889: { name: "null", size: 1 },
    9890: { name: "null", size: 1 },
    9891: { name: "null", size: 1 },
    9892: { name: "null", size: 1 },
    9893: { name: "null", size: 1 },
    9894: { name: "null", size: 1 },
    9895: { name: "null", size: 1 },
    9896: { name: "null", size: 1 },
    9897: { name: "null", size: 1 },
    9898: { name: "null", size: 1 },
    9899: { name: "null", size: 1 },
    9900: { name: "null", size: 1 },
    9901: { name: "null", size: 1 },
    9902: { name: "null", size: 1 },
    9903: { name: "null", size: 1 },
    9904: { name: "null", size: 1 },
    9905: { name: "null", size: 1 },
    9906: { name: "null", size: 1 },
    9907: { name: "null", size: 1 },
    9908: { name: "null", size: 1 },
    9909: { name: "null", size: 1 },
    9910: { name: "null", size: 1 },
    9911: { name: "null", size: 1 },
    9912: { name: "null", size: 1 },
    9913: { name: "null", size: 1 },
    9914: { name: "null", size: 1 },
    9915: { name: "null", size: 1 },
    9916: { name: "null", size: 1 },
    9917: { name: "null", size: 1 },
    9918: { name: "null", size: 1 },
    9919: { name: "null", size: 1 },
    9920: { name: "null", size: 1 },
    9921: { name: "null", size: 1 },
    9922: { name: "null", size: 1 },
    9923: { name: "null", size: 1 },
    9924: { name: "null", size: 1 },
    9925: { name: "null", size: 1 },
    9926: { name: "null", size: 1 },
    9927: { name: "null", size: 1 },
    9928: { name: "null", size: 1 },
    9929: { name: "null", size: 1 },
    9930: { name: "null", size: 1 },
    9931: { name: "null", size: 1 },
    9932: { name: "null", size: 1 },
    9933: { name: "null", size: 1 },
    9934: { name: "null", size: 1 },
    9935: { name: "null", size: 1 },
    9936: { name: "null", size: 1 },
    9937: { name: "null", size: 1 },
    9938: { name: "null", size: 1 },
    9939: { name: "null", size: 1 },
    9940: { name: "null", size: 1 },
    9941: { name: "null", size: 1 },
    9942: { name: "null", size: 1 },
    9943: { name: "null", size: 1 },
    9944: { name: "null", size: 1 },
    9945: { name: "null", size: 1 },
    9946: { name: "null", size: 1 },
    9947: { name: "null", size: 1 },
    9948: { name: "null", size: 1 },
    9949: { name: "null", size: 1 },
    9950: { name: "null", size: 1 },
    9951: { name: "null", size: 1 },
    9952: { name: "null", size: 1 },
    9953: { name: "null", size: 1 },
    9954: { name: "null", size: 1 },
    9955: { name: "null", size: 1 },
    9956: { name: "null", size: 1 },
    9957: { name: "null", size: 1 },
    9958: { name: "null", size: 1 },
    9959: { name: "null", size: 1 },
    9960: { name: "null", size: 1 },
    9961: { name: "null", size: 1 },
    9962: { name: "null", size: 1 },
    9963: { name: "null", size: 1 },
    9964: { name: "null", size: 1 },
    9965: { name: "null", size: 1 },
    9966: { name: "null", size: 1 },
    9967: { name: "null", size: 1 },
    9968: { name: "null", size: 1 },
    9969: { name: "null", size: 1 },
    9970: { name: "null", size: 1 },
    9971: { name: "null", size: 1 },
    9972: { name: "null", size: 1 },
    9973: { name: "null", size: 1 },
    9974: { name: "null", size: 1 },
    9975: { name: "null", size: 1 },
    9976: { name: "null", size: 1 },
    9977: { name: "null", size: 1 },
    9978: { name: "null", size: 1 },
    9979: { name: "null", size: 1 },
    9980: { name: "null", size: 1 },
    9981: { name: "null", size: 1 },
    9982: { name: "null", size: 1 },
    9983: { name: "null", size: 1 },
    9984: { name: "null", size: 1 },
    9985: { name: "null", size: 1 },
    9986: { name: "null", size: 1 },
    9987: { name: "null", size: 1 },
    9988: { name: "null", size: 1 },
    9989: { name: "null", size: 1 },
    9990: { name: "null", size: 1 },
    9991: { name: "null", size: 1 },
    9992: { name: "null", size: 1 },
    9993: { name: "null", size: 1 },
    9994: { name: "null", size: 1 },
    9995: { name: "null", size: 1 },
    9996: { name: "null", size: 1 },
    9997: { name: "null", size: 1 },
    9998: { name: "null", size: 1 },
    9999: { name: "null", size: 1 },
    10000: { name: "null", size: 1 },
    10001: { name: "null", size: 1 },
    10002: { name: "null", size: 1 },
    10003: { name: "null", size: 1 },
    10004: { name: "null", size: 1 },
    10005: { name: "null", size: 1 },
    10006: { name: "null", size: 1 },
    10007: { name: "null", size: 1 },
    10008: { name: "null", size: 1 },
    10009: { name: "null", size: 1 },
    10010: { name: "null", size: 1 },
    10011: { name: "null", size: 1 },
    10012: { name: "null", size: 1 },
    10013: { name: "null", size: 1 },
    10014: { name: "null", size: 1 },
    10015: { name: "null", size: 1 },
    10016: { name: "null", size: 1 },
    10017: { name: "null", size: 1 },
    10018: { name: "null", size: 1 },
    10019: { name: "null", size: 1 },
    10020: { name: "null", size: 1 },
    10021: { name: "null", size: 1 },
    10022: { name: "null", size: 1 },
    10023: { name: "null", size: 1 },
    10024: { name: "null", size: 1 },
    10025: { name: "null", size: 1 },
    10026: { name: "null", size: 1 },
    10027: { name: "null", size: 1 },
    10028: { name: "null", size: 1 },
    10029: { name: "null", size: 1 },
    10030: { name: "null", size: 1 },
    10031: { name: "null", size: 1 },
    10032: { name: "null", size: 1 },
    10033: { name: "null", size: 1 },
    10034: { name: "null", size: 1 },
    10035: { name: "null", size: 1 },
    10036: { name: "null", size: 1 },
    10037: { name: "null", size: 1 },
    10038: { name: "null", size: 1 },
    10039: { name: "null", size: 1 },
    10040: { name: "null", size: 1 },
    10041: { name: "null", size: 1 },
    10042: { name: "null", size: 1 },
    10043: { name: "null", size: 1 },
    10044: { name: "null", size: 1 },
    10045: { name: "null", size: 1 },
    10046: { name: "null", size: 1 },
    10047: { name: "null", size: 1 },
    10048: { name: "null", size: 1 },
    10049: { name: "null", size: 1 },
    10050: { name: "null", size: 1 },
    10051: { name: "null", size: 1 },
    10052: { name: "null", size: 1 },
    10053: { name: "null", size: 1 },
    10054: { name: "null", size: 1 },
    10055: { name: "null", size: 1 },
    10056: { name: "null", size: 1 },
    10057: { name: "null", size: 1 },
    10058: { name: "null", size: 1 },
    10059: { name: "null", size: 1 },
    10060: { name: "null", size: 1 },
    10061: { name: "null", size: 1 },
    10062: { name: "null", size: 1 },
    10063: { name: "null", size: 1 },
    10064: { name: "null", size: 1 },
    10065: { name: "null", size: 1 },
    10066: { name: "null", size: 1 },
    10067: { name: "null", size: 1 },
    10068: { name: "null", size: 1 },
    10069: { name: "null", size: 1 },
    10070: { name: "null", size: 1 },
    10071: { name: "null", size: 1 },
    10072: { name: "null", size: 1 },
    10073: { name: "null", size: 1 },
    10074: { name: "null", size: 1 },
    10075: { name: "null", size: 1 },
    10076: { name: "null", size: 1 },
    10077: { name: "null", size: 1 },
    10078: { name: "null", size: 1 },
    10079: { name: "null", size: 1 },
    10080: { name: "null", size: 1 },
    10081: { name: "null", size: 1 },
    10082: { name: "null", size: 1 },
    10083: { name: "null", size: 1 },
    10084: { name: "null", size: 1 },
    10085: { name: "null", size: 1 },
    10086: { name: "null", size: 1 },
    10087: { name: "null", size: 1 },
    10088: { name: "null", size: 1 },
    10089: { name: "null", size: 1 },
    10090: { name: "null", size: 1 },
    10091: { name: "null", size: 1 },
    10092: { name: "null", size: 1 },
    10093: { name: "null", size: 1 },
    10094: { name: "null", size: 1 },
    10095: { name: "null", size: 1 },
    10096: { name: "null", size: 1 },
    10097: { name: "null", size: 1 },
    10098: { name: "null", size: 1 },
    10099: { name: "null", size: 1 },
    10100: { name: "null", size: 1 },
    10101: { name: "null", size: 1 },
    10102: { name: "null", size: 1 },
    10103: { name: "null", size: 1 },
    10104: { name: "null", size: 1 },
    10105: { name: "null", size: 1 },
    10106: { name: "null", size: 1 },
    10107: { name: "null", size: 1 },
    10108: { name: "null", size: 1 },
    10109: { name: "null", size: 1 },
    10110: { name: "null", size: 1 },
    10111: { name: "null", size: 1 },
    10112: { name: "null", size: 1 },
    10113: { name: "null", size: 1 },
    10114: { name: "null", size: 1 },
    10115: { name: "null", size: 1 },
    10116: { name: "null", size: 1 },
    10117: { name: "null", size: 1 },
    10118: { name: "null", size: 1 },
    10119: { name: "null", size: 1 },
    10120: { name: "null", size: 1 },
    10121: { name: "null", size: 1 },
    10122: { name: "null", size: 1 },
    10123: { name: "null", size: 1 },
    10124: { name: "null", size: 1 },
    10125: { name: "null", size: 1 },
    10126: { name: "null", size: 1 },
    10127: { name: "null", size: 1 },
    10128: { name: "null", size: 1 },
    10129: { name: "null", size: 1 },
    10130: { name: "null", size: 1 },
    10131: { name: "null", size: 1 },
    10132: { name: "null", size: 1 },
    10133: { name: "null", size: 1 },
    10134: { name: "null", size: 1 },
    10135: { name: "null", size: 1 },
    10136: { name: "null", size: 1 },
    10137: { name: "null", size: 1 },
    10138: { name: "null", size: 1 },
    10139: { name: "null", size: 1 },
    10140: { name: "null", size: 1 },
    10141: { name: "null", size: 1 },
    10142: { name: "null", size: 1 },
    10143: { name: "null", size: 1 },
    10144: { name: "null", size: 1 },
    10145: { name: "null", size: 1 },
    10146: { name: "null", size: 1 },
    10147: { name: "null", size: 1 },
    10148: { name: "null", size: 1 },
    10149: { name: "null", size: 1 },
    10150: { name: "null", size: 1 },
    10151: { name: "null", size: 1 },
    10152: { name: "null", size: 1 },
    10153: { name: "null", size: 1 },
    10154: { name: "null", size: 1 },
    10155: { name: "null", size: 1 },
    10156: { name: "null", size: 1 },
    10157: { name: "null", size: 1 },
    10158: { name: "null", size: 1 },
    10159: { name: "null", size: 1 },
    10160: { name: "null", size: 1 },
    10161: { name: "null", size: 1 },
    10162: { name: "null", size: 1 },
    10163: { name: "null", size: 1 },
    10164: { name: "null", size: 1 },
    10165: { name: "null", size: 1 },
    10166: { name: "null", size: 1 },
    10167: { name: "null", size: 1 },
    10168: { name: "null", size: 1 },
    10169: { name: "null", size: 1 },
    10170: { name: "null", size: 1 },
    10171: { name: "null", size: 1 },
    10172: { name: "null", size: 1 },
    10173: { name: "null", size: 1 },
    10174: { name: "null", size: 1 },
    10175: { name: "null", size: 1 },
    10176: { name: "null", size: 1 },
    10177: { name: "null", size: 1 },
    10178: { name: "null", size: 1 },
    10179: { name: "null", size: 1 },
    10180: { name: "null", size: 1 },
    10181: { name: "null", size: 1 },
    10182: { name: "null", size: 1 },
    10183: { name: "null", size: 1 },
    10184: { name: "null", size: 1 },
    10185: { name: "null", size: 1 },
    10186: { name: "null", size: 1 },
    10187: { name: "null", size: 1 },
    10188: { name: "null", size: 1 },
    10189: { name: "null", size: 1 },
    10190: { name: "null", size: 1 },
    10191: { name: "null", size: 1 },
    10192: { name: "null", size: 1 },
    10193: { name: "null", size: 1 },
    10194: { name: "null", size: 1 },
    10195: { name: "null", size: 1 },
    10196: { name: "null", size: 1 },
    10197: { name: "null", size: 1 },
    10198: { name: "null", size: 1 },
    10199: { name: "null", size: 1 },
    10200: { name: "null", size: 1 },
    10201: { name: "null", size: 1 },
    10202: { name: "null", size: 1 },
    10203: { name: "null", size: 1 },
    10204: { name: "null", size: 1 },
    10205: { name: "null", size: 1 },
    10206: { name: "null", size: 1 },
    10207: { name: "null", size: 1 },
    10208: { name: "null", size: 1 },
    10209: { name: "null", size: 1 },
    10210: { name: "null", size: 1 },
    10211: { name: "null", size: 1 },
    10212: { name: "null", size: 1 },
    10213: { name: "null", size: 1 },
    10214: { name: "null", size: 1 },
    10215: { name: "null", size: 1 },
    10216: { name: "null", size: 1 },
    10217: { name: "null", size: 1 },
    10218: { name: "null", size: 1 },
    10219: { name: "null", size: 1 },
    10220: { name: "null", size: 1 },
    10221: { name: "null", size: 1 },
    10222: { name: "null", size: 1 },
    10223: { name: "null", size: 1 },
    10224: { name: "null", size: 1 },
    10225: { name: "null", size: 1 },
    10226: { name: "null", size: 1 },
    10227: { name: "null", size: 1 },
    10228: { name: "null", size: 1 },
    10229: { name: "null", size: 1 },
    10230: { name: "null", size: 1 },
    10231: { name: "null", size: 1 },
    10232: { name: "null", size: 1 },
    10233: { name: "null", size: 1 },
    10234: { name: "null", size: 1 },
    10235: { name: "null", size: 1 },
    10236: { name: "null", size: 1 },
    10237: { name: "null", size: 1 },
    10238: { name: "null", size: 1 },
    10239: { name: "null", size: 1 },
    10240: { name: "null", size: 1 },
    10241: { name: "null", size: 1 },
    10242: { name: "null", size: 1 },
    10243: { name: "null", size: 1 },
    10244: { name: "null", size: 1 },
    10245: { name: "null", size: 1 },
    10246: { name: "null", size: 1 },
    10247: { name: "null", size: 1 },
    10248: { name: "null", size: 1 },
    10249: { name: "null", size: 1 },
    10250: { name: "null", size: 1 },
    10251: { name: "null", size: 1 },
    10252: { name: "null", size: 1 },
    10253: { name: "null", size: 1 },
    10254: { name: "null", size: 1 },
    10255: { name: "null", size: 1 },
    10256: { name: "null", size: 1 },
    10257: { name: "null", size: 1 },
    10258: { name: "null", size: 1 },
    10259: { name: "null", size: 1 },
    10260: { name: "null", size: 1 },
    10261: { name: "null", size: 1 },
    10262: { name: "null", size: 1 },
    10263: { name: "null", size: 1 },
    10264: { name: "null", size: 1 },
    10265: { name: "null", size: 1 },
    10266: { name: "null", size: 1 },
    10267: { name: "null", size: 1 },
    10268: { name: "null", size: 1 },
    10269: { name: "null", size: 1 },
    10270: { name: "null", size: 1 },
    10271: { name: "null", size: 1 },
    10272: { name: "null", size: 1 },
    10273: { name: "null", size: 1 },
    10274: { name: "null", size: 1 },
    10275: { name: "null", size: 1 },
    10276: { name: "null", size: 1 },
    10277: { name: "null", size: 1 },
    10278: { name: "null", size: 1 },
    10279: { name: "null", size: 1 },
    10280: { name: "null", size: 1 },
    10281: { name: "null", size: 1 },
    10282: { name: "null", size: 1 },
    10283: { name: "null", size: 1 },
    10284: { name: "null", size: 1 },
    10285: { name: "null", size: 1 },
    10286: { name: "null", size: 1 },
    10287: { name: "null", size: 1 },
    10288: { name: "null", size: 1 },
    10289: { name: "null", size: 1 },
    10290: { name: "null", size: 1 },
    10291: { name: "null", size: 1 },
    10292: { name: "null", size: 1 },
    10293: { name: "null", size: 1 },
    10294: { name: "null", size: 1 },
    10295: { name: "null", size: 1 },
    10296: { name: "null", size: 1 },
    10297: { name: "null", size: 1 },
    10298: { name: "null", size: 1 },
    10299: { name: "null", size: 1 },
    10300: { name: "null", size: 1 },
    10301: { name: "null", size: 1 },
    10302: { name: "null", size: 1 },
    10303: { name: "null", size: 1 },
    10304: { name: "null", size: 1 },
    10305: { name: "null", size: 1 },
    10306: { name: "null", size: 1 },
    10307: { name: "null", size: 1 },
    10308: { name: "null", size: 1 },
    10309: { name: "null", size: 1 },
    10310: { name: "null", size: 1 },
    10311: { name: "null", size: 1 },
    10312: { name: "null", size: 1 },
    10313: { name: "null", size: 1 },
    10314: { name: "null", size: 1 },
    10315: { name: "null", size: 1 },
    10316: { name: "null", size: 1 },
    10317: { name: "null", size: 1 },
    10318: { name: "null", size: 1 },
    10319: { name: "null", size: 1 },
    10320: { name: "null", size: 1 },
    10321: { name: "null", size: 1 },
    10322: { name: "null", size: 1 },
    10323: { name: "null", size: 1 },
    10324: { name: "null", size: 1 },
    10325: { name: "null", size: 1 },
    10326: { name: "null", size: 1 },
    10327: { name: "null", size: 1 },
    10328: { name: "null", size: 1 },
    10329: { name: "null", size: 1 },
    10330: { name: "null", size: 1 },
    10331: { name: "null", size: 1 },
    10332: { name: "null", size: 1 },
    10333: { name: "null", size: 1 },
    10334: { name: "null", size: 1 },
    10335: { name: "null", size: 1 },
    10336: { name: "null", size: 1 },
    10337: { name: "null", size: 1 },
    10338: { name: "null", size: 1 },
    10339: { name: "null", size: 1 },
    10340: { name: "null", size: 1 },
    10341: { name: "null", size: 1 },
    10342: { name: "null", size: 1 },
    10343: { name: "null", size: 1 },
    10344: { name: "null", size: 1 },
    10345: { name: "null", size: 1 },
    10346: { name: "null", size: 1 },
    10347: { name: "null", size: 1 },
    10348: { name: "null", size: 1 },
    10349: { name: "null", size: 1 },
    10350: { name: "null", size: 1 },
    10351: { name: "null", size: 1 },
    10352: { name: "null", size: 1 },
    10353: { name: "null", size: 1 },
    10354: { name: "null", size: 1 },
    10355: { name: "null", size: 1 },
    10356: { name: "null", size: 1 },
    10357: { name: "null", size: 1 },
    10358: { name: "null", size: 1 },
    10359: { name: "null", size: 1 },
    10360: { name: "null", size: 1 },
    10361: { name: "null", size: 1 },
    10362: { name: "null", size: 1 },
    10363: { name: "null", size: 1 },
    10364: { name: "null", size: 1 },
    10365: { name: "null", size: 1 },
    10366: { name: "null", size: 1 },
    10367: { name: "null", size: 1 },
    10368: { name: "Squire", size: 1 },
    10369: { name: "Bobawu", size: 1 },
    10370: { name: "Marten", size: 1 },
    10371: { name: "Wizard", size: 1 },
    10372: { name: "Wizard", size: 1 },
    10373: { name: "Wizard", size: 1 },
    10374: { name: "Hill Giant", size: 2 },
    10375: { name: "Hill Giant", size: 2 },
    10376: { name: "Hill Giant", size: 2 },
    10377: { name: "Ferox", size: 1 },
    10378: { name: "Sigismund", size: 1 },
    10379: { name: "Zamorakian Acolyte", size: 1 },
    10380: { name: "Zamorakian Acolyte", size: 1 },
    10381: { name: "Zamorakian Acolyte", size: 1 },
    10382: { name: "Skully", size: 1 },
    10383: { name: "Refugee", size: 1 },
    10384: { name: "Refugee", size: 1 },
    10385: { name: "Refugee", size: 1 },
    10386: { name: "Phabelle Bile", size: 1 },
    10387: { name: "Derse Venator", size: 1 },
    10388: { name: "Andros Mai", size: 1 },
    10389: { name: "Banker", size: 1 },
    10390: { name: "Mercenary", size: 1 },
    10391: { name: "Financial Wizard", size: 1 },
    10392: { name: "Camarst", size: 1 },
    10393: { name: "Warrior of Murahs", size: 1 },
    10394: { name: "Warrior of Murahs", size: 1 },
    10395: { name: "Warrior of Murahs", size: 1 },
    10396: { name: "null", size: 1 },
    10397: { name: "Spiked Turoth", size: 3 },
    10398: { name: "Shadow Wyrm", size: 5 },
    10399: { name: "Shadow Wyrm", size: 5 },
    10400: { name: "Guardian Drake", size: 5 },
    10401: { name: "Guardian Drake", size: 5 },
    10402: { name: "Colossal Hydra", size: 5 },
    10403: { name: "Torfinn", size: 1 },
    10404: { name: "Torfinn", size: 1 },
    10405: { name: "Torfinn", size: 1 },
    10406: { name: "Torfinn", size: 1 },
    10407: { name: "Jarvald", size: 1 },
    10408: { name: "Marlo", size: 1 },
    10409: { name: "Marlo", size: 1 },
    10410: { name: "Ellie", size: 1 },
    10411: { name: "Ellie", size: 1 },
    10412: { name: "Angelo", size: 1 },
    10413: { name: "Angelo", size: 1 },
    10414: { name: "Bob", size: 1 },
    10415: { name: "Jeff", size: 1 },
    10416: { name: "Sarah", size: 1 },
    10417: { name: "Tau", size: 1 },
    10418: { name: "Larry", size: 1 },
    10419: { name: "Noella", size: 1 },
    10420: { name: "Ross", size: 1 },
    10421: { name: "Jess", size: 1 },
    10422: { name: "Mariah", size: 1 },
    10423: { name: "Leela", size: 1 },
    10424: { name: "Barbara", size: 1 },
    10425: { name: "Old Man Yarlo", size: 1 },
    10426: { name: "Old Man Yarlo", size: 1 },
    10427: { name: "Old Man Yarlo", size: 1 },
    10428: { name: "null", size: 1 },
    10429: { name: "null", size: 1 },
    10430: { name: "null", size: 1 },
    10431: { name: "null", size: 1 },
    10432: { name: "Spria", size: 1 },
    10433: { name: "Spria", size: 1 },
    10434: { name: "Spria", size: 1 },
    10435: { name: "Sourhog", size: 3 },
    10436: { name: "Sourhog", size: 3 },
    10437: { name: "Pig Thing", size: 1 },
    10438: { name: "Rosie", size: 1 },
    10439: { name: "Sheepdog", size: 1 },
    10440: { name: "null", size: 1 },
    10441: { name: "null", size: 1 },
    10442: { name: "Spider", size: 1 },
    10443: { name: "Spider", size: 1 },
    10444: { name: "Bees!", size: 1 },
    10445: { name: "Gnormadium Avlafrim", size: 1 },
    10446: { name: "Gnormadium Avlafrim", size: 1 },
    10447: { name: "Gnormadium Avlafrim", size: 1 },
    10448: { name: "Gnormadium Avlafrim", size: 1 },
    10449: { name: "Eve", size: 1 },
    10450: { name: "Gnormadium Avlafrim", size: 1 },
    10451: { name: "Gnormadium Avlafrim", size: 1 },
    10452: { name: "Captain Dalbur", size: 1 },
    10453: { name: "Captain Dalbur", size: 1 },
    10454: { name: "Captain Dalbur", size: 1 },
    10455: { name: "Captain Dalbur", size: 1 },
    10456: { name: "Captain Dalbur", size: 1 },
    10457: { name: "Captain Dalbur", size: 1 },
    10458: { name: "Captain Dalbur", size: 1 },
    10459: { name: "Captain Bleemadge", size: 1 },
    10460: { name: "<col=00ffff>Jack-o'-Lantern</col>", size: 1 },
    10461: { name: "Captain Bleemadge", size: 1 },
    10462: { name: "Captain Bleemadge", size: 1 },
    10463: { name: "Captain Bleemadge", size: 1 },
    10464: { name: "Captain Bleemadge", size: 1 },
    10465: { name: "Captain Bleemadge", size: 1 },
    10466: { name: "Captain Bleemadge", size: 1 },
    10467: { name: "Captain Errdo", size: 1 },
    10468: { name: "Captain Errdo", size: 1 },
    10469: { name: "Captain Errdo", size: 1 },
    10470: { name: "Captain Errdo", size: 1 },
    10471: { name: "Captain Errdo", size: 1 },
    10472: { name: "Captain Errdo", size: 1 },
    10473: { name: "Captain Errdo", size: 1 },
    10474: { name: "null", size: 1 },
    10475: { name: "null", size: 1 },
    10476: { name: "Bran", size: 1 },
    10477: { name: "Thessalia", size: 1 },
    10478: { name: "Thessalia", size: 1 },
    10479: { name: "Captain Klemfoodle", size: 1 },
    10480: { name: "Captain Klemfoodle", size: 1 },
    10481: { name: "Captain Klemfoodle", size: 1 },
    10482: { name: "Captain Klemfoodle", size: 1 },
    10483: { name: "Captain Klemfoodle", size: 1 },
    10484: { name: "Captain Klemfoodle", size: 1 },
    10485: { name: "Captain Klemfoodle", size: 1 },
    10486: { name: "Captain Shoracks", size: 1 },
    10487: { name: "Captain Shoracks", size: 1 },
    10488: { name: "Captain Shoracks", size: 1 },
    10489: { name: "Captain Shoracks", size: 1 },
    10490: { name: "Captain Shoracks", size: 1 },
    10491: { name: "Captain Shoracks", size: 1 },
    10492: { name: "Headless Beast (hard)", size: 3 },
    10493: { name: "Headless Beast", size: 3 },
    10494: { name: "Chicken", size: 1 },
    10495: { name: "Chicken", size: 1 },
    10496: { name: "Chicken", size: 1 },
    10497: { name: "Chicken", size: 1 },
    10498: { name: "Chicken", size: 1 },
    10499: { name: "Chicken", size: 1 },
    10500: { name: "Gordon", size: 1 },
    10501: { name: "Gordon", size: 1 },
    10502: { name: "Mary", size: 1 },
    10503: { name: "Mary", size: 1 },
    10504: { name: "Mary", size: 1 },
    10505: { name: "Sergeant", size: 1 },
    10506: { name: "Headless Beast", size: 3 },
    10507: { name: "<col=00ffff>Ornate undead combat dummy</col>", size: 1 },
    10508: { name: "<col=00ffff>Ornate wilderness combat dummy</col>", size: 1 },
    10509: { name: "<col=00ffff>Ornate kalphite combat dummy</col>", size: 1 },
    10510: { name: "<col=00ffff>Ornate kurask combat dummy</col>", size: 1 },
    10511: { name: "<col=00ffff>Ornate undead combat dummy</col>", size: 1 },
    10512: { name: "<col=00ffff>Ornate undead combat dummy</col>", size: 1 },
    10513: { name: "Fishing spot", size: 1 },
    10514: { name: "Fishing spot", size: 1 },
    10515: { name: "Fishing spot", size: 1 },
    10516: { name: "Nomad", size: 1 },
    10517: { name: "Zimberfizz", size: 1 },
    10518: { name: "Zimberfizz", size: 1 },
    10519: { name: "Zimberfizz", size: 1 },
    10520: { name: "Avatar of Creation", size: 5 },
    10521: { name: "Avatar of Destruction", size: 5 },
    10522: { name: "Wolf", size: 2 },
    10523: { name: "Forgotten Soul", size: 1 },
    10524: { name: "Forgotten Soul", size: 1 },
    10525: { name: "Forgotten Soul", size: 1 },
    10526: { name: "Forgotten Soul", size: 1 },
    10527: { name: "null", size: 1 },
    10528: { name: "Nomad", size: 1 },
    10529: { name: "Nomad", size: 1 },
    10530: { name: "Zimberfizz", size: 1 },
    10531: { name: "Avatar of Creation", size: 5 },
    10532: { name: "Avatar of Destruction", size: 5 },
    10533: { name: "Wolf", size: 2 },
    10534: { name: "Forgotten Soul", size: 1 },
    10535: { name: "Forgotten Soul", size: 1 },
    10536: { name: "Forgotten Soul", size: 1 },
    10537: { name: "Forgotten Soul", size: 1 },
    10538: { name: "Ghost", size: 1 },
    10539: { name: "<col=00ffff>Barricade</col>", size: 1 },
    10540: { name: "<col=00ffff>Barricade</col>", size: 1 },
    10541: { name: "Bird", size: 1 },
    10542: { name: "null", size: 2 },
    10543: { name: "null", size: 1 },
    10544: { name: "Forgotten Soul", size: 1 },
    10545: { name: "Forgotten Soul", size: 1 },
    10546: { name: "Duck", size: 1 },
    10547: { name: "Duck", size: 1 },
    10548: { name: "null", size: 1 },
    10549: { name: "null", size: 1 },
    10550: { name: "null", size: 1 },
    10551: { name: "null", size: 1 },
    10552: { name: "null", size: 1 },
    10553: { name: "null", size: 1 },
    10554: { name: "null", size: 1 },
    10555: { name: "null", size: 1 },
    10556: { name: "Chicken", size: 1 },
    10557: { name: "Ancient Ghost", size: 2 },
    10558: { name: "Ancient Ghost", size: 1 },
    10559: { name: "Scrubfoot", size: 1 },
    10560: { name: "Garl", size: 1 },
    10561: { name: "Red fireflies", size: 1 },
    10562: { name: "Tiny Tempor", size: 1 },
    10563: { name: "Crab", size: 1 },
    10564: { name: "Green fireflies", size: 1 },
    10565: { name: "Fishing spot", size: 1 },
    10566: { name: "Goblin", size: 1 },
    10567: { name: "Goblin", size: 1 },
    10568: { name: "Fishing spot", size: 1 },
    10569: { name: "Fishing spot", size: 1 },
    10570: { name: "<col=00ffff>Inactive spirit pool</col>", size: 3 },
    10571: { name: "<col=00ffff>Spirit pool</col>", size: 3 },
    10572: { name: "Tempoross", size: 9 },
    10573: { name: "null", size: 9 },
    10574: { name: "Tempoross", size: 9 },
    10575: { name: "Tempoross", size: 9 },
    10576: { name: "<col=00ffff>Ammunition crate</col>", size: 1 },
    10577: { name: "<col=00ffff>Ammunition crate</col>", size: 1 },
    10578: { name: "<col=00ffff>Ammunition crate</col>", size: 1 },
    10579: { name: "<col=00ffff>Ammunition crate</col>", size: 1 },
    10580: { name: "<col=00ffff>Lightning cloud</col>", size: 4 },
    10581: { name: "null", size: 2 },
    10582: { name: "null", size: 2 },
    10583: { name: "Captain Pudi", size: 1 },
    10584: { name: "Captain Dudi", size: 1 },
    10585: { name: "Captain Pudi", size: 1 },
    10586: { name: "Captain Pudi", size: 1 },
    10587: { name: "Captain Dudi", size: 1 },
    10588: { name: "Captain Dudi", size: 1 },
    10589: { name: "Urium Shade", size: 1 },
    10590: { name: "Dampe", size: 1 },
    10591: { name: "Undead Zealot", size: 1 },
    10592: { name: "Undead Zealot", size: 1 },
    10593: { name: "First Mate Deri", size: 1 },
    10594: { name: "Sheep", size: 1 },
    10595: { name: "First Mate Deri", size: 1 },
    10596: { name: "First Mate Peri", size: 1 },
    10597: { name: "First Mate Peri", size: 1 },
    10598: { name: "Cow", size: 2 },
    10599: { name: "Cannoneer", size: 1 },
    10600: { name: "Cannoneer", size: 1 },
    10601: { name: "Cannoneer", size: 1 },
    10602: { name: "Cannoneer", size: 1 },
    10603: { name: "Monkey on unicorn", size: 2 },
    10604: { name: "Sailor", size: 1 },
    10605: { name: "Spirit Angler", size: 1 },
    10606: { name: "Spirit Angler", size: 1 },
    10607: { name: "Spirit Angler", size: 1 },
    10608: { name: "Spirit Angler", size: 1 },
    10609: { name: "Spirit Angler", size: 1 },
    10610: { name: "Ferryman Sathwood", size: 1 },
    10611: { name: "Ferryman Nathwood", size: 1 },
    10612: { name: "Retired sailor", size: 1 },
    10613: { name: "Gita Prymes", size: 1 },
    10614: { name: "Taiman", size: 1 },
    10615: { name: "Koanee", size: 1 },
    10616: { name: "Timallus", size: 1 },
    10617: { name: "Lauretta", size: 1 },
    10618: { name: "Ishmael", size: 1 },
    10619: { name: "Bob", size: 1 },
    10620: { name: "JalRek-Jad", size: 1 },
    10621: { name: "TzHaar-Ket-Rak", size: 3 },
    10622: { name: "TzHaar-Ket-Rak", size: 3 },
    10623: { name: "JalTok-Jad", size: 5 },
    10624: { name: "Yt-HurKot", size: 1 },
    10625: { name: "JalRek-Jad", size: 1 },
    10626: { name: "null", size: 3 },
    10627: { name: "null", size: 1 },
    10628: { name: "<col=00ffff>Shadow</col>", size: 2 },
    10629: { name: "null", size: 2 },
    10630: { name: "Dusuri", size: 1 },
    10631: { name: "Dusuri", size: 1 },
    10632: { name: "Star Sprite", size: 1 },
    10633: { name: "null", size: 1 },
    10634: { name: "Shantay Guard", size: 1 },
    10635: { name: "Fishing spot", size: 1 },
    10636: { name: "Great blue heron", size: 1 },
    10637: { name: "Tiny Tempor", size: 1 },
    10638: { name: "null", size: 1 },
    10639: { name: "null", size: 1 },
    10640: { name: "null", size: 1 },
    10641: { name: "null", size: 1 },
    10642: { name: "null", size: 1 },
    10643: { name: "null", size: 1 },
    10644: { name: "null", size: 1 },
    10645: { name: "null", size: 1 },
    10646: { name: "null", size: 1 },
    10647: { name: "null", size: 1 },
    10648: { name: "null", size: 1 },
    10649: { name: "null", size: 1 },
    10650: { name: "Baby Mole-rat", size: 1 },
    10651: { name: "Baby Mole-rat", size: 1 },
    10652: { name: "Spider", size: 1 },
    10653: { name: "Fishing spot", size: 1 },
    10654: { name: "Ancient Guardian", size: 2 },
    10655: { name: "Willow", size: 1 },
    10656: { name: "Marley", size: 1 },
    10657: { name: "Checkal", size: 1 },
    10658: { name: "Atlas", size: 1 },
    10659: { name: "Burntof", size: 1 },
    10660: { name: "Burntof", size: 1 },
    10661: { name: "Willow", size: 1 },
    10662: { name: "Marley", size: 1 },
    10663: { name: "Checkal", size: 1 },
    10664: { name: "Burntof", size: 1 },
    10665: { name: "Ancient Guardian", size: 2 },
    10666: { name: "<col=00ffff>Rubble</col>", size: 2 },
    10667: { name: "<col=00ffff>Fuse</col>", size: 1 },
    10668: { name: "Tina", size: 1 },
    10669: { name: "Atlas", size: 1 },
    10670: { name: "Ramarno", size: 1 },
    10671: { name: "Tramp", size: 1 },
    10672: { name: "Man", size: 1 },
    10673: { name: "Man", size: 1 },
    10674: { name: "Woman", size: 1 },
    10675: { name: "Stray dog", size: 1 },
    10676: { name: "Barbarian", size: 1 },
    10677: { name: "Barbarian", size: 1 },
    10678: { name: "Barbarian", size: 1 },
    10679: { name: "Barbarian", size: 1 },
    10680: { name: "Zebak", size: 5 },
    10681: { name: "Aubury", size: 1 },
    10682: { name: "Rat", size: 1 },
    10683: { name: "Baraek", size: 1 },
    10684: { name: "Ramarno", size: 1 },
    10685: { name: "Ramarno", size: 1 },
    10686: { name: "Fishing spot", size: 1 },
    10687: { name: "Fishing spot", size: 1 },
    10688: { name: "Fishing spot", size: 1 },
    10689: { name: "Chaos Golem", size: 2 },
    10690: { name: "<col=00ffff>Rubble</col>", size: 2 },
    10691: { name: "Body Golem", size: 2 },
    10692: { name: "<col=00ffff>Rubble</col>", size: 2 },
    10693: { name: "Mind Golem", size: 2 },
    10694: { name: "<col=00ffff>Rubble</col>", size: 2 },
    10695: { name: "Flawed Golem", size: 2 },
    10696: { name: "<col=00ffff>Rubble</col>", size: 2 },
    10697: { name: "Ghost", size: 1 },
    10698: { name: "Ghost", size: 1 },
    10699: { name: "Ghost", size: 1 },
    10700: { name: "Frog", size: 1 },
    10701: { name: "null", size: 1 },
    10702: { name: "null", size: 1 },
    10703: { name: "null", size: 1 },
    10704: { name: "null", size: 1 },
    10705: { name: "null", size: 1 },
    10706: { name: "null", size: 1 },
    10707: { name: "Murphy", size: 1 },
    10708: { name: "Enormous Tentacle", size: 3 },
    10709: { name: "Enormous Tentacle", size: 3 },
    10710: { name: "null", size: 3 },
    10711: { name: "null", size: 3 },
    10712: { name: "null", size: 1 },
    10713: { name: "null", size: 1 },
    10714: { name: "null", size: 1 },
    10715: { name: "null", size: 1 },
    10716: { name: "null", size: 1 },
    10717: { name: "Skeleton", size: 1 },
    10718: { name: "Skeleton", size: 1 },
    10719: { name: "Skeleton", size: 1 },
    10720: { name: "Skeleton", size: 1 },
    10721: { name: "Skeleton", size: 1 },
    10722: { name: "Ice spider", size: 1 },
    10723: { name: "Veos", size: 1 },
    10724: { name: "Veos", size: 1 },
    10725: { name: "Sergeant", size: 1 },
    10726: { name: "Veos", size: 1 },
    10727: { name: "Veos", size: 1 },
    10728: { name: "Woman", size: 1 },
    10729: { name: "null", size: 1 },
    10730: { name: "null", size: 1 },
    10731: { name: "Ranger", size: 1 },
    10732: { name: "Zamorakian recruiter", size: 1 },
    10733: { name: "Meredith", size: 1 },
    10734: { name: "Banker", size: 1 },
    10735: { name: "Banker", size: 1 },
    10736: { name: "Banker", size: 1 },
    10737: { name: "Banker", size: 1 },
    10738: { name: "null", size: 1 },
    10739: { name: "Raqueel", size: 1 },
    10740: { name: "Raqueel", size: 1 },
    10741: { name: "Raqueel", size: 1 },
    10742: { name: "Raqueel", size: 1 },
    10743: { name: "Raqueel", size: 1 },
    10744: { name: "Raqueel", size: 1 },
    10745: { name: "Raqueel", size: 1 },
    10746: { name: "Raqueel", size: 1 },
    10747: { name: "null", size: 1 },
    10748: { name: "Gem", size: 1 },
    10749: { name: "Gem", size: 1 },
    10750: { name: "Gem", size: 1 },
    10751: { name: "Gem", size: 1 },
    10752: { name: "Gem", size: 1 },
    10753: { name: "Gem", size: 1 },
    10754: { name: "Gem", size: 1 },
    10755: { name: "Gem", size: 1 },
    10756: { name: "Gardener Jay Jr.", size: 1 },
    10757: { name: "Gardener Jay Jr.", size: 1 },
    10758: { name: "Gardener Jay Jr.", size: 1 },
    10759: { name: "Clerk", size: 1 },
    10760: { name: "Stray dog", size: 1 },
    10761: { name: "Lil' Maiden", size: 1 },
    10762: { name: "Lil' Bloat", size: 1 },
    10763: { name: "Lil' Nylo", size: 1 },
    10764: { name: "Lil' Sot", size: 1 },
    10765: { name: "Lil' Xarp", size: 1 },
    10766: { name: "Xarpus", size: 3 },
    10767: { name: "Xarpus", size: 3 },
    10768: { name: "Xarpus", size: 5 },
    10769: { name: "Xarpus", size: 5 },
    10770: { name: "Xarpus", size: 3 },
    10771: { name: "Xarpus", size: 3 },
    10772: { name: "Xarpus", size: 5 },
    10773: { name: "Xarpus", size: 5 },
    10774: { name: "Nylocas Ischyros", size: 1 },
    10775: { name: "Nylocas Toxobolos", size: 1 },
    10776: { name: "Nylocas Hagios", size: 1 },
    10777: { name: "Nylocas Ischyros", size: 2 },
    10778: { name: "Nylocas Toxobolos", size: 2 },
    10779: { name: "Nylocas Hagios", size: 2 },
    10780: { name: "Nylocas Ischyros", size: 1 },
    10781: { name: "Nylocas Toxobolos", size: 1 },
    10782: { name: "Nylocas Hagios", size: 1 },
    10783: { name: "Nylocas Ischyros", size: 2 },
    10784: { name: "Nylocas Toxobolos", size: 2 },
    10785: { name: "Nylocas Hagios", size: 2 },
    10786: { name: "Nylocas Vasilias", size: 4 },
    10787: { name: "Nylocas Vasilias", size: 4 },
    10788: { name: "Nylocas Vasilias", size: 4 },
    10789: { name: "Nylocas Vasilias", size: 4 },
    10790: { name: "null", size: 3 },
    10791: { name: "Nylocas Ischyros", size: 1 },
    10792: { name: "Nylocas Toxobolos", size: 1 },
    10793: { name: "Nylocas Hagios", size: 1 },
    10794: { name: "Nylocas Ischyros", size: 2 },
    10795: { name: "Nylocas Toxobolos", size: 2 },
    10796: { name: "Nylocas Hagios", size: 2 },
    10797: { name: "Nylocas Ischyros", size: 1 },
    10798: { name: "Nylocas Toxobolos", size: 1 },
    10799: { name: "Nylocas Hagios", size: 1 },
    10800: { name: "Nylocas Ischyros", size: 2 },
    10801: { name: "Nylocas Toxobolos", size: 2 },
    10802: { name: "Nylocas Hagios", size: 2 },
    10803: { name: "Nylocas Prinkipas", size: 3 },
    10804: { name: "Nylocas Prinkipas", size: 3 },
    10805: { name: "Nylocas Prinkipas", size: 3 },
    10806: { name: "Nylocas Prinkipas", size: 3 },
    10807: { name: "Nylocas Vasilias", size: 4 },
    10808: { name: "Nylocas Vasilias", size: 4 },
    10809: { name: "Nylocas Vasilias", size: 4 },
    10810: { name: "Nylocas Vasilias", size: 4 },
    10811: { name: "null", size: 3 },
    10812: { name: "Pestilent Bloat", size: 5 },
    10813: { name: "Pestilent Bloat", size: 5 },
    10814: { name: "The Maiden of Sugadinti", size: 6 },
    10815: { name: "The Maiden of Sugadinti", size: 6 },
    10816: { name: "The Maiden of Sugadinti", size: 6 },
    10817: { name: "The Maiden of Sugadinti", size: 6 },
    10818: { name: "The Maiden of Sugadinti", size: 6 },
    10819: { name: "The Maiden of Sugadinti", size: 6 },
    10820: { name: "Nylocas Matomenos", size: 2 },
    10821: { name: "Blood spawn", size: 1 },
    10822: { name: "The Maiden of Sugadinti", size: 6 },
    10823: { name: "The Maiden of Sugadinti", size: 6 },
    10824: { name: "The Maiden of Sugadinti", size: 6 },
    10825: { name: "The Maiden of Sugadinti", size: 6 },
    10826: { name: "The Maiden of Sugadinti", size: 6 },
    10827: { name: "The Maiden of Sugadinti", size: 6 },
    10828: { name: "Nylocas Matomenos", size: 2 },
    10829: { name: "Blood spawn", size: 1 },
    10830: { name: "Verzik Vitur", size: 5 },
    10831: { name: "Verzik Vitur", size: 5 },
    10832: { name: "Verzik Vitur", size: 5 },
    10833: { name: "Verzik Vitur", size: 3 },
    10834: { name: "Verzik Vitur", size: 7 },
    10835: { name: "Verzik Vitur", size: 7 },
    10836: { name: "Verzik Vitur", size: 3 },
    10837: { name: "<col=00ffff>Web</col>", size: 1 },
    10838: { name: "<col=00ffff>Collapsing Pillar</col>", size: 5 },
    10839: { name: "<col=00ffff>Collapsing Pillar</col>", size: 5 },
    10840: { name: "<col=00ffff>Supporting Pillar</col>", size: 3 },
    10841: { name: "Nylocas Ischyros", size: 2 },
    10842: { name: "Nylocas Toxobolos", size: 2 },
    10843: { name: "Nylocas Hagios", size: 2 },
    10844: { name: "Nylocas Athanatos", size: 3 },
    10845: { name: "Nylocas Matomenos", size: 2 },
    10846: { name: "null", size: 1 },
    10847: { name: "Verzik Vitur", size: 5 },
    10848: { name: "Verzik Vitur", size: 5 },
    10849: { name: "Verzik Vitur", size: 5 },
    10850: { name: "Verzik Vitur", size: 3 },
    10851: { name: "Verzik Vitur", size: 7 },
    10852: { name: "Verzik Vitur", size: 7 },
    10853: { name: "Verzik Vitur", size: 3 },
    10854: { name: "<col=00ffff>Web</col>", size: 1 },
    10855: { name: "<col=00ffff>Collapsing Pillar</col>", size: 5 },
    10856: { name: "<col=00ffff>Collapsing Pillar</col>", size: 5 },
    10857: { name: "<col=00ffff>Supporting Pillar</col>", size: 3 },
    10858: { name: "Nylocas Ischyros", size: 2 },
    10859: { name: "Nylocas Toxobolos", size: 2 },
    10860: { name: "Nylocas Hagios", size: 2 },
    10861: { name: "Nylocas Athanatos", size: 3 },
    10862: { name: "Nylocas Matomenos", size: 2 },
    10863: { name: "null", size: 1 },
    10864: { name: "Sotetseg", size: 5 },
    10865: { name: "Sotetseg", size: 5 },
    10866: { name: "null", size: 3 },
    10867: { name: "Sotetseg", size: 5 },
    10868: { name: "Sotetseg", size: 5 },
    10869: { name: "null", size: 3 },
    10870: { name: "Lil' Maiden", size: 1 },
    10871: { name: "Lil' Bloat", size: 1 },
    10872: { name: "Lil' Nylo", size: 1 },
    10873: { name: "Lil' Sot", size: 1 },
    10874: { name: "Lil' Xarp", size: 1 },
    10875: { name: "Mysterious Stranger", size: 1 },
    10876: { name: "Mysterious Stranger", size: 1 },
    10877: { name: "Mysterious Stranger", size: 1 },
    10878: { name: "null", size: 1 },
    10879: { name: "null", size: 1 },
    10880: { name: "null", size: 1 },
    10881: { name: "null", size: 1 },
    10882: { name: "null", size: 1 },
    10883: { name: "null", size: 1 },
    10884: { name: "null", size: 1 },
    10885: { name: "null", size: 1 },
    10886: { name: "null", size: 1 },
    10887: { name: "Town Crier", size: 1 },
    10888: { name: "Bat", size: 1 },
    10889: { name: "Asteros Arceuus", size: 1 },
    10890: { name: "Martin Holt", size: 1 },
    10891: { name: "Martin Holt", size: 1 },
    10892: { name: "Martin Holt", size: 1 },
    10893: { name: "Martin Holt", size: 1 },
    10894: { name: "Martin Holt", size: 1 },
    10895: { name: "Martin Holt", size: 2 },
    10896: { name: "Protest Leader", size: 1 },
    10897: { name: "Protester", size: 1 },
    10898: { name: "Protester", size: 1 },
    10899: { name: "Protester", size: 1 },
    10900: { name: "Protester", size: 1 },
    10901: { name: "Protester", size: 1 },
    10902: { name: "Protester", size: 1 },
    10903: { name: "Protester", size: 1 },
    10904: { name: "Protester", size: 1 },
    10905: { name: "Protester", size: 1 },
    10906: { name: "Protester", size: 1 },
    10907: { name: "Protester", size: 1 },
    10908: { name: "Protester", size: 1 },
    10909: { name: "Guard", size: 1 },
    10910: { name: "Guard", size: 1 },
    10911: { name: "Guard", size: 1 },
    10912: { name: "Guard", size: 1 },
    10913: { name: "Guard", size: 1 },
    10914: { name: "Guard", size: 1 },
    10915: { name: "Guard", size: 1 },
    10916: { name: "Guard", size: 1 },
    10917: { name: "Guard", size: 1 },
    10918: { name: "Guard", size: 1 },
    10919: { name: "Guard", size: 1 },
    10920: { name: "Guard", size: 1 },
    10921: { name: "Guard", size: 2 },
    10922: { name: "Commander Fullore", size: 1 },
    10923: { name: "Commander Fullore", size: 1 },
    10924: { name: "Royal Guard", size: 1 },
    10925: { name: "Royal Guard", size: 1 },
    10926: { name: "Councillor Andrews", size: 1 },
    10927: { name: "David Andrews", size: 1 },
    10928: { name: "Tomas Lawry", size: 1 },
    10929: { name: "Tomas Lawry", size: 2 },
    10930: { name: "null", size: 1 },
    10931: { name: "Captain Ginea", size: 1 },
    10932: { name: "Cabin Boy Herbert", size: 1 },
    10933: { name: "Cabin Boy Herbert", size: 1 },
    10934: { name: "Cabin Boy Herbert", size: 1 },
    10935: { name: "Sophia Hughes", size: 2 },
    10936: { name: "Judge of Yama", size: 4 },
    10937: { name: "Mysterious Voice", size: 1 },
    10938: { name: "Judge of Yama", size: 4 },
    10939: { name: "<col=00ffff>Fire Wave</col>", size: 1 },
    10940: { name: "Assassin", size: 1 },
    10941: { name: "Assassin", size: 1 },
    10942: { name: "Assassin", size: 1 },
    10943: { name: "Councillor Orson", size: 1 },
    10944: { name: "Councillor Orson", size: 1 },
    10945: { name: "Man", size: 1 },
    10946: { name: "<col=00ffff>Councillor Orson</col>", size: 2 },
    10947: { name: "Lizardman brute", size: 1 },
    10948: { name: "Lizardman", size: 1 },
    10949: { name: "Veos", size: 1 },
    10950: { name: "Mysterious Voice", size: 1 },
    10951: { name: "Xamphur", size: 3 },
    10952: { name: "Mysterious Voice", size: 1 },
    10953: { name: "Xamphur", size: 3 },
    10954: { name: "Xamphur", size: 3 },
    10955: { name: "Xamphur", size: 3 },
    10956: { name: "Xamphur", size: 3 },
    10957: { name: "Phantom Hand", size: 1 },
    10958: { name: "Phantom Hand", size: 1 },
    10959: { name: "Kubec Unkar", size: 1 },
    10960: { name: "Councillor Unkar", size: 1 },
    10961: { name: "Lord Trobin Arceuus", size: 1 },
    10962: { name: "Lord Trobin Arceuus", size: 1 },
    10963: { name: "Lord Shiro Shayzien", size: 1 },
    10964: { name: "Lord Shiro Shayzien", size: 2 },
    10965: { name: "Lord Shiro Shayzien", size: 1 },
    10966: { name: "Lord Kandur Hosidius", size: 1 },
    10967: { name: "King Kandur Hosidius", size: 1 },
    10968: { name: "King Kandur Hosidius", size: 3 },
    10969: { name: "King Kandur Hosidius", size: 3 },
    10970: { name: "Lord Kandur Hosidius", size: 2 },
    10971: { name: "Lord Kandur Hosidius", size: 1 },
    10972: { name: "Lady Vulcana Lovakengj", size: 1 },
    10973: { name: "Lady Vulcana Lovakengj", size: 1 },
    10974: { name: "Lady Shauna Piscarilius", size: 1 },
    10975: { name: "Lady Shauna Piscarilius", size: 1 },
    10976: { name: "Artur Hosidius", size: 1 },
    10977: { name: "King Artur Hosidius", size: 1 },
    10978: { name: "Asteros Arceuus", size: 1 },
    10979: { name: "Asteros Arceuus", size: 1 },
    10980: { name: "Lord Pandur Hosidius", size: 1 },
    10981: { name: "Pandur Hosidius", size: 1 },
    10982: { name: "Elena Hosidius", size: 1 },
    10983: { name: "Elena Hosidius", size: 1 },
    10984: { name: "Barbarian", size: 1 },
    10985: { name: "Barbarian", size: 1 },
    10986: { name: "Barbarian", size: 1 },
    10987: { name: "Barbarian", size: 1 },
    10988: { name: "Barbarian", size: 1 },
    10989: { name: "Barbarian Warlord", size: 1 },
    10990: { name: "null", size: 1 },
    10991: { name: "Phileas Rimor", size: 1 },
    10992: { name: "Captain Rachelle", size: 1 },
    10993: { name: "Protester", size: 1 },
    10994: { name: "Protester", size: 1 },
    10995: { name: "Protester", size: 1 },
    10996: { name: "Protester", size: 1 },
    10997: { name: "Protester", size: 1 },
    10998: { name: "Protester", size: 1 },
    10999: { name: "Protester", size: 1 },
    11000: { name: "Protester", size: 1 },
    11001: { name: "Protester", size: 1 },
    11002: { name: "Protester", size: 1 },
    11003: { name: "Protester", size: 1 },
    11004: { name: "Protester", size: 1 },
    11005: { name: "Protester", size: 1 },
    11006: { name: "Protester", size: 1 },
    11007: { name: "Protester", size: 1 },
    11008: { name: "Protester", size: 1 },
    11009: { name: "Protester", size: 1 },
    11010: { name: "Protester", size: 1 },
    11011: { name: "Protester", size: 1 },
    11012: { name: "Queen Zyanyi Arkan", size: 1 },
    11013: { name: "Kualti", size: 1 },
    11014: { name: "Kualti", size: 1 },
    11015: { name: "Kualti", size: 1 },
    11016: { name: "Kualti", size: 1 },
    11017: { name: "Kualti", size: 1 },
    11018: { name: "Kualti", size: 1 },
    11019: { name: "King Roald", size: 1 },
    11020: { name: "Sir Amik Varze", size: 1 },
    11021: { name: "Sir Tiffy Cashien", size: 1 },
    11022: { name: "King Lathas", size: 1 },
    11023: { name: "King Thoros", size: 1 },
    11024: { name: "Duke Horacio", size: 1 },
    11025: { name: "Queen Ellamaria", size: 1 },
    11026: { name: "Seagull", size: 1 },
    11027: { name: "Spider", size: 1 },
    11028: { name: "<col=00ffff>Ice chunks</col>", size: 1 },
    11029: { name: "<col=00ffff>Ice chunks</col>", size: 1 },
    11030: { name: "<col=00ffff>Ice chunks</col>", size: 1 },
    11031: { name: "<col=00ffff>Ice chunks</col>", size: 1 },
    11032: { name: "Man", size: 1 },
    11033: { name: "Lord Kandur Hosidius", size: 1 },
    11034: { name: "Elena Hosidius", size: 1 },
    11035: { name: "Lady Vulcana Lovakengj", size: 1 },
    11036: { name: "Councillor Unkar", size: 1 },
    11037: { name: "Jorra", size: 1 },
    11038: { name: "Lord Shiro Shayzien", size: 1 },
    11039: { name: "Kaht B'alam", size: 2 },
    11040: { name: "Blair", size: 1 },
    11041: { name: "Daryl", size: 1 },
    11042: { name: "Robyn", size: 1 },
    11043: { name: "Oswald", size: 1 },
    11044: { name: "Sheryl", size: 1 },
    11045: { name: "Farmer", size: 1 },
    11046: { name: "Soldier", size: 1 },
    11047: { name: "Soldier", size: 1 },
    11048: { name: "Drunken soldier", size: 1 },
    11049: { name: "Soldier", size: 1 },
    11050: { name: "Father Eysseric", size: 1 },
    11051: { name: "Mia", size: 1 },
    11052: { name: "Elijah", size: 1 },
    11053: { name: "Woman", size: 1 },
    11054: { name: "Woman", size: 1 },
    11055: { name: "Kaston", size: 1 },
    11056: { name: "Old man", size: 1 },
    11057: { name: "Man", size: 1 },
    11058: { name: "Man", size: 1 },
    11059: { name: "Commissioner Anwar", size: 1 },
    11060: { name: "Captain Bruce", size: 1 },
    11061: { name: "Sergeant Ricardo", size: 1 },
    11062: { name: "Jessie", size: 1 },
    11063: { name: "Bandit", size: 1 },
    11064: { name: "Bandit", size: 1 },
    11065: { name: "Bandit", size: 1 },
    11066: { name: "Boar", size: 1 },
    11067: { name: "Boar", size: 2 },
    11068: { name: "Lynx", size: 2 },
    11069: { name: "Lynx", size: 2 },
    11070: { name: "Lynx", size: 2 },
    11071: { name: "Lynx Tamer", size: 1 },
    11072: { name: "Soldier", size: 1 },
    11073: { name: "Sergeant", size: 1 },
    11074: { name: "Soldier", size: 1 },
    11075: { name: "Sergeant", size: 1 },
    11076: { name: "Soldier", size: 1 },
    11077: { name: "Sergeant", size: 1 },
    11078: { name: "Soldier", size: 1 },
    11079: { name: "Sergeant", size: 1 },
    11080: { name: "Soldier", size: 1 },
    11081: { name: "Sergeant", size: 1 },
    11082: { name: "Soldier", size: 1 },
    11083: { name: "Sergeant", size: 1 },
    11084: { name: "Soldier", size: 1 },
    11085: { name: "Sergeant", size: 1 },
    11086: { name: "Soldier", size: 1 },
    11087: { name: "Sergeant", size: 1 },
    11088: { name: "Necromancer", size: 1 },
    11089: { name: "Ranger", size: 1 },
    11090: { name: "Farolt", size: 1 },
    11091: { name: "Pandur Hosidius", size: 1 },
    11092: { name: "Guard", size: 1 },
    11093: { name: "Head Guard", size: 1 },
    11094: { name: "Guard", size: 1 },
    11095: { name: "Head Guard", size: 1 },
    11096: { name: "Guard", size: 1 },
    11097: { name: "Head Guard", size: 1 },
    11098: { name: "Guard", size: 1 },
    11099: { name: "Head Guard", size: 1 },
    11100: { name: "Guard", size: 1 },
    11101: { name: "Head Guard", size: 1 },
    11102: { name: "Guard", size: 1 },
    11103: { name: "Head Guard", size: 1 },
    11104: { name: "Guard", size: 1 },
    11105: { name: "Head Guard", size: 1 },
    11106: { name: "Guard", size: 1 },
    11107: { name: "Head Guard", size: 1 },
    11108: { name: "null", size: 1 },
    11109: { name: "Dark warrior", size: 1 },
    11110: { name: "Dark warrior", size: 1 },
    11111: { name: "Dark warrior", size: 1 },
    11112: { name: "Istoria", size: 1 },
    11113: { name: "Istoria", size: 1 },
    11114: { name: "null", size: 1 },
    11115: { name: "null", size: 1 },
    11116: { name: "null", size: 1 },
    11117: { name: "null", size: 1 },
    11118: { name: "null", size: 1 },
    11119: { name: "null", size: 1 },
    11120: { name: "null", size: 1 },
    11121: { name: "null", size: 1 },
    11122: { name: "null", size: 1 },
    11123: { name: "null", size: 1 },
    11124: { name: "null", size: 1 },
    11125: { name: "null", size: 1 },
    11126: { name: "null", size: 1 },
    11127: { name: "null", size: 1 },
    11128: { name: "null", size: 1 },
    11129: { name: "null", size: 1 },
    11130: { name: "null", size: 1 },
    11131: { name: "null", size: 1 },
    11132: { name: "null", size: 1 },
    11133: { name: "null", size: 1 },
    11134: { name: "null", size: 1 },
    11135: { name: "null", size: 1 },
    11136: { name: "null", size: 1 },
    11137: { name: "null", size: 1 },
    11138: { name: "null", size: 1 },
    11139: { name: "null", size: 1 },
    11140: { name: "null", size: 1 },
    11141: { name: "null", size: 1 },
    11142: { name: "null", size: 1 },
    11143: { name: "null", size: 1 },
    11144: { name: "null", size: 1 },
    11145: { name: "null", size: 1 },
    11146: { name: "null", size: 1 },
    11147: { name: "null", size: 1 },
    11148: { name: "null", size: 1 },
    11149: { name: "null", size: 2 },
    11150: { name: "null", size: 1 },
    11151: { name: "null", size: 1 },
    11152: { name: "Councillor Andrews", size: 1 },
    11153: { name: "Phosani's Nightmare", size: 5 },
    11154: { name: "Phosani's Nightmare", size: 5 },
    11155: { name: "Phosani's Nightmare", size: 5 },
    11156: { name: "Swarm", size: 1 },
    11157: { name: "Sraracha", size: 1 },
    11158: { name: "Sraracha", size: 1 },
    11159: { name: "Sraracha", size: 1 },
    11160: { name: "Sraracha", size: 1 },
    11161: { name: "Dusty Aliv", size: 1 },
    11162: { name: "Mysterious Stranger", size: 1 },
    11163: { name: "null", size: 1 },
    11164: { name: "null", size: 1 },
    11165: { name: "null", size: 1 },
    11166: { name: "null", size: 1 },
    11167: { name: "null", size: 1 },
    11168: { name: "null", size: 1 },
    11169: { name: "Vyrewatch", size: 1 },
    11170: { name: "Vyrewatch", size: 1 },
    11171: { name: "Vyrewatch", size: 1 },
    11172: { name: "Vyrewatch", size: 1 },
    11173: { name: "Vyrewatch", size: 1 },
    11174: { name: "Araxyte", size: 1 },
    11175: { name: "Araxyte", size: 1 },
    11176: { name: "Araxyte", size: 2 },
    11177: { name: "Ranis Drakan", size: 1 },
    11178: { name: "Verzik Vitur", size: 1 },
    11179: { name: "Verzik Vitur", size: 3 },
    11180: { name: "Vulcan Orvoros", size: 1 },
    11181: { name: "Nylocas Queen", size: 7 },
    11182: { name: "Nylocas", size: 1 },
    11183: { name: "The Maiden of Sugadinti", size: 6 },
    11184: { name: "Pestilent Bloat", size: 5 },
    11185: { name: "Nylocas Vasilias", size: 4 },
    11186: { name: "Sotetseg", size: 5 },
    11187: { name: "Xarpus", size: 5 },
    11188: { name: "Nylocas Athanatos", size: 3 },
    11189: { name: "Nylocas Ischyros", size: 1 },
    11190: { name: "Nylocas Toxobolos", size: 1 },
    11191: { name: "Nylocas Hagios", size: 1 },
    11192: { name: "Hespori", size: 3 },
    11193: { name: "Flower", size: 1 },
    11194: { name: "<col=00ffff>Flower</col>", size: 1 },
    11195: { name: "Hill Giant", size: 2 },
    11196: { name: "Lynx Tamer", size: 1 },
    11197: { name: "Lynx", size: 2 },
    11198: { name: "Sergeant", size: 1 },
    11199: { name: "Gnome guard", size: 1 },
    11200: { name: "Guard", size: 1 },
    11201: { name: "Guard", size: 1 },
    11202: { name: "Guard", size: 1 },
    11203: { name: "Guard", size: 1 },
    11204: { name: "Guard", size: 1 },
    11205: { name: "Ghost guard", size: 1 },
    11206: { name: "Guard", size: 1 },
    11207: { name: "Guard", size: 1 },
    11208: { name: "Guard", size: 1 },
    11209: { name: "Guard", size: 1 },
    11210: { name: "Guard", size: 1 },
    11211: { name: "Prifddinas guard", size: 1 },
    11212: { name: "null", size: 1 },
    11213: { name: "null", size: 1 },
    11214: { name: "null", size: 1 },
    11215: { name: "null", size: 1 },
    11216: { name: "null", size: 1 },
    11217: { name: "null", size: 1 },
    11218: { name: "null", size: 1 },
    11219: { name: "null", size: 1 },
    11220: { name: "null", size: 1 },
    11221: { name: "null", size: 1 },
    11222: { name: "null", size: 1 },
    11223: { name: "null", size: 1 },
    11224: { name: "null", size: 1 },
    11225: { name: "D3ad1i F15her", size: 1 },
    11226: { name: "Boar31337Killer", size: 1 },
    11227: { name: "Enraged Boar", size: 2 },
    11228: { name: "R0ck 5masher", size: 1 },
    11229: { name: "Regent", size: 1 },
    11230: { name: "Group Storage tutor", size: 1 },
    11231: { name: "Group Ironman tutor", size: 1 },
    11232: { name: "The Sage", size: 1 },
    11233: { name: "League Tutor", size: 1 },
    11234: { name: "Leagues Assistant", size: 1 },
    11235: { name: "null", size: 2 },
    11236: { name: "null", size: 1 },
    11237: { name: "null", size: 1 },
    11238: { name: "Dust devil", size: 1 },
    11239: { name: "Abyssal demon", size: 1 },
    11240: { name: "Greater Nechryael", size: 1 },
    11241: { name: "Jelly", size: 1 },
    11242: { name: "Jelly", size: 1 },
    11243: { name: "Jelly", size: 1 },
    11244: { name: "Jelly", size: 1 },
    11245: { name: "Jelly", size: 1 },
    11246: { name: "Revenant maledictus", size: 5 },
    11247: { name: "TzHaar-Ket-Keh", size: 2 },
    11248: { name: "TzHaar-Ket-Keh", size: 2 },
    11249: { name: "General Bentnoze", size: 1 },
    11250: { name: "General Wartface", size: 1 },
    11251: { name: "Grubfoot", size: 1 },
    11252: { name: "Grubfoot", size: 1 },
    11253: { name: "null", size: 1 },
    11254: { name: "Grubfoot", size: 1 },
    11255: { name: "Grubfoot", size: 1 },
    11256: { name: "null", size: 1 },
    11257: { name: "null", size: 1 },
    11258: { name: "null", size: 1 },
    11259: { name: "Grubfoot", size: 1 },
    11260: { name: "Zanik", size: 1 },
    11261: { name: "Zanik", size: 1 },
    11262: { name: "Zanik", size: 1 },
    11263: { name: "Zanik", size: 1 },
    11264: { name: "Zanik", size: 1 },
    11265: { name: "Oldak", size: 1 },
    11266: { name: "Oldak", size: 1 },
    11267: { name: "High Priest Bighead", size: 1 },
    11268: { name: "Skoblin", size: 1 },
    11269: { name: "Snothead", size: 1 },
    11270: { name: "Snailfeet", size: 1 },
    11271: { name: "Mosschin", size: 1 },
    11272: { name: "Redeyes", size: 1 },
    11273: { name: "Strongbones", size: 1 },
    11274: { name: "Snothead", size: 1 },
    11275: { name: "Snailfeet", size: 1 },
    11276: { name: "Nexling", size: 1 },
    11277: { name: "Nexling", size: 1 },
    11278: { name: "Nex", size: 3 },
    11279: { name: "Nex", size: 3 },
    11280: { name: "Nex", size: 3 },
    11281: { name: "Nex", size: 3 },
    11282: { name: "Nex", size: 3 },
    11283: { name: "Fumus", size: 1 },
    11284: { name: "Umbra", size: 1 },
    11285: { name: "Cruor", size: 1 },
    11286: { name: "Glacies", size: 1 },
    11287: { name: "Messenger", size: 1 },
    11288: { name: "Ashuelot Reis", size: 1 },
    11289: { name: "Ashuelot Reis", size: 1 },
    11290: { name: "Spiritual Warrior", size: 1 },
    11291: { name: "Spiritual Ranger", size: 1 },
    11292: { name: "Spiritual Mage", size: 1 },
    11293: { name: "Blood Reaver", size: 2 },
    11294: { name: "Blood Reaver", size: 2 },
    11295: { name: "null", size: 1 },
    11296: { name: "null", size: 1 },
    11297: { name: "Gull", size: 1 },
    11298: { name: "Mosschin", size: 1 },
    11299: { name: "Redeyes", size: 1 },
    11300: { name: "Strongbones", size: 1 },
    11301: { name: "Ghost", size: 1 },
    11302: { name: "Priest", size: 1 },
    11303: { name: "Priest", size: 1 },
    11304: { name: "Priest", size: 1 },
    11305: { name: "Priest", size: 1 },
    11306: { name: "Priest", size: 1 },
    11307: { name: "Priest", size: 1 },
    11308: { name: "Priest", size: 1 },
    11309: { name: "Priest", size: 1 },
    11310: { name: "Priest", size: 1 },
    11311: { name: "Priest", size: 1 },
    11312: { name: "Priest", size: 1 },
    11313: { name: "Priest", size: 1 },
    11314: { name: "Goblin guard", size: 1 },
    11315: { name: "Goblin guard", size: 1 },
    11316: { name: "Guard", size: 1 },
    11317: { name: "Guard", size: 1 },
    11318: { name: "Guard", size: 1 },
    11319: { name: "Guard", size: 1 },
    11320: { name: "Guard", size: 1 },
    11321: { name: "Guard", size: 1 },
    11322: { name: "Goblin", size: 1 },
    11323: { name: "Goblin", size: 1 },
    11324: { name: "Goblin", size: 1 },
    11325: { name: "Goblin", size: 1 },
    11326: { name: "Goblin", size: 1 },
    11327: { name: "Goblin", size: 1 },
    11328: { name: "Goblin", size: 1 },
    11329: { name: "Goblin", size: 1 },
    11330: { name: "Goblin", size: 1 },
    11331: { name: "Goblin", size: 1 },
    11332: { name: "Goblin", size: 1 },
    11333: { name: "Goblin", size: 1 },
    11334: { name: "Goblin", size: 1 },
    11335: { name: "Goblin", size: 1 },
    11336: { name: "Goblin", size: 1 },
    11337: { name: "Goblin", size: 1 },
    11338: { name: "Goblin", size: 1 },
    11339: { name: "Goblin", size: 1 },
    11340: { name: "Goblin", size: 1 },
    11341: { name: "Goblin", size: 1 },
    11342: { name: "Goblin", size: 1 },
    11343: { name: "Goblin", size: 1 },
    11344: { name: "Goblin", size: 1 },
    11345: { name: "Goblin", size: 1 },
    11346: { name: "Goblin", size: 1 },
    11347: { name: "Goblin", size: 1 },
    11348: { name: "Goblin", size: 1 },
    11349: { name: "Goblin", size: 1 },
    11350: { name: "Goblin", size: 1 },
    11351: { name: "Goblin", size: 1 },
    11352: { name: "Goblin", size: 1 },
    11353: { name: "Goblin", size: 1 },
    11354: { name: "Goblin", size: 1 },
    11355: { name: "Goblin", size: 1 },
    11356: { name: "Goblin", size: 1 },
    11357: { name: "Goblin", size: 1 },
    11358: { name: "Goblin", size: 1 },
    11359: { name: "Goblin", size: 1 },
    11360: { name: "Goblin", size: 1 },
    11361: { name: "Goblin", size: 1 },
    11362: { name: "Goblin", size: 1 },
    11363: { name: "Goblin", size: 1 },
    11364: { name: "Goblin", size: 1 },
    11365: { name: "Goblin", size: 1 },
    11366: { name: "Goblin", size: 1 },
    11367: { name: "Goblin", size: 1 },
    11368: { name: "Goblin", size: 1 },
    11369: { name: "Goblin", size: 1 },
    11370: { name: "Goblin", size: 1 },
    11371: { name: "Goblin", size: 1 },
    11372: { name: "Goblin", size: 1 },
    11373: { name: "Goblin", size: 1 },
    11374: { name: "Goblin", size: 1 },
    11375: { name: "Goblin", size: 1 },
    11376: { name: "Goblin", size: 1 },
    11377: { name: "Goblin", size: 1 },
    11378: { name: "Goblin", size: 1 },
    11379: { name: "Goblin", size: 1 },
    11380: { name: "Goblin", size: 1 },
    11381: { name: "Goblin", size: 1 },
    11382: { name: "Preacher", size: 1 },
    11383: { name: "Dipflip", size: 1 },
    11384: { name: "Oldak", size: 1 },
    11385: { name: "Oldak", size: 1 },
    11386: { name: "null", size: 1 },
    11387: { name: "null", size: 1 },
    11388: { name: "null", size: 1 },
    11389: { name: "null", size: 1 },
    11390: { name: "null", size: 1 },
    11391: { name: "null", size: 1 },
    11392: { name: "null", size: 1 },
    11393: { name: "null", size: 1 },
    11394: { name: "null", size: 1 },
    11395: { name: "null", size: 1 },
    11396: { name: "null", size: 1 },
    11397: { name: "null", size: 1 },
    11398: { name: "null", size: 1 },
    11399: { name: "Wizard Distentor", size: 1 },
    11400: { name: "Wizard Distentor", size: 1 },
    11401: { name: "Greatish guardian", size: 1 },
    11402: { name: "Abyssal protector", size: 1 },
    11403: { name: "The Great Guardian", size: 5 },
    11404: { name: "Apprentice Felix", size: 1 },
    11405: { name: "Abyssal guardian", size: 1 },
    11406: { name: "Abyssal walker", size: 1 },
    11407: { name: "Abyssal leech", size: 1 },
    11408: { name: "Weak catalytic guardian", size: 2 },
    11409: { name: "Rick", size: 1 },
    11410: { name: "Rick", size: 1 },
    11411: { name: "Medium catalytic guardian", size: 2 },
    11412: { name: "Strong catalytic guardian", size: 2 },
    11413: { name: "Overcharged catalytic guardian", size: 3 },
    11414: { name: "Weak elemental guardian", size: 2 },
    11415: { name: "Medium elemental guardian", size: 2 },
    11416: { name: "Strong elemental guardian", size: 2 },
    11417: { name: "Overcharged elemental guardian", size: 3 },
    11418: { name: "null", size: 2 },
    11419: { name: "null", size: 3 },
    11420: { name: "null", size: 2 },
    11421: { name: "null", size: 3 },
    11422: { name: "null", size: 2 },
    11423: { name: "null", size: 3 },
    11424: { name: "null", size: 2 },
    11425: { name: "null", size: 3 },
    11426: { name: "null", size: 1 },
    11427: { name: "Friendly Forester", size: 1 },
    11428: { name: "Greatish guardian", size: 1 },
    11429: { name: "Abyssal protector", size: 1 },
    11430: { name: "Brimstail", size: 1 },
    11431: { name: "Brimstail", size: 1 },
    11432: { name: "Archmage Sedridor", size: 1 },
    11433: { name: "Archmage Sedridor", size: 1 },
    11434: { name: "Aubury", size: 1 },
    11435: { name: "Aubury", size: 1 },
    11436: { name: "Wizard Persten", size: 1 },
    11437: { name: "Wizard Persten", size: 1 },
    11438: { name: "Wizard Persten", size: 1 },
    11439: { name: "Wizard Persten", size: 1 },
    11440: { name: "Apprentice Tamara", size: 1 },
    11441: { name: "Apprentice Tamara", size: 1 },
    11442: { name: "Apprentice Tamara", size: 1 },
    11443: { name: "Apprentice Cordelia", size: 1 },
    11444: { name: "Apprentice Cordelia", size: 1 },
    11445: { name: "Apprentice Cordelia", size: 1 },
    11446: { name: "Apprentice Felix", size: 1 },
    11447: { name: "Apprentice Felix", size: 1 },
    11448: { name: "Apprentice Felix", size: 1 },
    11449: { name: "Wizard Traiborn", size: 1 },
    11450: { name: "Archmage Sedridor", size: 1 },
    11451: { name: "Mysterious Voice", size: 1 },
    11452: { name: "Mysterious Voice", size: 1 },
    11453: { name: "Abyssal guardian", size: 1 },
    11454: { name: "Abyssal walker", size: 1 },
    11455: { name: "The Great Guardian", size: 5 },
    11456: { name: "The Great Guardian", size: 5 },
    11457: { name: "null", size: 1 },
    11458: { name: "null", size: 1 },
    11459: { name: "null", size: 1 },
    11460: { name: "null", size: 1 },
    11461: { name: "null", size: 1 },
    11462: { name: "Menaphite Shadow", size: 1 },
    11463: { name: "Reanimated hellhound", size: 2 },
    11464: { name: "Apprentice Tamara", size: 1 },
    11465: { name: "Apprentice Tamara", size: 1 },
    11466: { name: "Smithing catalyst", size: 3 },
    11467: { name: "Hill Giant", size: 2 },
    11468: { name: "Kovac", size: 2 },
    11469: { name: "Kovac", size: 2 },
    11470: { name: "Kovac", size: 2 },
    11471: { name: "null", size: 1 },
    11472: { name: "Kovac", size: 2 },
    11473: { name: "Tarik", size: 1 },
    11474: { name: "Maisa", size: 1 },
    11475: { name: "Maisa", size: 1 },
    11476: { name: "Maisa", size: 1 },
    11477: { name: "Maisa", size: 1 },
    11478: { name: "Spirit", size: 1 },
    11479: { name: "Mehhar", size: 1 },
    11480: { name: "High Priest of Scabaras", size: 1 },
    11481: { name: "High Priest of Scabaras", size: 1 },
    11482: { name: "Champion of Scabaras", size: 1 },
    11483: { name: "Champion of Scabaras", size: 1 },
    11484: { name: "Scarab Swarm", size: 1 },
    11485: { name: "<col=00ffff>Shadow Rift</col>", size: 1 },
    11486: { name: "Osman", size: 1 },
    11487: { name: "Osman", size: 1 },
    11488: { name: "null", size: 1 },
    11489: { name: "Selim", size: 1 },
    11490: { name: "Menaphite Akh", size: 1 },
    11491: { name: "Menaphite Akh", size: 1 },
    11492: { name: "Menaphite Akh", size: 1 },
    11493: { name: "Menaphite Akh", size: 1 },
    11494: { name: "null", size: 2 },
    11495: { name: "Menaphite Akh", size: 1 },
    11496: { name: "Menaphite Akh", size: 1 },
    11497: { name: "Menaphite Akh", size: 1 },
    11498: { name: "Menaphite Akh", size: 1 },
    11499: { name: "Coenus", size: 1 },
    11500: { name: "Jabari", size: 1 },
    11501: { name: "Pharaoh Kemesis", size: 2 },
    11502: { name: "High Priest", size: 2 },
    11503: { name: "Priest", size: 1 },
    11504: { name: "Menaphite Guard", size: 1 },
    11505: { name: "Menaphite Guard", size: 1 },
    11506: { name: "Menaphite Guard", size: 1 },
    11507: { name: "Menaphite Guard", size: 1 },
    11508: { name: "Scarab Mage", size: 1 },
    11509: { name: "Scarab Swarm", size: 1 },
    11510: { name: "Scarab Mage", size: 1 },
    11511: { name: "Scarab Mage", size: 1 },
    11512: { name: "null", size: 3 },
    11513: { name: "Crocodile", size: 3 },
    11514: { name: "Roger", size: 3 },
    11515: { name: "Menaphite Guard", size: 1 },
    11516: { name: "Menaphite Guard", size: 1 },
    11517: { name: "Menaphite Guard", size: 1 },
    11518: { name: "Menaphite Guard", size: 1 },
    11519: { name: "Menaphite Guard", size: 1 },
    11520: { name: "Menaphite Guard", size: 1 },
    11521: { name: "Menaphite Guard", size: 1 },
    11522: { name: "Menaphite Guard", size: 1 },
    11523: { name: "Menaphite Guard", size: 1 },
    11524: { name: "Menaphite Guard", size: 1 },
    11525: { name: "Menaphite Guard", size: 1 },
    11526: { name: "Menaphite Guard", size: 1 },
    11527: { name: "Menaphite Guard", size: 1 },
    11528: { name: "Head Menaphite Guard", size: 1 },
    11529: { name: "Head Menaphite Guard", size: 1 },
    11530: { name: "Head Menaphite Guard", size: 1 },
    11531: { name: "Head Menaphite Guard", size: 2 },
    11532: { name: "Head Menaphite Guard", size: 2 },
    11533: { name: "Citizen", size: 1 },
    11534: { name: "Citizen", size: 1 },
    11535: { name: "Citizen", size: 1 },
    11536: { name: "Citizen", size: 1 },
    11537: { name: "Citizen", size: 1 },
    11538: { name: "Citizen", size: 1 },
    11539: { name: "Citizen", size: 1 },
    11540: { name: "Citizen", size: 1 },
    11541: { name: "Citizen", size: 1 },
    11542: { name: "Citizen", size: 1 },
    11543: { name: "Citizen", size: 1 },
    11544: { name: "Citizen", size: 1 },
    11545: { name: "Citizen", size: 1 },
    11546: { name: "Citizen", size: 1 },
    11547: { name: "Citizen", size: 1 },
    11548: { name: "Citizen", size: 1 },
    11549: { name: "Citizen", size: 1 },
    11550: { name: "Citizen", size: 1 },
    11551: { name: "Citizen", size: 1 },
    11552: { name: "Citizen", size: 1 },
    11553: { name: "Citizen", size: 1 },
    11554: { name: "Citizen", size: 1 },
    11555: { name: "Citizen", size: 1 },
    11556: { name: "Citizen", size: 1 },
    11557: { name: "Citizen", size: 1 },
    11558: { name: "Citizen", size: 1 },
    11559: { name: "null", size: 1 },
    11560: { name: "null", size: 2 },
    11561: { name: "Selim", size: 1 },
    11562: { name: "Jex", size: 1 },
    11563: { name: "Jex", size: 1 },
    11564: { name: "Maisa", size: 1 },
    11565: { name: "Osman", size: 1 },
    11566: { name: "Osman", size: 1 },
    11567: { name: "null", size: 1 },
    11568: { name: "null", size: 1 },
    11569: { name: "Scarab Swarm", size: 1 },
    11570: { name: "Menaphite Guard", size: 1 },
    11571: { name: "Menaphite Guard", size: 1 },
    11572: { name: "Menaphite Guard", size: 1 },
    11573: { name: "Menaphite Guard", size: 1 },
    11574: { name: "Menaphite Guard", size: 1 },
    11575: { name: "Menaphite Guard", size: 1 },
    11576: { name: "Coenus", size: 2 },
    11577: { name: "Joe", size: 1 },
    11578: { name: "Lady Keli", size: 1 },
    11579: { name: "Prince Ali", size: 1 },
    11580: { name: "Prince Ali", size: 1 },
    11581: { name: "Crocodile", size: 2 },
    11582: { name: "Crocodile", size: 2 },
    11583: { name: "Jackal", size: 1 },
    11584: { name: "Locust", size: 1 },
    11585: { name: "Locust", size: 1 },
    11586: { name: "Plague frog", size: 1 },
    11587: { name: "Plague cow", size: 2 },
    11588: { name: "Plague cow", size: 2 },
    11589: { name: "Plague cow", size: 2 },
    11590: { name: "Wanderer", size: 1 },
    11591: { name: "Amascut", size: 1 },
    11592: { name: "Worker", size: 1 },
    11593: { name: "Worker", size: 1 },
    11594: { name: "Worker", size: 1 },
    11595: { name: "Worker", size: 1 },
    11596: { name: "Embalmer", size: 1 },
    11597: { name: "Carpenter", size: 1 },
    11598: { name: "Raetul", size: 1 },
    11599: { name: "Raetul", size: 1 },
    11600: { name: "Raetul", size: 1 },
    11601: { name: "Siamun", size: 1 },
    11602: { name: "High Priest", size: 1 },
    11603: { name: "High Priest", size: 1 },
    11604: { name: "High Priest", size: 2 },
    11605: { name: "High Priest", size: 1 },
    11606: { name: "Priest", size: 1 },
    11607: { name: "Priest", size: 1 },
    11608: { name: "Possessed Priest", size: 1 },
    11609: { name: "Priest", size: 1 },
    11610: { name: "Priest", size: 1 },
    11611: { name: "null", size: 1 },
    11612: { name: "null", size: 1 },
    11613: { name: "null", size: 1 },
    11614: { name: "null", size: 1 },
    11615: { name: "null", size: 1 },
    11616: { name: "null", size: 1 },
    11617: { name: "null", size: 1 },
    11618: { name: "null", size: 1 },
    11619: { name: "null", size: 1 },
    11620: { name: "null", size: 1 },
    11621: { name: "null", size: 1 },
    11622: { name: "null", size: 1 },
    11623: { name: "null", size: 1 },
    11624: { name: "null", size: 1 },
    11625: { name: "null", size: 1 },
    11626: { name: "null", size: 1 },
    11627: { name: "null", size: 1 },
    11628: { name: "null", size: 1 },
    11629: { name: "null", size: 1 },
    11630: { name: "null", size: 1 },
    11631: { name: "null", size: 1 },
    11632: { name: "null", size: 1 },
    11633: { name: "null", size: 1 },
    11634: { name: "null", size: 1 },
    11635: { name: "null", size: 1 },
    11636: { name: "null", size: 1 },
    11637: { name: "null", size: 1 },
    11638: { name: "null", size: 1 },
    11639: { name: "null", size: 1 },
    11640: { name: "null", size: 1 },
    11641: { name: "null", size: 1 },
    11642: { name: "null", size: 1 },
    11643: { name: "null", size: 1 },
    11644: { name: "null", size: 1 },
    11645: { name: "null", size: 1 },
    11646: { name: "null", size: 2 },
    11647: { name: "null", size: 2 },
    11648: { name: "null", size: 2 },
    11649: { name: "null", size: 1 },
    11650: { name: "Lanthus", size: 1 },
    11651: { name: "Hannibal", size: 1 },
    11652: { name: "Tumeken's Guardian", size: 1 },
    11653: { name: "Elidinis' Guardian", size: 1 },
    11654: { name: "Apmeken", size: 1 },
    11655: { name: "Apmeken", size: 1 },
    11656: { name: "Apmeken", size: 1 },
    11657: { name: "Crondis", size: 3 },
    11658: { name: "Gilbert", size: 1 },
    11659: { name: "Crondis", size: 1 },
    11660: { name: "Crondis", size: 1 },
    11661: { name: "Scabaras", size: 1 },
    11662: { name: "Scabaras", size: 1 },
    11663: { name: "Scabaras", size: 1 },
    11664: { name: "Arena Guard Fronk", size: 1 },
    11665: { name: "Arena Guard Nikkolas", size: 1 },
    11666: { name: "Arena Guard Fawry", size: 1 },
    11667: { name: "Arena Guard Yon", size: 1 },
    11668: { name: "Arena Guard Drakno", size: 1 },
    11669: { name: "Arena Guard Rachi", size: 1 },
    11670: { name: "Arena Guard Joby", size: 1 },
    11671: { name: "Arena Guard Beni", size: 1 },
    11672: { name: "1v1 Tournament Guide", size: 1 },
    11673: { name: "Duel Guide", size: 1 },
    11674: { name: "Chris", size: 1 },
    11675: { name: "Fighter", size: 1 },
    11676: { name: "Fighter", size: 1 },
    11677: { name: "Fighter", size: 1 },
    11678: { name: "Fighter", size: 1 },
    11679: { name: "Fighter", size: 1 },
    11680: { name: "Fighter", size: 1 },
    11681: { name: "Fighter", size: 1 },
    11682: { name: "Fighter", size: 1 },
    11683: { name: "Fighter", size: 1 },
    11684: { name: "null", size: 1 },
    11685: { name: "null", size: 1 },
    11686: { name: "Het", size: 2 },
    11687: { name: "Het", size: 1 },
    11688: { name: "Het", size: 1 },
    11689: { name: "Osmumten", size: 1 },
    11690: { name: "Osmumten", size: 1 },
    11691: { name: "Spirit", size: 1 },
    11692: { name: "Osmumten", size: 1 },
    11693: { name: "Osmumten", size: 1 },
    11694: { name: "Helpful Spirit", size: 1 },
    11695: { name: "Ghost", size: 1 },
    11696: { name: "Amascut", size: 1 },
    11697: { name: "Scarab", size: 1 },
    11698: { name: "<col=00ffff>Obelisk</col>", size: 1 },
    11699: { name: "<col=00ffff>Obelisk</col>", size: 1 },
    11700: { name: "<col=00ffff>Palm of Resourcefulness</col>", size: 5 },
    11701: { name: "<col=00ffff>Palm of Resourcefulness</col>", size: 5 },
    11702: { name: "<col=00ffff>Palm of Resourcefulness</col>", size: 5 },
    11703: { name: "<col=00ffff>Palm of Resourcefulness</col>", size: 5 },
    11704: { name: "<col=00ffff>Palm of Resourcefulness</col>", size: 5 },
    11705: { name: "Crocodile", size: 2 },
    11706: { name: "<col=00ffff>Het's Seal (protected)</col>", size: 3 },
    11707: { name: "<col=00ffff>Het's Seal (weakened)</col>", size: 3 },
    11708: { name: "<col=00ffff>Orb of Darkness</col>", size: 1 },
    11709: { name: "Baboon Brawler", size: 1 },
    11710: { name: "Baboon Thrower", size: 1 },
    11711: { name: "Baboon Mage", size: 1 },
    11712: { name: "Baboon Brawler", size: 1 },
    11713: { name: "Baboon Thrower", size: 1 },
    11714: { name: "Baboon Mage", size: 1 },
    11715: { name: "Baboon Shaman", size: 1 },
    11716: { name: "Volatile Baboon", size: 1 },
    11717: { name: "Cursed Baboon", size: 1 },
    11718: { name: "Baboon Thrall", size: 1 },
    11719: { name: "Kephri", size: 5 },
    11720: { name: "Kephri", size: 5 },
    11721: { name: "Kephri", size: 5 },
    11722: { name: "Kephri", size: 5 },
    11723: { name: "Scarab Swarm", size: 1 },
    11724: { name: "Soldier Scarab", size: 3 },
    11725: { name: "Spitting Scarab", size: 3 },
    11726: { name: "Arcane Scarab", size: 3 },
    11727: { name: "Agile Scarab", size: 1 },
    11728: { name: "<col=00ffff>Egg</col>", size: 1 },
    11729: { name: "<col=00ffff>Egg</col>", size: 1 },
    11730: { name: "Zebak", size: 9 },
    11731: { name: "Zebak's Tail", size: 9 },
    11732: { name: "Zebak", size: 9 },
    11733: { name: "Zebak", size: 9 },
    11734: { name: "Zebak's Tail", size: 9 },
    11735: { name: "<col=00ffff>Jug</col>", size: 1 },
    11736: { name: "<col=00ffff>Jug</col>", size: 1 },
    11737: { name: "<col=00ffff>Boulder</col>", size: 1 },
    11738: { name: "<col=00ffff>Wave</col>", size: 1 },
    11739: { name: "<col=00ffff>Bloody Wave</col>", size: 1 },
    11740: { name: "Crocodile", size: 2 },
    11741: { name: "Crocodile", size: 1 },
    11742: { name: "Blood Cloud", size: 2 },
    11743: { name: "Blood Cloud", size: 1 },
    11744: { name: "null", size: 7 },
    11745: { name: "null", size: 3 },
    11746: { name: "Elidinis' Warden", size: 5 },
    11747: { name: "Tumeken's Warden", size: 5 },
    11748: { name: "Elidinis' Warden", size: 5 },
    11749: { name: "Tumeken's Warden", size: 5 },
    11750: { name: "<col=00ffff>Obelisk</col>", size: 3 },
    11751: { name: "<col=00ffff>Obelisk</col>", size: 3 },
    11752: { name: "<col=00ffff>Obelisk</col>", size: 3 },
    11753: { name: "Elidinis' Warden", size: 5 },
    11754: { name: "Elidinis' Warden", size: 5 },
    11755: { name: "Elidinis' Warden", size: 5 },
    11756: { name: "Tumeken's Warden", size: 5 },
    11757: { name: "Tumeken's Warden", size: 5 },
    11758: { name: "Tumeken's Warden", size: 5 },
    11759: { name: "Elidinis' Warden", size: 5 },
    11760: { name: "Tumeken's Warden", size: 5 },
    11761: { name: "Elidinis' Warden", size: 5 },
    11762: { name: "Tumeken's Warden", size: 5 },
    11763: { name: "Elidinis' Warden", size: 5 },
    11764: { name: "Tumeken's Warden", size: 5 },
    11765: { name: "null", size: 5 },
    11766: { name: "null", size: 5 },
    11767: { name: "<col=00ffff>Phantom</col>", size: 8 },
    11768: { name: "<col=00ffff>Phantom</col>", size: 8 },
    11769: { name: "null", size: 1 },
    11770: { name: "<col=00ffff>Core</col>", size: 1 },
    11771: { name: "<col=00ffff>Core</col>", size: 1 },
    11772: { name: "<col=00ffff>Energy Siphon</col>", size: 1 },
    11773: { name: "null", size: 1 },
    11774: { name: "Zebak's Phantom", size: 5 },
    11775: { name: "Ba-Ba's Phantom", size: 5 },
    11776: { name: "Kephri's Phantom", size: 5 },
    11777: { name: "Akkha's Phantom", size: 5 },
    11778: { name: "Ba-Ba", size: 5 },
    11779: { name: "Ba-Ba", size: 5 },
    11780: { name: "Ba-Ba", size: 5 },
    11781: { name: "Baboon", size: 1 },
    11782: { name: "<col=00ffff>Boulder</col>", size: 3 },
    11783: { name: "<col=00ffff>Boulder</col>", size: 3 },
    11784: { name: "<col=00ffff>Rubble</col>", size: 3 },
    11785: { name: "<col=00ffff>Rubble</col>", size: 3 },
    11786: { name: "<col=00ffff>Rubble</col>", size: 3 },
    11787: { name: "<col=00ffff>Rubble</col>", size: 3 },
    11788: { name: "null", size: 1 },
    11789: { name: "Akkha", size: 3 },
    11790: { name: "Akkha", size: 3 },
    11791: { name: "Akkha", size: 3 },
    11792: { name: "Akkha", size: 3 },
    11793: { name: "Akkha", size: 3 },
    11794: { name: "Akkha", size: 3 },
    11795: { name: "Akkha", size: 3 },
    11796: { name: "Akkha", size: 3 },
    11797: { name: "Akkha's Shadow", size: 3 },
    11798: { name: "Akkha's Shadow", size: 3 },
    11799: { name: "Akkha's Shadow", size: 3 },
    11800: { name: "<col=00ffff>Orb of Lightning</col>", size: 1 },
    11801: { name: "<col=00ffff>Orb of Darkness</col>", size: 1 },
    11802: { name: "<col=00ffff>Burning Orb</col>", size: 1 },
    11803: { name: "<col=00ffff>Frozen Orb</col>", size: 1 },
    11804: { name: "<col=00ffff>Unstable Orb</col>", size: 1 },
    11805: { name: "null", size: 2 },
    11806: { name: "Bank Camel", size: 2 },
    11807: { name: "Maisa", size: 1 },
    11808: { name: "Maisa", size: 1 },
    11809: { name: "Apmeken", size: 1 },
    11810: { name: "Crondis", size: 1 },
    11811: { name: "Het", size: 1 },
    11812: { name: "Tumeken's Guardian", size: 1 },
    11813: { name: "Elidinis' Guardian", size: 1 },
    11814: { name: "Messenger", size: 1 },
    11815: { name: "Messenger", size: 1 },
    11816: { name: "Messenger", size: 1 },
    11817: { name: "Messenger", size: 1 },
    11818: { name: "null", size: 1 },
    11819: { name: "null", size: 1 },
    11820: { name: "null", size: 3 },
    11821: { name: "null", size: 1 },
    11822: { name: "null", size: 1 },
    11823: { name: "null", size: 1 },
    11824: { name: "null", size: 1 },
    11825: { name: "null", size: 1 },
    11826: { name: "null", size: 2 },
    11827: { name: "null", size: 1 },
    11828: { name: "null", size: 1 },
    11829: { name: "null", size: 1 },
    11830: { name: "null", size: 1 },
    11831: { name: "null", size: 1 },
    11832: { name: "null", size: 1 },
    11833: { name: "null", size: 1 },
    11834: { name: "null", size: 1 },
    11835: { name: "null", size: 1 },
    11836: { name: "null", size: 1 },
    11837: { name: "null", size: 1 },
    11838: { name: "null", size: 1 },
    11839: { name: "null", size: 1 },
    11840: { name: "Akkhito", size: 1 },
    11841: { name: "Babi", size: 1 },
    11842: { name: "Kephriti", size: 1 },
    11843: { name: "Zebo", size: 2 },
    11844: { name: "Tumeken's Damaged Guardian", size: 1 },
    11845: { name: "Elidinis' Damaged Guardian", size: 1 },
    11846: { name: "Akkhito", size: 1 },
    11847: { name: "Babi", size: 1 },
    11848: { name: "Kephriti", size: 1 },
    11849: { name: "Zebo", size: 2 },
    11850: { name: "Tumeken's Damaged Guardian", size: 1 },
    11851: { name: "Elidinis' Damaged Guardian", size: 1 },
    11852: { name: "Eliza", size: 1 },
    11853: { name: "Eliza", size: 1 },
    11854: { name: "null", size: 1 },
    11855: { name: "Costumed child", size: 1 },
    11856: { name: "Costumed child", size: 1 },
    11857: { name: "Costumed child", size: 1 },
    11858: { name: "null", size: 1 },
    11859: { name: "null", size: 1 },
    11860: { name: "Ghost", size: 1 },
    11861: { name: "null", size: 1 },
    11862: { name: "Ghost", size: 1 },
    11863: { name: "Generous Treater", size: 1 },
    11864: { name: "Generous Treater", size: 1 },
    11865: { name: "Generous Treater", size: 1 },
    11866: { name: "Generous Treater", size: 1 },
    11867: { name: "Spooky dog", size: 1 },
    11868: { name: "Aris", size: 1 },
    11869: { name: "null", size: 1 },
    11870: { name: "Rana the Dyer", size: 1 },
    11871: { name: "null", size: 1 },
    11872: { name: "Jalal the Drunk", size: 1 },
    11873: { name: "null", size: 1 },
    11874: { name: "Faisal the Barman", size: 1 },
    11875: { name: "null", size: 1 },
    11876: { name: "Isma'il The Kebab Seller", size: 1 },
    11877: { name: "null", size: 1 },
    11878: { name: "Sami the Camel Man", size: 1 },
    11879: { name: "Hakeem the Mayor", size: 1 },
    11880: { name: "null", size: 1 },
    11881: { name: "Alisha the Hag", size: 1 },
    11882: { name: "null", size: 1 },
    11883: { name: "Badir the Snake Charmer", size: 1 },
    11884: { name: "null", size: 2 },
    11885: { name: "null", size: 2 },
    11886: { name: "Jasim the Camel", size: 2 },
    11887: { name: "Ibil the Camel", size: 2 },
    11888: { name: "null", size: 1 },
    11889: { name: "Rashid the Operator", size: 1 },
    11890: { name: "Aris", size: 1 },
    11891: { name: "Aris", size: 1 },
    11892: { name: "White Knight", size: 1 },
    11893: { name: "Paladin", size: 1 },
    11894: { name: "Paladin", size: 1 },
    11895: { name: "Soldier", size: 1 },
    11896: { name: "Soldier", size: 1 },
    11897: { name: "Soldier", size: 1 },
    11898: { name: "Soldier", size: 1 },
    11899: { name: "Soldier", size: 1 },
    11900: { name: "Soldier", size: 1 },
    11901: { name: "Paladin", size: 1 },
    11902: { name: "Knight of Ardougne", size: 1 },
    11903: { name: "Guard", size: 1 },
    11904: { name: "Guard", size: 1 },
    11905: { name: "Guard", size: 1 },
    11906: { name: "Captain", size: 1 },
    11907: { name: "Trainee Guard", size: 1 },
    11908: { name: "Trainee Guard", size: 1 },
    11909: { name: "Trainee Guard", size: 1 },
    11910: { name: "Trainee Guard", size: 1 },
    11911: { name: "Guard", size: 1 },
    11912: { name: "Guard", size: 1 },
    11913: { name: "Guard", size: 1 },
    11914: { name: "Guard", size: 1 },
    11915: { name: "Guard", size: 1 },
    11916: { name: "Guard", size: 1 },
    11917: { name: "Guard", size: 1 },
    11918: { name: "Farmer", size: 1 },
    11919: { name: "Farmer", size: 1 },
    11920: { name: "Farmer", size: 1 },
    11921: { name: "Farmer", size: 1 },
    11922: { name: "Guard", size: 1 },
    11923: { name: "Guard", size: 1 },
    11924: { name: "Guard", size: 1 },
    11925: { name: "Warrior", size: 1 },
    11926: { name: "Warrior", size: 1 },
    11927: { name: "Warrior", size: 1 },
    11928: { name: "Warrior", size: 1 },
    11929: { name: "Warrior", size: 1 },
    11930: { name: "Paladin", size: 1 },
    11931: { name: "Paladin", size: 1 },
    11932: { name: "Paladin", size: 1 },
    11933: { name: "Paladin", size: 1 },
    11934: { name: "Hero", size: 1 },
    11935: { name: "Hero", size: 1 },
    11936: { name: "Knight of Ardougne", size: 1 },
    11937: { name: "Guard", size: 1 },
    11938: { name: "Guard", size: 1 },
    11939: { name: "Guard", size: 1 },
    11940: { name: "Master Farmer", size: 1 },
    11941: { name: "Master Farmer", size: 1 },
    11942: { name: "Guard", size: 1 },
    11943: { name: "Guard", size: 1 },
    11944: { name: "Guard", size: 1 },
    11945: { name: "Guard", size: 1 },
    11946: { name: "Guard", size: 1 },
    11947: { name: "Guard", size: 1 },
    11948: { name: "White Knight", size: 1 },
    11949: { name: "White Knight", size: 1 },
    11950: { name: "White Knight", size: 1 },
    11951: { name: "White Knight", size: 1 },
    11952: { name: "Black Knight", size: 1 },
    11953: { name: "Black Knight", size: 1 },
    11954: { name: "Tribesman", size: 1 },
    11955: { name: "Tribeswoman", size: 1 },
    11956: { name: "Tribeswoman", size: 1 },
    11957: { name: "Treznor", size: 1 },
    11958: { name: "The Maiden of Sugadinti", size: 5 },
    11959: { name: "Vorkath", size: 5 },
    11960: { name: "Galvek", size: 5 },
    11961: { name: "Abyssal Sire", size: 5 },
    11962: { name: "Alchemical Hydra", size: 5 },
    11963: { name: "null", size: 2 },
    11964: { name: "null", size: 2 },
    11965: { name: "null", size: 1 },
    11966: { name: "null", size: 1 },
    11967: { name: "null", size: 1 },
    11968: { name: "null", size: 1 },
    11969: { name: "null", size: 1 },
    11970: { name: "null", size: 1 },
    11971: { name: "null", size: 1 },
    11972: { name: "null", size: 1 },
    11973: { name: "null", size: 1 },
    11974: { name: "null", size: 1 },
    11975: { name: "null", size: 1 },
    11976: { name: "null", size: 1 },
    11977: { name: "null", size: 1 },
    11978: { name: "null", size: 1 },
    11979: { name: "null", size: 1 },
    11980: { name: "null", size: 1 },
    11981: { name: "Venenatis spiderling", size: 1 },
    11982: { name: "Callisto cub", size: 1 },
    11983: { name: "Vet'ion Jr.", size: 1 },
    11984: { name: "Vet'ion Jr.", size: 1 },
    11985: { name: "Venenatis spiderling", size: 1 },
    11986: { name: "Callisto cub", size: 1 },
    11987: { name: "Vet'ion Jr.", size: 1 },
    11988: { name: "Vet'ion Jr.", size: 1 },
    11989: { name: "Grizzly bear", size: 2 },
    11990: { name: "Poison spider", size: 1 },
    11991: { name: "Madam Sikaro", size: 1 },
    11992: { name: "Artio", size: 3 },
    11993: { name: "Calvar'ion", size: 3 },
    11994: { name: "Calvar'ion", size: 3 },
    11995: { name: "Calvar'ion", size: 3 },
    11996: { name: "null", size: 3 },
    11998: { name: "Spindel", size: 4 },
    11999: { name: "Poison spider", size: 1 },
    12000: { name: "Venenatis' Spiderling", size: 1 },
    12001: { name: "Spindel's Spiderling", size: 1 },
    12002: { name: "Vet'ion", size: 3 },
    12003: { name: "Desert Lizard", size: 2 },
    12004: { name: "Small Lizard", size: 1 },
    12005: { name: "Muphin", size: 1 },
    12006: { name: "Muphin", size: 1 },
    12007: { name: "Muphin", size: 1 },
    12008: { name: "Crow", size: 1 },
    12009: { name: "Crow", size: 1 },
    12010: { name: "Crow", size: 1 },
    12011: { name: "Crow", size: 1 },
    12012: { name: "Crow", size: 1 },
    12013: { name: "Crow", size: 1 },
    12014: { name: "Muphin", size: 1 },
    12015: { name: "Muphin", size: 1 },
    12016: { name: "Muphin", size: 1 },
    12017: { name: "Door Guard", size: 1 },
    12018: { name: "Door Guard", size: 1 },
    12019: { name: "Khazard Guard", size: 1 },
    12020: { name: "Khazard Guard", size: 1 },
    12021: { name: "Khazard Guard", size: 1 },
    12022: { name: "Khazard Guard", size: 1 },
    12023: { name: "Khazard Guard", size: 1 },
    12024: { name: "Khazard Guard", size: 1 },
    12025: { name: "General Khazard", size: 2 },
    12026: { name: "General Khazard", size: 2 },
    12027: { name: "General Khazard", size: 2 },
    12028: { name: "Slave", size: 1 },
    12029: { name: "Lady Servil", size: 1 },
    12030: { name: "Sammy Servil", size: 1 },
    12031: { name: "Sammy Servil", size: 1 },
    12032: { name: "Sammy Servil", size: 1 },
    12033: { name: "null", size: 1 },
    12034: { name: "Justin Servil", size: 1 },
    12035: { name: "Justin Servil", size: 1 },
    12036: { name: "null", size: 2 },
    12037: { name: "Bouncer", size: 2 },
    12038: { name: "Bouncer", size: 2 },
    12039: { name: "null", size: 2 },
    12040: { name: "Khazard Ogre", size: 2 },
    12041: { name: "Khazard Ogre", size: 2 },
    12042: { name: "null", size: 3 },
    12043: { name: "Khazard Scorpion", size: 3 },
    12044: { name: "Khazard Scorpion", size: 3 },
    12045: { name: "Guard", size: 1 },
    12046: { name: "Evelot", size: 1 },
    12047: { name: "Evelot", size: 1 },
    12048: { name: "Hazeel Cultist", size: 1 },
    12049: { name: "Clivet", size: 1 },
    12050: { name: "Hazeel", size: 2 },
    12051: { name: "Hazeel", size: 2 },
    12052: { name: "Hazeel", size: 2 },
    12053: { name: "Big Fish", size: 2 },
    12054: { name: "General Khazard", size: 2 },
    12055: { name: "General Khazard", size: 2 },
    12056: { name: "General Khazard", size: 2 },
    12057: { name: "General Khazard", size: 2 },
    12058: { name: "General Khazard", size: 2 },
    12059: { name: "General Khazard", size: 2 },
    12060: { name: "Dead Wolf", size: 2 },
    12061: { name: "Assassin", size: 1 },
    12062: { name: "Assassin", size: 1 },
    12063: { name: "Strange Creature", size: 5 },
    12064: { name: "Jhallan", size: 3 },
    12065: { name: "Jhallan", size: 3 },
    12066: { name: "Khazard Soldier", size: 1 },
    12067: { name: "Khazard Soldier", size: 1 },
    12068: { name: "Khazard Soldier", size: 1 },
    12069: { name: "Khazard Soldier", size: 1 },
    12070: { name: "Captain Malleta", size: 1 },
    12071: { name: "Sergeant Raymond", size: 1 },
    12072: { name: "Scout", size: 1 },
    12073: { name: "Strange Creature", size: 5 },
    12074: { name: "Strange Creature", size: 5 },
    12075: { name: "Strange Creature", size: 5 },
    12076: { name: "Strange Creature", size: 5 },
    12077: { name: "Phantom Muspah", size: 5 },
    12078: { name: "Phantom Muspah", size: 5 },
    12079: { name: "Phantom Muspah", size: 5 },
    12080: { name: "Phantom Muspah", size: 5 },
    12081: { name: "Strange Creature", size: 5 },
    12082: { name: "Phantom Muspah", size: 5 },
    12083: { name: "null", size: 1 },
    12084: { name: "Snake", size: 1 },
    12085: { name: "Ceril Carnillean", size: 1 },
    12086: { name: "Ceril Carnillean", size: 1 },
    12087: { name: "Guard", size: 1 },
    12088: { name: "Guard", size: 1 },
    12089: { name: "Butler Jones", size: 1 },
    12090: { name: "Jones", size: 1 },
    12091: { name: "Butler Jones", size: 1 },
    12092: { name: "Alomone", size: 1 },
    12093: { name: "Alomone", size: 1 },
    12094: { name: "Alomone", size: 1 },
    12095: { name: "Clivet", size: 1 },
    12096: { name: "Hazeel Cultist", size: 1 },
    12097: { name: "Hazeel Cultist", size: 1 },
    12098: { name: "Hazeel Cultist", size: 1 },
    12099: { name: "Scruffy", size: 1 },
    12100: { name: "Ceril Carnillean", size: 1 },
    12101: { name: "Henryeta Carnillean", size: 1 },
    12102: { name: "Ali the Tea Seller", size: 1 },
    12103: { name: "Morgiana", size: 1 },
    12104: { name: "Cassim", size: 1 },
    12105: { name: "null", size: 1 },
    12106: { name: "null", size: 3 },
    12107: { name: "Skeleton Hellhound", size: 2 },
    12108: { name: "Greater Skeleton Hellhound", size: 2 },
    12109: { name: "Tool Leprechaun", size: 1 },
    12110: { name: "Hallowed Sepulchre Coffin", size: 1 },
    12111: { name: "Hallowed Sepulchre Grand Coffin", size: 1 },
    12112: { name: "Ent trunk", size: 1 },
    12113: { name: "Emblem Trader", size: 2 },
    12114: { name: "Corrupted Warrior", size: 1 },
    12115: { name: "Corrupted Warrior", size: 1 },
    12116: { name: "Emma", size: 1 },
    12117: { name: "Suzie", size: 1 },
    12118: { name: "Corrupted Warrior", size: 1 },
    12119: { name: "null", size: 1 },
    12120: { name: "<col=00ffff>Flowering bush (Lilac)</col>", size: 1 },
    12121: { name: "Sir Pear Visor", size: 1 },
    12122: { name: "Sir Pear Visor", size: 1 },
    12123: { name: "Crystalline Hunllef", size: 5 },
    12124: { name: "Zalcano", size: 5 },
    12125: { name: "Kephri", size: 5 },
    12126: { name: "Akkha", size: 3 },
    12127: { name: "Ba-Ba", size: 5 },
    12128: { name: "Zebak", size: 9 },
    12129: { name: "Zebak's Tail", size: 9 },
    12130: { name: "Tumeken's Warden", size: 5 },
    12131: { name: "Elidinis' Warden", size: 5 },
    12132: { name: "<col=00ffff>Obelisk</col>", size: 3 },
    12133: { name: "<col=00ffff>Strange bush (Lilac)</col>", size: 1 },
    12134: { name: "<col=00ffff>Flowering bush (Pink)</col>", size: 1 },
    12135: { name: "<col=00ffff>Strange bush (Pink)</col>", size: 1 },
    12136: { name: "null", size: 1 },
    12137: { name: "Dallas Jones", size: 1 },
    12138: { name: "Bob", size: 1 },
    12139: { name: "Zorgoth", size: 2 },
    12140: { name: "<col=00ffff>Shadow</col>", size: 3 },
    12141: { name: "<col=00ffff>Flowering bush (Red)</col>", size: 1 },
    12142: { name: "<col=00ffff>Strange bush (Red)</col>", size: 1 },
    12143: { name: "<col=00ffff>Flowering bush (Orange)</col>", size: 1 },
    12144: { name: "<col=00ffff>Strange bush (Orange)</col>", size: 1 },
    12145: { name: "<col=00ffff>Flowering bush (Yellow)</col>", size: 1 },
    12146: { name: "<col=00ffff>Strange bush (Yellow)</col>", size: 1 },
    12147: { name: "<col=00ffff>Flowering bush (White)</col>", size: 1 },
    12148: { name: "<col=00ffff>Strange bush (White)</col>", size: 1 },
    12149: { name: "<col=00ffff>Flowering bush (Green)</col>", size: 1 },
    12150: { name: "<col=00ffff>Strange bush (Green)</col>", size: 1 },
    12151: { name: "<col=00ffff>Flowering bush (Blue)</col>", size: 1 },
    12152: { name: "<col=00ffff>Strange bush (Blue)</col>", size: 1 },
    12153: { name: "Wisp", size: 1 },
    12154: { name: "Butch", size: 1 },
    12155: { name: "Baron", size: 1 },
    12156: { name: "Lil'viathan", size: 1 },
    12157: { name: "Wisp", size: 1 },
    12158: { name: "Butch", size: 1 },
    12159: { name: "Baron", size: 1 },
    12160: { name: "Lil'viathan", size: 1 },
    12161: { name: "Mysterious Voice", size: 1 },
    12162: { name: "Asgarnia Smith", size: 1 },
    12163: { name: "Woodcutting Leprechaun", size: 1 },
    12164: { name: "null", size: 1 },
    12165: { name: "null", size: 2 },
    12166: { name: "Duke Sucellus", size: 7 },
    12167: { name: "Duke Sucellus", size: 7 },
    12168: { name: "Friendly Bees", size: 1 },
    12169: { name: "Beaver", size: 1 },
    12170: { name: "Beaver", size: 1 },
    12171: { name: "Beaver", size: 1 },
    12172: { name: "Beaver", size: 1 },
    12173: { name: "Beaver", size: 1 },
    12174: { name: "Beaver", size: 1 },
    12175: { name: "Beaver", size: 1 },
    12176: { name: "Beaver", size: 1 },
    12177: { name: "Beaver", size: 1 },
    12178: { name: "Beaver", size: 1 },
    12179: { name: "Apprentice Cordelia", size: 1 },
    12180: { name: "Apprentice Cordelia", size: 1 },
    12181: { name: "Beaver", size: 1 },
    12182: { name: "Beaver", size: 1 },
    12183: { name: "Beaver", size: 1 },
    12184: { name: "Beaver", size: 1 },
    12185: { name: "Beaver", size: 1 },
    12186: { name: "Beaver", size: 1 },
    12187: { name: "Beaver", size: 1 },
    12188: { name: "Beaver", size: 1 },
    12189: { name: "Beaver", size: 1 },
    12190: { name: "Beaver", size: 1 },
    12191: { name: "Duke Sucellus", size: 7 },
    12192: { name: "Duke Sucellus", size: 7 },
    12193: { name: "Duke Sucellus", size: 7 },
    12194: { name: "Duke Sucellus", size: 7 },
    12195: { name: "Duke Sucellus", size: 7 },
    12196: { name: "Duke Sucellus", size: 7 },
    12197: { name: "null", size: 2 },
    12198: { name: "null", size: 3 },
    12199: { name: "null", size: 5 },
    12200: { name: "null", size: 5 },
    12201: { name: "null", size: 5 },
    12202: { name: "null", size: 7 },
    12203: { name: "Odd Figure", size: 3 },
    12204: { name: "The Whisperer", size: 3 },
    12205: { name: "The Whisperer", size: 3 },
    12206: { name: "The Whisperer", size: 3 },
    12207: { name: "The Whisperer", size: 3 },
    12208: { name: "<col=00ffff>Tentacle</col>", size: 3 },
    12209: { name: "<col=00ffff>Floating Column</col>", size: 2 },
    12210: { name: "<col=00ffff>Floating Column</col>", size: 2 },
    12211: { name: "Lost Soul", size: 1 },
    12212: { name: "Lost Soul", size: 1 },
    12213: { name: "Odd Figure", size: 3 },
    12214: { name: "The Leviathan", size: 7 },
    12215: { name: "The Leviathan", size: 7 },
    12216: { name: "Tail", size: 7 },
    12217: { name: "Tail", size: 7 },
    12218: { name: "Tail", size: 7 },
    12219: { name: "The Leviathan", size: 7 },
    12220: { name: "Abyssal pathfinder", size: 3 },
    12221: { name: "The Leviathan?", size: 7 },
    12222: { name: "null", size: 1 },
    12223: { name: "Vardorvis", size: 2 },
    12224: { name: "Vardorvis", size: 2 },
    12225: { name: "Large Tendril", size: 3 },
    12226: { name: "Vardorvis' Head", size: 1 },
    12227: { name: "null", size: 3 },
    12228: { name: "Vardorvis", size: 3 },
    12229: { name: "null", size: 3 },
    12230: { name: "Helena", size: 1 },
    12231: { name: "Prescott", size: 1 },
    12232: { name: "Ketla", size: 1 },
    12233: { name: "Ketla", size: 1 },
    12234: { name: "Lost Soul", size: 1 },
    12235: { name: "Me?", size: 1 },
    12236: { name: "Me?", size: 1 },
    12237: { name: "Mysterious Voice", size: 1 },
    12238: { name: "Mysterious Voice", size: 1 },
    12239: { name: "Mysterious Voice", size: 1 },
    12240: { name: "Mysterious Voice", size: 1 },
    12241: { name: "Mysterious Voice", size: 1 },
    12242: { name: "Mysterious Voice", size: 1 },
    12243: { name: "Mysterious Voice", size: 1 },
    12244: { name: "Mysterious Voice", size: 1 },
    12245: { name: "Mysterious Voice", size: 1 },
    12246: { name: "Mysterious Voice", size: 1 },
    12247: { name: "Mysterious Voice", size: 1 },
    12248: { name: "Shadow Keeper", size: 2 },
    12249: { name: "Crawling Hand", size: 1 },
    12250: { name: "null", size: 1 },
    12251: { name: "null", size: 1 },
    12252: { name: "null", size: 1 },
    12253: { name: "null", size: 1 },
    12254: { name: "Ghost", size: 1 },
    12255: { name: "Barus", size: 1 },
    12256: { name: "Kasonde", size: 1 },
    12257: { name: "Kasonde", size: 1 },
    12258: { name: "Kasonde", size: 1 },
    12259: { name: "Kasonde", size: 1 },
    12260: { name: "Kasonde", size: 1 },
    12261: { name: "Kasonde", size: 3 },
    12262: { name: "Kasonde", size: 1 },
    12263: { name: "Kasonde", size: 1 },
    12264: { name: "Kasonde", size: 1 },
    12265: { name: "null", size: 1 },
    12266: { name: "null", size: 1 },
    12267: { name: "Fishing spot", size: 1 },
    12268: { name: "Strangled", size: 1 },
    12269: { name: "Strangled", size: 1 },
    12270: { name: "Strangled", size: 1 },
    12271: { name: "Strangled", size: 1 },
    12272: { name: "Strangled", size: 1 },
    12273: { name: "Strangled", size: 1 },
    12274: { name: "Strangled", size: 1 },
    12275: { name: "Strangled", size: 1 },
    12276: { name: "Strangled", size: 1 },
    12277: { name: "Strangled", size: 1 },
    12278: { name: "Strangled", size: 1 },
    12279: { name: "Strangled", size: 1 },
    12280: { name: "Strangled", size: 1 },
    12281: { name: "Strangled", size: 1 },
    12282: { name: "Strangled", size: 1 },
    12283: { name: "Strangled", size: 1 },
    12284: { name: "Strangled", size: 1 },
    12285: { name: "Strangled", size: 1 },
    12286: { name: "Strangled", size: 1 },
    12287: { name: "Strangled", size: 1 },
    12288: { name: "Strangled Bear", size: 3 },
    12289: { name: "Strangled Boar", size: 1 },
    12290: { name: "Strangled Lynx", size: 2 },
    12291: { name: "Asgarnia Smith", size: 1 },
    12292: { name: "Asgarnia Smith", size: 1 },
    12293: { name: "Dr Banikan", size: 1 },
    12294: { name: "Dr Banikan", size: 1 },
    12295: { name: "Mysterious Voice", size: 1 },
    12296: { name: "null", size: 1 },
    12297: { name: "Mysterious Figure", size: 1 },
    12298: { name: "Mysterious Figure", size: 1 },
    12299: { name: "Sandwich lady", size: 1 },
    12300: { name: "Mysterious Figure", size: 1 },
    12301: { name: "Mysterious Figure", size: 1 },
    12302: { name: "Hazeel", size: 2 },
    12303: { name: "Hazeel", size: 2 },
    12304: { name: "Hazeel", size: 2 },
    12305: { name: "Azzanadra", size: 2 },
    12306: { name: "Azzanadra", size: 2 },
    12307: { name: "Azzanadra", size: 3 },
    12308: { name: "Azzanadra", size: 2 },
    12309: { name: "Enakhra", size: 2 },
    12310: { name: "Enakhra", size: 2 },
    12311: { name: "Akthanakos", size: 2 },
    12312: { name: "Akthanakos", size: 2 },
    12313: { name: "Akthanakos", size: 2 },
    12314: { name: "Sliske", size: 3 },
    12315: { name: "Sliske", size: 3 },
    12316: { name: "Ahrim the Blighted", size: 1 },
    12317: { name: "Dharok the Wretched", size: 1 },
    12318: { name: "Guthan the Infested", size: 1 },
    12319: { name: "Karil the Tainted", size: 1 },
    12320: { name: "Torag the Corrupted", size: 1 },
    12321: { name: "Verac the Defiled", size: 1 },
    12322: { name: "Ahrim the Blighted", size: 1 },
    12323: { name: "Dharok the Wretched", size: 1 },
    12324: { name: "Guthan the Infested", size: 1 },
    12325: { name: "Karil the Tainted", size: 1 },
    12326: { name: "Torag the Corrupted", size: 1 },
    12327: { name: "Verac the Defiled", size: 1 },
    12328: { name: "The Forsaken Assassin", size: 1 },
    12329: { name: "Ketla the Unworthy", size: 1 },
    12330: { name: "Ketla the Unworthy", size: 1 },
    12331: { name: "Kasonde the Craven", size: 1 },
    12332: { name: "Kasonde the Craven", size: 1 },
    12333: { name: "Persten the Deceitful", size: 1 },
    12334: { name: "<col=00ffff>Portal</col>", size: 1 },
    12335: { name: "Wighted Leech", size: 1 },
    12336: { name: "Ancient Guardian", size: 3 },
    12337: { name: "Ancient Guardian", size: 3 },
    12338: { name: "Golem", size: 1 },
    12339: { name: "<col=00ffff>Smoke Totem</col>", size: 1 },
    12340: { name: "<col=00ffff>Smoke Totem</col>", size: 1 },
    12341: { name: "<col=00ffff>Shadow Totem</col>", size: 1 },
    12342: { name: "<col=00ffff>Shadow Totem</col>", size: 1 },
    12343: { name: "<col=00ffff>Blood Totem</col>", size: 1 },
    12344: { name: "<col=00ffff>Blood Totem</col>", size: 1 },
    12345: { name: "<col=00ffff>Ice Totem</col>", size: 1 },
    12346: { name: "<col=00ffff>Ice Totem</col>", size: 1 },
    12347: { name: "Mysterious Bandit", size: 1 },
    12348: { name: "Assassin", size: 1 },
    12349: { name: "Assassin", size: 1 },
    12350: { name: "Assassin", size: 1 },
    12351: { name: "Assassin", size: 3 },
    12352: { name: "Mysterious Voice", size: 1 },
    12353: { name: "Jhallan", size: 2 },
    12354: { name: "Jhallan", size: 2 },
    12355: { name: "Jhallan", size: 2 },
    12356: { name: "Cerebral pathfinder", size: 1 },
    12357: { name: "Cerebral pathbreaker", size: 1 },
    12358: { name: "null", size: 1 },
    12359: { name: "<col=00ffff>Catalyst</col>", size: 3 },
    12360: { name: "Scarred lesser demon", size: 2 },
    12361: { name: "Lesser demon", size: 2 },
    12362: { name: "Scarred lesser demon", size: 2 },
    12363: { name: "Lesser demon", size: 2 },
    12364: { name: "Scarred lesser demon", size: 2 },
    12365: { name: "Lesser demon", size: 2 },
    12366: { name: "Abyssal leech", size: 1 },
    12367: { name: "Light leech", size: 1 },
    12368: { name: "Light leech", size: 1 },
    12369: { name: "Crimson sanguisphera", size: 1 },
    12370: { name: "Radiant sanguisphera", size: 1 },
    12371: { name: "<col=00ffff>Brain Pillar</col>", size: 4 },
    12372: { name: "Abyssal antibody", size: 1 },
    12373: { name: "Scarred greater demon", size: 3 },
    12374: { name: "Scarred hellhound", size: 2 },
    12375: { name: "Abyssal axon", size: 1 },
    12376: { name: "Lesser demon", size: 1 },
    12377: { name: "Scarred imp", size: 1 },
    12378: { name: "Scarred lesser demon", size: 2 },
    12379: { name: "Scarred hellhound", size: 2 },
    12380: { name: "Wizard Persten", size: 1 },
    12381: { name: "Wizard Persten", size: 1 },
    12382: { name: "Wizard Persten", size: 1 },
    12383: { name: "Catalytic Guardian", size: 2 },
    12384: { name: "Catalytic Guardian", size: 2 },
    12385: { name: "Black demon", size: 3 },
    12386: { name: "Scarred black demon", size: 3 },
    12387: { name: "Greater demon", size: 3 },
    12388: { name: "Scarred greater demon", size: 3 },
    12389: { name: "Lesser demon", size: 2 },
    12390: { name: "Scarred lesser demon", size: 2 },
    12391: { name: "Abyssal leech", size: 1 },
    12392: { name: "Abyssal walker", size: 1 },
    12393: { name: "Abyssal leech", size: 1 },
    12394: { name: "Abyssal walker", size: 1 },
    12395: { name: "Enakhra", size: 2 },
    12396: { name: "null", size: 2 },
    12397: { name: "Akthanakos", size: 2 },
    12398: { name: "Akthanakos", size: 2 },
    12399: { name: "null", size: 1 },
    12400: { name: "null", size: 1 },
    12401: { name: "null", size: 1 },
    12402: { name: "null", size: 1 },
    12403: { name: "null", size: 1 },
    12404: { name: "null", size: 1 },
    12405: { name: "null", size: 1 },
    12406: { name: "null", size: 2 },
    12407: { name: "null", size: 2 },
    12408: { name: "null", size: 1 },
    12409: { name: "null", size: 1 },
    12410: { name: "null", size: 1 },
    12411: { name: "null", size: 1 },
    12412: { name: "null", size: 1 },
    12413: { name: "null", size: 1 },
    12414: { name: "null", size: 1 },
    12415: { name: "null", size: 1 },
    12416: { name: "null", size: 1 },
    12417: { name: "null", size: 2 },
    12418: { name: "null", size: 1 },
    12419: { name: "null", size: 3 },
    12420: { name: "null", size: 3 },
    12421: { name: "null", size: 3 },
    12422: { name: "null", size: 3 },
    12423: { name: "null", size: 1 },
    12424: { name: "Strangled", size: 3 },
    12425: { name: "Vardorvis", size: 3 },
    12426: { name: "Vardorvis", size: 3 },
    12427: { name: "null", size: 3 },
    12428: { name: "null", size: 1 },
    12429: { name: "Strangled", size: 1 },
    12430: { name: "Strangled", size: 1 },
    12431: { name: "Strangled", size: 1 },
    12432: { name: "Strangled", size: 1 },
    12433: { name: "Strangled", size: 1 },
    12434: { name: "Strangled", size: 1 },
    12435: { name: "Strangled", size: 1 },
    12436: { name: "Tail", size: 7 },
    12437: { name: "Tail", size: 7 },
    12438: { name: "Tail", size: 7 },
    12439: { name: "Dagannoth Rex", size: 3 },
    12440: { name: "King Black Dragon", size: 5 },
    12441: { name: "Dagannoth Supreme", size: 3 },
    12442: { name: "Dagannoth Prime", size: 3 },
    12443: { name: "Kree'arra", size: 5 },
    12444: { name: "General Graardor", size: 4 },
    12445: { name: "Commander Zilyana", size: 2 },
    12446: { name: "K'ril Tsutsaroth", size: 5 },
    12447: { name: "Dharok the Wretched", size: 2 },
    12448: { name: "Porazdir", size: 3 },
    12449: { name: "Justiciar Zachariah", size: 3 },
    12450: { name: "Derwen", size: 3 },
    12451: { name: "Greater abyssal demon", size: 3 },
    12452: { name: "Giant goblin", size: 2 },
    12453: { name: "Flaming pyrelord", size: 3 },
    12454: { name: "Cave abomination", size: 3 },
    12455: { name: "Jal-ImKot", size: 4 },
    12456: { name: "Malevolent Mage", size: 1 },
    12457: { name: "Vitreous warped Jelly", size: 3 },
    12458: { name: "Sulphur Lizard", size: 2 },
    12459: { name: "Night beast", size: 4 },
    12460: { name: "Guard", size: 1 },
    12461: { name: "Guard", size: 1 },
    12462: { name: "null", size: 1 },
    12463: { name: "null", size: 1 },
    12464: { name: "Mutated Terrorbird", size: 3 },
    12465: { name: "Mutated Tortoise", size: 4 },
    12466: { name: "Longramble", size: 1 },
    12467: { name: "null", size: 1 },
    12468: { name: "Spirit tree", size: 2 },
    12469: { name: "Spirit tree", size: 2 },
    12470: { name: "Yewnock the Engineer", size: 1 },
    12471: { name: "King Bolrie", size: 1 },
    12472: { name: "Bolrie", size: 1 },
    12473: { name: "Hazelmere", size: 1 },
    12474: { name: "Advisor", size: 1 },
    12475: { name: "Argenthorg", size: 1 },
    12476: { name: "Cute Creature", size: 1 },
    12477: { name: "Evil Creature", size: 1 },
    12478: { name: "Cute Creature", size: 1 },
    12479: { name: "Evil Creature", size: 1 },
    12480: { name: "Terrorbird Guard 21", size: 2 },
    12481: { name: "Terrorbird Guard 72", size: 2 },
    12482: { name: "Terrorbird Guard 21", size: 2 },
    12483: { name: "Terrorbird Guard 72", size: 2 },
    12484: { name: "Terrorbird Guard 21", size: 2 },
    12485: { name: "Terrorbird Guard 72", size: 2 },
    12486: { name: "<col=00ffff>Big Monolith</col>", size: 2 },
    12487: { name: "<col=00ffff>Small Monolith</col>", size: 1 },
    12488: { name: "<col=00ffff>Big Monolith</col>", size: 2 },
    12489: { name: "<col=00ffff>Small Monolith</col>", size: 1 },
    12490: { name: "Warped Tortoise", size: 3 },
    12491: { name: "Warped Terrorbird", size: 2 },
    12492: { name: "Warped Terrorbird", size: 2 },
    12493: { name: "Warped Terrorbird", size: 2 },
    12494: { name: "Warped Terrorbird", size: 2 },
    12495: { name: "Warped Terrorbird", size: 2 },
    12496: { name: "Warped Terrorbird", size: 2 },
    12497: { name: "Warped Terrorbird", size: 2 },
    12498: { name: "Warped Terrorbird", size: 2 },
    12499: { name: "Warped Terrorbird", size: 2 },
    12500: { name: "Warped Terrorbird", size: 2 },
    12501: { name: "Warped Terrorbird", size: 2 },
    12502: { name: "Warped Terrorbird", size: 2 },
    12503: { name: "Warped Terrorbird", size: 2 },
    12504: { name: "Warped Terrorbird", size: 2 },
    12505: { name: "null", size: 1 },
    12506: { name: "null", size: 1 },
    12507: { name: "null", size: 2 },
    12508: { name: "null", size: 2 },
    12509: { name: "null", size: 2 },
    12510: { name: "null", size: 2 },
    12511: { name: "null", size: 2 },
    12512: { name: "Hagus", size: 1 },
    12513: { name: "Spidey dog", size: 1 },
    12514: { name: "Bee keeper", size: 1 },
    12515: { name: "<col=00ffff>Unfinished Beehive</col>", size: 1 },
    12516: { name: "<col=00ffff>Unfinished Beehive</col>", size: 1 },
    12517: { name: "<col=00ffff>Complete Beehive</col>", size: 1 },
    12518: { name: "<col=00ffff>Wild Beehive</col>", size: 1 },
    12519: { name: "Dryad", size: 1 },
    12520: { name: "<col=00ffff>Ritual circle (green)</col>", size: 1 },
    12521: { name: "<col=00ffff>Ritual circle (yellow)</col>", size: 1 },
    12522: { name: "<col=00ffff>Ritual circle (blue)</col>", size: 1 },
    12523: { name: "<col=00ffff>Ritual circle (red)</col>", size: 1 },
    12524: { name: "<col=00ffff>Ritual circle (green)</col>", size: 1 },
    12525: { name: "<col=00ffff>Ritual circle (yellow)</col>", size: 1 },
    12526: { name: "<col=00ffff>Ritual circle (blue)</col>", size: 1 },
    12527: { name: "<col=00ffff>Ritual circle (red)</col>", size: 1 },
    12528: { name: "<col=00ffff>Ritual circle (green)</col>", size: 1 },
    12529: { name: "<col=00ffff>Ritual circle (yellow)</col>", size: 1 },
    12530: { name: "<col=00ffff>Ritual circle (blue)</col>", size: 1 },
    12531: { name: "<col=00ffff>Ritual circle (red)</col>", size: 1 },
    12532: { name: "<col=00ffff>Ritual circle (green)</col>", size: 1 },
    12533: { name: "<col=00ffff>Ritual circle (yellow)</col>", size: 1 },
    12534: { name: "<col=00ffff>Ritual circle (blue)</col>", size: 1 },
    12535: { name: "<col=00ffff>Ritual circle (red)</col>", size: 1 },
    12536: { name: "Freaky Forester", size: 1 },
    12537: { name: "Pheasant", size: 1 },
    12538: { name: "Bloodthirsty hand", size: 3 },
    12539: { name: "Bloodthirsty Crawler", size: 3 },
    12540: { name: "Bloodthirsty banshee", size: 3 },
    12541: { name: "<col=00ffff>Fox trap</col>", size: 1 },
    12542: { name: "Bloodthirsty twisted banshee", size: 3 },
    12543: { name: "Entling", size: 1 },
    12544: { name: "Pruned Entling", size: 1 },
    12545: { name: "Friendly Ent", size: 2 },
    12546: { name: "Friendly Ent", size: 1 },
    12547: { name: "Pheasant", size: 1 },
    12548: { name: "Fox", size: 1 },
    12549: { name: "Pheasant", size: 1 },
    12550: { name: "Fox", size: 1 },
    12551: { name: "Count Check", size: 1 },
    12552: { name: "Count Check", size: 1 },
    12553: { name: "Poacher", size: 1 },
    12554: { name: "Poacher", size: 1 },
    12555: { name: "Poacher", size: 1 },
    12556: { name: "Poacher", size: 1 },
    12557: { name: "Poacher", size: 1 },
    12558: { name: "Poacher", size: 1 },
    12559: { name: "Frightened Fox", size: 1 },
    12560: { name: "Frightened Fox", size: 1 },
    12561: { name: "Bloodthirst rockslug", size: 3 },
    12562: { name: "Bloodthirsty Cockathrice", size: 3 },
    12563: { name: "Bloodthirsty pyrelord", size: 2 },
    12564: { name: "Bloodthirsty Infernal pyrelord", size: 3 },
    12565: { name: "Bloodthirsty basilisk", size: 3 },
    12566: { name: "Bloodthirsty Basilisk Sentinel", size: 3 },
    12567: { name: "Bloodthirsty Mage", size: 2 },
    12568: { name: "Bloodthirsty Bloodveld", size: 3 },
    12569: { name: "Bloodthirsty mutated Bloodveld", size: 3 },
    12570: { name: "Bloodthirsty Jelly", size: 2 },
    12571: { name: "Bloodthirsty warped Jelly", size: 3 },
    12572: { name: "Bloodthirsty abomination", size: 2 },
    12573: { name: "Bloodthirsty spectre", size: 3 },
    12574: { name: "Bloodthirsty Repugnant spectre", size: 3 },
    12575: { name: "Bloodthirsty choke devil", size: 2 },
    12576: { name: "Bloodthirsty kurask", size: 5 },
    12577: { name: "Bloodthirsty smoke devil", size: 2 },
    12578: { name: "Bloodthirsty gargoyle", size: 3 },
    12579: { name: "Bloodthirsty gargoyle", size: 3 },
    12580: { name: "Bloodthirsty Night beast", size: 4 },
    12581: { name: "Bloodthirsty abyssal demon", size: 1 },
    12582: { name: "Bloodthirsty Nechryarch", size: 2 },
    12583: { name: "Bloodthirsty Turoth", size: 3 },
    12584: { name: "Bloodthirsty Shadow Wyrm", size: 5 },
    12585: { name: "Bloodthirsty Shadow Wyrm", size: 5 },
    12586: { name: "Bloodthirsty Drake", size: 5 },
    12587: { name: "Bloodthirsty Drake", size: 5 },
    12588: { name: "Bloodthirsty Hydra", size: 5 },
    12589: { name: "null", size: 1 },
    12590: { name: "Muspah", size: 5 },
    12591: { name: "The Leviathan", size: 5 },
    12592: { name: "Ric", size: 1 },
    12593: { name: "Bran", size: 1 },
    12594: { name: "Jal-ImKot", size: 2 },
    12595: { name: "Ric", size: 1 },
    12596: { name: "Branda the Fire Queen", size: 3 },
    12597: { name: "Goose", size: 1 },
    12598: { name: "Anti-Santa", size: 2 },
    12599: { name: "Key Master", size: 1 },
    12600: { name: "Guss Mistletoad", size: 1 },
    12601: { name: "Santa's scribe", size: 1 },
    12602: { name: "Santa Claus", size: 3 },
    12603: { name: "Barbarian bartender", size: 1 },
    12604: { name: "Waitress", size: 1 },
    12605: { name: "Goose", size: 1 },
    12606: { name: "null", size: 1 },
    12607: { name: "Lava dragon", size: 4 },
    12608: { name: "Lava dragon", size: 4 },
    12609: { name: "null", size: 1 },
    12610: { name: "Elias White", size: 1 },
    12611: { name: "Elias White", size: 1 },
    12612: { name: "Arrav", size: 1 },
    12613: { name: "Arrav", size: 1 },
    12614: { name: "Zemouregal", size: 2 },
    12615: { name: "Zemouregal", size: 3 },
    12616: { name: "null", size: 3 },
    12617: { name: "Sharathteerk", size: 2 },
    12618: { name: "Monk of Zamorak", size: 1 },
    12619: { name: "Monk of Zamorak", size: 1 },
    12620: { name: "Aeonisig Raispher", size: 1 },
    12621: { name: "King Roald", size: 1 },
    12622: { name: "Sir Prysin", size: 1 },
    12623: { name: "Ambassador Ferrnook", size: 1 },
    12624: { name: "Queen Ellamaria", size: 1 },
    12625: { name: "Queen Ellamaria", size: 1 },
    12626: { name: "Reldo", size: 1 },
    12627: { name: "Captain Rovin", size: 1 },
    12628: { name: "Treznor", size: 1 },
    12629: { name: "Dimintheis", size: 1 },
    12630: { name: "Trader Stan", size: 1 },
    12631: { name: "Trader Stan", size: 1 },
    12632: { name: "Trader Crewmember", size: 1 },
    12633: { name: "Trader Crewmember", size: 1 },
    12634: { name: "Trader Crewmember", size: 1 },
    12635: { name: "Trader Crewmember", size: 1 },
    12636: { name: "Trader Crewmember", size: 1 },
    12637: { name: "Trader Crewmember", size: 1 },
    12638: { name: "Trader Crewmember", size: 1 },
    12639: { name: "Trader Crewmember", size: 1 },
    12640: { name: "Trader Crewmember", size: 1 },
    12641: { name: "Trader Crewmember", size: 1 },
    12642: { name: "Trader Crewmember", size: 1 },
    12643: { name: "Trader Crewmember", size: 1 },
    12644: { name: "Alina", size: 1 },
    12645: { name: "Alina", size: 1 },
    12646: { name: "Noah", size: 1 },
    12647: { name: "Noah", size: 1 },
    12648: { name: "Sergeant Tobyn", size: 1 },
    12649: { name: "Sergeant Tobyn", size: 1 },
    12650: { name: "Prince Itzla Arkan", size: 1 },
    12651: { name: "Prince Itzla Arkan", size: 1 },
    12652: { name: "Servius, Teokan of Ralos", size: 1 },
    12653: { name: "Furia Tullus", size: 1 },
    12654: { name: "Ennius Tullus", size: 1 },
    12655: { name: "Knight of Varlamore", size: 1 },
    12656: { name: "Knight of Varlamore", size: 1 },
    12657: { name: "Knight of Varlamore", size: 1 },
    12658: { name: "Knight of Varlamore", size: 1 },
    12659: { name: "Knight of Varlamore", size: 1 },
    12660: { name: "Knight of Varlamore", size: 1 },
    12661: { name: "Guard", size: 1 },
    12662: { name: "Guard", size: 1 },
    12663: { name: "Bandit", size: 1 },
    12664: { name: "Bandit", size: 1 },
    12665: { name: "Bandit", size: 1 },
    12666: { name: "Bandit", size: 1 },
    12667: { name: "Bandit", size: 1 },
    12668: { name: "Guard", size: 1 },
    12669: { name: "Guard", size: 1 },
    12670: { name: "Guard", size: 1 },
    12671: { name: "Guard", size: 1 },
    12672: { name: "Guard", size: 1 },
    12673: { name: "Guard", size: 1 },
    12674: { name: "Guard", size: 1 },
    12675: { name: "Guard", size: 1 },
    12676: { name: "Guard", size: 1 },
    12677: { name: "Guard", size: 1 },
    12678: { name: "Guard", size: 1 },
    12679: { name: "Guard", size: 1 },
    12680: { name: "Guard", size: 1 },
    12681: { name: "Guard", size: 1 },
    12682: { name: "Guard", size: 1 },
    12683: { name: "Guard", size: 1 },
    12684: { name: "Guard", size: 1 },
    12685: { name: "Guard", size: 1 },
    12686: { name: "Guard", size: 1 },
    12687: { name: "Guard", size: 1 },
    12688: { name: "Guard", size: 1 },
    12689: { name: "Guard", size: 1 },
    12690: { name: "Guard", size: 1 },
    12691: { name: "Guard", size: 1 },
    12692: { name: "Guard", size: 1 },
    12693: { name: "Guard", size: 1 },
    12694: { name: "Guard", size: 1 },
    12695: { name: "Guard", size: 1 },
    12696: { name: "Guard", size: 1 },
    12697: { name: "Guard", size: 1 },
    12698: { name: "King Roald", size: 1 },
    12699: { name: "Aeonisig Raispher", size: 1 },
    12700: { name: "Hosa", size: 1 },
    12701: { name: "Lovada", size: 1 },
    12702: { name: "Ferain", size: 1 },
    12703: { name: "Elnes", size: 1 },
    12704: { name: "Hordal", size: 1 },
    12705: { name: "Buneir", size: 1 },
    12706: { name: "Lassin", size: 1 },
    12707: { name: "Hatna", size: 1 },
    12708: { name: "Dwarf", size: 1 },
    12709: { name: "Dwarf", size: 1 },
    12710: { name: "Dwarf", size: 1 },
    12711: { name: "Dwarf", size: 1 },
    12712: { name: "Dwarf", size: 1 },
    12713: { name: "Guard", size: 1 },
    12714: { name: "Guard", size: 1 },
    12715: { name: "Guard", size: 1 },
    12716: { name: "Guard", size: 1 },
    12717: { name: "Guard", size: 1 },
    12718: { name: "Guard", size: 1 },
    12719: { name: "Guard", size: 1 },
    12720: { name: "Armoured zombie", size: 1 },
    12721: { name: "Armoured zombie", size: 1 },
    12722: { name: "Armoured zombie", size: 1 },
    12723: { name: "Armoured zombie", size: 1 },
    12724: { name: "Armoured zombie", size: 1 },
    12725: { name: "Armoured zombie", size: 1 },
    12726: { name: "Armoured zombie", size: 1 },
    12727: { name: "Armoured zombie", size: 1 },
    12728: { name: "Armoured zombie", size: 1 },
    12729: { name: "Armoured zombie", size: 1 },
    12730: { name: "Armoured zombie", size: 1 },
    12731: { name: "Armoured zombie", size: 1 },
    12732: { name: "Armoured zombie", size: 1 },
    12733: { name: "Armoured zombie", size: 1 },
    12734: { name: "Armoured zombie", size: 1 },
    12735: { name: "Armoured zombie", size: 1 },
    12736: { name: "Armoured zombie", size: 1 },
    12737: { name: "Armoured zombie", size: 1 },
    12738: { name: "Armoured zombie", size: 1 },
    12739: { name: "Armoured zombie", size: 1 },
    12740: { name: "Armoured zombie", size: 1 },
    12741: { name: "Armoured zombie", size: 1 },
    12742: { name: "Armoured zombie", size: 1 },
    12743: { name: "Armoured zombie", size: 1 },
    12744: { name: "Armoured zombie", size: 1 },
    12745: { name: "Armoured zombie", size: 1 },
    12746: { name: "Armoured zombie", size: 1 },
    12747: { name: "Armoured zombie", size: 1 },
    12748: { name: "Armoured zombie", size: 1 },
    12749: { name: "Armoured zombie", size: 1 },
    12750: { name: "null", size: 1 },
    12751: { name: "null", size: 1 },
    12752: { name: "null", size: 1 },
    12753: { name: "null", size: 1 },
    12754: { name: "null", size: 1 },
    12755: { name: "Armoured zombie", size: 1 },
    12756: { name: "Armoured zombie", size: 1 },
    12757: { name: "Armoured zombie", size: 1 },
    12758: { name: "Armoured zombie", size: 1 },
    12759: { name: "Armoured zombie", size: 1 },
    12760: { name: "Armoured zombie", size: 1 },
    12761: { name: "Armoured zombie", size: 1 },
    12762: { name: "Armoured zombie", size: 1 },
    12763: { name: "Armoured zombie", size: 1 },
    12764: { name: "Armoured zombie", size: 1 },
    12765: { name: "Tool Leprechaun", size: 1 },
    12766: { name: "Harminia", size: 1 },
    12767: { name: "Smol Heredit", size: 1 },
    12768: { name: "Quetzin", size: 1 },
    12769: { name: "Tecu salamander", size: 1 },
    12770: { name: "Sunlight Moth", size: 1 },
    12771: { name: "Moonlight moth", size: 1 },
    12772: { name: "Moonlight moth", size: 1 },
    12773: { name: "Moonlight moth", size: 1 },
    12774: { name: "Rod Fishing spot", size: 1 },
    12775: { name: "Fishing spot", size: 1 },
    12776: { name: "Fishing spot", size: 1 },
    12777: { name: "Fishing spot", size: 1 },
    12778: { name: "Fishing spot", size: 1 },
    12779: { name: "Trader Stan", size: 1 },
    12780: { name: "Trader Stan", size: 1 },
    12781: { name: "Trader Stan", size: 1 },
    12782: { name: "Trader Stan", size: 1 },
    12783: { name: "Trader Crewmember", size: 1 },
    12784: { name: "Trader Crewmember", size: 1 },
    12785: { name: "Trader Crewmember", size: 1 },
    12786: { name: "Trader Crewmember", size: 1 },
    12787: { name: "Trader Crewmember", size: 1 },
    12788: { name: "Trader Crewmember", size: 1 },
    12789: { name: "Trader Crewmember", size: 1 },
    12790: { name: "Trader Crewmember", size: 1 },
    12791: { name: "Trader Crewmember", size: 1 },
    12792: { name: "Trader Crewmember", size: 1 },
    12793: { name: "Trader Crewmember", size: 1 },
    12794: { name: "Trader Crewmember", size: 1 },
    12795: { name: "Trader Crewmember", size: 1 },
    12796: { name: "Trader Crewmember", size: 1 },
    12797: { name: "Trader Crewmember", size: 1 },
    12798: { name: "Trader Crewmember", size: 1 },
    12799: { name: "Trader Crewmember", size: 1 },
    12800: { name: "Trader Crewmember", size: 1 },
    12801: { name: "Trader Crewmember", size: 1 },
    12802: { name: "Trader Crewmember", size: 1 },
    12803: { name: "Trader Crewmember", size: 1 },
    12804: { name: "Trader Crewmember", size: 1 },
    12805: { name: "Trader Crewmember", size: 1 },
    12806: { name: "Trader Crewmember", size: 1 },
    12807: { name: "Minimus", size: 1 },
    12808: { name: "Minimus", size: 1 },
    12809: { name: "Gloria", size: 1 },
    12810: { name: "Jaguar warrior", size: 2 },
    12811: { name: "Serpent shaman", size: 1 },
    12812: { name: "Minotaur", size: 3 },
    12813: { name: "Minotaur", size: 3 },
    12814: { name: "Fremennik warband archer", size: 1 },
    12815: { name: "Fremennik warband seer", size: 1 },
    12816: { name: "Fremennik warband berserker", size: 1 },
    12817: { name: "Javelin Colossus", size: 3 },
    12818: { name: "Manticore", size: 3 },
    12819: { name: "Shockwave Colossus", size: 3 },
    12820: { name: "<col=00ffff>Pillar</col>", size: 3 },
    12821: { name: "Sol Heredit", size: 5 },
    12822: { name: "Doom Scorpion", size: 1 },
    12823: { name: "Bee Swarm", size: 2 },
    12824: { name: "null", size: 1 },
    12825: { name: "Healing totem", size: 1 },
    12826: { name: "null", size: 1 },
    12827: { name: "Sol Heredit", size: 4 },
    12828: { name: "null", size: 1 },
    12829: { name: "null", size: 3 },
    12830: { name: "null", size: 3 },
    12831: { name: "null", size: 3 },
    12832: { name: "null", size: 5 },
    12833: { name: "Passionate Supporter", size: 1 },
    12834: { name: "Gladiator", size: 1 },
    12835: { name: "Gladiator", size: 1 },
    12836: { name: "Gladiator", size: 1 },
    12837: { name: "Gladiator", size: 2 },
    12838: { name: "Gladiator", size: 2 },
    12839: { name: "Gladiator", size: 2 },
    12840: { name: "Gladiator", size: 1 },
    12841: { name: "Gladiator", size: 1 },
    12842: { name: "Gladiator", size: 1 },
    12843: { name: "Duelist", size: 1 },
    12844: { name: "Moss giant", size: 2 },
    12845: { name: "Moss giant", size: 2 },
    12846: { name: "Moss giant", size: 2 },
    12847: { name: "Moss giant", size: 2 },
    12848: { name: "Hill Giant", size: 2 },
    12849: { name: "Hill Giant", size: 2 },
    12850: { name: "Hill Giant", size: 2 },
    12851: { name: "Rabbit", size: 1 },
    12852: { name: "Rabbit", size: 1 },
    12853: { name: "Rabbit", size: 1 },
    12854: { name: "Squirrel", size: 1 },
    12855: { name: "Squirrel", size: 1 },
    12856: { name: "Squirrel", size: 1 },
    12857: { name: "Smol Heredit", size: 1 },
    12858: { name: "Quetzin", size: 1 },
    12859: { name: "Zuma", size: 1 },
    12860: { name: "Zuma", size: 1 },
    12861: { name: "Attala", size: 1 },
    12862: { name: "Jessamine", size: 1 },
    12863: { name: "null", size: 1 },
    12864: { name: "null", size: 1 },
    12865: { name: "Jessamine", size: 1 },
    12866: { name: "null", size: 3 },
    12867: { name: "null", size: 3 },
    12868: { name: "null", size: 3 },
    12869: { name: "Eyatlalli", size: 1 },
    12870: { name: "Eyatlalli", size: 1 },
    12871: { name: "null", size: 2 },
    12872: { name: "Builder", size: 1 },
    12873: { name: "Builder", size: 1 },
    12874: { name: "null", size: 2 },
    12875: { name: "null", size: 2 },
    12876: { name: "Builder", size: 1 },
    12877: { name: "Builder", size: 1 },
    12878: { name: "null", size: 2 },
    12879: { name: "Sulphur Nagua", size: 2 },
    12880: { name: "null", size: 1 },
    12881: { name: "Guard", size: 1 },
    12882: { name: "Blacksmith", size: 1 },
    12883: { name: "Regulus Cento", size: 1 },
    12884: { name: "Regulus Cento", size: 1 },
    12885: { name: "Regulus Cento", size: 1 },
    12886: { name: "Quetzal", size: 3 },
    12887: { name: "Primio", size: 3 },
    12888: { name: "Primio", size: 3 },
    12889: { name: "Primio", size: 3 },
    12890: { name: "Furia Tullus", size: 1 },
    12891: { name: "Furia Tullus", size: 1 },
    12892: { name: "Ennius Tullus", size: 1 },
    12893: { name: "Ennius Tullus", size: 1 },
    12894: { name: "Metzli, Teokan of Ranul", size: 1 },
    12895: { name: "Metzli, Teokan of Ranul", size: 1 },
    12896: { name: "Prince Itzla Arkan", size: 1 },
    12897: { name: "Prince Itzla Arkan", size: 1 },
    12898: { name: "Prince Itzla Arkan", size: 1 },
    12899: { name: "Servius, Teokan of Ralos", size: 1 },
    12900: { name: "Servius, Teokan of Ralos", size: 1 },
    12901: { name: "Servius, Teokan of Ralos", size: 2 },
    12902: { name: "Knight of Varlamore", size: 1 },
    12903: { name: "Knight of Varlamore", size: 1 },
    12904: { name: "Knight of Varlamore", size: 1 },
    12905: { name: "Knight of Varlamore", size: 1 },
    12906: { name: "Knight of Varlamore", size: 1 },
    12907: { name: "Knight of Varlamore", size: 1 },
    12908: { name: "Knight of Varlamore", size: 1 },
    12909: { name: "Knight of Varlamore", size: 1 },
    12910: { name: "Knight of Varlamore", size: 1 },
    12911: { name: "Knight of Varlamore", size: 1 },
    12912: { name: "Knight of Varlamore", size: 1 },
    12913: { name: "Knight of Varlamore", size: 1 },
    12914: { name: "Knight of Varlamore", size: 1 },
    12915: { name: "Knight of Varlamore", size: 1 },
    12916: { name: "Knight of Varlamore", size: 1 },
    12917: { name: "Knight of Varlamore", size: 1 },
    12918: { name: "Cultist", size: 1 },
    12919: { name: "Cultist", size: 1 },
    12920: { name: "Cultist", size: 1 },
    12921: { name: "Cultist", size: 1 },
    12922: { name: "Cultist", size: 1 },
    12923: { name: "Cultist", size: 1 },
    12924: { name: "Guard", size: 1 },
    12925: { name: "Guard", size: 1 },
    12926: { name: "Guard", size: 1 },
    12927: { name: "Guard", size: 1 },
    12928: { name: "Guard", size: 1 },
    12929: { name: "Citizen", size: 1 },
    12930: { name: "<col=00ffff>Fountain</col>", size: 3 },
    12931: { name: "Injured hunter", size: 1 },
    12932: { name: "Guild Hunter Fox", size: 1 },
    12933: { name: "Atza", size: 1 },
    12934: { name: "Guild Hunter Kiko", size: 1 },
    12935: { name: "Marcellus", size: 1 },
    12936: { name: "Marcellus", size: 1 },
    12937: { name: "Frog", size: 1 },
    12938: { name: "Frog", size: 1 },
    12939: { name: "Gary", size: 1 },
    12940: { name: "Gary", size: 1 },
    12941: { name: "Frog", size: 1 },
    12942: { name: "Frog", size: 1 },
    12943: { name: "Dave", size: 1 },
    12944: { name: "Dave", size: 1 },
    12945: { name: "Frog", size: 1 },
    12946: { name: "Frog", size: 1 },
    12947: { name: "Sue", size: 1 },
    12948: { name: "Sue", size: 1 },
    12949: { name: "Frog", size: 1 },
    12950: { name: "Frog", size: 1 },
    12951: { name: "Jane", size: 1 },
    12952: { name: "Jane", size: 1 },
    12953: { name: "Frog", size: 1 },
    12954: { name: "Frog", size: 1 },
    12955: { name: "Cuthbert", size: 1 },
    12956: { name: "Cuthbert", size: 1 },
    12957: { name: "Cuthbert, Lord of Dread", size: 1 },
    12958: { name: "Capybara", size: 1 },
    12959: { name: "Capybara", size: 1 },
    12960: { name: "Capybara Pup", size: 1 },
    12961: { name: "Capybara", size: 1 },
    12962: { name: "Capybara", size: 1 },
    12963: { name: "Capybara pup", size: 1 },
    12964: { name: "Capybara", size: 1 },
    12965: { name: "Capybara", size: 1 },
    12966: { name: "Capybara", size: 1 },
    12967: { name: "Capybara", size: 1 },
    12968: { name: "Capybara", size: 1 },
    12969: { name: "Capybara", size: 1 },
    12970: { name: "Capybara", size: 1 },
    12971: { name: "Capybara", size: 1 },
    12972: { name: "Green skink", size: 1 },
    12973: { name: "Green skink", size: 1 },
    12974: { name: "Sunbeam skink", size: 1 },
    12975: { name: "Sunbeam skink", size: 1 },
    12976: { name: "Jaguar", size: 2 },
    12977: { name: "Jaguar cub", size: 1 },
    12978: { name: "Black jaguar", size: 2 },
    12979: { name: "White jaguar", size: 2 },
    12980: { name: "Fennec fox", size: 1 },
    12981: { name: "Fennec fox cub", size: 1 },
    12982: { name: "Jerboa", size: 1 },
    12983: { name: "Toucan", size: 1 },
    12984: { name: "Toucan", size: 1 },
    12985: { name: "Mountain toucan", size: 1 },
    12986: { name: "Alpaca", size: 2 },
    12987: { name: "Alpaca", size: 2 },
    12988: { name: "Alpaca", size: 2 },
    12989: { name: "Alpaca", size: 2 },
    12990: { name: "Alpaca cria", size: 1 },
    12991: { name: "Alpaca cria", size: 1 },
    12992: { name: "Molossus", size: 1 },
    12993: { name: "Molossus", size: 1 },
    12994: { name: "Molossus", size: 1 },
    12995: { name: "Molossus", size: 1 },
    12996: { name: "Xolo", size: 1 },
    12997: { name: "Xolo", size: 1 },
    12998: { name: "Lola", size: 1 },
    12999: { name: "Molossus", size: 1 },
    13000: { name: "Xolo", size: 1 },
    13001: { name: "Scorpion", size: 2 },
    13002: { name: "Snake", size: 1 },
    13003: { name: "Harpy eagle", size: 2 },
    13004: { name: "Buffalo", size: 2 },
    13005: { name: "Buffalo", size: 2 },
    13006: { name: "Oryx", size: 2 },
    13007: { name: "Flies", size: 1 },
    13008: { name: "Flies", size: 1 },
    13009: { name: "null", size: 5 },
    13010: { name: "Green dragon", size: 4 },
    13011: { name: "Blood Moon", size: 1 },
    13012: { name: "Eclipse Moon", size: 1 },
    13013: { name: "Blue Moon", size: 1 },
    13014: { name: "null", size: 1 },
    13015: { name: "null", size: 2 },
    13016: { name: "null", size: 5 },
    13017: { name: "null", size: 5 },
    13018: { name: "null", size: 5 },
    13019: { name: "null", size: 5 },
    13020: { name: "<col=00ffff>Moon Shield</col>", size: 2 },
    13021: { name: "Blood jaguar", size: 2 },
    13022: { name: "null", size: 2 },
    13023: { name: "null", size: 2 },
    13024: { name: "null", size: 2 },
    13025: { name: "<col=00ffff>Frozen weapons</col>", size: 1 },
    13026: { name: "<col=00ffff>Cracked ice</col>", size: 1 },
    13027: { name: "null", size: 1 },
    13028: { name: "Moss Lizard", size: 1 },
    13029: { name: "Grimy Lizard", size: 2 },
    13030: { name: "Moonlight Cockatrice", size: 3 },
    13031: { name: "Wyrmling", size: 2 },
    13032: { name: "Wyrmling", size: 2 },
    13033: { name: "Sulphur Nagua", size: 2 },
    13034: { name: "Frog", size: 1 },
    13035: { name: "Shopkeeper", size: 1 },
    13036: { name: "Nahta", size: 1 },
    13037: { name: "Tizoro", size: 1 },
    13038: { name: "Blacksmith", size: 1 },
    13039: { name: "Yarnio", size: 1 },
    13040: { name: "Conara", size: 1 },
    13041: { name: "Huito", size: 1 },
    13042: { name: "Sonalo", size: 1 },
    13043: { name: "Bartender", size: 1 },
    13044: { name: "Banker", size: 1 },
    13045: { name: "Banker", size: 1 },
    13046: { name: "Banker", size: 1 },
    13047: { name: "Banker", size: 1 },
    13048: { name: "Guard", size: 1 },
    13049: { name: "Guard", size: 1 },
    13050: { name: "Guard", size: 1 },
    13051: { name: "Guard", size: 1 },
    13052: { name: "Guard", size: 1 },
    13053: { name: "Guard", size: 1 },
    13054: { name: "Guard", size: 1 },
    13055: { name: "Guard", size: 1 },
    13056: { name: "Guard", size: 1 },
    13057: { name: "Guard", size: 1 },
    13058: { name: "Guard", size: 1 },
    13059: { name: "Guard", size: 1 },
    13060: { name: "Guard", size: 1 },
    13061: { name: "Guard", size: 1 },
    13062: { name: "Guard", size: 1 },
    13063: { name: "Citizen", size: 1 },
    13064: { name: "Citizen", size: 1 },
    13065: { name: "Citizen", size: 1 },
    13066: { name: "Citizen", size: 1 },
    13067: { name: "Citizen", size: 1 },
    13068: { name: "Citizen", size: 1 },
    13069: { name: "Citizen", size: 1 },
    13070: { name: "Citizen", size: 1 },
    13071: { name: "Citizen", size: 1 },
    13072: { name: "Citizen", size: 1 },
    13073: { name: "Citizen", size: 1 },
    13074: { name: "Citizen", size: 1 },
    13075: { name: "Citizen", size: 1 },
    13076: { name: "Citizen", size: 1 },
    13077: { name: "Citizen", size: 1 },
    13078: { name: "Citizen", size: 1 },
    13079: { name: "Citizen", size: 1 },
    13080: { name: "Citizen", size: 1 },
    13081: { name: "Citizen", size: 1 },
    13082: { name: "Citizen", size: 1 },
    13083: { name: "Child", size: 1 },
    13084: { name: "Child", size: 1 },
    13085: { name: "Child", size: 1 },
    13086: { name: "Child", size: 1 },
    13087: { name: "Funbo", size: 1 },
    13088: { name: "Kalko", size: 1 },
    13089: { name: "Worker", size: 1 },
    13090: { name: "Worker", size: 1 },
    13091: { name: "Worker", size: 1 },
    13092: { name: "Worker", size: 1 },
    13093: { name: "Worker", size: 1 },
    13094: { name: "Worker", size: 1 },
    13095: { name: "Worker", size: 1 },
    13096: { name: "Worker", size: 1 },
    13097: { name: "Cuica", size: 1 },
    13098: { name: "Callio", size: 1 },
    13099: { name: "Attala", size: 1 },
    13100: { name: "Guard", size: 1 },
    13101: { name: "Guard", size: 1 },
    13102: { name: "Guard", size: 1 },
    13103: { name: "Guard", size: 1 },
    13104: { name: "Guard", size: 1 },
    13105: { name: "Guard", size: 1 },
    13106: { name: "Guard", size: 1 },
    13107: { name: "Guard", size: 1 },
    13108: { name: "Guard", size: 1 },
    13109: { name: "Guard", size: 1 },
    13110: { name: "Guard", size: 1 },
    13111: { name: "Guard", size: 1 },
    13112: { name: "Guard", size: 1 },
    13113: { name: "Guard", size: 1 },
    13114: { name: "Knight of Varlamore", size: 1 },
    13115: { name: "Knight of Varlamore", size: 1 },
    13116: { name: "Knight of Varlamore", size: 1 },
    13117: { name: "Knight of Varlamore", size: 1 },
    13118: { name: "Knight of Varlamore", size: 1 },
    13119: { name: "Knight of Varlamore", size: 1 },
    13120: { name: "Guildmaster Apatura", size: 1 },
    13121: { name: "Huntmaster Gilman (Novice)", size: 1 },
    13122: { name: "Guild Hunter Ornus (Adept)", size: 1 },
    13123: { name: "Guild Hunter Cervus (Adept)", size: 1 },
    13124: { name: "Guild Hunter Aco (Expert)", size: 1 },
    13125: { name: "Guild Hunter Teco (Expert)", size: 1 },
    13126: { name: "Guild Hunter Wolf (Master)", size: 1 },
    13127: { name: "Guild Scribe Verity", size: 1 },
    13128: { name: "Bank buffalo", size: 2 },
    13129: { name: "Banker", size: 1 },
    13130: { name: "Pellem", size: 1 },
    13131: { name: "Imia", size: 1 },
    13132: { name: "Moonlight antelope", size: 2 },
    13133: { name: "Sunlight antelope", size: 2 },
    13134: { name: "Quetzal", size: 3 },
    13135: { name: "Soar Leader Pitri", size: 1 },
    13136: { name: "Orsaga", size: 1 },
    13137: { name: "Orsaga's chinchompa", size: 1 },
    13138: { name: "Pyre fox", size: 1 },
    13139: { name: "Embertailed jerboa", size: 1 },
    13140: { name: "Metla", size: 1 },
    13141: { name: "Worker", size: 1 },
    13142: { name: "Worker", size: 1 },
    13143: { name: "Worker", size: 1 },
    13144: { name: "Worker", size: 1 },
    13145: { name: "Avinia", size: 1 },
    13146: { name: "Bard", size: 1 },
    13147: { name: "Bard", size: 1 },
    13148: { name: "Bard", size: 1 },
    13149: { name: "Worker", size: 1 },
    13150: { name: "Worker", size: 1 },
    13151: { name: "Worker", size: 1 },
    13152: { name: "Worker", size: 1 },
    13153: { name: "Worker", size: 1 },
    13154: { name: "Worker", size: 1 },
    13155: { name: "Worker", size: 1 },
    13156: { name: "Worker", size: 1 },
    13157: { name: "Ru Merald", size: 1 },
    13158: { name: "Picaria", size: 1 },
    13159: { name: "Shopkeeper", size: 1 },
    13160: { name: "Thurid", size: 1 },
    13161: { name: "Reinn", size: 1 },
    13162: { name: "Inga", size: 1 },
    13163: { name: "Arnkel", size: 1 },
    13164: { name: "Citizen", size: 1 },
    13165: { name: "Citizen", size: 1 },
    13166: { name: "Citizen", size: 1 },
    13167: { name: "Citizen", size: 1 },
    13168: { name: "Citizen", size: 1 },
    13169: { name: "Citizen", size: 1 },
    13170: { name: "Citizen", size: 1 },
    13171: { name: "Citizen", size: 1 },
    13172: { name: "Citizen", size: 1 },
    13173: { name: "Citizen", size: 1 },
    13174: { name: "Child", size: 1 },
    13175: { name: "Child", size: 1 },
    13176: { name: "Child", size: 1 },
    13177: { name: "Child", size: 1 },
    13178: { name: "Citizen", size: 1 },
    13179: { name: "Citizen", size: 1 },
    13180: { name: "Citizen", size: 1 },
    13181: { name: "Citizen", size: 1 },
    13182: { name: "Citizen", size: 1 },
    13183: { name: "Citizen", size: 1 },
    13184: { name: "Citizen", size: 1 },
    13185: { name: "Citizen", size: 1 },
    13186: { name: "Citizen", size: 1 },
    13187: { name: "Citizen", size: 1 },
    13188: { name: "Child", size: 1 },
    13189: { name: "Child", size: 1 },
    13190: { name: "Child", size: 1 },
    13191: { name: "Child", size: 1 },
    13192: { name: "Citizen", size: 1 },
    13193: { name: "Citizen", size: 1 },
    13194: { name: "Citizen", size: 1 },
    13195: { name: "Citizen", size: 1 },
    13196: { name: "Citizen", size: 1 },
    13197: { name: "Citizen", size: 1 },
    13198: { name: "Citizen", size: 1 },
    13199: { name: "Citizen", size: 1 },
    13200: { name: "Citizen", size: 1 },
    13201: { name: "Citizen", size: 1 },
    13202: { name: "Citizen", size: 1 },
    13203: { name: "Citizen", size: 1 },
    13204: { name: "Citizen", size: 1 },
    13205: { name: "Citizen", size: 1 },
    13206: { name: "Tourist", size: 1 },
    13207: { name: "Tourist", size: 1 },
    13208: { name: "Tourist", size: 1 },
    13209: { name: "Tourist", size: 1 },
    13210: { name: "Tourist", size: 1 },
    13211: { name: "Tourist", size: 1 },
    13212: { name: "Banker", size: 1 },
    13213: { name: "Banker", size: 1 },
    13214: { name: "Banker", size: 1 },
    13215: { name: "Banker", size: 1 },
    13216: { name: "Banker", size: 1 },
    13217: { name: "Banker", size: 1 },
    13218: { name: "Banker", size: 1 },
    13219: { name: "Banker", size: 1 },
    13220: { name: "Banker", size: 1 },
    13221: { name: "Banker", size: 1 },
    13222: { name: "Banker", size: 1 },
    13223: { name: "Banker", size: 1 },
    13224: { name: "Banker", size: 1 },
    13225: { name: "Banker", size: 1 },
    13226: { name: "Banker", size: 1 },
    13227: { name: "Banker", size: 1 },
    13228: { name: "Farmer", size: 1 },
    13229: { name: "Farmer", size: 1 },
    13230: { name: "Farmer", size: 1 },
    13231: { name: "Farmer", size: 1 },
    13232: { name: "Farmer", size: 1 },
    13233: { name: "Farmer", size: 1 },
    13234: { name: "Farmer", size: 1 },
    13235: { name: "Farmer", size: 1 },
    13236: { name: "Master Farmer", size: 1 },
    13237: { name: "Master Farmer", size: 1 },
    13238: { name: "Master Farmer", size: 1 },
    13239: { name: "Master Farmer", size: 1 },
    13240: { name: "Master Farmer", size: 1 },
    13241: { name: "Master Farmer", size: 1 },
    13242: { name: "Master Farmer", size: 1 },
    13243: { name: "Master Farmer", size: 1 },
    13244: { name: "Squire", size: 1 },
    13245: { name: "Squire", size: 1 },
    13246: { name: "Squire", size: 1 },
    13247: { name: "Squire", size: 1 },
    13248: { name: "Sailor", size: 1 },
    13249: { name: "Sailor", size: 1 },
    13250: { name: "Sailor", size: 1 },
    13251: { name: "Sailor", size: 1 },
    13252: { name: "Fisher", size: 1 },
    13253: { name: "Fisher", size: 1 },
    13254: { name: "Fisher", size: 1 },
    13255: { name: "Fisher", size: 1 },
    13256: { name: "Worker", size: 1 },
    13257: { name: "Worker", size: 1 },
    13258: { name: "Worker", size: 1 },
    13259: { name: "Worker", size: 1 },
    13260: { name: "Worker", size: 1 },
    13261: { name: "Worker", size: 1 },
    13262: { name: "Nelta of Ralos", size: 1 },
    13263: { name: "Nelta of Ralos", size: 1 },
    13264: { name: "Nelta of Ralos", size: 1 },
    13265: { name: "Nelta of Ralos", size: 1 },
    13266: { name: "Nelta of Ralos", size: 1 },
    13267: { name: "Nelta of Ralos", size: 1 },
    13268: { name: "Nelta of Ralos", size: 1 },
    13269: { name: "Nelta of Ralos", size: 1 },
    13270: { name: "Nelta of Ralos", size: 1 },
    13271: { name: "Nelta of Ralos", size: 1 },
    13272: { name: "Nelta of Ranul", size: 1 },
    13273: { name: "Nelta of Ranul", size: 1 },
    13274: { name: "Nelta of Ranul", size: 1 },
    13275: { name: "Nelta of Ranul", size: 1 },
    13276: { name: "Nelta of Ranul", size: 1 },
    13277: { name: "Nelta of Ranul", size: 1 },
    13278: { name: "Nelta of Ranul", size: 1 },
    13279: { name: "Nelta of Ranul", size: 1 },
    13280: { name: "Nelta of Ranul", size: 1 },
    13281: { name: "Nelta of Ranul", size: 1 },
    13282: { name: "Mugger", size: 1 },
    13283: { name: "Bandit", size: 1 },
    13284: { name: "Bandit", size: 1 },
    13285: { name: "Bandit", size: 1 },
    13286: { name: "Bandit", size: 1 },
    13287: { name: "Bandit", size: 1 },
    13288: { name: "Bandit", size: 1 },
    13289: { name: "Bandit", size: 1 },
    13290: { name: "Bandit", size: 1 },
    13291: { name: "Thief", size: 1 },
    13292: { name: "Thief", size: 1 },
    13293: { name: "Thief", size: 1 },
    13294: { name: "Thief", size: 1 },
    13295: { name: "Thief", size: 1 },
    13296: { name: "Thief", size: 1 },
    13297: { name: "Curator Herminius", size: 1 },
    13298: { name: "Academic", size: 1 },
    13299: { name: "Academic", size: 1 },
    13300: { name: "Academic", size: 1 },
    13301: { name: "Academic", size: 1 },
    13302: { name: "Wealthy citizen", size: 1 },
    13303: { name: "Wealthy citizen", size: 1 },
    13304: { name: "Wealthy citizen", size: 1 },
    13305: { name: "Wealthy citizen", size: 1 },
    13306: { name: "Leo", size: 1 },
    13307: { name: "Julia", size: 1 },
    13308: { name: "Aurelia", size: 1 },
    13309: { name: "Cassius", size: 1 },
    13310: { name: "Dodgy Character", size: 1 },
    13311: { name: "Oriana", size: 1 },
    13312: { name: "Lavinia", size: 1 },
    13313: { name: "Victor", size: 1 },
    13314: { name: "Caius", size: 1 },
    13315: { name: "Rufus", size: 1 },
    13316: { name: "Ava", size: 1 },
    13317: { name: "Lucia", size: 1 },
    13318: { name: "Oli", size: 1 },
    13319: { name: "Helvia", size: 1 },
    13320: { name: "Warden", size: 1 },
    13321: { name: "Prisoner", size: 1 },
    13322: { name: "Prisoner", size: 1 },
    13323: { name: "Guard Captain", size: 1 },
    13324: { name: "Cuali, Teoki of Ralos", size: 1 },
    13325: { name: "Hegio, Teoki of Ranul", size: 1 },
    13326: { name: "null", size: 1 },
    13327: { name: "null", size: 1 },
    13328: { name: "null", size: 1 },
    13329: { name: "Fishing spot", size: 1 },
    13330: { name: "Shopkeeper", size: 1 },
    13331: { name: "Shopkeeper", size: 1 },
    13332: { name: "Blacksmith", size: 1 },
    13333: { name: "Spike", size: 1 },
    13334: { name: "Silk Merchant", size: 1 },
    13335: { name: "Fur Merchant", size: 1 },
    13336: { name: "Gem Merchant", size: 1 },
    13337: { name: "Spice Merchant", size: 1 },
    13338: { name: "Baker", size: 1 },
    13339: { name: "Agelus", size: 1 },
    13340: { name: "Floria", size: 1 },
    13341: { name: "Cobado", size: 1 },
    13342: { name: "Artima", size: 1 },
    13343: { name: "Estate Agent", size: 1 },
    13344: { name: "Bartender", size: 1 },
    13345: { name: "Bartender", size: 1 },
    13346: { name: "Virilis", size: 1 },
    13347: { name: "Zuma", size: 1 },
    13348: { name: "Renu", size: 3 },
    13349: { name: "Renu", size: 3 },
    13350: { name: "Renu", size: 3 },
    13351: { name: "Renu", size: 3 },
    13352: { name: "Renu", size: 3 },
    13353: { name: "Renu", size: 3 },
    13354: { name: "Renu", size: 3 },
    13355: { name: "null", size: 3 },
    13356: { name: "null", size: 3 },
    13357: { name: "null", size: 3 },
    13358: { name: "null", size: 3 },
    13359: { name: "null", size: 3 },
    13360: { name: "null", size: 3 },
    13361: { name: "null", size: 3 },
    13362: { name: "null", size: 3 },
    13363: { name: "null", size: 1 },
    13364: { name: "null", size: 1 },
    13365: { name: "null", size: 1 },
    13366: { name: "null", size: 1 },
    13367: { name: "null", size: 1 },
    13368: { name: "null", size: 1 },
    13369: { name: "null", size: 1 },
    13370: { name: "null", size: 1 },
    13371: { name: "null", size: 3 },
    13372: { name: "null", size: 1 },
    13373: { name: "null", size: 1 },
    13374: { name: "null", size: 1 },
    13375: { name: "null", size: 1 },
    13376: { name: "null", size: 1 },
    13377: { name: "null", size: 1 },
    13378: { name: "null", size: 1 },
    13379: { name: "null", size: 1 },
    13380: { name: "null", size: 1 },
    13381: { name: "null", size: 1 },
    13382: { name: "null", size: 1 },
    13383: { name: "null", size: 2 },
    13384: { name: "null", size: 1 },
    13385: { name: "null", size: 1 },
    13386: { name: "null", size: 1 },
    13387: { name: "null", size: 1 },
    13388: { name: "null", size: 1 },
    13389: { name: "null", size: 1 },
    13390: { name: "null", size: 1 },
    13391: { name: "null", size: 1 },
    13392: { name: "null", size: 1 },
    13393: { name: "null", size: 1 },
    13394: { name: "null", size: 1 },
    13395: { name: "null", size: 1 },
    13396: { name: "null", size: 1 },
    13397: { name: "null", size: 1 },
    13398: { name: "null", size: 1 },
    13399: { name: "null", size: 1 },
    13400: { name: "null", size: 1 },
    13401: { name: "null", size: 1 },
    13402: { name: "null", size: 1 },
    13403: { name: "null", size: 1 },
    13404: { name: "null", size: 1 },
    13405: { name: "null", size: 1 },
    13406: { name: "null", size: 1 },
    13407: { name: "null", size: 2 },
    13408: { name: "null", size: 2 },
    13409: { name: "null", size: 2 },
    13410: { name: "null", size: 2 },
    13411: { name: "null", size: 2 },
    13412: { name: "null", size: 2 },
    13413: { name: "null", size: 2 },
    13414: { name: "null", size: 2 },
    13415: { name: "null", size: 1 },
    13416: { name: "null", size: 1 },
    13417: { name: "null", size: 1 },
    13418: { name: "null", size: 1 },
    13419: { name: "null", size: 1 },
    13420: { name: "Ueman, Teoki of Ralos", size: 1 },
    13421: { name: "Seia, Teoki of Ranul", size: 1 },
    13422: { name: "null", size: 1 },
    13423: { name: "Hunter guild member", size: 1 },
    13424: { name: "Hunter guild member", size: 1 },
    13425: { name: "Buffalo", size: 2 },
    13426: { name: "Guild Hunter Fox", size: 1 },
    13427: { name: "Red skink", size: 1 },
    13428: { name: "Red skink", size: 1 },
    13429: { name: "Light creature", size: 3 },
    13430: { name: "Harrallak Menarous", size: 1 },
    13431: { name: "Harrallak Menarous", size: 1 },
    13432: { name: "Harrallak Menarous", size: 1 },
    13433: { name: "Turael", size: 1 },
    13434: { name: "Turael", size: 1 },
    13435: { name: "Cyrisus", size: 1 },
    13436: { name: "Cyrisus", size: 1 },
    13437: { name: "Ghommal", size: 1 },
    13438: { name: "Mazchna", size: 1 },
    13439: { name: "Mazchna", size: 1 },
    13440: { name: "Mazchna", size: 1 },
    13441: { name: "Duradel", size: 1 },
    13442: { name: "Sir Kit Breaker", size: 1 },
    13443: { name: "Frog Prin", size: 1 },
    13444: { name: "Frog Prin", size: 1 },
    13445: { name: "Frog Prince", size: 1 },
    13446: { name: "Frog Princess", size: 1 },
    13447: { name: "Sloane", size: 1 },
    13448: { name: "Hazelmere", size: 1 },
    13449: { name: "Hazelmere", size: 1 },
    13450: { name: "Hazelmere", size: 1 },
    13451: { name: "Hazelmere", size: 1 },
    13452: { name: "<col=00ffff>Hazelmere's hat</col>", size: 1 },
    13453: { name: "Undead Hero", size: 1 },
    13454: { name: "Undead Hero", size: 1 },
    13455: { name: "Undead Hero", size: 1 },
    13456: { name: "Undead Hero", size: 1 },
    13457: { name: "Undead Hero", size: 1 },
    13458: { name: "Undead Hero", size: 1 },
    13459: { name: "Undead Hero", size: 1 },
    13460: { name: "Undead Hero", size: 1 },
    13461: { name: "Undead Hero", size: 1 },
    13462: { name: "Undead Hero", size: 1 },
    13463: { name: "Elite Black Knight", size: 1 },
    13464: { name: "Elite Black Knight", size: 1 },
    13465: { name: "Elite Dark Warrior", size: 1 },
    13466: { name: "Elite Dark Warrior", size: 1 },
    13467: { name: "Elite Dark Ranger", size: 1 },
    13468: { name: "Elite Dark Ranger", size: 1 },
    13469: { name: "Elite Dark Mage", size: 1 },
    13470: { name: "Elite Dark Mage", size: 1 },
    13471: { name: "Undead Mage", size: 1 },
    13472: { name: "null", size: 1 },
    13473: { name: "null", size: 1 },
    13474: { name: "null", size: 1 },
    13475: { name: "null", size: 1 },
    13476: { name: "Skeleton", size: 1 },
    13477: { name: "Skeleton", size: 1 },
    13478: { name: "Skeleton", size: 1 },
    13479: { name: "Skeleton", size: 1 },
    13480: { name: "Elite Black Knight", size: 1 },
    13481: { name: "Elite Black Knight", size: 1 },
    13482: { name: "Surok Magis", size: 1 },
    13483: { name: "Agile Warrior", size: 1 },
    13484: { name: "Strong Warrior", size: 1 },
    13485: { name: "Enraged Blood Moon", size: 1 },
    13486: { name: "Enraged Blue Moon", size: 1 },
    13487: { name: "Enraged Eclipse Moon", size: 1 },
    13488: { name: "Green dragon", size: 4 },
    13489: { name: "Zombie pirate", size: 1 },
    13490: { name: "Zombie pirate", size: 1 },
    13491: { name: "Zombie pirate", size: 1 },
    13492: { name: "Zombie pirate", size: 1 },
    13493: { name: "Zombie pirate", size: 1 },
    13494: { name: "Zombie pirate", size: 1 },
    13495: { name: "Skeleton", size: 1 },
    13496: { name: "Skeleton", size: 1 },
    13497: { name: "Skeleton", size: 1 },
    13498: { name: "Skeleton", size: 1 },
    13499: { name: "Skeleton", size: 1 },
    13500: { name: "Skeleton", size: 1 },
    13501: { name: "Skeleton", size: 1 },
    13502: { name: "Hill Giant", size: 2 },
    13503: { name: "Hill Giant", size: 2 },
    13504: { name: "Hill Giant", size: 2 },
    13505: { name: "<col=00ffff>Earth spell</col>", size: 1 },
    13506: { name: "<col=00ffff>Water spell</col>", size: 1 },
    13507: { name: "<col=00ffff>Fire spell</col>", size: 1 },
    13508: { name: "<col=00ffff>Wind spell</col>", size: 1 },
    13509: { name: "Guardian of Armadyl", size: 1 },
    13510: { name: "Ivy Sophista", size: 1 },
    13511: { name: "Thaerisk", size: 1 },
    13512: { name: "Thaerisk", size: 1 },
    13513: { name: "Thaerisk", size: 1 },
    13514: { name: "Assassin", size: 1 },
    13515: { name: "Assassin", size: 1 },
    13516: { name: "Khazard Launderer", size: 2 },
    13517: { name: "Wild broav", size: 1 },
    13518: { name: "Broav", size: 1 },
    13519: { name: "Silif", size: 1 },
    13520: { name: "Silif", size: 1 },
    13521: { name: "Silif", size: 1 },
    13522: { name: "null", size: 1 },
    13523: { name: "null", size: 1 },
    13524: { name: "Silif", size: 2 },
    13525: { name: "Lucien", size: 2 },
    13526: { name: "Lucien", size: 2 },
    13527: { name: "Lucien", size: 2 },
    13528: { name: "Balance Elemental", size: 3 },
    13529: { name: "Balance Elemental", size: 3 },
    13530: { name: "Balance Elemental", size: 3 },
    13531: { name: "Elite Khazard Guard", size: 1 },
    13532: { name: "Elite Khazard Guard", size: 1 },
    13533: { name: "Elite Khazard Guard", size: 1 },
    13534: { name: "Elite Khazard Guard", size: 1 },
    13535: { name: "Mercenary axeman", size: 1 },
    13536: { name: "Mercenary axeman", size: 1 },
    13537: { name: "Mercenary mage", size: 1 },
    13538: { name: "Guardian of Armadyl", size: 1 },
    13539: { name: "Guardian of Armadyl", size: 1 },
    13540: { name: "Idria", size: 1 },
    13541: { name: "Idria", size: 1 },
    13542: { name: "Idria", size: 1 },
    13543: { name: "Akrisae", size: 1 },
    13544: { name: "Akrisae", size: 1 },
    13545: { name: "Shady stranger", size: 1 },
    13546: { name: "Shady stranger", size: 1 },
    13547: { name: "Shady stranger", size: 1 },
    13548: { name: "Surok Magis", size: 1 },
    13549: { name: "Surok Magis", size: 3 },
    13550: { name: "null", size: 3 },
    13551: { name: "Surok Magis", size: 3 },
    13552: { name: "Druid", size: 1 },
    13553: { name: "Druid", size: 1 },
    13554: { name: "Druid", size: 1 },
    13555: { name: "Suspicious outsider", size: 1 },
    13556: { name: "Ghommal", size: 1 },
    13557: { name: "Harrallak Menarous", size: 1 },
    13558: { name: "Sloane", size: 1 },
    13559: { name: "Cyrisus", size: 1 },
    13560: { name: "Turael", size: 1 },
    13561: { name: "Mazchna", size: 1 },
    13562: { name: "Duradel", size: 1 },
    13563: { name: "Hazelmere", size: 1 },
    13564: { name: "Hazelmere", size: 1 },
    13565: { name: "Dark Squall", size: 1 },
    13566: { name: "Elite Black Knight", size: 1 },
    13567: { name: "Movario", size: 1 },
    13568: { name: "Movario", size: 1 },
    13569: { name: "Movario", size: 1 },
    13570: { name: "Darve", size: 1 },
    13571: { name: "Darve", size: 1 },
    13572: { name: "Cave goblin", size: 1 },
    13573: { name: "null", size: 1 },
    13574: { name: "Cave goblin", size: 1 },
    13575: { name: "Druid spirit", size: 1 },
    13576: { name: "Druid spirit", size: 1 },
    13577: { name: "Guardian of Armadyl", size: 1 },
    13578: { name: "Guardian of Armadyl", size: 1 },
    13579: { name: "Guardian of Armadyl", size: 1 },
    13580: { name: "Guardian of Armadyl", size: 1 },
    13581: { name: "Idria", size: 1 },
    13582: { name: "Idria", size: 1 },
    13583: { name: "Sithaph", size: 2 },
    13584: { name: "Sakirth", size: 2 },
    13585: { name: "Strisath", size: 3 },
    13586: { name: "<col=00ffff>Strange tube</col>", size: 6 },
    13587: { name: "<col=00ffff>Strange tube</col>", size: 6 },
    13588: { name: "<col=00ffff>Strange tube</col>", size: 6 },
    13589: { name: "<col=00ffff>Strange tube</col>", size: 6 },
    13590: { name: "<col=00ffff>Strange tube</col>", size: 6 },
    13591: { name: "<col=00ffff>Strange tube</col>", size: 6 },
    13592: { name: "<col=00ffff>Strange tube</col>", size: 4 },
    13593: { name: "V", size: 1 },
    13594: { name: "Robert the Strong", size: 1 },
    13595: { name: "Robert the Strong", size: 1 },
    13596: { name: "Aivas", size: 1 },
    13597: { name: "Tristan", size: 1 },
    13598: { name: "Camorra", size: 1 },
    13599: { name: "Tormented Demon", size: 3 },
    13600: { name: "Tormented Demon", size: 3 },
    13601: { name: "Tormented Demon", size: 3 },
    13602: { name: "Tormented Demon", size: 3 },
    13603: { name: "Tormented Demon", size: 3 },
    13604: { name: "Tormented Demon", size: 3 },
    13605: { name: "Tormented Demon", size: 3 },
    13606: { name: "Tormented Demon", size: 3 },
    13607: { name: "Lucien", size: 1 },
    13608: { name: "Lucien", size: 1 },
    13609: { name: "Lucien", size: 1 },
    13610: { name: "null", size: 1 },
    13611: { name: "Debug Man", size: 1 },
    13612: { name: "Debug Woman", size: 1 },
    13613: { name: "Ghommal", size: 1 },
    13614: { name: "Laidee Gnonock", size: 1 },
    13615: { name: "Harrallak Menarous", size: 1 },
    13616: { name: "Sloane", size: 1 },
    13617: { name: "Yadech Strongarm", size: 1 },
    13618: { name: "Turael", size: 1 },
    13619: { name: "Aya", size: 1 },
    13620: { name: "Mazchna", size: 1 },
    13621: { name: "Achtryn", size: 1 },
    13622: { name: "Duradel", size: 1 },
    13623: { name: "Kuradal", size: 1 },
    13624: { name: "Rat", size: 1 },
    13625: { name: "null", size: 1 },
    13626: { name: "null", size: 1 },
    13627: { name: "null", size: 1 },
    13628: { name: "null", size: 1 },
    13629: { name: "null", size: 1 },
    13630: { name: "null", size: 1 },
    13631: { name: "null", size: 1 },
    13632: { name: "null", size: 1 },
    13633: { name: "null", size: 1 },
    13634: { name: "null", size: 1 },
    13635: { name: "null", size: 1 },
    13636: { name: "null", size: 1 },
    13637: { name: "null", size: 1 },
    13638: { name: "null", size: 1 },
    13639: { name: "null", size: 1 },
    13640: { name: "null", size: 1 },
    13641: { name: "null", size: 1 },
    13642: { name: "null", size: 1 },
    13643: { name: "null", size: 1 },
    13644: { name: "null", size: 1 },
    13645: { name: "null", size: 1 },
    13646: { name: "null", size: 1 },
    13647: { name: "null", size: 1 },
    13648: { name: "null", size: 1 },
    13649: { name: "null", size: 1 },
    13650: { name: "null", size: 1 },
    13651: { name: "null", size: 1 },
    13652: { name: "null", size: 1 },
    13653: { name: "null", size: 1 },
    13654: { name: "null", size: 1 },
    13655: { name: "Zembo", size: 1 },
    13656: { name: "Vardorvis", size: 2 },
    13657: { name: "Cerberus", size: 5 },
    13658: { name: "Bee Swarm", size: 3 },
    13659: { name: "Thermonuclear smoke devil", size: 4 },
    13660: { name: "Jaguar warrior", size: 2 },
    13661: { name: "TzTok-Jad", size: 5 },
    13662: { name: "Durial321", size: 1 },
    13663: { name: "Magic Mark", size: 1 },
    13664: { name: "Ranging Ro", size: 1 },
    13665: { name: "Broav", size: 1 },
    13666: { name: "Araxyte", size: 1 },
    13667: { name: "Araxyte", size: 2 },
    13668: { name: "Araxxor", size: 7 },
    13669: { name: "Araxxor", size: 3 },
    13670: { name: "Egg", size: 2 },
    13671: { name: "Mirrorback Araxyte", size: 2 },
    13672: { name: "Egg", size: 2 },
    13673: { name: "Ruptura Araxyte", size: 2 },
    13674: { name: "Egg", size: 2 },
    13675: { name: "Acidic Araxyte", size: 2 },
    13676: { name: "null", size: 3 },
    13677: { name: "Weave", size: 1 },
    13678: { name: "Arancini", size: 1 },
    13679: { name: "Man", size: 1 },
    13680: { name: "Dreadborn Araxyte", size: 3 },
    13681: { name: "Nid", size: 1 },
    13682: { name: "Rax", size: 1 },
    13683: { name: "Nid", size: 1 },
    13684: { name: "Rax", size: 1 },
    13685: { name: "Amoxliatl", size: 3 },
    13686: { name: "Amoxliatl", size: 3 },
    13687: { name: "Amoxliatl", size: 3 },
    13688: { name: "<col=00ffff>Unstable ice</col>", size: 1 },
    13689: { name: "Amoxliatl", size: 3 },
    13690: { name: "Prince Itzla Arkan", size: 1 },
    13691: { name: "Prince Itzla Arkan", size: 1 },
    13692: { name: "Prince Itzla Arkan", size: 1 },
    13693: { name: "Prince Itzla Arkan", size: 1 },
    13694: { name: "Servius, Teokan of Ralos", size: 1 },
    13695: { name: "Queen Zyanyi Arkan", size: 1 },
    13696: { name: "Kualti", size: 1 },
    13697: { name: "Kualti", size: 1 },
    13698: { name: "Kualti", size: 1 },
    13699: { name: "Kualti", size: 1 },
    13700: { name: "Felius", size: 1 },
    13701: { name: "Caritta", size: 1 },
    13702: { name: "Sergius", size: 1 },
    13703: { name: "Sergius", size: 1 },
    13704: { name: "Nova", size: 1 },
    13705: { name: "Nova", size: 1 },
    13706: { name: "Emissary Ascended", size: 1 },
    13707: { name: "Emissary Ascended", size: 1 },
    13708: { name: "Emissary Ascended", size: 1 },
    13709: { name: "Emissary Ascended", size: 1 },
    13710: { name: "Emissary Ascended", size: 1 },
    13711: { name: "Emissary Ascended", size: 1 },
    13712: { name: "Emissary Ascended", size: 1 },
    13713: { name: "Emissary Ascended", size: 1 },
    13714: { name: "Forebearer Janus", size: 1 },
    13715: { name: "Forebearer Fides", size: 1 },
    13716: { name: "Forebearer Fides", size: 1 },
    13717: { name: "Augur Metzli", size: 1 },
    13718: { name: "Furia Tullus", size: 1 },
    13719: { name: "Ennius Tullus", size: 1 },
    13720: { name: "Emissary Ascended", size: 1 },
    13721: { name: "Emissary Ascended", size: 1 },
    13722: { name: "Emissary Acolyte", size: 1 },
    13723: { name: "Emissary Acolyte", size: 1 },
    13724: { name: "Emissary Acolyte", size: 1 },
    13725: { name: "Emissary Acolyte", size: 1 },
    13726: { name: "Forebearer Vulcan", size: 1 },
    13727: { name: "Forebearer Vulcan", size: 1 },
    13728: { name: "Frost Nagua", size: 1 },
    13729: { name: "Emissary Ascended", size: 1 },
    13730: { name: "Emissary Ascended", size: 1 },
    13731: { name: "Forebearer Honos", size: 2 },
    13732: { name: "Emissary Acolyte", size: 1 },
    13733: { name: "Emissary Acolyte", size: 1 },
    13734: { name: "Emissary Acolyte", size: 1 },
    13735: { name: "Emissary Acolyte", size: 1 },
    13736: { name: "Emissary Acolyte", size: 1 },
    13737: { name: "Emissary Acolyte", size: 1 },
    13738: { name: "Emissary Chosen", size: 1 },
    13739: { name: "Emissary Chosen", size: 1 },
    13740: { name: "Emissary Chosen", size: 1 },
    13741: { name: "Emissary Chosen", size: 1 },
    13742: { name: "Emissary Chosen", size: 1 },
    13743: { name: "Emissary Chosen", size: 1 },
    13744: { name: "Emissary Ascended", size: 1 },
    13745: { name: "Emissary Ascended", size: 1 },
    13746: { name: "Emissary Ascended", size: 1 },
    13747: { name: "Emissary Ascended", size: 1 },
    13748: { name: "Emissary Ascended", size: 1 },
    13749: { name: "Emissary Ascended", size: 1 },
    13750: { name: "Emissary Ascended", size: 1 },
    13751: { name: "Emissary Ascended", size: 1 },
    13752: { name: "Emissary Forebearer", size: 1 },
    13753: { name: "Emissary Forebearer", size: 1 },
    13754: { name: "Emissary Forebearer", size: 1 },
    13755: { name: "Emissary Forebearer", size: 1 },
    13756: { name: "Emissary Forebearer", size: 1 },
    13757: { name: "Emissary Forebearer", size: 1 },
    13758: { name: "Emissary Chef", size: 1 },
    13759: { name: "Emissary Fisher", size: 1 },
    13760: { name: "Goat", size: 1 },
    13761: { name: "Goat", size: 1 },
    13762: { name: "Emissary Herder", size: 1 },
    13763: { name: "Emissary Acolyte", size: 1 },
    13764: { name: "Emissary Chosen", size: 1 },
    13765: { name: "Emissary Acolyte", size: 1 },
    13766: { name: "Forebearer Janus", size: 1 },
    13767: { name: "Emissary Ascended", size: 1 },
    13768: { name: "Emissary Ascended", size: 1 },
    13769: { name: "Emissary Ascended", size: 1 },
    13770: { name: "Prince Itzla Arkan", size: 1 },
    13771: { name: "Prince Itzla Arkan", size: 1 },
    13772: { name: "Prince Itzla Arkan", size: 1 },
    13773: { name: "Emissary Brawler", size: 1 },
    13774: { name: "Emissary Brawler", size: 1 },
    13775: { name: "Emissary Brawler", size: 1 },
    13776: { name: "Emissary Brawler", size: 1 },
    13777: { name: "Emissary Conjurer", size: 1 },
    13778: { name: "Emissary Conjurer", size: 1 },
    13779: { name: "Emissary", size: 1 },
    13780: { name: "Tenoch", size: 1 },
    13781: { name: "Silia", size: 1 },
    13782: { name: "Adrius", size: 1 },
    13783: { name: "Eleuia", size: 1 },
    13784: { name: "Prince Itzla Arkan", size: 1 },
    13785: { name: "Prince Itzla Arkan", size: 1 },
    13786: { name: "Prince Itzla Arkan", size: 1 },
    13787: { name: "Frost Nagua", size: 1 },
    13788: { name: "Frost Nagua", size: 1 },
    13789: { name: "Frost Crab", size: 1 },
    13790: { name: "Ice block", size: 1 },
    13791: { name: "Frost Crab", size: 1 },
    13792: { name: "Ice block", size: 1 },
    13793: { name: "Brew'ma", size: 1 },
    13794: { name: "Marika", size: 1 },
    13795: { name: "Brutal blue dragon", size: 4 },
    13796: { name: "Ice giant", size: 2 },
    13797: { name: "Ice giant", size: 2 },
    13798: { name: "Ice spider", size: 1 },
    13799: { name: "Chilled jelly", size: 1 },
    13800: { name: "Cave crawler", size: 2 },
    13801: { name: "Cave crawler", size: 2 },
    13802: { name: "Ice warrior", size: 1 },
    13803: { name: "Hazelmere", size: 1 },
    13804: { name: "Emelio", size: 1 },
    13805: { name: "Emelio", size: 1 },
    13806: { name: "Lelia", size: 1 },
    13807: { name: "Alba", size: 1 },
    13808: { name: "Lucas", size: 1 },
    13809: { name: "Renata", size: 1 },
    13810: { name: "Vincens", size: 1 },
    13811: { name: "Colosseum Announcer", size: 1 },
    13812: { name: "Dire Wolf Alpha", size: 3 },
    13813: { name: "Dire Wolf", size: 2 },
    13814: { name: "Minotaur", size: 3 },
    13815: { name: "Minotaur", size: 2 },
    13816: { name: "Curator Haig Halen", size: 1 },
    13817: { name: "Thief", size: 1 },
    13818: { name: "Patzi", size: 1 },
    13819: { name: "Patzi", size: 1 },
    13820: { name: "Adala", size: 1 },
    13821: { name: "Adala", size: 1 },
    13822: { name: "Adala", size: 1 },
    13823: { name: "Adala", size: 1 },
    13824: { name: "Adala", size: 1 },
    13825: { name: "Constantinius", size: 1 },
    13826: { name: "Constantinius", size: 1 },
    13827: { name: "Cozyac", size: 1 },
    13828: { name: "Cozyac", size: 1 },
    13829: { name: "Xocotla", size: 1 },
    13830: { name: "Xocotla", size: 1 },
    13831: { name: "Pavo", size: 1 },
    13832: { name: "Pavo", size: 1 },
    13833: { name: "Head Butler", size: 1 },
    13834: { name: "Stradius", size: 1 },
    13835: { name: "Hutza", size: 1 },
    13836: { name: "Hutza", size: 1 },
    13837: { name: "Costumer", size: 1 },
    13838: { name: "Naiatli", size: 1 },
    13839: { name: "Clodius", size: 1 },
    13840: { name: "Wandering Guard", size: 1 },
    13841: { name: "null", size: 1 },
    13842: { name: "Smart-looking dog", size: 1 },
    13843: { name: "Smart-looking dog", size: 1 },
    13844: { name: "Patzi", size: 1 },
    13845: { name: "Constantinius", size: 1 },
    13846: { name: "Cozyac", size: 1 },
    13847: { name: "Xocotla", size: 1 },
    13848: { name: "Pavo", size: 1 },
    13849: { name: "Stradius", size: 1 },
    13850: { name: "Hutza", size: 1 },
    13851: { name: "null", size: 1 },
    13852: { name: "Butler", size: 1 },
    13853: { name: "null", size: 1 },
    13854: { name: "Butler", size: 1 },
    13855: { name: "null", size: 1 },
    13856: { name: "Butler", size: 1 },
    13857: { name: "null", size: 1 },
    13858: { name: "Butler", size: 1 },
    13859: { name: "null", size: 1 },
    13860: { name: "Butler", size: 1 },
    13861: { name: "null", size: 1 },
    13862: { name: "Butler", size: 1 },
    13863: { name: "null", size: 1 },
    13864: { name: "Butler", size: 1 },
    13865: { name: "null", size: 1 },
    13866: { name: "Butler", size: 1 },
    13867: { name: "null", size: 1 },
    13868: { name: "Butler", size: 1 },
    13869: { name: "null", size: 1 },
    13870: { name: "Butler", size: 1 },
    13871: { name: "Villa Guard", size: 1 },
    13872: { name: "Man", size: 3 },
    13873: { name: "<col=00ffff>Livius</col>", size: 3 },
    13874: { name: "null", size: 1 },
    13875: { name: "Cleaner", size: 1 },
    13876: { name: "Seer", size: 1 },
    13877: { name: "Cleaner", size: 1 },
    13878: { name: "Cleaner", size: 1 },
    13879: { name: "Cleaner", size: 1 },
    13880: { name: "Cleaner", size: 1 },
    13881: { name: "Cleaner", size: 1 },
    13882: { name: "Cleaner", size: 1 },
    13883: { name: "Citizen", size: 1 },
    13884: { name: "Citizen", size: 1 },
    13885: { name: "Citizen", size: 1 },
    13886: { name: "Citizen", size: 1 },
    13887: { name: "Citizen", size: 1 },
    13888: { name: "Citizen", size: 1 },
    13889: { name: "Citizen", size: 1 },
    13890: { name: "Citizen", size: 1 },
    13891: { name: "Citizen", size: 1 },
    13892: { name: "Citizen", size: 1 },
    13893: { name: "Citizen", size: 1 },
    13894: { name: "Citizen", size: 1 },
    13895: { name: "Citizen", size: 1 },
    13896: { name: "Citizen", size: 1 },
    13897: { name: "Citizen", size: 1 },
    13898: { name: "Citizen", size: 1 },
    13899: { name: "Citizen", size: 1 },
    13900: { name: "Citizen", size: 1 },
    13901: { name: "Citizen", size: 1 },
    13902: { name: "Child", size: 1 },
    13903: { name: "Child", size: 1 },
    13904: { name: "Vineyard worker", size: 1 },
    13905: { name: "Vineyard worker", size: 2 },
    13906: { name: "Vineyard worker", size: 1 },
    13907: { name: "Vineyard worker", size: 1 },
    13908: { name: "Vineyard foreman", size: 1 },
    13909: { name: "Well-dressed man", size: 1 },
    13910: { name: "Cabbage Farmer", size: 1 },
    13911: { name: "Nasty Nick", size: 1 },
    13912: { name: "Fishing spot", size: 1 },
    13913: { name: "Shopkeeper", size: 1 },
    13914: { name: "Faustus", size: 1 },
    13915: { name: "Toci", size: 1 },
    13916: { name: "Antonius", size: 1 },
    13917: { name: "Bartender", size: 1 },
    13918: { name: "null", size: 1 },
    13919: { name: "null", size: 1 },
    13920: { name: "Supervisor Lalo", size: 1 },
    13921: { name: "Supervisor Lalo", size: 1 },
    13922: { name: "Meztlan", size: 1 },
    13923: { name: "Clanila", size: 1 },
    13924: { name: "Tzatelli", size: 1 },
    13925: { name: "Juatil", size: 1 },
    13926: { name: "Alaxxi", size: 1 },
    13927: { name: "Kali", size: 1 },
    13928: { name: "Bartender", size: 1 },
    13929: { name: "Ichta", size: 1 },
    13930: { name: "Necto", size: 1 },
    13931: { name: "Banker", size: 1 },
    13932: { name: "Banker", size: 1 },
    13933: { name: "Guard", size: 1 },
    13934: { name: "Guard", size: 1 },
    13935: { name: "Guard", size: 1 },
    13936: { name: "Citizen", size: 1 },
    13937: { name: "Citizen", size: 1 },
    13938: { name: "Citizen", size: 1 },
    13939: { name: "Citizen", size: 1 },
    13940: { name: "Citizen", size: 1 },
    13941: { name: "Citizen", size: 1 },
    13942: { name: "Citizen", size: 1 },
    13943: { name: "Citizen", size: 1 },
    13944: { name: "Child", size: 1 },
    13945: { name: "Child", size: 1 },
    13946: { name: "Child", size: 1 },
    13947: { name: "Child", size: 1 },
    13948: { name: "Worker", size: 1 },
    13949: { name: "Worker", size: 1 },
    13950: { name: "Worker", size: 1 },
    13951: { name: "Worker", size: 1 },
    13952: { name: "Worker", size: 1 },
    13953: { name: "Worker", size: 1 },
    13954: { name: "Worker", size: 1 },
    13955: { name: "Worker", size: 1 },
    13956: { name: "Overseer Khougo", size: 1 },
    13957: { name: "Capybara", size: 1 },
    13958: { name: "Techichi", size: 1 },
    13959: { name: "Techichi", size: 1 },
    13960: { name: "Techichi", size: 1 },
    13961: { name: "Chiribaya", size: 1 },
    13962: { name: "Chiribaya", size: 1 },
    13963: { name: "Chiribaya", size: 1 },
    13964: { name: "Chiribaya", size: 1 },
    13965: { name: "Locke McScruff", size: 1 },
    13966: { name: "Woofson", size: 1 },
    13967: { name: "Scorpion", size: 1 },
    13968: { name: "Porcupine", size: 1 },
    13969: { name: "Shopkeeper", size: 1 },
    13970: { name: "Salius", size: 1 },
    13971: { name: "Salvager", size: 1 },
    13972: { name: "Salvager", size: 1 },
    13973: { name: "Salvager", size: 1 },
    13974: { name: "Salvager", size: 1 },
    13975: { name: "Salvager", size: 1 },
    13976: { name: "Salvager", size: 1 },
    13977: { name: "Salvager", size: 1 },
    13978: { name: "Chosen Aluinus", size: 1 },
    13979: { name: "Hugh", size: 1 },
    13980: { name: "Izel", size: 1 },
    13981: { name: "Worm Tongue", size: 2 },
    13982: { name: "Worm Tongue", size: 2 },
    13983: { name: "Construction Worker", size: 1 },
    13984: { name: "Antonia", size: 1 },
    13985: { name: "Antonia", size: 1 },
    13986: { name: "Guard", size: 1 },
    13987: { name: "Guard", size: 1 },
    13988: { name: "Guard", size: 1 },
    13989: { name: "Guard", size: 1 },
    13990: { name: "Guard", size: 1 },
    13991: { name: "Guard", size: 1 },
    13992: { name: "Guard", size: 1 },
    13993: { name: "Guard", size: 1 },
    13994: { name: "Guard", size: 1 },
    13995: { name: "Guard", size: 1 },
    13996: { name: "Taala", size: 1 },
    13997: { name: "Seer", size: 1 },
    13998: { name: "Chef", size: 1 },
    13999: { name: "Marla", size: 1 },
    14000: { name: "Guard", size: 1 },
    14001: { name: "Guard", size: 1 },
    14002: { name: "Viero", size: 1 },
    14003: { name: "Worker", size: 1 },
    14004: { name: "Worker", size: 1 },
    14005: { name: "Worker", size: 1 },
    14006: { name: "Worker", size: 1 },
    14007: { name: "Manauia", size: 1 },
    14008: { name: "Seer", size: 1 },
    14009: { name: "The Hueycoatl", size: 7 },
    14010: { name: "The Hueycoatl", size: 7 },
    14011: { name: "The Hueycoatl", size: 7 },
    14012: { name: "The Hueycoatl", size: 7 },
    14013: { name: "The Hueycoatl", size: 7 },
    14014: { name: "Hueycoatl tail", size: 5 },
    14015: { name: "Hueycoatl tail (broken)", size: 5 },
    14016: { name: "null", size: 1 },
    14017: { name: "Hueycoatl body", size: 1 },
    14018: { name: "<col=00ffff>Rubble</col>", size: 1 },
    14019: { name: "null", size: 1 },
    14020: { name: "Bartender", size: 1 },
    14021: { name: "Shopkeeper", size: 1 },
    14022: { name: "Pilgrim", size: 1 },
    14023: { name: "Ezco, Teoki of Ranul", size: 1 },
    14024: { name: "Yolotli, Teoki of Ralos", size: 1 },
    14025: { name: "Worker", size: 1 },
    14026: { name: "null", size: 3 },
    14027: { name: "null", size: 3 },
    14028: { name: "null", size: 3 },
    14029: { name: "Lucrezia", size: 1 },
    14030: { name: "Ercos", size: 1 },
    14031: { name: "Chasm Crawler", size: 3 },
    14032: { name: "Bone Squirrel", size: 1 },
    14033: { name: "Huberte", size: 1 },
    14034: { name: "Moxi", size: 1 },
    14035: { name: "null", size: 1 },
    14036: { name: "Rod Fishing spot", size: 1 },
    14037: { name: "Fishing spot", size: 1 },
    14038: { name: "Fishing spot", size: 1 },
    14039: { name: "Fishing spot", size: 1 },
    14040: { name: "Fishing spot", size: 1 },
    14041: { name: "Fishing spot", size: 1 },
    14042: { name: "Bat", size: 1 },
    14043: { name: "Unicorn", size: 2 },
    14044: { name: "Bone Squirrel", size: 1 },
    14045: { name: "Huberte", size: 1 },
    14046: { name: "Moxi", size: 1 },
    14047: { name: "Gull", size: 2 },
    14048: { name: "null", size: 1 },
    14049: { name: "null", size: 1 },
    14050: { name: "null", size: 1 },
    14051: { name: "null", size: 1 },
    14052: { name: "null", size: 1 },
    14053: { name: "null", size: 1 },
    14054: { name: "null", size: 1 },
    14055: { name: "null", size: 1 },
    14056: { name: "null", size: 1 },
    14057: { name: "null", size: 1 },
    14058: { name: "null", size: 1 },
    14059: { name: "null", size: 1 },
    14060: { name: "null", size: 1 },
    14061: { name: "null", size: 1 },
    14062: { name: "null", size: 1 },
    14063: { name: "null", size: 1 },
    14064: { name: "null", size: 1 },
    14065: { name: "null", size: 1 },
    14066: { name: "null", size: 1 },
    14067: { name: "null", size: 1 },
    14068: { name: "null", size: 1 },
    14069: { name: "null", size: 1 },
    14070: { name: "null", size: 1 },
    14071: { name: "null", size: 1 },
    14072: { name: "null", size: 1 },
    14073: { name: "null", size: 1 },
    14074: { name: "null", size: 1 },
    14075: { name: "null", size: 1 },
    14076: { name: "null", size: 1 },
    14077: { name: "null", size: 1 },
    14078: { name: "null", size: 1 },
    14079: { name: "null", size: 1 },
    14080: { name: "null", size: 1 },
    14081: { name: "null", size: 1 },
    14082: { name: "null", size: 1 },
    14083: { name: "null", size: 1 },
    14084: { name: "null", size: 1 },
    14085: { name: "null", size: 1 },
    14086: { name: "null", size: 1 },
    14087: { name: "null", size: 1 },
    14088: { name: "null", size: 1 },
    14089: { name: "null", size: 1 },
    14090: { name: "null", size: 1 },
    14091: { name: "null", size: 1 },
    14092: { name: "null", size: 1 },
    14093: { name: "null", size: 1 },
    14094: { name: "null", size: 1 },
    14095: { name: "null", size: 1 },
    14096: { name: "null", size: 1 },
    14097: { name: "null", size: 1 },
    14098: { name: "null", size: 3 },
    14099: { name: "null", size: 1 },
    14100: { name: "null", size: 2 },
    14101: { name: "null", size: 1 },
    14102: { name: "null", size: 1 },
    14103: { name: "Blue dragon", size: 4 },
    14104: { name: "Blue dragon", size: 4 },
    14105: { name: "Baby blue dragon", size: 2 },
    14106: { name: "Baby blue dragon", size: 2 },
    14107: { name: "Marika", size: 1 },
    14108: { name: "Postie Pete", size: 1 },
    14109: { name: "Ghost Cat", size: 1 },
    14110: { name: "Crow", size: 1 },
    14111: { name: "null", size: 1 },
    14112: { name: "Stray dog", size: 1 },
    14113: { name: "Armoured zombie", size: 1 },
    14114: { name: "Armoured zombie", size: 1 },
    14115: { name: "Armoured zombie", size: 1 },
    14116: { name: "Armoured zombie", size: 1 },
    14117: { name: "Armoured zombie", size: 1 },
    14118: { name: "Armoured zombie", size: 1 },
    14119: { name: "Armoured zombie", size: 1 },
    14120: { name: "Armoured zombie", size: 1 },
    14121: { name: "Armoured zombie", size: 1 },
    14122: { name: "Armoured zombie", size: 1 },
    14123: { name: "null", size: 1 },
    14124: { name: "null", size: 1 },
    14125: { name: "Golem guard", size: 2 },
    14126: { name: "Small scarab", size: 1 },
    14127: { name: "Zemouregal", size: 2 },
    14128: { name: "Sharathteerk", size: 2 },
    14129: { name: "Arrav", size: 1 },
    14130: { name: "Arrav", size: 2 },
    14131: { name: "Arrav", size: 2 },
    14132: { name: "Arrav", size: 1 },
    14133: { name: "Arrav", size: 1 },
    14134: { name: "null", size: 1 },
    14135: { name: "Armoured zombie", size: 1 },
    14136: { name: "Armoured zombie", size: 1 },
    14137: { name: "Weaponsmaster", size: 1 },
    14138: { name: "Jonny the Beard", size: 1 },
    14139: { name: "Jonny the Beard", size: 1 },
    14140: { name: "Spinolyp", size: 1 },
    14141: { name: "Suspicious water", size: 1 },
    14142: { name: "null", size: 1 },
    14143: { name: "null", size: 2 },
    14144: { name: "The Sage", size: 1 },
    14145: { name: "League Navigator", size: 1 },
    14146: { name: "Black dragon", size: 4 },
    14147: { name: "Eldric the Ice King", size: 3 },
    14148: { name: "Branda the Fire Queen", size: 3 },
    14149: { name: "Eldric the Ice King", size: 3 },
    14150: { name: "Fire elemental", size: 1 },
    14151: { name: "Ice elemental", size: 1 },
    14152: { name: "<col=00ffff>Fire</col>", size: 1 },
    14153: { name: "<col=00ffff>Icicles</col>", size: 1 },
    14155: { name: "Pirate", size: 1 },
    14156: { name: "Pirate", size: 1 },
    14163: { name: "Sadie", size: 1 },
    14165: { name: "Sir Burrus", size: 1 },
    14167: { name: "Archibald", size: 1 },
    14169: { name: "Duke", size: 1 },
    14171: { name: "Kimba", size: 1 },
};
